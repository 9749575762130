import { DOCUMENT, TitleCasePipe } from '@angular/common';
import { OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { AppNavV2Service } from 'src/app/services/app-nav-v2/app-nav-v2.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { AppNavItem, TileLinkType } from 'src/app/shared/models/app-nav-item.model';

@Component({
  selector: 'report-search',
  templateUrl: './report-search.component.html',
  styleUrls: ['./report-search.component.scss']
})
export class ReportSearchComponent implements OnInit {

  @Output() hideAccordion: EventEmitter<void> = new EventEmitter();
  @Output() showAccordion: EventEmitter<void> = new EventEmitter();
  private window: Window;

  public searchValue: string = '';
  public searchResults: AppNavItem[] = [];
  private searchReports: AppNavItem[] = [];

  public showNoResultsFound: boolean = false;
  public shortQuery: boolean = false;
  public showSpinner: boolean = false;

  public readonly spinnerDisplayTimeMs = 500;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private adminHandlerService: AdminHandlerService,
    private appNavV2Service: AppNavV2Service,
    private launchControlService: LaunchControlService,
    private titlecasePipe: TitleCasePipe
  ) {
    this.window = this.document.defaultView as Window;
  }

  async ngOnInit() {
    this.searchReports = await this.appNavV2Service.getAvailableV2SearchReports();
  }

  public onEnter(query: string) {
    this.searchValue = query.trim();

    if (this.searchValue.length > 0) {
      if (this.searchValue.length > 1) {
        this.pendoTrackSearchQuery();

        this.shortQuery = false;
        this.hideAccordion.emit();

        this.showSpinner = true;
        setTimeout(() => {
          this.showSpinner = false;

          this.search(this.searchValue);

          // No results found
          if (this.searchResults.length == 0) {
            this.showNoResultsFound = true;
          }
          else {
            this.showNoResultsFound = false;
          }
        }, this.spinnerDisplayTimeMs);
      }
      else {
        // Search must be at least 2 characters
        this.shortQuery = true;
      }
    }
  }

  private search(query: string) {
    this.searchResults = this.searchReports.filter(report => {
      return report.displayName.toLowerCase().includes(query.toLowerCase()) ||
        report.subHeader?.toLowerCase().includes(query.toLowerCase()) ||
        report.imagePrefix?.toLowerCase().includes(query.toLowerCase());
    });
  }

  public async navigateByLaunchControl(result: AppNavItem) {

    if (result.appCode == ProductAppCodes.Freckle || result.appCode == ProductAppCodes.Lalilo) {
      let deepLink = await this.appNavV2Service.getReportDeepLink(result);
      result.deepLinkId = deepLink.toString();
    }

    if (result.appCode) {
      this.launchControlService.launchApp(result.appCode, result.deepLinkId, true);
    }
  }

  public openInNewTab(url: string) {
    this.window.open(url, '_blank')?.focus();
  }

  // Clear everything but query on back button click
  public back() {
    this.searchResults = [];
    this.showNoResultsFound = false;
    this.shortQuery = false;
    this.showAccordion.emit();
  }

  public clear() {
    this.searchValue = '';
    this.searchResults = [];
    this.showNoResultsFound = false;
    this.shortQuery = false;
    this.showAccordion.emit();
  }

  private pendoTrackSearchQuery() {
    const window = this.document.defaultView as any;
    if (window.pendo) {
      const pendo = window.pendo;
      pendo.track("ReportSearchQuery", {
        searchQuery: this.searchValue
      });
    }
  }

  transformToTitleCase(value: string): string {
    value = value.replace('_', ' ');
    return this.titlecasePipe.transform(value);
  }

  public reportEnabledForUser(report: AppNavItem): boolean {
    if (report.tileLinkType === TileLinkType.FRECKLE_REPORTS_PAGE && this.adminHandlerService.isAdmin()) {
      return false;
    }
    return true;
  }
}
