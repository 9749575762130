import { Component, Input } from '@angular/core';
import { SeenBeforeService } from 'src/app/services/seen-before/seen-before.service';

@Component({
  selector: 'app-seen-before',
  templateUrl: './seen-before.component.html',
  styleUrls: ['./seen-before.component.scss']
})
export class SeenBeforeComponent {
  public seenBefore = false;

  @Input() studentIds: string[] = [];
  @Input() skillId: string = 'unknown';

  constructor(private seenBeforeService: SeenBeforeService) { }

  ngOnInit() {
    this.seenBefore = this.seenBeforeService.hasBeenSeenBefore(this.skillId, this.studentIds);
    this.seenBeforeService.addToSeen(this.skillId, this.studentIds);
  }
}
