import { Component, Input } from "@angular/core";
import { SubjectTypes } from "src/app/services/subject/subject-types";
import { SubjectService } from "src/app/services/subject/subject.service";
import { ClassProducts } from "src/app/shared/models/class.model";
import { Recommendation, RecommendationType } from "src/app/shared/models/recommendation.model";

@Component({
  selector: 'no-recommendation',
  templateUrl: './no-recommendation.component.html',
  styleUrls: ['./no-recommendation.component.scss'],
})
export class NoRecommendationComponent {
  @Input() public recommendations: Recommendation[] = [];
  @Input() public classProducts!: ClassProducts;
  @Input() public studentFirstName!: string;

  public constructor(private subjectService: SubjectService) { }

  public getProduct(products: string[] | undefined): string {
    if (!products) {
      return "";
    }
    return products[0].toLowerCase();
  }

  public haveAnySkillsPracticeRecommendations(recommendations: Recommendation[]): boolean {
    return recommendations.some(recommendation => recommendation.skillsPracticeProducts && recommendation.skillsPracticeProducts.length > 0 && this.hasNoProductLinks(recommendation));
  }

  public haveAnyReadingPracticeRecommendations(recommendations: Recommendation[]): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING && recommendations.some(recommendation => recommendation.readingPracticeProducts && recommendation.readingPracticeProducts.length > 0 && this.hasNoProductLinks(recommendation));
  }

  public showEncourageRecommendation(): boolean {
    return this.classProducts.skillsPracticeProducts.length == 0 &&
           this.recommendations.every(x => x.recommendationType == RecommendationType.None) &&
            (
              this.subjectService.selectedSubject$.getValue() == SubjectTypes.MATH ||
              this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING &&
              (
                this.studentHasAnyReadingPractice(this.recommendations) ||
                this.classProducts.readingPracticeProducts.length == 0
              )
            );
  }

  public displaySkillProductHelperText(): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING && this.classProducts.skillsPracticeProducts.length > 1;
  }

  public displayReadingProductHelperText(): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING && this.classProducts.readingPracticeProducts.length > 1;
  }

  public hasNoProductLinks(recommendation: Recommendation): boolean {
    return Object.keys(recommendation.productActionLinks).length === 0;
  }

  public isSkillsPracticeRecommendation(recommendation: Recommendation): boolean {
    return !!recommendation.skillsPracticeProducts && recommendation.skillsPracticeProducts.length > 0;
  }

  public isReadingPracticeRecommendation(recommendation: Recommendation): boolean {
    return !!recommendation.readingPracticeProducts && recommendation.readingPracticeProducts.length > 0;
  }

  public studentHasAnyReadingPractice(recommendations: Recommendation[]): boolean {
    return recommendations.some(recommendation => recommendation.studentHasReadingPractice);
  }
}
