import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { AppNavDynamicLinkingService } from 'src/app/services/app-nav-v2/app-nav-dynamic-linking.service';
import { AppNavV2ItemConfigurationService } from 'src/app/services/app-nav-v2/app-nav-v2-item-configuration.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { AppNavItemIds } from 'src/app/shared/models/app-nav-item.model';
import { ProductKeys, ActionLink, Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { Link } from 'src/app/util/link/link';
import { ActionClickEvent } from './action-click-event';

@Component({
  selector: 'take-action-links-v2',
  templateUrl: './take-action-links.component.html',
  styleUrls: ['./take-action-links.component.scss'],
})
export class TakeActionLinksComponentV2 implements OnInit {

  @Input() public recommendation!: Recommendation;
  @Output() public actionClick = new EventEmitter<ActionClickEvent>();
  @Input () public category: string = '';

  public freckleLink?: ActionLink;
  public nearpodLink?: ActionLink;
  public laliloLink?: ActionLink;
  public savvasLink?: ActionLink;
  public pendoId = '';
  private window: Window;

  constructor(
    public adminHandlerService: AdminHandlerService,
    private launchControlService: LaunchControlService,
    private appNavDynamicLinkingService: AppNavDynamicLinkingService,
    private appNavV2ItemConfigurationService: AppNavV2ItemConfigurationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView as Window;
  }

  public ngOnInit(): void {
    this.setNearpodLink();
    this.setFreckleLink();
    this.setLaliloLink();
    this.setSavvasLink();

    switch (this.recommendation.recommendationType) {
      case RecommendationType.Appropriate:
        this.pendoId = 'pendo-reassign-skill';
        break;
      case RecommendationType.NeedsChallenge:
        this.pendoId = 'pendo-assign-next-skill';
        break;
      case RecommendationType.NeedsHelp:
        this.pendoId = 'pendo-assign-pre-requisite-skill';
        break;
    }
    this.category = this.category.replace(/'/g, '');    // Sanitize the category to remove any single quotes
  }

  private async setNearpodLink() {
    this.nearpodLink = this.recommendation.productActionLinks[ProductKeys.Nearpod] ?? undefined;
  }

  private setFreckleLink() {
    this.freckleLink = this.recommendation.productActionLinks[ProductKeys.Freckle] ?? undefined;
  }

  private setLaliloLink() {
    this.laliloLink = this.recommendation.productActionLinks[ProductKeys.Lalilo] ?? undefined;
  }

  private setSavvasLink() {
    this.savvasLink = this.recommendation.productActionLinks[ProductKeys.Savvas] ?? undefined;
  }

  public navigateFreckleByLaunchControl(event: Event, link: Link) {
    event.preventDefault();
    this.launchControlService.launchApp(ProductAppCodes.Freckle, link.url, true);
  }

  public navigateLaliloByLaunchControl(event: Event, link: Link) {
    event.preventDefault();

    //there is a lalilo link to a help page which should not use launch control
    if (link.url === 'https://lalilo-help.renaissance.com/hc/en-us/categories/8781333653019-Getting-Started') {
      this.window.open(link.url, '_blank');
      return;
    }

    this.launchControlService.launchApp(ProductAppCodes.Lalilo, link.url, true);
  }

  public navigateToProduct(event: Event, link: Link, type: string, isLaunchApp: boolean) {
    if (isLaunchApp) {
      if (type === 'freckleLink') {
        this.navigateFreckleByLaunchControl(event, link);
      }
      if (type === 'laliloLink') {
        this.navigateLaliloByLaunchControl(event, link);
      }
    }

    if (type === 'savvasLink') {
      this.savvasNavigation(event);
    }

    this.actionClick.emit({ type, isClicked: true });
  }

  public savvasNavigation(event: Event) {
    event.preventDefault();
    const otherApps = this.appNavV2ItemConfigurationService.getOtherAppNavs();
    const savvasAppNavItem = otherApps.find(app => app.id === AppNavItemIds.SAVVAS);

    if (savvasAppNavItem) {
      this.appNavDynamicLinkingService.navigateToDynamicApp(savvasAppNavItem, this.recommendation);
    }
  }
}
