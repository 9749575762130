import { Injectable } from '@angular/core';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { StudentContext } from 'src/app/shared/models/student.model';
import { ProductAppDisplayNames } from '../../product-info/product-info.service';
import { SubjectTypes } from '../../subject/subject-types';
import { SubjectService } from '../../subject/subject.service';
import { NudgeTextService } from './nudge-text/nudge-text.service';
import { FeatureToggleService } from '../../feature-toggle/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class NoSkillsRecommendationService {

  constructor(
    private subjectService: SubjectService,
    private nudgeTextService: NudgeTextService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getNoSkillsRecommendation(selectedStudent: StudentContext, classProducts: ClassProducts): Promise<Recommendation> {
    let noRecentActivityText = this.getNoRecentActivityText(selectedStudent, classProducts);

    let enableUpdatedNoRecommendations = await this.featureToggleService.isTrueAsync('enable-updated-no-recommendation');
    if (!enableUpdatedNoRecommendations) {
      let noSkillsRecommendationText = this.getNoSkillsRecommendationText(selectedStudent, classProducts);
      let noSkillsRecommendation: Recommendation = {
        rationale: noRecentActivityText,
        textContent: noSkillsRecommendationText,
        skillMetadata: null,
        recommendationType: RecommendationType.None,
        productActionLinks: {},
        nudgeText: {}
      };
      return noSkillsRecommendation;
    }

    let subject = this.subjectService.selectedSubject$.getValue();
    let nudgeText = this.nudgeTextService.getNudgeText(selectedStudent, classProducts, subject);

    let noSkillsRecommendation: Recommendation = {
      rationale: noRecentActivityText,
      nudgeText: nudgeText,
      skillMetadata: null,
      recommendationType: RecommendationType.None,
      productActionLinks: {},
      skillsPracticeProducts: classProducts.skillsPracticeProducts,
      readingPracticeProducts: classProducts.readingPracticeProducts,
      studentHasReadingPractice: this.studentHasReadingPractice(selectedStudent)
    };

    return noSkillsRecommendation;
  }

  private getNoRecentActivityText(student: StudentContext, classProducts: ClassProducts): string {
    let assessmentProducts = classProducts.assessmentProducts;
    let skillsPracticeProducts = classProducts.skillsPracticeProducts;

    if (assessmentProducts.length > 0) {
      if (skillsPracticeProducts.length > 1) {
        return `${student.firstName} has no recent activity in Star, ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
      if (skillsPracticeProducts.length > 0) {
        return `${student.firstName} has no recent activity in Star or ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
      return `${student.firstName} has no recent activity in Star`;
    }
    if (skillsPracticeProducts.length > 0) {
      return `${student.firstName} has no recent activity in ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
    }
    return '';
  }

  private getNoSkillsRecommendationText(student: StudentContext, classProducts: ClassProducts): string {
    let studentName = student.firstName;
    let subject = this.subjectService.selectedSubject$.getValue();
    let skillsPracticeProducts = classProducts.skillsPracticeProducts;
    let readingPracticeProducts = classProducts.readingPracticeProducts;

    if (subject == SubjectTypes.MATH) {
      if (skillsPracticeProducts.includes(ProductAppDisplayNames.Freckle)) {
        return `Have ${studentName} log in to Freckle to start working on adaptive ${subject.toLocaleLowerCase()} practice.`;
      }
    }

    if (subject == SubjectTypes.READING) {
      if (readingPracticeProducts.length > 0 && !this.studentHasReadingPractice(student)) {
        if (skillsPracticeProducts.length > 0) {
          return `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}, ` +
            `and log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`;
        }
        return `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}.`
      }
      if (skillsPracticeProducts.length == 1) {
        if (skillsPracticeProducts.includes(ProductAppDisplayNames.Freckle)) {
          return `Have ${studentName} log in to Freckle to start working on adaptive ELA practice.`; //special case for Freckle as it's ELA, not reading
        }
        return `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} ` +
          `to start working on adaptive ${subject.toLocaleLowerCase()} practice.`
      }
      if (skillsPracticeProducts.length > 1) {
        return `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`
      }
    }
    return `Practice makes perfect. Encourage ${studentName} to practice their ${subject.toLocaleLowerCase()} skills regularly.`
  }

  private getReadingProductsDisplayString(readingProducts: string[]): string {
    return readingProducts.join(' and ');
  }

  private getSkillProductsDisplayString(skillProducts: string[]): string {
    return skillProducts.join(' or ');
  }

  private studentHasReadingPractice(student: StudentContext): boolean {
    return student.readingPractices.length > 0;
  }
}
