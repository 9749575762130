import { Injectable } from '@angular/core';
import { EducatorPortalInterappRedirectService } from '../educator-portal-interapp-redirect-service/educator-portal-interapp-redirect-service';

@Injectable({
  providedIn: 'root'
})
export class MyonLinkGeneratorService {

  constructor(
    private educatorPortalInterappRedirectService: EducatorPortalInterappRedirectService
  ) { }

  public getReportsPageLink(): string {
    let myonReportsDeepLinkId = 'MYON_REPORTS';

    let redirectUrl = this.getEducatorPortalMyonRedirectUrl(myonReportsDeepLinkId);

    return redirectUrl;
  }

  private getEducatorPortalMyonRedirectUrl(myonReportsDeepLinkId: string) {
    let taskId = '4900';
    let applicationid = '49';

    return this.educatorPortalInterappRedirectService.getLaunchAndRedirectDeepLinkUrl(taskId, applicationid, myonReportsDeepLinkId);
  }
}
