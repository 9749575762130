import { Injectable } from '@angular/core';
import { EducatorPortalInterappRedirectService } from '../educator-portal-interapp-redirect-service/educator-portal-interapp-redirect-service';

@Injectable({
  providedIn: 'root'
})
export class StarPhonicsLinkGeneratorService {

  constructor(
    private educatorPortalInterappRedirectService: EducatorPortalInterappRedirectService
  ) { }

  public getReportsPageLink(): string {
    let domain = this.getStarphonicsDomain();

    // ex:
    // https://starphonics.renaissance.com/dashboard/reports

    let starPhonicsUrl = `https://${domain}/dashboard/reports`;

    let redirectUrl = this.getEducatorPortalStarPhonicsRedirectUrl(starPhonicsUrl);

    return redirectUrl;
  }

  private getEducatorPortalStarPhonicsRedirectUrl(starPhonicsUrl: string) {
    let taskId = '6900';
    let applicationid = '69';

    return this.educatorPortalInterappRedirectService.getLaunchAndRedirectUrl(taskId, applicationid, starPhonicsUrl);
  }

  private getStarphonicsDomain(): string {
    return `starphonics.renaissance.com`;
  }
}
