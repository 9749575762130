<div class="header-loading-container">
  <header class="rlheader">
    <div class="rlheader__cells flx-container rlheader__cells--wider">
      <div class="rlheader__cells__cell--logo">
        <img class="biglogo" id="rlheader-logo" src="../../../assets/renaissance-learning-horizontal.svg">
        <img class="smalllogo" id="rlheader-logosmall" src="../../../assets/r-logo.svg">
      </div>

      <nav role="navigation" class="rlheader__cells__cell rlheader__cells__cell--nav hide-on-print">
        <ul class="nav-list">
          <li class="nav-list__li">
            <button id="nav-help" class="nav-list__li__badge nav-list__li__badge--icon glyph-header-help shimmer">
            </button>
          </li>
          <li class="nav-list__li  nav-list__li--appnav">
            <button id="nav-appnav" class="nav-list__li__badge nav-list__li__badge--icon glyph-header-waffle-menu shimmer">
            </button>
          </li>
          <li class="nav-list__li">
            <button id="nav-user" class="nav-list__li__badge nav-list__li__badge--text">
              <span class="glyph-generic-user-avatar shimmer"></span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</div>
