<footer>
  <div class="tj-footer-container">
    <div class="tj-links-non-desktop">
        <span class="glyph glyph-bubble6"></span>
        <a id="send-feedback-mobile" class="footer-send-feedback" href="#" (click)="pendoTrackFeedback($event)">Send feedback</a>
        <span class="spacer">
        |
        </span>
        <span class="glyph glyph-lamp2"></span>
        <a class="learn-more-link"
          href="https://www.renaissance.com/products/"
          target="_blank"
          aria-label="Learn more (link opens in a new tab)">Learn More
        </a>
    </div>
    <div class="footer-title">
      <div class="copy-notice">© {{getCurrentYear()}} Renaissance Learning, Inc. All rights reserved. </div>
      <div>
          <ul>
              <li><a [href]="getSystemStatusLink()" target="_blank" aria-label="System Status (link opens in a new tab)">System Status</a></li>
          </ul>
      </div>
    </div>
  </div>
</footer>
