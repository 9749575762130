import { ApolloLink, Operation, NextLink } from '@apollo/client/core';
import { visit, Kind, DirectiveNode } from 'graphql';
import { HasuraCacheService } from '../hasura-cache/hasura-cache.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

const CACHE_TIME = environment.apiCacheTtl;
// Cached directive objects for ttl and refresh
const directiveMap = {
  refresh: {
    // @cached(refresh:true)
    kind: 'Argument',
    name: { kind: 'Name', value: 'refresh' },
    value: { kind: 'BooleanValue', value: true },
  },
  ttl: {
    // @cached(ttl: CACHE_TIME)
    kind: 'Argument',
    name: { kind: 'Name', value: 'ttl' },
    value: { kind: 'IntValue', value: `${CACHE_TIME}` },
  },
} as { [key: string]: any };

@Injectable({ providedIn: 'root' })
export class GraphQLMiddlewareService {
  constructor(private cacheService: HasuraCacheService) {}

  // Middleware to override the cached directive arguments based on the cache directive type
  cachedDirectiveMiddleware: ApolloLink = new ApolloLink(
    (op: Operation, forward: NextLink) => {
      const cacheDirectiveType = this.cacheService.getCacheDirectiveType(
        op.operationName
      );

      op.query = visit(op.query, {
        Directive(node: DirectiveNode) {
          if (node.name.value !== 'cached') {
            return node;
          }

          return {
            kind: Kind.DIRECTIVE,
            name: { kind: Kind.NAME, value: 'cached' },
            arguments: [directiveMap[cacheDirectiveType]],
          } as DirectiveNode;
        },
      });

      return forward(op);
    }
  );
}
