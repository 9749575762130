import { Injectable } from "@angular/core";
import { Assessment } from "src/app/shared/models/assessment.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { LexileHelperService } from "src/app/util/lexile-helper/lexile-helper.service";
import { StarHelperService } from "src/app/util/star-helper/star-helper.service";

@Injectable({ providedIn: 'root' })
export class StarLexileCalculatorService {
  constructor(
    private lexileHelperService: LexileHelperService
  ) { }

  public calculateStarLexile(assessments: Assessment[]): number | null {
    const englishReadingAssessments = assessments.filter(assessment => StarHelperService.IsEnglishStarReadingProductId(assessment.productId));

    if (englishReadingAssessments.length === 0) {
      return null;
    }

    const latestAssessment = englishReadingAssessments.reduce((latest, assessment) => {
      return new Date(assessment.completedDate) > new Date(latest.completedDate) ? assessment : latest;
    }, englishReadingAssessments[0]);

    if (!latestAssessment || !DateHelper.isWithinThreeMonthsOfToday(latestAssessment.completedDate)) {
      return null;
    }

    const numericLexile = this.lexileHelperService.convertLexileToNumber(latestAssessment.lexile);

    return numericLexile;
  }

}
