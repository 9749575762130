import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideBarExpanderService {
  private expanded: boolean = false;
  private _expanded$ = new BehaviorSubject(false);
  public expanded$ = this._expanded$.asObservable();

  constructor() { }

  toggleExpand() {
    this.expanded = !this.expanded;
    this._expanded$.next(this.expanded);
  }
}
