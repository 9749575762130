<div class="degraded-container renaissance-style-scoped">
  <object type="image/svg+xml" role="presentation" alt="" class="oops-image"
      data="../assets/hero-something_went_wrong-ani.svg"></object>
  <div class="oops-message-container">
    <div class="oops-message-header">
      Something went wrong
    </div>
    <div class="oops-message-content">
      We can't show your data until we are done fixing the issue.
    </div>
    <a class="ren-btn" href="/summary">Try Again</a>
  </div>
</div>
