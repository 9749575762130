import { AfterViewInit, Component, Input, OnChanges, Host, Optional } from '@angular/core';
import { SkillMetadata } from '../models/skill-metadata.model';


@Component({
  selector: 'app-skill-description',
  templateUrl: './skill-description.component.html',
  styleUrls: ['./skill-description.component.scss'],
})
export class SkillDescriptionComponent implements OnChanges, AfterViewInit {
  @Input() public skillMetadata: SkillMetadata | null = null;
  @Input() public hasAmbiguousShortName: boolean = false;
  @Input() public isModalHeading: boolean = false;
  @Input()  public isSummary: boolean = true; 
  @Input() public isActionMode: boolean = true;
  @Input() public isSkillsFewer: boolean = true;
  @Input() public isTakeActionSum: boolean = true;
  public doShowFullName: boolean = false;
  public shortName: string = '';
  public skillId: string = '';
  public doneLoading = false;
  public skillUUID: string = '';

  public ngOnChanges(): void {
    if (this.skillMetadata) {
      if (this.hasAmbiguousShortName) {
        this.shortName = `${this.skillMetadata.skillShortName} - ${this.skillMetadata.skillProgressionOrder}`;
      } else {
        this.shortName = this.skillMetadata.skillShortName;
      }
      this.skillId = this.skillMetadata.renaissanceSkillId;
      this.skillUUID = 'skill-desc-' + this.skillId + '-' + crypto.randomUUID();
    }
  }

  public ngAfterViewInit() {
    // make sure dom is loaded before we detect clampage
    Promise.resolve().then(() => (this.doneLoading = true));
  }

  public getElementId(): string {
    return this.doShowFullName
      ? 'skill-desc-long-' + this.skillId
      : this.skillUUID;
  }

  // New method to return the content, either shortName or full name
  public getDescriptionContent(): string {
    return this.doShowFullName ? this.getFullName() : this.shortName;
  }

  public getFullName(): string {
    if (this.skillMetadata) {
      return this.skillMetadata.skillName;
    }
    return '';
  }

  public getProductName(): string {
    let productName = '';

    if (this.skillMetadata) {
      switch (this.skillMetadata.productName.toUpperCase()) {
        case 'FRECKLE': {
          productName = 'Freckle';
          break;
        }
        case 'LALILO': {
          productName = 'Lalilo';
          break;
        }
      }
    }

    return productName;
  }

  public showFullDescription() {
    this.doShowFullName = true;
  }

  public expandClampedDescription() {
    const el = document.getElementById(this.skillUUID);
    if (el) {
      el.classList.remove('clamp');
    }
  }

  public isTextClamped(): boolean {
    const el = document.getElementById(this.skillUUID);
    if (el) {
      return el.scrollHeight > el.clientHeight + 7;
    }
    return false;
  }
}
