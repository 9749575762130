import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, take } from 'rxjs';
import { Superstar, SuperstarResponse } from 'src/app/shared/models/superstar.model';
import { environment } from 'src/environments/environment';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SuperstarDeleteRequest, SuperstarDeleteRequestStudent, SuperstarSaveRequest, SuperstarSaveRequestStudent } from './superstar.history.model';

@Injectable({
  providedIn: 'root'
})
export class SuperstarHistoryService {

  private readonly classEndpoint: string = '/v1/class';
  private readonly deleteClassEndpoint: string = '/v1/deleteclass';

  constructor(
    private httpClient: HttpClient
  ) { }

  public async getSuperstarHistory(classId: string): Promise<SuperstarResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let params = new HttpParams()
      .set('class', classId);

    let superstarResponse = await firstValueFrom(this.httpClient.get(environment.superstarUrl + this.classEndpoint, {
      headers: headers,
      params: params
    }).pipe(take(1)));

    return superstarResponse as SuperstarResponse;
  }

  public saveSuperstars(superstars: Superstar[], classId: string) {
    const currentMondayDate = DateHelper.getCurrentWeekMondayDateString();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Need to save 25 superstars at a time to fit dynamo's BatchWriteItem limit
    // https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_BatchWriteItem.html
    const batchLimit = 25;
    let batchSize = 0;

    let superstarSaveRequest: SuperstarSaveRequest = {
      classId: classId,
      mostRecentMonday: currentMondayDate,
      students: [] as SuperstarSaveRequestStudent[]
    };

    superstars.forEach(async superstar => {
      superstarSaveRequest.students.push({
        studentId: superstar.student.renaissanceId,
        qualifications: superstar.qualifications,
        category: superstar.category
      });

      batchSize++;

      // Save 25 superstars at a time
      if (batchSize === batchLimit) {
        this.httpClient.post<SuperstarSaveRequest>(environment.superstarUrl + this.classEndpoint, superstarSaveRequest, { headers: headers }).subscribe();

        batchSize = 0;
        superstarSaveRequest.students = [];
      }
    });

    // Save any remaining superstars
    if (batchSize > 0) {
      this.httpClient.post<SuperstarSaveRequest>(environment.superstarUrl + this.classEndpoint, superstarSaveRequest, { headers: headers }).subscribe();
    }
  }

  public deleteSuperstars(students: StudentContext[], classId: string) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Need to delete 25 superstars at a time to fit dynamo's BatchWriteItem limit
    // https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_BatchWriteItem.html
    const batchLimit = 25;
    let batchSize = 0;

    let superstarDeleteRequest: SuperstarDeleteRequest = {
      classId: classId,
      students: [] as SuperstarDeleteRequestStudent[]
    };

    students.forEach(async student => {
      superstarDeleteRequest.students.push({
        studentId: student.renaissanceId
      });

      batchSize++;

      if (batchSize === batchLimit) {
        this.httpClient.post<SuperstarDeleteRequest>(environment.superstarUrl + this.deleteClassEndpoint, superstarDeleteRequest, { headers: headers }).subscribe();

        batchSize = 0;
        superstarDeleteRequest.students = [];
      }
    });

    // Delete any remaining superstars
    if (batchSize > 0) {
      this.httpClient.post<SuperstarDeleteRequest>(environment.superstarUrl + this.deleteClassEndpoint, superstarDeleteRequest, { headers: headers }).subscribe();
    }
  }
}
