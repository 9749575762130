import { Student } from "src/app/shared/models/student.model";

export class StudentSorter {
    
  public static sortStudents(students: Student[]) {
    students.sort((a, b) => this.compareStudents(a, b));
  }

  private static compareStudents(student1: Student, student2: Student): number {
    if (student1.lastName < student2.lastName) {
      return -1;
    }

    if (student1.lastName > student2.lastName) {
      return 1;
    }

    if (student1.lastName == student2.lastName) {
      if (student1.firstName < student2.firstName) {
        return -1;
      }

      if (student1.firstName > student2.firstName) {
        return 1;
      }
    }
  
    return 0;
  }
}
