import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';


@Injectable({
  providedIn: 'root',
})
/**
 * Service to manage the cache for Hasura queries
 *
 * Cache is stored in local storage as a key value pair of query name and last updated time
 *  example key value pair: { queryName1: lastUpdatedTime, queryName2: lastUpdatedTime, ... }
 *
 * If the cache is stale, the query will be forced to refresh using the @cached(refresh:true) directive
 * If the cache is not stale, the query will use the @cached(ttl: CACHE_TIME) directive
 * To force a refresh of all the queries, call setForceRefresh() which will set the cache to an empty object
 *
 */
export class HasuraCacheService {
  constructor(private storageService: LocalStorageService) {}

  private CACHE_KEY = 'h_cache.last_updated';
  private REFRESH_INTERVAL = 43200000; // 12 hours in milliseconds

  private FORCE_REFRESH_DIRECTIVE = `refresh`;
  private TTL_REFRESH_DIRECTIVE = `ttl`;

  // Set the cache to an empty object to force a refresh of all queries
  setForceRefresh = () =>
    this.storageService.setItem(this.CACHE_KEY, JSON.stringify({}));

  // Get the cache from local storage, if it doesn't exist, return an empty object
  getRefreshCache = (): { [key: string]: string } => {
    const lastUpdated = this.storageService.getItem(this.CACHE_KEY) || '{}';

    return JSON.parse(lastUpdated);
  };

  // Set the last updated time for a query
  setRefreshCacheForQuery = (queryName: string) => {
    const currentTime = new Date().getTime();
    const currentCache = this.getRefreshCache();
    currentCache[queryName] = currentTime.toString();

    this.storageService.setItem(this.CACHE_KEY, JSON.stringify(currentCache));
  };

  // Refresh the cache for a query if it is stale
  shouldRefreshCache = (queryName: string): boolean => {
    const refreshCache = this.getRefreshCache();
    const queryLastUpdated = refreshCache[queryName];

    // If the cache is stale, refresh
    if (queryLastUpdated) {
      const currentTime = new Date().getTime();
      const timeSinceLastUpdate = currentTime - parseInt(queryLastUpdated, 10);
      return timeSinceLastUpdate > this.REFRESH_INTERVAL;
    }

    //If the query is not in the cache, refresh. This will occur:
    // * on the first query
    // * if we emptied the cache (forcing a refresh of all queries)
    return true;
  };

  // Get the cache directive type (ttl, refresh) for the query
  getCacheDirectiveType = (queryName: string): string => {
    if (this.shouldRefreshCache(queryName)) {
      // Set the refresh time for the query
      this.setRefreshCacheForQuery(queryName);
      return this.FORCE_REFRESH_DIRECTIVE;
    }

    //Use the TTL directive
    return this.TTL_REFRESH_DIRECTIVE;
  };
}
