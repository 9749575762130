import { StudentContext } from "src/app/shared/models/student.model";
import { Injectable } from "@angular/core";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { ProductIds, ProductIdsByGroup, ProductInfoService } from "../../product-info/product-info.service";

@Injectable({
  providedIn: 'root'
})
export class SuperstarEffortImprovementService {

  constructor(private productInfoService: ProductInfoService) { }

  public getEffortImprovementQualifications(student: StudentContext): string[] {
    let qualifications: string[] = [];

    // 3 qualifiers for Effort Improvement:
    // Weekly increase in minutes spent on practice product
    const practiceProductTimeSpentQualified = this.qualifyPracticeProductTimeSpent(student);
    if (practiceProductTimeSpentQualified) {
      qualifications.push(practiceProductTimeSpentQualified);
    }

    // Weekly increase in practice / assessment product sessions
    const productSessionsQualified = this.qualifyProductSessionCount(student);
    if (productSessionsQualified) {
      qualifications.push(productSessionsQualified);
    }

    // Weekly increase in words read
    const wordsReadQualified = this.qualifyWordsRead(student);
    if (wordsReadQualified) {
      qualifications.push(wordsReadQualified);
    }

    return qualifications;
  }

  private qualifyPracticeProductTimeSpent(student: StudentContext): string {
    let qualification: string = '';

    let studentPracticeProducts = new Set<string>();
    let qualifiedPracticeProducts = new Set<string>();

    let lastWeekTimeSpentSeconds = 0;
    let oldTimeSpentSeconds = 0;

    // Skill practice
    const practices = student.skills.filter(practice => {
      if (ProductIdsByGroup.PracticeProductIds.includes(practice.productId)) {
        studentPracticeProducts.add(practice.productId);
        return true;
      };
      return false;
    });

    if (practices.length > 0) {

      // Split practice up by week
      const lastWeekPractices = practices.filter(practice => DateHelper.isWithinOneWeekOfToday(practice.lastPracticedDate, true));
      const oldPractices = practices.filter(practice =>
        !lastWeekPractices.includes(practice) && DateHelper.isWithinTwoWeeksOfToday(practice.lastPracticedDate, true)
        );

      studentPracticeProducts.forEach(productId => {
        let lastWeekProductTimeSpentSeconds = 0;
        let oldProductTimeSpentSeconds = 0;

        const lastWeekProductPractices = lastWeekPractices.filter(practice => practice.productId === productId);
        const oldProductPractices = oldPractices.filter(practice => practice.productId === productId);

        lastWeekProductPractices.forEach(practice => {
          lastWeekProductTimeSpentSeconds += (practice.durationInMinutes * 60); // Convert to seconds
        });

        oldProductPractices.forEach(practice => {
          oldProductTimeSpentSeconds += (practice.durationInMinutes * 60); // Convert to seconds
        });

        // Weekly practice product time spent improvement
        if (lastWeekProductTimeSpentSeconds > oldProductTimeSpentSeconds) {
          qualifiedPracticeProducts.add(productId);
          lastWeekTimeSpentSeconds += lastWeekProductTimeSpentSeconds;
          oldTimeSpentSeconds += oldProductTimeSpentSeconds;
        }
      });
    }

    // Reading practice - only includes Myon for now, since only Myon keeps track of time spent
    if (student.readingPractices.map(practice => practice.product).includes(ProductIds.Myon)) {
      qualifiedPracticeProducts.add(ProductIds.Myon);

      // Add time spent on reading practice products last week
      lastWeekTimeSpentSeconds += student.readingPracticeSummary.secondsReadWithinSevenDays;

      // Add time spent on reading practice products before last week
      oldTimeSpentSeconds += student.readingPracticeSummary.secondsReadWithinEightToFourteenDays;
    };

    // Weekly practice product time spent improvement
    if (lastWeekTimeSpentSeconds > oldTimeSpentSeconds) {
      const productsForDisplay = this.formatProductsForDisplay(qualifiedPracticeProducts);
      const minutesImprovement = Math.round((lastWeekTimeSpentSeconds - oldTimeSpentSeconds) / 60);

      qualification = `Practiced in ${productsForDisplay} by ${minutesImprovement} more minutes in the last 7 days`;
    }

    return qualification;
  }

  private qualifyProductSessionCount(student: StudentContext): string {
    let qualification: string = '';

    let studentQualifiedProducts = new Set<string>();
    let lastWeekSessionsCount = 0;
    let oldSessionsCount = 0;

    const skillPractices = student.skills;

    // Split activity up by week
    const lastWeekSkillPractices = skillPractices.filter(practice => DateHelper.isWithinOneWeekOfToday(practice.lastPracticedDate, true));
    const oldSkillPractices = skillPractices.filter(practice =>
      !lastWeekSkillPractices.includes(practice) && DateHelper.isWithinTwoWeeksOfToday(practice.lastPracticedDate, true)
    );

    // Calculate skill practice product session count
    const skillPracticeProductIds = new Set<string>(skillPractices.map(practice => practice.productId));

    skillPracticeProductIds.forEach(productId => {
      const lastWeekProductSessionsCount = lastWeekSkillPractices.filter(practice => practice.productId === productId).length;
      const oldProductSessionsCount = oldSkillPractices.filter(practice => practice.productId === productId).length;

      if (lastWeekProductSessionsCount > oldProductSessionsCount) {
        // Add skill practice product to qualified
        studentQualifiedProducts.add(productId);
        lastWeekSessionsCount += lastWeekProductSessionsCount;
        oldSessionsCount += oldProductSessionsCount;
      }
    });


    // Weekly product session count improvement
    if (lastWeekSessionsCount > oldSessionsCount) {
      const productsForDisplay = this.formatProductsForDisplay(studentQualifiedProducts);
      const sessionsImprovement = lastWeekSessionsCount - oldSessionsCount;

      qualification = `Practiced ${sessionsImprovement} more ${this.formatSessionStringForDisplay(sessionsImprovement)} in ${productsForDisplay} in the last 7 days`;
    }

    return qualification;
  }

  private qualifyWordsRead(student: StudentContext): string {
    let qualification: string = '';

    const lastWeekWordsRead = student.readingPracticeSummary.wordsReadWithinSevenDays;
    const oldWordsRead = student.readingPracticeSummary.wordsReadWithinEightToFourteenDays;

    // Biweekly words read improvement
    if (lastWeekWordsRead > oldWordsRead) {
      const wordsReadImprovement = lastWeekWordsRead - oldWordsRead;

      qualification = `Read ${wordsReadImprovement} more words in the last 7 days`;
    }

    return qualification;
  }

  private formatProductsForDisplay(productIds: Set<string>): string {
    if (productIds.size === 0)
      return '';

    let productDisplayNames = Array.from(productIds).map(productId => this.productInfoService.GetProductName(productId));

    const lastProductDisplayName = productDisplayNames.pop();

    return productDisplayNames.length > 0
      ? `${productDisplayNames.join(', ')} and ${lastProductDisplayName}`
      : `${lastProductDisplayName}`;
  }

  private formatSessionStringForDisplay(sessionCount: number): string {
    return sessionCount === 1 ? 'session' : 'sessions';
  }
}
