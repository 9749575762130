import { Injectable } from '@angular/core';
import { School } from 'src/app/shared/models/school.model';
import { GraphqlService } from '../graphql/graphql.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  private _selectedSchool$ = new BehaviorSubject<School | undefined>(undefined);
  public get selectedSchool$(): BehaviorSubject<School | undefined> { return this._selectedSchool$ }
  private schools: School[] | undefined;

  constructor(
    private graphqlService: GraphqlService
  ) { }

  public async getSchools(): Promise<School[]> {
    if (!this.schools) {
      this.schools = (await this.graphqlService.getSchools()).slice()
      this.schools.sort(this.sortSchools);
    }

    return this.schools;
  }

  private sortSchools(aSchool: School, bSchool: School) {
    return aSchool.schoolName.localeCompare(bSchool.schoolName) || aSchool.schoolRenaissanceId.localeCompare(bSchool.schoolRenaissanceId);
  }
}
