import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewRelicInstrumentationService } from 'src/app/services/new-relic-instrumentation/new-relic-instrumentation.service';
import { SearchResult } from '../models/search-result.model';
import { SearchService } from '../services/search.service';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'search-results-v2',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewChecked {
  public isInitialLoad: boolean = true;
  public isLoading: boolean = false;
  public isSearchboxScroll: boolean = false;
  public moreResults: boolean = true;
  public newlyAddedFirstElementID: string = '';
  public pageNumber: number = 1;
  public query: string = '';
  public searchConstants: any = CONSTANTS;
  public searchResults: SearchResult[] = [];
  public showOops: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private newRelicInstrumentationService: NewRelicInstrumentationService,
    private searchService: SearchService
  ) { }

  // For search results table drop shadow.
  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    this.isSearchboxScroll = event.srcElement.scrollTop > 0;
  }

  async ngOnInit(): Promise<void> {
    console.log('results v2');
    this.setupSearch();
  }

  public ngAfterViewChecked(): void {
    this.focusNewlyAddedElement();
    this.cdr.detectChanges();
  }

  public async setupSearch(): Promise<void> {
    this.subscriptions.push(this.searchService.searchFilters$.subscribe(async () => {
      if (this.isInitialLoad) {
        this.searchService.subscribeToQueryParams();
        await this.searchService.productLicensingCheck();
        this.pageNumber = 1;
        this.isInitialLoad = false;
      }

      this.search();
    }));

    this.subscriptions.push(this.searchService.query$.subscribe(async (query: string) => {
      this.query = query;
      await this.search();
    }));
  }

  public async search(): Promise<void> {
    this.isLoading = true;
    this.searchResults = [];
    this.showOops = false;
    this.pageNumber = 1;

    await this.handleSearch();
  }

  public async handleSearch() {
    this.moreResults = true;

    try {
      const searchResults = await this.searchService.getSortedSearchResults(this.pageNumber);

      if (searchResults.length > 0) {
        if (this.pageNumber === 1) {
          this.setInitialSearchResults(searchResults);
        }
        else {
          this.appendSearchResults(searchResults);
        }

        this.newlyAddedFirstElementID = searchResults[0].product_skill_id.toString();
      }
      else {
        this.hideMoreResultsButton();
      }

      this.isLoading = false;
    }
    catch (error: any) {
      this.isLoading = false;
      this.showOops = true;
      this.newRelicInstrumentationService.noticeError(error);
      this.newRelicInstrumentationService.recordSearchDegradedInfo(`search-error-${error.name}`);
      console.error(error);
    }
  }

  public setInitialSearchResults(searchResults: SearchResult[]): void {
    this.searchResults = searchResults;
  }

  public appendSearchResults(searchResults: SearchResult[]): void {
    this.searchResults = this.searchResults.concat(searchResults);
  }

  public hideMoreResultsButton(): void {
    this.moreResults = false;
  }

  public focusNewlyAddedElement(): void {
    if (this.newlyAddedFirstElementID) {
      const element = document.getElementById(this.newlyAddedFirstElementID);
      if (element) {
        element.focus();
        this.newlyAddedFirstElementID = '';
      }
    }
  }

  public async getMoreResults(): Promise<void> {
    this.pageNumber++;
    await this.handleSearch();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
