import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AvailableAppNavService } from 'src/app/services/app-nav/available-app-nav.service';
import { IntroAnimationService } from 'src/app/services/intro-animation/intro-animation.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { UserService } from 'src/app/services/user/user.service';
import { DateTime } from 'luxon';
import { DateHelper } from 'src/app/util/date-helper/date-helper';

@Component({
  selector: 'loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent {
  private readonly messages = [
    "Make every moment count!",
    "Aim high and achieve!",
    "Success is a journey!",
    "Persistence leads to progress!",
    "Every day you make a difference!",
    "Celebrate progress!",
    "Connect with your students daily!",
    "Your influence is immeasurable!",
    "Believe in your students' potential!",
    "Teach with kindness and compassion!",
    "A positive attitude is contagious!",
    "Empower your students with knowledge!",
    "Celebrate the joy of learning!",
    "Encourage curiosity and creativity!",
    "Teaching is a journey, enjoy it!",
    "Believe in the power of connection!",
    "You are making a positive impact!",
    "Your teaching is making a difference!",
    "You've got this!",
    "Your efforts matter!",
    "Keep up the great work!",
    "Stay strong, teach on!",
    "Your dedication shapes lives!",
    "Ignite curiosity, fuel success!",
    "Today's lesson, tomorrow's future!",
    "Empower minds, change the world!",
    "We're grateful for your work!",
    "We're thankful for your passion!",
    "Your dedication is truly appreciated!",
    "Thank you for all you do!",
    "You are awakening curiosity!",
    "By teaching you will learn!",
    "Teaching is the art of assisting discovery!",
    "Education can change the world!",
    "You are inspiring!",
    "You are igniting imaginations!",
    "You're brave, strong, and smart!",
    "Get up and make opportunities today!",
    "You can help someone today!",
    "It is the courage to continue that counts!"
  ];

  public message: string = "";
  public showLoadingSpinner: boolean = true;
  public showLoadingAnimation: boolean = false;
  public introAnimationEnabled: boolean = false;
  private lastSeenKey: string = "lastSeenLoadingAnimation";
  public readonly spinnerOnlyLengthMs = 1000;
  public readonly animationLengthMs = 4000;
  public readonly animationPlusFadeOutLengthMs = 5000;
  public fadeOut: boolean = false;
  public isLoggedIn: boolean = false;
  public isAppNavLoaded: boolean = false;
  public spinnerMinimumDone: boolean = false;

  private subscriptions: Subscription[] = [];

  public constructor(
    private _localStorage: LocalStorageService,
    private _userService: UserService,
    private _appNavService: AvailableAppNavService,
    private _introAnimationService: IntroAnimationService
  ) { }

  ngOnInit() {
    let subscription = this._introAnimationService.introAnimationEnabled$.subscribe((enabled: boolean | undefined) => {
      if (enabled) {
        this.introAnimationEnabled = true;
      }
    });
    this.subscriptions.push(subscription);

    setTimeout(() => { this.triggerWelcomeAndMarkSpinnerDone() }, this.spinnerOnlyLengthMs);

    this.message = this.messages[Math.floor(Math.random() * this.messages.length)];

    this._userService.isLoggedIn().then(x=> this.isLoggedIn = true);
    this._appNavService.isLoaded().then(x=> this.isAppNavLoaded = true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private hasBeenSeenToday(): boolean {
    const maybeSeenToday = this._localStorage.getItem(this.lastSeenKey);
    let today = DateTime.now().toFormat(DateHelper.DISPLAY_DATE_FORMAT);

    // key hasn't been seen or date doesn't match today
    if (!maybeSeenToday || maybeSeenToday !== today) {
      this._localStorage.setItem(this.lastSeenKey, today);
      return false;
    }

    return true;
  }

  private triggerWelcomeAndMarkSpinnerDone() {
    let hasBeenSeenToday = this.hasBeenSeenToday();
    if (!hasBeenSeenToday && !this.introAnimationEnabled) {
      this.showLoadingAnimation = true;
      this.showLoadingSpinner = false;
      setTimeout(() => { this.fadeOut = true }, this.animationLengthMs);
      setTimeout(() => { this.showLoadingAnimation = false }, this.animationPlusFadeOutLengthMs);
    }

    this.spinnerMinimumDone = true;
  }
}
