import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { MyOnLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/myon-link-generator-v2.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';


export interface DeepLinkContext {
  studentRenaissanceId: string;
  classRenaissanceId: string;
  schoolRenaissanceId: string;
  subject: string;
}


@Component({
  selector: 'product-deep-link',
  templateUrl: './product-deep-link.component.html',
  styleUrls: ['./product-deep-link.component.scss']
})
export class ProductDeepLinkComponent {

  @Input() zoneHomeUri: string = '';
  @Input() type: 'myon' | 'ar' | 'star' = 'ar';
  @Input() context?: DeepLinkContext;
  private window: Window;
  private readonly logoAssetBase = "../../../../../../../assets/logos/" as const;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private launchControlService: LaunchControlService,
    private myonLinkGeneratorV2Service: MyOnLinkGeneratorV2Service,
  ) {
    this.window = this.document.defaultView as Window;
  }

  get imageLink() {
    switch (this.type) {
      case 'ar': return this.logoAssetBase + 'ar_logo.svg';
      case 'myon': return this.logoAssetBase + 'myon_logo.svg';
      case 'star': return this.logoAssetBase + 'star_logo.svg';
      default: throw Error('Invalid deep link image type');
    }
  }

  get labelText() {
    switch (this.type) {
      case 'ar': return 'AR Record Book';
      case 'myon': return 'myON Dashboard';
      case 'star': return 'Star Record Book';
      default: throw Error('Invalid label text type');
    }
  }

  private getStarBaseUriFromZoneUri() {
    if (this.zoneHomeUri.search("https://global-") == -1) {
      throw new Error('invalid Uri');
    }
    else {
      const zone = this.zoneHomeUri.slice(this.zoneHomeUri.indexOf("global-") + 7, this.zoneHomeUri.indexOf("."));
      const restOfDomain = this.zoneHomeUri.slice(this.zoneHomeUri.indexOf("renaissance"), this.zoneHomeUri.indexOf("com") + 3);
      return `https://${zone}-educator.${restOfDomain}/record-book/student/overall/`;
    }
  }

  private getRGPBaseUriFromZoneUri() {
    if (this.zoneHomeUri.search("https://global-") === -1 || this.zoneHomeUri.search("educatorportal") === -1) {
      throw new Error('invalid Uri');
    }
    else {
      const zoneBase = this.zoneHomeUri.split('educatorportal')[0];
      return zoneBase;
    }
  }

  public async launchLink() {
    switch (this.type) {
      case 'ar': return this.linkToArRecordBook();
      case 'myon': return await this.linkToMyOn();
      case 'star': return this.linkToStarRecordBook();
      default: throw Error('Invalid launch link type');
    }
  }


  public linkToStarRecordBook() {
    const student = this.context?.studentRenaissanceId;
    const school = this.context?.schoolRenaissanceId;
    const clazz = this.context?.classRenaissanceId;

    const subject = this.context?.subject.toLowerCase();
    const baseUri = this.getStarBaseUriFromZoneUri();

    const deepLinkUrl = `${baseUri}${subject}/gs_${school}/gs_${clazz}/gs_${student}/record-book`;
    this.window.open(deepLinkUrl, '_blank');
  }

  public linkToArRecordBook() {
    const student = this.context?.studentRenaissanceId;
    const school = this.context?.schoolRenaissanceId;
    const clazz = this.context?.classRenaissanceId;

    const arBaseUri = this.getRGPBaseUriFromZoneUri() + 'recordbook/ar/students/goals?'

    const deepLinkeUrl = `${arBaseUri}school_guid=gs_${school}&class_guid=gs_${clazz}&student_guid=gs_${student}`;
    this.window.open(deepLinkeUrl, '_blank');
  }

  public linkToMyOn() {
    let deepLink = this.myonLinkGeneratorV2Service.getDeepLink(this.context?.studentRenaissanceId);
    this.launchControlService.launchApp(ProductAppCodes.Myon, deepLink, true);
  }

  public onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.launchLink();
    }
  }
}
