<app-searchbox-wrapper></app-searchbox-wrapper>
<div class='home-container'>
    <app-tab-selector [selectedTab]="'math'"></app-tab-selector>

    <section class="summer-math-container">
        <section class="summer-math-educators-container">
            <img class="hello-educators-img" alt="Hello Educators"
                src="../../../assets/summer_math_hello_educators.png">
            <div class="summer-math-educators-description">
                <h2 id="skip-nav" tabindex="-1">Hello Educators!</h2>
                <p>
                    Renaissance is excited to continue to partner with you as we deliver high quality content
                    to you and your students. Keep your students thriving this summer by utilizing some of
                    these resources.
                </p>
                <p>
                    Going forward, Renaissance will continue to improve your experience through the
                    curation of valuable math resources to support your daily instruction. Share your
                    thoughts with us. Your feedback makes us stronger.
                </p>
                <div data-test="share-your-feedback-button">
                    <button type="button" id="pendo-share-your-feedback" class="ren-btn ren-btn--secondary share-your-feedback-button">Share Your
                        Feedback
                    </button>
                </div>
            </div>
        </section>

        <section class="summer-math-guide-container">
            <div class="left-block">
                <h2>Freckle Grade 3 Summer Math Guide</h2>
                <p>
                    Encourage math practice in any environment with time-saving, turnkey resources and DIY challenge ideas.
                    Materials for students and families are available in English and Spanish.
                </p>
                <div class="guide-contains-container">
                    <span>What it contains (English and Spanish):</span>
                    <ul>
                        <li>Family Letter</li>
                        <li>Student Login Card Template</li>
                        <li>Goal Sheet Templates</li>
                        <li>Practice Planner Templates</li>
                        <li>Competitive and Personal Challenges</li>
                        <li>Depth of Knowledge Challenges</li>
                        <li>Student Certificate Templates</li>
                    </ul>
                </div>
                <div class="download-guide" id="pendo-download-the-kit">
                    <a id="pendo-download-the-kit-icon" class="download-icon-container" href="https://renaissance.widen.net/s/6qwtknmqdd/727899_2024_sum_engagmentkits_freckle" target="_blank">
                        <img src="../../../assets/download-summer-guide.svg">
                    </a>
                    <a id="pendo-download-the-kit-link" href="https://renaissance.widen.net/s/6qwtknmqdd/727899_2024_sum_engagmentkits_freckle" target="_blank">Download the Kit</a>
                </div>
            </div>
            <div class="right-block">
                <img src="../../../assets/summer_guide.png">
            </div>
        </section>

        <section class="freckle-summer-plan-container">
            <section class="freckle-summer-plan">
                <div class="sp-info-container">
                    <h2>Freckle Grade 3 Six-Week Summer Plan (CCSS)</h2>
                    <div class="sp-info1">This plan is designed to keep rising Grade 4 students fresh with the content already learned in Grade 3. Alternatively, it can be used by rising Grade 3 students who are independent learners in preparation for subsequent Grade 3 learning.</div>
                    <div class="sp-info2">
                        <span class="sp-text">You can use this schedule in conjunction with the resources in the Summer Math
                            Guide.</span>
                        <span class="sp-note">Note: you can assign any of the activities below by navigating to Freckle. For others, such as Fact Practice and Adaptive practice, the student will need to log in and navigate there directly.</span>
                        <div class="assign-practice-container">
                            <span class="glyph-info"></span>
                            <a href="https://frecklehelp.renaissance.com/hc/en-us/articles/10478053719707-How-can-I-assign-Freckle-practice-to-my-students" target="_blank">How to assign practice</a>
                        </div>
                    </div>
                </div>
                <div class="download-pdf-container" data-test="download-pdf">
                    <a href="https://renaissance.widen.net/s/knhjqtqn9c/grade3_summerplan_sample" target="_blank" id="pendo-download-pdf" class="ren-btn ren-btn--secondary download-pdf">Download PDF
                    </a>
                </div>
            </section>

            <section class="summer-plan-container">

                <div class="summer-plan-header">
                    <div class="week-header">Week</div>

                    <div class="sp-mid-header">
                        <span class="header-day">Day</span>
                        <span class="header-fa1">Freckle Activity 1</span>
                        <span class="header-fa2">Freckle Activity 2</span>
                    </div>

                    <div class="sff-header">Suggestions for Families</div>
                </div>

                <div class="summer-plan-content" *ngFor="let item of summerPlanData">
                    <div class="data-seperator"></div>

                    <div class="summer-plan-data">
                        <div class="week-data">
                            <h2>{{item.weekData.sno}}</h2>
                            <span>{{item.weekData.data}}</span>
                            <span *ngIf="item.weekData.data2" class="text-italic">{{item.weekData.data2}}</span>
                        </div>

                        <div class="summer-plan-table-container">
                            <div class="mid-data-container" *ngFor="let midItem of item.middleData">
                                <div class="data-day">
                                    {{midItem.day}}
                                </div>
                                <div class="data-fa1">
                                    <span class="text-type1">{{midItem.frackleActivity1.data1}}</span>
                                    <span *ngIf="!midItem.frackleActivity1.data2Ref"
                                        class="text-type2">{{midItem.frackleActivity1.data2}}</span>
                                </div>
                                <div class="data-fa2">
                                    <ng-container *ngIf="!midItem.frackleActivity2.data2">
                                        <span class="text-type2">{{midItem.frackleActivity2.data1}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="midItem.frackleActivity2.data2">
                                        <span class="text-type1">{{midItem.frackleActivity2.data1}}</span>
                                        <span class="text-type2">{{midItem.frackleActivity2.data2}}</span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="suggestion-family-data">
                            <p>
                                <span class="option-heading">Option 1: </span>
                                <span class="option-value">{{item.suggestionForFamilyData.option1Data}}</span>
                            </p>
                            <p>
                                <span class="option-heading">Option 2: </span>
                                <span class="option-value">{{item.suggestionForFamilyData.option2Data}}</span>
                            </p>
                        </div>
                    </div>

                </div>

                <div class="summer-plan-content last-no-content">
                    <div class="data-seperator"></div>
                </div>

            </section>
        </section>
    </section>

    <app-footer></app-footer>
</div>
