import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { PendoService } from 'src/app/services/pendo/pendo.service';
import { ProductAppTags } from 'src/app/services/product-info/product-info.service';
import { environment } from 'src/environments/environment';
import { KeyValues } from '../models/key-values.model';
import { ProductSettings } from '../models/products-settings.model';
import { SearchFilter, SearchFilterOptions } from '../models/search-filters';
import { SearchResult } from '../models/search-result.model';
import { PRODUCT_SETTINGS } from '../settings/products.constants';
import * as CONSTANTS from '../settings/search.constants';

@Injectable({
  providedIn: 'root'
})
export class SearchService implements OnDestroy {
  public productSettings = PRODUCT_SETTINGS;
  public query$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchFilters$: BehaviorSubject<SearchFilter[]> = new BehaviorSubject<SearchFilter[]>(CONSTANTS.SEARCH_FILTERS_SETTINGS);

  private subscriptions: Subscription[] = [];

  constructor(
    private classesService: ClassesService,
    private featureToggleService: FeatureToggleService,
    private http: HttpClient,
    private licenseInfoService: LicenseInfoService,
    private pendoService: PendoService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public subscribeToQueryParams(): void {
    const urlParamsSub = this.route.queryParams.subscribe(params => {
      const query = params['q'];
      if (query?.length > 0) {
        this.query$.next(query);
      }
    });

    this.subscriptions.push(urlParamsSub);
  }

  public getFilterOptionsFromSettings(filterDisplayName: string): SearchFilterOptions[] {
    return CONSTANTS.SEARCH_FILTERS_SETTINGS.find(filter =>
      filter.settings.filterDisplayName === filterDisplayName)?.options || [];
  }

  public updateSearchFilters(searchFilters: SearchFilter[]): void {
    this.searchFilters$.next(searchFilters);
    this.clearQueryParamsThenSearchNavigate();
  }

  public clearQueryParamsThenSearchNavigate(route: string = `/` + CONSTANTS.ROUTER.SEARCH): void {
    this.pendoService.sendEvent('SearchQuery', { searchQuery: this.query$.value });

    this.router.navigate([], { relativeTo: this.route, queryParams: {} }).then(() => {
      const searchFiltersQueryParams = this.formatSearchFiltersForQueryParams();
      const queryParams: Params | null = route === `/` + CONSTANTS.ROUTER.SEARCH ?
        { q: this.query$.value, ...searchFiltersQueryParams } : null;
      this.router.navigate([route], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    });
  }

  public formatSearchFiltersForQueryParams(): { [key: string]: any; } {
    return this.searchFilters$.value.reduce((filters: { [key: string]: any }, filter) => {
      const key = filter.settings.formControlName;
      const selectedValues = filter.options.filter(option => option.isSelected).map(option => option.displayName);
      const availableOptions = filter.options.filter(option => option.isAvailable);
      const allSelected = availableOptions.length > 0 && availableOptions.every(option => option.isSelected);
      const noneSelected = availableOptions.every(option => !option.isSelected);

      if (!allSelected && !noneSelected) {
        filters[key] = selectedValues;
      }

      return filters;
    }, {});
  }

  public async getSortedSearchResults(pageNumber: number): Promise<SearchResult[]> {
    const standardSets = await this.classesService.getStandardSetsForClass();
    const tags = this.convertFiltersToTags();
    const searchQuery = { 'textQuery': this.query$.value, 'standardSets': standardSets, tags, 'pageNumber': pageNumber };
    const searchResponse = await firstValueFrom(this.http.post<SearchResult[]>(environment.searchLambdaUrl, searchQuery));
    const productResults = this.initializeProductResults();
    const uniqueResults = new Set<string>();

    for (const result of searchResponse) {
      if (!uniqueResults.has(result.product_skill_id)) {
        uniqueResults.add(result.product_skill_id);
        await this.addResultToProductResults(result, productResults);
      }
    }

    const searchResults = Object.values(productResults).flat();
    return searchResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 20);
  }

  public convertFiltersToTags(): KeyValues[] {
    return this.searchFilters$.value.reduce((acc: any, filter) => {
      if (filter.options.length === 0) {
        return acc;
      }

      const selectedOptions = filter.options.filter((option: SearchFilterOptions) => option.isSelected)
        .map((option: SearchFilterOptions) => option.value);

      if (selectedOptions.length > 0) {
        acc.push({ key: filter.settings.formControlName, value: selectedOptions });
      }

      return acc;
    }, []);
  }

  public updateQuery(query: string): void {
    this.query$.next(query);
    this.clearQueryParamsThenSearchNavigate();
  }

  public initializeProductResults(): { [key: string]: SearchResult[] } {
    const productResults: { [key: string]: SearchResult[] } = {};

    for (const productKey in PRODUCT_SETTINGS) {
      productResults[productKey] = [];
    }

    return productResults;
  }

  public async addResultToProductResults(result: SearchResult, productResults: { [key: string]: SearchResult[] }): Promise<void> {
    const productKey = Object.keys(this.productSettings).find((key) =>
      this.productSettings[key as keyof typeof PRODUCT_SETTINGS].sourceId === result.source);
    if (productKey) {
      const productSetting = this.productSettings[productKey as keyof typeof PRODUCT_SETTINGS];

      if (productSetting.licensedAppTags && productSetting.licensedAppTags.length > 0) {
        const hasLicense = await this.classesLicensedToUseProducts(productSetting.licensedAppTags);
        if (hasLicense) {
          productResults[productKey].push(result);
        }
      } else {
        productResults[productKey].push(result);
      }
    }
  }

  private compareResultsByDistance(a: SearchResult, b: SearchResult): number {
    return a.distance - b.distance;
  }

  private async classesLicensedToUseProducts(tags: ProductAppTags[]): Promise<boolean> {
    const classes = await this.classesService.getClasses();
    return classes!.some(c => tags.some(tag => c.classAppTags.includes(tag.toString())));
  }

  public async productLicensingCheck(): Promise<void> {
    const searchFilters = this.searchFilters$.getValue();

    for (const filter of searchFilters) {
      if (filter.settings.formControlName === 'products') {
        filter.options = await Promise.all(
          filter.options.map(async option => {
            try {
              const productSetting = this.productSettings[option.value];
              const isLicensed = await this.productAvailable(productSetting, false);
              const isAllowed = await this.productAvailable(productSetting, true);

              return isLicensed && isAllowed ? option : null;
            } catch (error) {
              return null;
            }
          })
        ).then(options => options.filter(option => option !== null) as SearchFilterOptions[]);
      }
    }
  }

  public async productAvailable(productSetting: ProductSettings, isFeatureToggle: boolean): Promise<boolean> {
    const productLicensing = isFeatureToggle ?
      productSetting?.productLicensing?.featureToggle : productSetting?.productLicensing?.licenseMethod;
    let productAvailable = true;

    if (productLicensing && productLicensing.length > 0) {
      try {
        productAvailable = await Promise.all(productLicensing.map(licensing => {
          if (isFeatureToggle) {
            return this.featureToggleService.isTrueAsync(licensing);
          } else {
            return this.licenseInfoService[licensing as keyof LicenseInfoService]();
          }
        })).then(results => results.every(result => result));
      } catch (error) {
        productAvailable = false;
      }
    }

    return productAvailable;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
