// Dates from Snowflake from each product/logo have different formats
// We need to account for these format differences ourselves until there exists a unified format

// Ex: Freckle lastPracticedDate: "2023-06-12 16:22:30.308 Z"
// Ex: Star Math lastAssessmentDate: "2023-06-12 16:22:30.308"

import { DateTime } from "luxon";

export abstract class DateHelper {
  public static DISPLAY_DATE_FORMAT = 'M/d/yyyy';

  public static parseDate(dateToTest: string): DateTime {
    return DateTime.fromJSDate(new Date(dateToTest.replace(/\sZ/, "")));
  }

  public static compareByDate(a: string, b: string) {
    let aDate = DateHelper.parseDate(a);
    let bDate = DateHelper.parseDate(b);

    if (aDate > bDate) {
      return -1;
    }
    if (aDate < bDate) {
      return 1;
    }
    return 0;
  }

  public static isWithinOneWeekOfToday(dateToTest: string | DateTime | undefined | null, pastDaysOnly: boolean = false): boolean {
    return this.isWithinDaysOfToday(dateToTest, 7, pastDaysOnly);
  }

  public static isWithinTwoWeeksOfToday(dateToTest: string | DateTime | undefined | null, pastDaysOnly: boolean = false): boolean {
    return this.isWithinDaysOfToday(dateToTest, 14, pastDaysOnly);
  }

  public static isWithinThreeMonthsOfToday(dateToTest: string | DateTime | undefined | null, pastDaysOnly: boolean = false): boolean {
    return this.isWithinDaysOfToday(dateToTest, 90, pastDaysOnly);
  }

  private static isWithinDaysOfToday(dateToTest: string | DateTime | undefined | null, daysAgo: number, pastDaysOnly: boolean = false): boolean {
    if (dateToTest === undefined || dateToTest === null) {
      return false;
    }

    if (typeof dateToTest == "string") {
      dateToTest = DateHelper.parseDate(dateToTest);
    }

    const daysAgoMs = daysAgo * 24 * 60 * 60 * 1000; // days ago in ms

    // Set now to the start of the day so shown 2 week old skills do not disappear throughout the day
    let todaysDate = DateTime.now().startOf('day');

    let difference = dateToTest.diff(todaysDate, "milliseconds").milliseconds;

    if (pastDaysOnly) {
      if (difference > 0) {
        return false;
      }
    }

    difference = Math.abs(difference);

    return difference < daysAgoMs;
  }

  public static getCurrentWeekMondayDate(): DateTime {
    return DateTime.now().startOf('week');
  }

  public static getCurrentWeekMondayDateString(): string {
    return this.getCurrentWeekMondayDate().toFormat('yyyy-MM-dd');
  }

  public static getCurrentWeekFridayDate(): DateTime {
    return DateTime.now().startOf('week').plus({ days: 4 });
  }

  public static snowflakeDateToDisplayDate(snowflakeDate: string): string {
    const date = DateTime.fromSQL(snowflakeDate).toFormat(this.DISPLAY_DATE_FORMAT);

    if (!date || date === 'Invalid DateTime') {
      console.error(`Invalid date format: ${snowflakeDate}`);

      return snowflakeDate
    }

    return date
  }
}
