import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';

@Component({
  selector: 'search-intro-animation',
  templateUrl: './search-intro-animation.component.html',
  styleUrls: ['./search-intro-animation.component.scss']
})
export class SearchIntroAnimationComponent implements OnInit {

  public showIntroAnimation: boolean = false;
  public fadeOut: boolean = false;
  public readonly delayBeforeAnimationStartLengthMs = 1000;
  public readonly animationLengthMs = 5500;
  public readonly animationLengthAndFadeoutMs = 6000;

  public subscriptions: Subscription[] = [];

  constructor(private _introAnimationService: IntroAnimationService) { }

  async ngOnInit() {
    let subscription = this._introAnimationService.leftNavAnimationStatus$.subscribe((animationStatus: IntroAnimationStatus | undefined) => {
      if (animationStatus) {
        this.triggerIntroAnimation(animationStatus);
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public triggerIntroAnimation(leftNavAnimationStatus: IntroAnimationStatus) {
    if (leftNavAnimationStatus.complete) {
      if (leftNavAnimationStatus.enabled) {
        let animationStatus: IntroAnimationStatus = {
          enabled: true,
          complete: true
        };
        setTimeout(() => { this.showIntroAnimation = true; }, this.delayBeforeAnimationStartLengthMs);
        setTimeout(() => { this.fadeOut = true }, this.animationLengthMs);
        setTimeout(() => {
          this.showIntroAnimation = false;
          this._introAnimationService.updateSearchIntroAnimationStatus(animationStatus);
        }, this.animationLengthAndFadeoutMs);
      }
      else {
        let animationStatus: IntroAnimationStatus = {
          enabled: false,
          complete: true
        };
        this._introAnimationService.updateSearchIntroAnimationStatus(animationStatus);
      }
    }
  }
}
