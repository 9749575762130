import { Injectable } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { Recommendation } from 'src/app/shared/models/recommendation.model';
import { SavvasLinkGeneratorService } from '../link-generators-v2/savvas-link-generator.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavDynamicLinkingService {
  private validServiceNames: string[] = ['savvasLinkGeneratorService'];
  private validMethodNames: string[] = ['appLaunch'];

  constructor(private savvasLinkGeneratorService: SavvasLinkGeneratorService) { }

  public navigateToDynamicApp(appNavItem: AppNavItem, recommendation?: Recommendation) {
    const { navigateServiceName, navigateMethodName } = appNavItem;

    const serviceName = navigateServiceName && this.validServiceNames.includes(navigateServiceName) ?
      this[navigateServiceName as keyof AppNavDynamicLinkingService] : undefined;

    const methodName = serviceName && navigateMethodName && this.validMethodNames.includes(navigateMethodName) &&
      typeof serviceName[navigateMethodName as keyof typeof serviceName] === 'function' ?
      navigateMethodName : undefined;

    if (serviceName && methodName) {
      const skillId = recommendation?.skillMetadata?.rlSkillId ? [recommendation.skillMetadata.rlSkillId] : undefined;
      skillId ? (serviceName[navigateMethodName as keyof typeof serviceName] as Function)(skillId) :
        (serviceName[navigateMethodName as keyof typeof serviceName] as Function)();
    } else {
      throw new Error(`Unable to resolve linking for Other App: ${appNavItem.displayName}`);
    }
  }
}
