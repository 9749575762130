import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';
import { FilterOptionValues, FilterOptions } from './filter-skills/filter-skills.component';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';

@Component({
  selector: 'skills-practice-list',
  templateUrl: './skills-practice-list.component.html',
  styleUrls: ['./skills-practice-list.component.scss']
})
export class SkillsPracticeListComponent implements OnInit, OnChanges {
  @Input() public skillsPracticeCards: SkillsPracticeCard[] = [];
  @Input() public inactiveStudents: StudentContext[] = [];
  public sortStrategy: FilterOptionValues = FilterOptions.MostStuck;
  public originalSkillPracticeCards: SkillsPracticeCard[] = [];

  ngOnInit() {
    // default sort
    this.sort(this.sortStrategy);
  }

  ngOnChanges() {
    this.originalSkillPracticeCards = this.skillsPracticeCards.slice();
    this.sort(this.sortStrategy);
  }

  isStudentStuck(student: StudentContext) {
    const minSkillAccuracyRate = SkillTests.getEffectiveMinSkillAccuracyRate(student.skills);
    return SkillTests.isStuckSkillAccuracyRate(minSkillAccuracyRate);
  }

  countStuckStudents(students: StudentContext[]): number {
    return students.filter(this.isStudentStuck).length;
  }

  sortByStuck() {
    this.skillsPracticeCards.sort((a, b) => {
      // first sort alphabetically
      return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
    });
    this.skillsPracticeCards.sort((a,b) => {
      // sort by # of stuck students
      if (b.stuckStudents.length !== 0 || a.stuckStudents.length !== 0) {
        return b.stuckStudents.length - a.stuckStudents.length;
      }
      // if neither have stuck students
      else  {
        // if one has practice makes perfect and the other doesn't, the one with it is first
        let bHasPracticeMakesPerfect = b.practiceMakesPerfectStudents.length !== 0;
        let aHasPracticeMakesPerfect = a.practiceMakesPerfectStudents.length !== 0;
        if (bHasPracticeMakesPerfect && !aHasPracticeMakesPerfect) {
          return 1;
        }
        else if (!bHasPracticeMakesPerfect && aHasPracticeMakesPerfect) {
          return -1;
        }
        // if neither have practice makes perfect students
        // if one has ready for challenge and the other doesn't, the one with it is first
        else if (!bHasPracticeMakesPerfect && !aHasPracticeMakesPerfect) {
          let bHasReadyForChallenge = b.readyForChallengeStudents.length !== 0;
          let ahasReadyForChallenge = a.readyForChallengeStudents.length !== 0;
          if (bHasReadyForChallenge && !ahasReadyForChallenge) {
            return 1;
          }
          else if (!bHasReadyForChallenge && ahasReadyForChallenge) {
            return -1;
          }
        }
        return 0;
      }
    });
  }

  sortByPracticing() {
    this.skillsPracticeCards.sort((a, b) => {
      let sumB = b.justStartingStudents.length + b.stuckStudents.length + b.practiceMakesPerfectStudents.length + b.readyForChallengeStudents.length;
      let sumA = a.justStartingStudents.length + a.stuckStudents.length + a.practiceMakesPerfectStudents.length + a.readyForChallengeStudents.length;

      if (sumB - sumA === 0) {
        return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
      }
      return sumB - sumA;
    });

  }

  sortBySkillShortName() {
    this.skillsPracticeCards.sort((a, b) => {
      // ascending a - b
      let shortNameProgressionOrderA = a.skillMetadata.skillShortName + a.skillMetadata.skillProgressionOrder;
      let shortNameProgressionOrderB = b.skillMetadata.skillShortName + b.skillMetadata.skillProgressionOrder;

      return shortNameProgressionOrderA.localeCompare(shortNameProgressionOrderB);
    });
  }


  sort(strategy: FilterOptionValues) {
    this.sortStrategy = strategy;
    // Use a copy of the original list because .sort() is stable
    // and will preserve the order of equalivalent sort rank items which is good normally
    // however toggling between 2-different strategies round-trip without original this will cause
    // the original strategy to be sorted different likely causing user confusion
    this.skillsPracticeCards = this.originalSkillPracticeCards.slice();

    switch(strategy) {
      case FilterOptions.SkillName: {
        this.sortBySkillShortName();
        break;
      }
      case FilterOptions.MostPracticing: {
        this.sortByPracticing();
        break;
      }
      case FilterOptions.MostStuck: {
        this.sortByStuck();
        break;
      }
      default:
        // We should never run this code
        // This trick is to fail compile time type check if there is ever a new FilterOption that is unhandled by the switch/case
        this._exhaustiveGuard(strategy);
    }
  }

  private _exhaustiveGuard(value: never): never {
    throw new Error(`Unknown sort option! ${value}`)
  }
}
