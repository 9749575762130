import { Injectable } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';
import { ProductAppCodes, ProductAppImagePrefix } from '../product-info/product-info.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavV2ReportService {

  constructor() { }

  public getAppNavV2ReportItems(appNavV2ReportItems: AppNavV2[]): AppNavItem[] {
    let appNavItems: AppNavItem[] = [];

    for (let i = 0; i < appNavV2ReportItems.length; i++) {
      let appNavItem: AppNavItem = { displayName: "" };
      var isChildrenExists = appNavV2ReportItems[i] != null && appNavV2ReportItems[i].children && appNavV2ReportItems[i].children!.length > 0 ? true : false;
      if (isChildrenExists) {
        appNavItem.children = [];
      }

      if (appNavV2ReportItems[i] != null) {
        appNavItem.appCode = appNavV2ReportItems[i].appCode;
        appNavItem.deepLinkId = appNavV2ReportItems[i].deepLink;
        appNavItem.displayName = appNavV2ReportItems[i].displayName;
        appNavItem.subHeader = appNavV2ReportItems[i].subHeader;
        if (this.isImagePrefixRequired(appNavItem.appCode) == true) {
          appNavItem.imagePrefix = this.getImagePrefixByAppCode(appNavItem.appCode);
        }
        if (appNavV2ReportItems[i].children && appNavV2ReportItems[i].children!.length > 0) {
          appNavV2ReportItems[i].children?.forEach(child => {
            let appNavChildItem: AppNavItem = { displayName: "" };
            appNavChildItem.appCode = child.appCode;
            appNavChildItem.deepLinkId = child.deepLink;
            appNavChildItem.displayName = child.displayName;
            appNavItem.children?.push(appNavChildItem)
          });
        }
      }
      appNavItems.push(appNavItem);
    }

    return appNavItems;
  }

  private isImagePrefixRequired(appCode: string): boolean {
    let isImagePrefixRequired = true;
    switch (appCode) {
      case ProductAppCodes.AdminReports:
      case ProductAppCodes.MyCreatedReports:
      case ProductAppCodes.DataSharingReports:
      case ProductAppCodes.CustomReports:
        isImagePrefixRequired = false;
        break;
    }
    return isImagePrefixRequired
  }
  private getImagePrefixByAppCode(appCode: string): string {
    let imagePrefix = "";
    switch (appCode) {
      case ProductAppCodes.StarReading:
      case ProductAppCodes.StarMath:
      case ProductAppCodes.StarEarlyLiteracy:
      case ProductAppCodes.StarReadingSpanish:
      case ProductAppCodes.StarMathSpanish:
      case ProductAppCodes.StarEarlyLiteracySpanish:
      case ProductAppCodes.StarCbmMath:
      case ProductAppCodes.StarCbmReading:
      case ProductAppCodes.StarCbmLectura:
        imagePrefix = ProductAppImagePrefix.Star;
        break;
      case ProductAppCodes.AR:
        imagePrefix = ProductAppImagePrefix.AR;
        break;
      case ProductAppCodes.Myon:
        imagePrefix = ProductAppImagePrefix.Myon;
        break;
    }
    return imagePrefix;
  }
}
