import { Component, OnDestroy, OnInit } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';
import { StudentContext } from 'src/app/shared/models/student.model';
import { ClassContext } from 'src/app/shared/models/class.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { Subscription } from 'rxjs';
import { StudentSorter } from 'src/app/util/student-sorter/student-sorter';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { FreckleLinkGeneratorService } from 'src/app/services/link-generators/freckle-link-generator.service';
import { LaliloLinkGeneratorService } from 'src/app/services/link-generators/lalilo-link-generator.service';
import { AppNavItemService } from 'src/app/services/app-nav/app-nav-item.service';
import { AppNavItemConfigurationService } from 'src/app/services/app-nav/app-nav-item-configuration.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';
import { Skill } from 'src/app/shared/models/skill.model';
import { AppNavV2HelperService } from 'src/app/services/app-nav-v2/app-nav-v2-helper.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';

@Component({
  selector: 'app-skills-practice',
  templateUrl: './skills-practice.component.html',
  styleUrls: ['./skills-practice.component.scss'],
})

export class SkillsPracticeComponent implements OnInit, OnDestroy {
  public currentClass: ClassContext | undefined;
  public didLoad: boolean = false;
  public hasData: boolean = false;
  public showSpinner: boolean = false;
  public skillsPracticeCards: SkillsPracticeCard[] = [];
  public inactiveStudents: StudentContext[] = [];
  public subscriptions: Subscription[] = [];

  public headerTitle: string = '';
  public headerDescription: string = '';

  public freckleActivityFeedLink: string = '';
  public laliloClassSnapShotLink: string = '';
  public showActivityFeedLink: boolean = false;
  public showLaliloSnapShotLink: boolean = false;

  public constructor(
    private contextDataService: ContextDataService,
    private freckleLinkGeneratorService: FreckleLinkGeneratorService,
    private laliloLinkGeneratorService: LaliloLinkGeneratorService,
    private appNavItemService: AppNavItemService,
    private appNav: AppNavItemConfigurationService,
    public featureToggleService: FeatureToggleService,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private spinnerService: SpinnerService,
    private appNavV2HelperService: AppNavV2HelperService
  ) { }

  public async ngOnInit(): Promise<void> {
    if (await this.featureToggleService.isTrueAsync('teach-3935-rename-skills-tab-to-student-groups')) {
      this.headerTitle = 'Student Groups';
      this.headerDescription = 'Recommendations and differentiated resources for small group work.';
    }
    else {
      this.headerTitle = 'Skills Practice Results and Recommendations';
      this.headerDescription = 'Summary information about how your students are doing in their skills practice work, broken down by skill.';
    }
    let subscription = this.contextDataService.classDataContext$.subscribe(async (classData: ClassContext | undefined) => {
      if (classData) {
        this.updateSelectedClass(classData);
        this.sortSkillPracticeCards();

        if (await this.featureToggleService.isTrueAsync("use-new-launch")) {
          this.showActivityFeedLink = this.appNavV2HelperService.isLicensedAppCode(ProductAppCodes.Freckle);
          this.showLaliloSnapShotLink = this.appNavV2HelperService.isLicensedAppCode(ProductAppCodes.Lalilo);

          this.freckleActivityFeedLink = this.freckleLinkGeneratorV2Service.getActivityFeedDeepLink(classData.students);
          this.laliloClassSnapShotLink = await this.laliloLinkGeneratorV2Service.getLaliloPageDeepLink('class-snapshot');
        }
        else {
          this.showActivityFeedLink = true;
          this.showLaliloSnapShotLink = this.appNavItemService.hasApp([this.appNav.AppId.lalilo]);

          this.freckleActivityFeedLink = this.freckleLinkGeneratorService.getActivityFeedLink(classData.students);
          this.laliloClassSnapShotLink = await this.laliloLinkGeneratorService.getLaliloPageLink('class-snapshot');
        }
      }
    });
    this.subscriptions.push(subscription);

    subscription = this.spinnerService.spinnerVisible$.subscribe((spinnerVisible: boolean) => {
      this.showSpinner = spinnerVisible;
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async updateSelectedClass(newSelectedClass: ClassContext) {
    this.currentClass = newSelectedClass;
    if (!!this.currentClass) {
      await this.getSkillCards(this.currentClass.students);
    }
    this.hasData = (this.inactiveStudents.length > 0 || this.skillsPracticeCards.length > 0);
    this.didLoad = !!this.currentClass;
  }

  public async getSkillCards(students: StudentContext[]) {
    this.inactiveStudents = [];
    this.skillsPracticeCards = [];
    if (await this.featureToggleService.isTrueAsync('data-follows-student')) {
      students.forEach(student => {
        if (student.skills == null || student.skills.length == 0) {
          this.inactiveStudents.push(student);
        }
        else {
          const aggregateSkills = SkillAggregate.aggregate(student.skills);

          aggregateSkills.forEach(skill => {
            let skillId = skill.standardSetMetadata!.contentActivityId;
            let skillsPracticeCard = this.skillsPracticeCards.find(card => skillId == card.skillMetadata.contentActivityId);
            if (skillsPracticeCard != null) {
              this.putStudentInSkillCard(student, skillsPracticeCard, skill);
            }
            else {
              let skillShortName = skill.standardSetMetadata!.skillShortName;
              let existingShortNameCard = this.skillsPracticeCards.find(card => {
                return skillShortName === card.skillMetadata.skillShortName && skillId !== card.skillMetadata.contentActivityId;
              });

              if (existingShortNameCard) {
                // mark existing as duplicate
                existingShortNameCard.hasAmbiguousShortName = true;
              }

              let skillsPracticeCard: SkillsPracticeCard = {
                skillMetadata: skill.standardSetMetadata!,
                justStartingStudents: <StudentContext[]>[],
                stuckStudents: <StudentContext[]>[],
                practiceMakesPerfectStudents: <StudentContext[]>[],
                readyForChallengeStudents: <StudentContext[]>[],
                hasAmbiguousShortName: existingShortNameCard ? true : false
              }
              this.skillsPracticeCards.push(skillsPracticeCard);
              this.putStudentInSkillCard(student, skillsPracticeCard, skill);
            }
          });
        }
      });
    }
    else {
      students.forEach(student => {
        if (!student.classProductUsage.hasPracticeProductAssigned || student.skills == null || student.skills.length == 0) {
          this.inactiveStudents.push(student);
        }
        else {
          const aggregateSkills = SkillAggregate.aggregate(student.skills);

          aggregateSkills.forEach(skill => {
            let skillId = skill.standardSetMetadata!.contentActivityId;
            let skillsPracticeCard = this.skillsPracticeCards.find(card => skillId == card.skillMetadata.contentActivityId);
            if (skillsPracticeCard != null) {
              this.putStudentInSkillCard(student, skillsPracticeCard, skill);
            }
            else {
              let skillShortName = skill.standardSetMetadata!.skillShortName;
              let existingShortNameCard = this.skillsPracticeCards.find(card => {
                return skillShortName === card.skillMetadata.skillShortName && skillId !== card.skillMetadata.contentActivityId;
              });

              if (existingShortNameCard) {
                // mark existing as duplicate
                existingShortNameCard.hasAmbiguousShortName = true;
              }

              let skillsPracticeCard: SkillsPracticeCard = {
                skillMetadata: skill.standardSetMetadata!,
                justStartingStudents: <StudentContext[]>[],
                stuckStudents: <StudentContext[]>[],
                practiceMakesPerfectStudents: <StudentContext[]>[],
                readyForChallengeStudents: <StudentContext[]>[],
                hasAmbiguousShortName: existingShortNameCard ? true : false
              }
              this.skillsPracticeCards.push(skillsPracticeCard);
              this.putStudentInSkillCard(student, skillsPracticeCard, skill);
            }
          });
        }
      });
    }
  }

  private putStudentInSkillCard(student: StudentContext, skillsPracticeCard: SkillsPracticeCard, skill: Skill) {
    if (SkillTests.isJustStarting(skill.totalAnswers)) {
      return skillsPracticeCard.justStartingStudents.push(student);
    }
    else {
      let accuracyRate = SkillTests.getAccuracyRate(skill.correctAnswers, skill.totalAnswers);
      if (accuracyRate < 0.5) {
        return skillsPracticeCard.stuckStudents.push(student);
      }
      else if (accuracyRate >= 0.8) {
        return skillsPracticeCard.readyForChallengeStudents.push(student);
      }
      else {
        return skillsPracticeCard.practiceMakesPerfectStudents.push(student);
      }
    }
  }

  private sortSkillPracticeCards() {
    this.skillsPracticeCards.forEach(skillsPracticeCard => {
      StudentSorter.sortStudents(skillsPracticeCard.stuckStudents);
      StudentSorter.sortStudents(skillsPracticeCard.practiceMakesPerfectStudents);
      StudentSorter.sortStudents(skillsPracticeCard.readyForChallengeStudents);
      StudentSorter.sortStudents(skillsPracticeCard.justStartingStudents);
    });
    StudentSorter.sortStudents(this.inactiveStudents);
  }
}
