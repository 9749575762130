import { Injectable } from '@angular/core';
import { NearpodLinkGeneratorService } from 'src/app/services/link-generators/nearpod-link-generator.service';
import { ProductTypeGroups } from 'src/app/services/product-info/product-info.service';
import { ActionLink, BaseRecommendationSkill, ProductKeys, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { OverlapSkillRecommendationService } from '../../overlap-skill/overlap-skill-recommendation.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class NearpodActionRecommendationService {
  private readonly NEARPOD_IMAGE_PATH = `../../../../../../assets/logos/nearpod_logo.svg`

  constructor(
    private overlapSkillRecommendationService: OverlapSkillRecommendationService,
    private nearpodLinkGenService: NearpodLinkGeneratorService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getNearpodActionForRecommendation(skill: BaseRecommendationSkill, nearpodMetadata: SkillMetadata | null, freckleMetadata: SkillMetadata | null, laliloMetadata: SkillMetadata | null): Promise<ActionLink | null> {
    let actionLink = null;

    const isFreckleLaliloOverlapSkill = await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations') &&
      this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(skill.skillId);

    /* Recommend Nearpod for
      - 1. targeted practice for every category
        - except Needs Challenge only for Lalilo
        - except for non-Needs Help Freckle-Lalilo overlap skills
      - 2. adaptive practice for every category
        - except Needs Challenge
        - except for Freckle-Lalilo overlap skills
      - 3. star recommendations
    */

    if ((!this.isLaliloNeedsChallengeRecommendation(laliloMetadata, skill) &&
          skill.practiceProductType &&
          (
            (ProductTypeGroups.TargetedPracticeIds.includes(skill.practiceProductType) && !this.isFreckleLaliloOverlapSkillAndNotNeedsHelpRecommendation(isFreckleLaliloOverlapSkill, skill)) ||
            (ProductTypeGroups.AdaptivePracticeIds.includes(skill.practiceProductType) && skill.recommendationType != RecommendationType.NeedsChallenge && !isFreckleLaliloOverlapSkill)
          )
        ) ||
        skill.recommendationType === RecommendationType.Star) {

      const nearpodUrl = await this.nearpodLinkGenService.getSearchLinkV2(nearpodMetadata, freckleMetadata, laliloMetadata);

      if (nearpodUrl) {
        actionLink = {
          link: nearpodUrl,
          name: 'Lessons in Nearpod',
          description: 'Find a teacher resource for instruction on this skill',
          imageUrl: this.NEARPOD_IMAGE_PATH,
          type: ProductKeys.Nearpod
        } as ActionLink;
      }
    }

    return actionLink;
  }

  private isLaliloNeedsChallengeRecommendation(laliloMetadata: SkillMetadata | null, skill: BaseRecommendationSkill): boolean {
    return !!laliloMetadata && skill.recommendationType === RecommendationType.NeedsChallenge;
  }

  private isFreckleLaliloOverlapSkillAndNotNeedsHelpRecommendation(isFreckleLaliloOverlapSkill: boolean, skill: BaseRecommendationSkill): boolean {
    return isFreckleLaliloOverlapSkill && skill.recommendationType !== RecommendationType.NeedsHelp;
  }
}
