<ng-container [ngSwitch]="template">

  <!-- Desktop view -->
  <ng-container *ngSwitchDefault>
    <td>
      <app-student-icon [student]="studentContext"></app-student-icon>
    </td>
    <td>
      <div *ngIf="!studentHasSkillsPractice() && !studentContext.classProductUsage.hasPracticeProductAssigned">
        <div>--</div>
      </div>
      <div data-test="skills-practiced"
        *ngIf="(studentHasSkillsPractice() || studentContext.classProductUsage.hasPracticeProductAssigned) && aggregateSkills.length !== undefined">
        <div [attr.data-test]="practiceAlert() ? 'skill-alert' : null" [class.alert]="practiceAlert()" aria-label="alert">
          <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="practiceAlert()"/>
          <button
            data-test="skills-detail-text"
            *ngIf="aggregateSkills.length !== 0"
            id="pendo-skills-popup-{{category}}"
            class="detail-button skill-detail-button"
            [class.alert-text-top]="practiceAlert()" (click)="openSkillDetail()">
            {{getSkillsText(aggregateSkills.length)}}
          </button>
          <div *ngIf="classHasSkillsPracticeProducts() && aggregateSkills.length == 0" class="skill-detail-no-skills" [class.alert-text]="practiceAlert()">
            {{getSkillsText(aggregateSkills.length)}}
          </div>
          <div data-test="subtext-stuck-skill" *ngIf="hasStuckSkill()" class="subtext alert-text-bottom">
            Stuck on {{getNumStuckSkillsText(aggregateSkills)}}
          </div>
        </div>
        <div *ngIf="aggregateSkills.length > 0" class="subtext bottom">{{displayMinutesSpentPerDay(studentContext.minutesSpentPerDay)}} min/day
        </div>
        <div data-test="subtext-zero-skill" *ngIf="aggregateSkills.length == 0" class="subtext bottom">
          <div *ngIf="studentContext.lastSkillPracticedDate !== undefined; else noPracticeLogins">Last
            activity:
            {{displayDate(studentContext.lastSkillPracticedDate)}}</div>
          <ng-template #noPracticeLogins>
            {{displayNoSkillsPractice()}}
          </ng-template>
        </div>
      </div>
    </td>
    <td *ngIf="this.selectedSubjectIsReading()">
      <div *ngIf="studentHasReadingPractice(); else noReadingPractice"
      [attr.data-test]="readingPracticeAlert() ? 'reading-alert' : null" [class.alert]="readingPracticeAlert()">
        <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="readingPracticeAlert()"/>
      <button
          data-test="reading-practice-detail-text"
          id="pendo-reading-practice-popup-{{category}}"
          class="detail-button reading-practice-detail-button"
          [class.alert-text-top]="readingPracticeAlert()"
          (click)="openReadingPracticeModal()">
          {{displayBookCountText(studentContext.readingPractices)}}
        </button>
        <div data-test="reading-subtext" class="subtext" [ngClass]="readingPracticeAlert() ? 'alert-text-bottom' : 'bottom'">
          {{displayAverageReadingPracticePercentCorrect(studentContext.readingPracticeSummary.averageQuizScore)}}
          <div data-test="reading-alert-subtext" *ngIf="readingPracticeAlert()">
            Below 75% on {{getNumStuckReadingPracticesText(studentContext.readingPractices)}}
          </div>
        </div>
      </div>
      <ng-template #noReadingPractice>
        <div *ngIf="classHasReadingPracticeProducts(); else noReadingPracticeProducts" class="no-data">
          No reading
        </div>
        <ng-template #noReadingPracticeProducts>
          <div>--</div>
        </ng-template>
      </ng-template>
    </td>
    <td>
      <div *ngIf="!studentHasAssessments()">
        <ng-container *ngIf="classHasAssessmentProducts(); else noAssessmentProductsText">
          <div data-test="no-assessment" class="no-data">No tests in the last 90 days</div>
        </ng-container>
        <ng-template #noAssessmentProductsText>
          <div class="no-data">--</div>
        </ng-template>
      </div>
      <div *ngIf="studentHasAssessments()">
        <div [class.alert]="assessmentAlert()" aria-label="alert">
          <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="assessmentAlert()"/>
          <button
            id="pendo-assessment-popup-{{category}}"
            data-test="assessment-result"
            class="detail-button assessment-detail-button"
            [class.alert-text-top]="assessmentAlert()"
            (click)="openAssessmentDetail()">
            {{displayAssessments(studentContext.assessments)}}
          </button>
          <div data-test="assessment-alert-subtext" *ngIf="assessmentAlert()" class="subtext alert-text-bottom">
            Below benchmark on latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}
          </div>
        </div>
        <div data-test="assessment-subtext" *ngIf="!assessmentAlert()" class="subtext bottom">Latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}</div>
      </div>
    </td>
    <td *ngIf="showTakeAction">
      <button type="button" class="ren-btn ren-btn--secondary take-action" data-test="take-action-button"
        (click)="openTakeActionModal(students)">Take Action</button>
    </td>
  </ng-container>

  <!-- Tablet view -->
  <ng-container *ngSwitchCase="'tabletLayout'">
    <td>
      <app-student-icon [student]="studentContext"></app-student-icon>
      <button *ngIf="showTakeAction" type="button" class="ren-btn ren-btn--secondary take-action" (click)="openTakeActionModal(students)">
        Take Action
      </button>
    </td>
    <td>
      <div *ngIf="!studentHasSkillsPractice() && !studentContext.classProductUsage.hasPracticeProductAssigned">
        <div>--</div>
      </div>
      <div *ngIf="(studentHasSkillsPractice() || studentContext.classProductUsage.hasPracticeProductAssigned) && aggregateSkills.length !== undefined">
        <div [class.alert]="practiceAlert()">
          <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="practiceAlert()"/>
          <button
            *ngIf="aggregateSkills.length !== 0"
            id="pendo-skills-popup-{{category}}"
            class="detail-button skill-detail-button"
            [class.alert-text-top]="practiceAlert()"
            (click)="openSkillDetail()">
            {{getSkillsText(aggregateSkills.length)}}
          </button>
          <div *ngIf="classHasSkillsPracticeProducts() && aggregateSkills.length == 0" class="skill-detail-no-skills" [class.alert-text]="practiceAlert()">
            {{getSkillsText(aggregateSkills.length)}}
          </div>
          <div *ngIf="hasStuckSkill()" class="subtext alert-text-bottom">
            Stuck on {{getNumStuckSkillsText(aggregateSkills)}}
          </div>
        </div>
        <div *ngIf="aggregateSkills.length > 0" class="subtext bottom">{{displayMinutesSpentPerDay(studentContext.minutesSpentPerDay)}} min/day
        </div>
        <div *ngIf="aggregateSkills.length == 0" class="subtext bottom">
          <div *ngIf="studentContext.lastSkillPracticedDate !== undefined; else noPracticeLogins">Last
            activity:
            {{displayDate(studentContext.lastSkillPracticedDate)}}</div>
          <ng-template #noPracticeLogins>
            {{displayNoSkillsPractice()}}
          </ng-template>
        </div>
      </div>
    </td>
    <td *ngIf="this.selectedSubjectIsReading()">
      <div *ngIf="studentHasReadingPractice(); else noReadingPractice"
        [class.alert]="readingPracticeAlert()">
        <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="readingPracticeAlert()"/>
        <button
          data-test="reading-practice-detail-text"
          id="pendo-reading-practice-popup-{{category}}"
          class="detail-button reading-practice-detail-button"
          [class.alert-text-top]="readingPracticeAlert()"
          (click)="openReadingPracticeModal()">
          {{displayBookCountText(studentContext.readingPractices)}}
        </button>
        <div class="subtext" [ngClass]="readingPracticeAlert() ? 'alert-text-bottom' : 'bottom'">
          {{displayAverageReadingPracticePercentCorrect(studentContext.readingPracticeSummary.averageQuizScore)}}
          <div *ngIf="readingPracticeAlert()">
            Below 75% on {{getNumStuckReadingPracticesText(studentContext.readingPractices)}}
          </div>
        </div>
      </div>
      <ng-template #noReadingPractice>
        <div *ngIf="classHasReadingPracticeProducts(); else noReadingPracticeProducts" class="no-data">
          No reading
        </div>
        <ng-template #noReadingPracticeProducts>
          <div>--</div>
        </ng-template>
      </ng-template>
    </td>
    <td>
      <div *ngIf="!studentHasAssessments()">
        <ng-container *ngIf="classHasAssessmentProducts(); else noAssessmentProductsText">
          <div class="no-data">No tests in the last 90 days</div>
        </ng-container>
        <ng-template #noAssessmentProductsText>
          <div class="no-data">--</div>
        </ng-template>
      </div>
      <div *ngIf="studentHasAssessments()">
        <div [class.alert]="assessmentAlert()">
          <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="assessmentAlert()"/>
          <button
            id="pendo-assessment-popup-{{category}}"
            class="detail-button assessment-detail-button"
            [class.alert-text]="assessmentAlert()"
            (click)="openAssessmentDetail()">
            {{displayAssessments(studentContext.assessments)}}
          </button>
          <div *ngIf="assessmentAlert()" class="subtext alert-text-bottom">
            Below benchmark on latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}
          </div>
        </div>
        <div *ngIf="!assessmentAlert()" class="subtext bottom">Latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}</div>
      </div>
    </td>
  </ng-container>

  <!-- Mobile view -->
  <ng-container *ngSwitchCase="'mobileLayout'">
    <div class="student-header">
      <app-student-icon class="title student" [student]="studentContext"></app-student-icon>
      <div class="take-action-container" *ngIf="showTakeAction">
        <button type="button" class="ren-btn ren-btn--secondary take-action" (click)="openTakeActionModal(students)">
          Take Action
        </button>
      </div>
    </div>
    <div class="section">
      <div class="heading">
        Skills Practice In Progress <div class="product">{{skillsPracticeProductsHeader}}</div>
      </div>
      <div class="content">
        <div *ngIf="!studentHasSkillsPractice() && !studentContext.classProductUsage.hasPracticeProductAssigned">
          <div class="dashes">--</div>
        </div>
        <div *ngIf="(studentHasSkillsPractice() || studentContext.classProductUsage.hasPracticeProductAssigned) && aggregateSkills.length !== undefined">
          <div [class.alert]="practiceAlert()">
            <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="practiceAlert()"/>
            <button
              *ngIf="aggregateSkills.length !== 0"
              id="pendo-skills-popup-{{category}}"
              class="detail-button skill-detail-button"
              [class.alert-text-top]="practiceAlert()"
              (click)="openSkillDetail()">
              {{getSkillsText(aggregateSkills.length)}}
            </button>
            <div *ngIf="classHasSkillsPracticeProducts() && aggregateSkills.length == 0" class="skill-detail-no-skills" [class.alert-text]="practiceAlert()">
              {{getSkillsText(aggregateSkills.length)}}
            </div>
            <div *ngIf="hasStuckSkill()" class="subtext alert-text-bottom">
              Stuck on {{getNumStuckSkillsText(aggregateSkills)}}
            </div>
          </div>
          <div *ngIf="aggregateSkills.length > 0" class="subtext bottom">{{displayMinutesSpentPerDay(studentContext.minutesSpentPerDay)}} min/day
          </div>
          <div *ngIf="aggregateSkills.length == 0" class="subtext bottom">
            <div *ngIf="studentContext.lastSkillPracticedDate !== undefined; else noPracticeLogins">Last
              activity:
              {{displayDate(studentContext.lastSkillPracticedDate)}}</div>
            <ng-template #noPracticeLogins>
              {{displayNoSkillsPractice()}}
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="section" *ngIf="this.selectedSubjectIsReading()">
      <div class="heading">
        Reading Practice <div class="product">{{readingPracticeProductsHeader}}</div>
      </div>
      <div class="content">
        <div *ngIf="studentHasReadingPractice(); else noReadingPractice"
          [class.alert]="readingPracticeAlert()">
          <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="readingPracticeAlert()"/>
          <button
            data-test="reading-practice-detail-text"
            id="pendo-reading-practice-popup-{{category}}"
            class="detail-button reading-practice-detail-button"
            [class.alert-text-top]="readingPracticeAlert()"
            (click)="openReadingPracticeModal()">
            {{displayBookCountText(studentContext.readingPractices)}}
          </button>
          <div class="subtext" [ngClass]="readingPracticeAlert() ? 'alert-text-bottom' : 'bottom'">
            {{displayAverageReadingPracticePercentCorrect(studentContext.readingPracticeSummary.averageQuizScore)}}
            <div *ngIf="readingPracticeAlert()">
              Below 75% on {{getNumStuckReadingPracticesText(studentContext.readingPractices)}}
            </div>
          </div>
        </div>
        <ng-template #noReadingPractice>
          <div *ngIf="classHasReadingPracticeProducts(); else noReadingPracticeProducts" class="no-data">
            No reading
          </div>
          <ng-template #noReadingPracticeProducts>
            <div>--</div>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="section">
      <div class="heading">
        Assessment <div class="product">{{assessmentProductsHeader}}</div>
      </div>
      <div class="content">
        <div *ngIf="!studentHasAssessments()">
          <ng-container *ngIf="classHasAssessmentProducts(); else noAssessmentProductsText">
            <div class="no-data">No tests in the last 90 days</div>
          </ng-container>
          <ng-template #noAssessmentProductsText>
            <div class="no-data">--</div>
          </ng-template>
        </div>
        <div *ngIf="studentHasAssessments()">
          <div [class.alert]="assessmentAlert()">
            <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" *ngIf ="assessmentAlert()"/>
            <button
              id="pendo-assessment-popup-{{category}}"
              class="detail-button assessment-detail-button"
              [class.alert-text]="assessmentAlert()"
              (click)="openAssessmentDetail()">
              {{displayAssessments(studentContext.assessments)}}
            </button>
            <div *ngIf="assessmentAlert()" class="subtext alert-text-bottom">
              Below benchmark on latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}
            </div>
          </div>
          <div *ngIf="!assessmentAlert()" class="subtext bottom">Latest test: {{displayDate(studentContext.latestAssessment.completedDate)}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
