<div class="tooltip-wrapper">
  <button #tooltipButton class="tooltip-title" id="description-button" aria-controls="tool-tip-description" data-component="tooltip-title" [attr.aria-expanded]="ShowTooltip"
      (click)="shouldShowTooltip()">
      {{ tooltipLabel }}
  </button>
  <div role="tooltip" #toolTip class="tooltip tooltip--text-align-left tooltip--visible" id="tool-tip-description" [class.tooltip--visible]="ShowTooltip">
      <div class="tooltip-content-container">
          <div class="tooltip-description-container">
              {{tooltipDescription}}
          </div>
          <div>
              <button (click)="hideTooltip()" class="tooltip-close-button">Close</button>
          </div>
      </div>
  </div>
</div>
