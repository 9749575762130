import { Skill } from "src/app/shared/models/skill.model";

export class AmbiguousSkillShortNameFinder {

  public static getAmbiguousShortNameSkillIds(skills: Skill[]): string[] {
    const shortNamesToSkillIds: { [key: string] : string[] } = {};
    skills.forEach((skill) => {
      if (!shortNamesToSkillIds[skill.skillShortName]) {
        shortNamesToSkillIds[skill.skillShortName] = [];
      }
      let newSkillId = skill.contentActivityId;
      if (shortNamesToSkillIds[skill.skillShortName].indexOf(newSkillId) === -1) {
        shortNamesToSkillIds[skill.skillShortName].push(newSkillId);
      }
    });

    let ambiguousSkillIds: string[] = [];
    for (let shortName in shortNamesToSkillIds) {
      const skillsForShortName = shortNamesToSkillIds[shortName];
      if (skillsForShortName.length > 1) {
        ambiguousSkillIds = ambiguousSkillIds.concat(skillsForShortName);
      }
    }

    return ambiguousSkillIds;
  }
}
