<!-- TODO: Section is overall zoom accessible, but placement requires review. -->
<div class="searchbox-container">
  <search-intro-animation class="search-intro-animation"></search-intro-animation>
  <ng-container *ngIf="isIntroAnimationComplete">
    <div class="searchbox" [class.fade-in-ani]="playFadeInAnimation">
      <p id="search-header" class="header-text">{{searchConstants.SEARCHBOX.HEADER_WHAT_NEXT}}</p>
      <div class="search-input-container">
        <!-- TODO: When clicking near input, the cursor sometimes appears outside of the input at the front. -->
        <app-search-input-v2 #searchInput [showLearnMore]="true"></app-search-input-v2>
      </div>
    </div>
  </ng-container>
</div>
