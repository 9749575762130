import { StudentContext } from "src/app/shared/models/student.model";
import { SkillTests } from "src/app/util/skill-tests/skill-tests";
import { Injectable } from "@angular/core";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { ProductIds, ProductIdsByGroup, ProductInfoService } from "../../product-info/product-info.service";

@Injectable({
  providedIn: 'root'
})
export class SuperstarEffortService {

  constructor(private productInfoService: ProductInfoService) {}

  public getEffortQualifications(student: StudentContext): string[] {
    let qualifications: string[] = [];

    // 2 qualifiers for Effort:
    // Has spent some time practicing within past two weeks (myON, Freckle, Lalilo)
    const practicedQualified = this.qualifyPracticed(student);
    if (practicedQualified) {
      qualifications.push(practicedQualified);
    }

    // Has taken an assessment and did not rush (per Star rushing logic)
    const starNotRushedQualified = this.qualifyStarNotRushed(student);
    if (starNotRushedQualified) {
      qualifications.push(starNotRushedQualified);
    }

    return qualifications;
  }

  private qualifyPracticed(student: StudentContext): string {
    let qualification: string = '';

    let practicedReadingMinutes = Math.floor(student.readingPracticeSummary.secondsReadWithinSevenDays / 60);

    let skillPracticePreviousWeek = student.skills.filter( skill =>
      DateHelper.isWithinOneWeekOfToday(skill.lastPracticedDate, true)
    );

    let practicedProductStrings: string[] = [];

    // skill practice
    for (let practiceProductId of ProductIdsByGroup.PracticeProductIds) {
      let productSkills = skillPracticePreviousWeek.filter(skill => skill.productId === practiceProductId);

      let practicedSkillMinutes = 0;
      productSkills.forEach(skill => {
        practicedSkillMinutes += skill.durationInMinutes;
      });
      if (practicedSkillMinutes > 0) {
        let productName = this.productInfoService.GetProductName(practiceProductId);
        practicedProductStrings.push(`${practicedSkillMinutes} minutes in ${productName}`);
      }
    }

    // reading practice
    if (practicedReadingMinutes > 0) {
      let productName = this.productInfoService.GetProductName(ProductIds.Myon);
      practicedProductStrings.push(`${practicedReadingMinutes} minutes in ${productName}`);
    }

    if (practicedProductStrings.length > 0) {
      qualification = `Practiced ${practicedProductStrings.join(', ')} in the past week`;
    }

    return qualification;
  }

  private qualifyStarNotRushed(student: StudentContext): string {
    let qualification: string = '';

    const assessmentsInQualifierWeek = student.assessments.filter(assessment =>
      DateHelper.isWithinOneWeekOfToday(assessment.completedDate, true)
    );
    const anyNotRushed = assessmentsInQualifierWeek.some(assessment =>
      !SkillTests.isShortTestTime(assessment.subject, assessment.assessmentTimeInSeconds)
    );

    if (anyNotRushed) {
      qualification = `Showed effort on their recent Star test`;
    }

    return qualification;
  }
}
