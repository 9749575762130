import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';
import { Category } from 'src/app/shared/models/category.model';
import { CategorizationService } from 'src/app/services/categorization/categorization.service';
import { ClassContext, ClassProducts } from 'src/app/shared/models/class.model';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { Subscription } from 'rxjs';
import { StudentSorter } from 'src/app/util/student-sorter/student-sorter';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';

@Component({
  selector: 'app-activity-summary',
  templateUrl: './activity-summary.component.html',
  styleUrls: ['./activity-summary.component.scss'],
})
export class ActivitySummaryComponent implements OnInit, OnDestroy {

  public starReadingSpanishProductId: string = 'STAR_READING_SPANISH';
  public arProductId: string = 'AR';
  public currentClass: ClassContext | undefined;

  public needsAHelpingHandStudents: StudentContext[] = [];
  public workingAtAnAppropriateLevelStudents: StudentContext[] = [];
  public readyToBeChallengedStudents: StudentContext[] = [];
  public timeToPracticeStudents: StudentContext[] = [];
  public numTotalStudents: number = 0;
  public studentsWithData: number = 0;
  public showSpinner: boolean = false;
  public didLoad: boolean = false;
  public hasData: boolean = false;
  public doShowSearchbox: boolean = false;
  public classProducts!: ClassProducts;
  private subscriptions: Subscription[] = [];

  public isIntroAnimationComplete: boolean = false;
  public playFadeInAnimation: boolean = false;
  public showAnimationCompletePendoElement: boolean = false;
  public readonly fadeInAnimationMs: number = 400;
  public readonly showPendoMs: number = 2000;

  constructor(
    private categorizationService: CategorizationService,
    private contextDataService: ContextDataService,
    private subjectService: SubjectService,
    private spinnerService: SpinnerService,
    public introAnimationService: IntroAnimationService,
    private changeDetectorRef: ChangeDetectorRef,
    public featureToggleService: FeatureToggleService
  ) {

  }

  public async ngOnInit() {
    let subscription = this.contextDataService.classDataContext$.subscribe(async (classData: ClassContext | undefined) => {
      if (classData) {
        await this.updateSelectedClass(classData);
      }
    });
    this.subscriptions.push(subscription);

    subscription = this.spinnerService.spinnerVisible$.subscribe((spinnerVisible) => {
      this.showSpinner = spinnerVisible;
    });
    this.subscriptions.push(subscription);

    subscription = this.introAnimationService.summaryIntroAnimationStatus$.subscribe((animationStatus: IntroAnimationStatus | undefined) => {
      if (animationStatus) {
        this.checkAnimationStatus(animationStatus)
      }
    });

    this.subscriptions.push(subscription);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }


  public async updateSelectedClass(newSelectedClass: ClassContext) {
    this.currentClass = newSelectedClass;
    if (!!this.currentClass) {
      let students = this.getSummaryStudents(this.currentClass.students);
      this.studentsWithData = this.getNumberOfStudentsWithData(students);
      this.classProducts = {
        skillsPracticeProducts: this.currentClass.classProducts.skillsPracticeProducts,
        readingPracticeProducts: this.currentClass.classProducts.readingPracticeProducts,
        assessmentProducts: this.currentClass.classProducts.assessmentProducts
      }

      await this.categorizeStudents(students);
      this.sortStudents();

      this.numTotalStudents = this.needsAHelpingHandStudents.length + this.workingAtAnAppropriateLevelStudents.length + this.readyToBeChallengedStudents.length + this.timeToPracticeStudents.length;
    }
    this.didLoad = !!this.currentClass;
    this.hasData = this.studentsWithData > 0;
  }

  public async categorizeStudents(students: StudentContext[]) {
    this.needsAHelpingHandStudents = [];
    this.workingAtAnAppropriateLevelStudents = [];
    this.readyToBeChallengedStudents = [];
    this.timeToPracticeStudents = [];

    for (let student of students) {
      let studentCategory = await this.categorizationService.categorizeStudent(student);
      switch (studentCategory) {
        case Category.NeedsAHelpingHand:
          this.needsAHelpingHandStudents.push(student);
          break;
        case Category.WorkingAtAnAppropriateLevel:
          this.workingAtAnAppropriateLevelStudents.push(student);
          break;
        case Category.ReadyToBeChallenged:
          this.readyToBeChallengedStudents.push(student);
          break;
        case Category.TimeToPractice:
          this.timeToPracticeStudents.push(student);
          break;
      }
    }
  }

  private getSummaryStudents(students: StudentContext[]): StudentContext[] {
    let summaryStudents: StudentContext[] = [];
    let productsToExclude: string[] = [
      'AR'
    ];

    students.forEach(student => {
      if (student.latestAssessment) {
        if (productsToExclude.includes(student.latestAssessment.productId)) {
          student.latestAssessment = student.assessments.filter(assessment => !productsToExclude.includes(assessment.productId))[0] ?? null;
        }
      }
      summaryStudents.push(student);
    });

    return summaryStudents;
  }

  private getNumberOfStudentsWithData(students: StudentContext[]): number {
    let studentsWithData = [];

    // don't include readingPractices if subject is math
    if (this.subjectService.selectedSubject$.getValue() == SubjectTypes.MATH) {
      studentsWithData = students.filter(student =>
        student.latestAssessment !== null || student.skills.length > 0
      );
      return studentsWithData.length
    }
    studentsWithData = students.filter(student =>
      student.latestAssessment !== null || student.skills.length > 0 || student.readingPractices.length > 0
    );

    return studentsWithData.length;
  }

  private sortStudents() {
    StudentSorter.sortStudents(this.needsAHelpingHandStudents);
    StudentSorter.sortStudents(this.workingAtAnAppropriateLevelStudents);
    StudentSorter.sortStudents(this.readyToBeChallengedStudents);
    StudentSorter.sortStudents(this.timeToPracticeStudents);
  }

  public checkAnimationStatus(animationStatus: IntroAnimationStatus) {
    if (animationStatus.complete) {
      this.isIntroAnimationComplete = true;
      // show pendo element for pendo guide
      setTimeout(() => { this.showAnimationCompletePendoElement = true }, this.showPendoMs + this.fadeInAnimationMs)
    }
    if (animationStatus.enabled) {
      this.playFadeInAnimation = true;
      // turn off animation after it has been played
      setTimeout(() => { this.playFadeInAnimation = false }, this.fadeInAnimationMs);
    }
  }
}
