import { Injectable } from "@angular/core";
import { StarLexileCalculatorService } from "./star-lexile-calculator.service";
import { Assessment } from "src/app/shared/models/assessment.model";
import { MyONLexileCalculatorService } from "./myon-lexile-calculator.service";
import { ReadingPractice } from "src/app/shared/models/reading-practice.model";
import { ARLexileCalculatorService } from "./ar-lexile-calculator.service";

@Injectable({ providedIn: 'root' })
export class LexileCalculatorService {
  constructor(
    private starLexileCalculatorService: StarLexileCalculatorService,
    private arLexileCalculatorService: ARLexileCalculatorService,
    private myonLexileCalculatorService: MyONLexileCalculatorService
  ) { }

  public calculateLexileLevels(assessments: Assessment[], readingPractices: ReadingPractice[]): number | null {

    let lexileLevel = this.starLexileCalculatorService.calculateStarLexile(assessments);

    if (lexileLevel != null) {
      return lexileLevel;
    }

    lexileLevel = this.arLexileCalculatorService.calculateARLexile(readingPractices);

    if (lexileLevel != null) {
      return lexileLevel;
    }

    lexileLevel = this.myonLexileCalculatorService.calculateMyONLexile(readingPractices);

    return lexileLevel;
  }
}
