import { Injectable } from '@angular/core';
import { FreckleLinkGeneratorService } from 'src/app/services/link-generators/freckle-link-generator.service';
import { LaliloLinkGeneratorService } from 'src/app/services/link-generators/lalilo-link-generator.service';
import { ProductAppTags, ProductTypeGroups } from 'src/app/services/product-info/product-info.service';
import { ActionLink, BaseRecommendationSkill, ProductKeys, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { Student } from 'src/app/shared/models/student.model';
import { Link } from 'src/app/util/link/link';
import { OverlapSkillRecommendationService } from '../../overlap-skill/overlap-skill-recommendation.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { ClassesService } from 'src/app/services/classes/classes.service';

@Injectable({
  providedIn: 'root'
})
export class LaliloActionRecommendationService {
  private readonly LALILO_IMAGE_PATH = `../../../../../../assets/logos/lalilo_logo.svg`;
  private readonly FRECKLE_IMAGE_PATH = `../../../../../../assets/logos/freckle_logo.svg`;
  private readonly FINAL_LALILO_LESSON_ID = '1017';
  private readonly LALILO_HELP_GETTING_STARTED_URL = 'https://lalilo-help.renaissance.com/hc/en-us/categories/8781333653019-Getting-Started';

  constructor(
    private laliloLinkGenService: LaliloLinkGeneratorService,
    private freckleLinkGenService: FreckleLinkGeneratorService,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private overlapSkillRecommendationService: OverlapSkillRecommendationService,
    private featureToggleService: FeatureToggleService,
    private classesService: ClassesService
  ) { }

  public async getLaliloActionForRecommendation(skill: BaseRecommendationSkill, laliloSkillMetadata: SkillMetadata | null, freckleMetadata: SkillMetadata | null, students: Student[]): Promise<ActionLink | null> {
    let actionLink: ActionLink | null = null;

    const isFreckleLaliloOverlapSkill = !!freckleMetadata && this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(freckleMetadata.contentActivityId);
    let classAppTags = this.classesService.selectedClass$.getValue()?.appTags ?? [];

    let classHasLalilo = classAppTags.some(x=> x === ProductAppTags.Lalilo) || !await this.featureToggleService.isTrueAsync('data-follows-student') ;

    // Recommend Freckle Activity Feed for final Lalilo lesson
    if (skill.skillId === this.FINAL_LALILO_LESSON_ID && skill.recommendationType === RecommendationType.NeedsChallenge && classHasLalilo) {
      let freckleUrl = '';
      if (await this.featureToggleService.isTrueAsync('use-new-launch')) {
        freckleUrl = this.freckleLinkGeneratorV2Service.getActivityFeedDeepLink(students);
      }
      else {
        freckleUrl = this.freckleLinkGenService.getActivityFeedLink(students);
      }

      actionLink = {
        link: new Link(freckleUrl),
        name: 'View your Freckle Activity Feed to learn more',
        description: 'View your Freckle Activity Feed to learn more',
        imageUrl: this.FRECKLE_IMAGE_PATH,
        type: ProductKeys.Freckle
      };
    }

    // Recommend Lalilo learn more link for Freckle Lalilo overlap skill
    // Only show if skill is not stuck or if product is adaptive
    else if (isFreckleLaliloOverlapSkill &&
      (skill.recommendationType != RecommendationType.NeedsHelp ||
        skill.practiceProductType && ProductTypeGroups.AdaptivePracticeIds.includes(skill.practiceProductType))) {
      actionLink = {
        link: new Link(this.LALILO_HELP_GETTING_STARTED_URL),
        name: 'Learn about Lalilo Adaptive practice',
        description: '',
        imageUrl: this.LALILO_IMAGE_PATH,
        type: ProductKeys.Lalilo
      };
    }

    // Recommend lalilo for targeted practice for every category except Needs Challenge
    else if (laliloSkillMetadata && classHasLalilo &&
      skill.practiceProductType &&
      ProductTypeGroups.TargetedPracticeIds.includes(skill.practiceProductType) &&
      skill.recommendationType != RecommendationType.NeedsChallenge
    ) {
      let laliloUrl = '';

      if (await this.featureToggleService.isTrueAsync('use-new-launch')) {
        laliloUrl = await this.laliloLinkGeneratorV2Service.getLaliloAssignLinkForStudents(laliloSkillMetadata?.contentActivityId, students);
      }
      else {
        laliloUrl = await this.laliloLinkGenService.getLaliloAssignLinkForStudents(laliloSkillMetadata?.contentActivityId, students);
      }

      if (laliloUrl) {
        actionLink = {
          link: new Link(laliloUrl),
          name: 'Skill Practice in Lalilo',
          description: 'Reassign practice on this skill',
          imageUrl: this.LALILO_IMAGE_PATH,
          type: ProductKeys.Lalilo
        };
      }
    }

    return actionLink;
  }
}
