import { Injectable, OnDestroy } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { AppNavItemConfigurationService } from './app-nav-item-configuration.service';
import { AppNav } from 'src/app/shared/models/appnav.model';
import { AvailableAppNavService } from './available-app-nav.service';
import { Subscription } from 'rxjs';
import { ReportLinkGeneratorService } from '../link-generators/report-link-generator.service';
import { UserService } from '../user/user.service';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { ReportSearchFilter } from '../report/report-search-filter.service';
import { ClassContext } from 'src/app/shared/models/class.model';
import { ContextDataService } from '../context-data/context-data.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavItemService implements OnDestroy {

  public appNav: AppNav[] = [];
  private isInitialized = false;
  private isDegradedNav = false;
  private skipAvailabilityCheck = false;
  private subscriptions: Subscription[] = [];
  private currentClass: ClassContext | undefined;

  constructor(
    private _appNavItemConfigService: AppNavItemConfigurationService,
    private _appNavService: AvailableAppNavService,
    private _reportLinkGeneratorService: ReportLinkGeneratorService,
    private _contextDataService: ContextDataService,
    private _featureToggleService: FeatureToggleService,
    private _reportSearchFilter: ReportSearchFilter,
    private _userService: UserService
  ) {
    let contextDataSub = this._contextDataService.classDataContext$.subscribe((classData: ClassContext | undefined) => {
      if (classData) {
        this.currentClass = classData;
      }
    });
    this.subscriptions.push(contextDataSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async initialize() {
    if (this.isInitialized) return;

    await this._userService.isLoggedIn();
    let skipAppNavCall = this._userService.getSkipNavAccessCheck();

    await this._appNavService.isLoaded();
    this.appNav = this._appNavService.getAppNav();
    this.isDegradedNav = this._appNavService.getIsDegraded();

    if (this.isDegradedNav || skipAppNavCall) {
      this.skipAvailabilityCheck = true;
    }

    this.isInitialized = true;
  }

  public hasApp(ids: string[]) {
    let uniqueAppIds = this.getDistinctAvailableAppIds();
    return uniqueAppIds.some(id => ids.includes(id));
  }

  public getAvailableApps(): AppNavItem[] {
    let myAppsNavItems: AppNavItem[] = [];
    if (this._featureToggleService.isTrue("use-new-launch") && this._featureToggleService.isTrue("launch-ar-direct-to-recordbook")) {
      myAppsNavItems = this._appNavItemConfigService.getMyAppsNavs2();
    }
    else{
      myAppsNavItems = this._appNavItemConfigService.getMyAppsNavs();
    }

    if (this.skipAvailabilityCheck) {
      return myAppsNavItems;
    }
    let appNavItems = this.getAvailableTilesRecursive(myAppsNavItems);

    return appNavItems;
  }

  public getAvailableMyData(): AppNavItem[] {
    let myDataNavs = this._appNavItemConfigService.getMyDataNavs();
    if (this.skipAvailabilityCheck) {
      return myDataNavs;
    }
    let appNavItems = this.getAvailableTilesRecursive(myDataNavs);

    return appNavItems;
  }

  public getIsDegraded(): boolean {
    return this.isDegradedNav;
  }

  public async resolveLinks(appNavItems: AppNavItem[]): Promise<AppNavItem[]> {
    let headerAppItems: AppNavItem[] = [];
    for (let appNavItem of appNavItems) {
      let resolvedItem = await this.resolveLinksRecursive(appNavItem);
      headerAppItems.push(resolvedItem!);
    }
    return headerAppItems;
  }

  public async getAvailableSearchReports(): Promise<AppNavItem[]> {
    let searchReportNavs = this._appNavItemConfigService.getSearchReports();
    if (this.skipAvailabilityCheck) {
      return searchReportNavs;
    }
    let appNavItems = this.getAvailableTilesRecursive(searchReportNavs);

    if (await this._featureToggleService.isTrueAsync('tj-dashboard-tile-hide-list-chicago')) {
      appNavItems = this._reportSearchFilter.removeReportForChicago(appNavItems);
    }

    if (await this._featureToggleService.isTrueAsync('tj-dashboard-tile-hide-custom-list')) {
      appNavItems = this._reportSearchFilter.removeReportForCustom(appNavItems);
    }

    if (await this._featureToggleService.isTrueAsync('reports-fl-terminology-replacement')) {
      appNavItems = this._reportSearchFilter.getTranslationForFlorida(appNavItems);
    }

    return appNavItems;
  }

  public async getReportUrl(appNavItem: AppNavItem): Promise<string> {
    let reportUrl = '';
    if (appNavItem.tileLinkType && this.currentClass) {
      let tileLinkType = appNavItem.tileLinkType;
      reportUrl = await this._reportLinkGeneratorService.generateRedirectLink(tileLinkType, this.currentClass, appNavItem.url);
    }
    return reportUrl;
  }

  private getAvailableTilesRecursive(appNavItems: AppNavItem[]): AppNavItem[] {
    if (this.skipAvailabilityCheck) {
      return [...appNavItems];
    }
    let availableAppNavItems: AppNavItem[] = [];
    for (let appNavItem of appNavItems) {
      let availableAppNavItem = undefined;
      // only show if valid
      if (this.isAppTaskAvailable(appNavItem)) {
        if (appNavItem.children) {
          appNavItem.children = this.getAvailableTilesRecursive(appNavItem.children);
        }
        // hide unless no children are configurable or it has non-zero children
        if (!appNavItem.children ||
          appNavItem.children.length !== 0
        ) {
          availableAppNavItem = appNavItem;
        }
      }
      if (availableAppNavItem) {
        availableAppNavItems.push(availableAppNavItem);
      }
    }
    return availableAppNavItems;
  }

  private isAppTaskAvailable(appNavItem: AppNavItem): boolean {
    // if task ID not specified, only need app ID
    // if app ID not specified, it's available
    return this.appNav.some(app =>
      !appNavItem.applicationId ||
      !appNavItem.taskId && app.applicationId == appNavItem.applicationId ||
      app.taskId == appNavItem.taskId);
  }

  private async resolveLinksRecursive(appNavItem: AppNavItem): Promise<AppNavItem> {
    let resolvedAppNavItem: AppNavItem = { ...appNavItem };
    if (appNavItem.tileLinkType && this.currentClass) {
      let tileLinkType = appNavItem.tileLinkType;
      if (appNavItem.url) {
        let reportsPageLink = await this._reportLinkGeneratorService.generateRedirectLink(tileLinkType, this.currentClass, appNavItem.url);
        resolvedAppNavItem.url = reportsPageLink;
      }
      else {
        let reportsPageLink = await this._reportLinkGeneratorService.generateRedirectLink(tileLinkType, this.currentClass);
        resolvedAppNavItem.url = reportsPageLink;
      }
    }
    if (appNavItem.children) {
      resolvedAppNavItem.children = [];
      for (let child of appNavItem.children) {
        let resolvedChild = await this.resolveLinksRecursive(child);
        resolvedAppNavItem.children.push(resolvedChild!);
      }
    }
    return resolvedAppNavItem;
  }

  private getAppNavChildren(appNavItems: AppNavItem[]): AppNavItem[] {
    let resolvedItems: AppNavItem[] = [];
    for (let appNavItem of appNavItems) {
      let resolvedItem = this.getAppNavChildrenRecursive(appNavItem);
      resolvedItems = resolvedItems.concat(resolvedItem!);
    }
    return resolvedItems;
  }

  private getAppNavChildrenRecursive(appNavItem: AppNavItem): AppNavItem[] {
    let flattenedApps: AppNavItem[] = [];
    if (appNavItem.children) {
      for (let child of appNavItem.children) {
        flattenedApps = flattenedApps.concat(this.getAppNavChildrenRecursive(child));
      }
    }
    flattenedApps.push(appNavItem)
    return flattenedApps;
  }

  private getDistinctAvailableAppIds(): string[] {
    let apps = this.getAvailableApps();
    let appIds = this.getAppNavChildren(apps).map(x => {
      return x.applicationId
    });
    let uniqueAppIds = [...new Set(appIds)] as string[];

    return uniqueAppIds;
  }
}
