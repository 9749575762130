<div class='search-results-container'>
  <div class="searchbox-container" [ngClass]="{'searchbox-container-shadow': isSearchboxScroll}" id="search-input-container">
    <button id="skip-nav" class="search-back-button" (click)="goBack()">
      <span class="glyph-chevron-previous"></span>
      <span> Back</span>
    </button>
    <div class="search-input-container">
      <input #searchBox (keyup.enter)="search(searchBox.value)" id="pendo-search-query" class="search-input"
        data-component="search-input" aria-label="Search" type="text" [value]="query">
      <button type="button" (click)="search(searchBox.value)" aria-label="Search" data-component="search-button"><span
          class="glyph-search2"></span></button>
    </div>
  </div>

  <ng-container *ngIf="showOops; else searchResultsTable">
    <div class="search-error-container renaissance-style-scoped">
      <object type="image/svg+xml" role="presentation" alt="" class="oops-image"
          data="../assets/hero-search_error-ani.svg"></object>
      <div class="oops-message-container">
        <div class="oops-message-header">
          Something Went Wrong
        </div>
        <div class="oops-message-content">
          We ran into a problem getting those search results.
        </div>
        <a class="ren-btn" href="/search?q={{query}}">Try Again</a>
      </div>
    </div>
  </ng-container>

  <ng-template #searchResultsTable>
    <div class="search-results-table" (scroll)="onScroll($event)">
      <table>
        <app-search-filters [filters]="queryFilters" (updateFilters)="updateFilters($event)"></app-search-filters>
        <mat-spinner diameter="60" mode="indeterminate" class="mat-spinner search-spinner" *ngIf="isLoading"></mat-spinner>
        <tbody *ngIf="searchResults.length > 0">
          <hr class="divider">
          <tr class="search-results-tr" [attr.id]="result.product_skill_id" tabindex="-1" *ngFor="let result of searchResults;" [searchResult]="result" search-result></tr>
          <div class="more-results-container">
            <button *ngIf="!isLoading && moreResults" id="pendo-guide-search-more-results" class="search-more-results" (click)="getMoreResults()">More Results</button>
            <mat-spinner *ngIf="isLoading" diameter="20" mode="indeterminate" class="mat-spinner more-results-spinner"></mat-spinner>
            <div class="more-results-footer">
              <span class="glyph-lamp2 tips-glyph"></span>
              <div class="more-results-footer-text">
                Having trouble finding what you're looking for?
                <a id="pendo-guide-learn-more-search-tips" class="more-results-tips" href="#" (click)="$event.preventDefault()" role="button">Try these tips</a>
              </div>
            </div>
          </div>
        </tbody>
        <div *ngIf="searchResults.length == 0 && query != '' && !isLoading" class="no-search-results">
          <hr class="divider">
          <div class="no-search-results-text">
          Sorry, no results are available. Please adjust the selections above.
          </div>
        </div>
        <div class="footer-spacer" *ngIf="searchResults.length == 0 || isLoading"></div>
        <app-footer></app-footer>
      </table>
    </div>
  </ng-template>
</div>
