import { Component, Input } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';

@Component({
  selector: 'inactive-card',
  templateUrl: './inactive-card.component.html',
  styleUrls: ['./inactive-card.component.scss']
})

export class InactiveCardComponent {
  @Input() public inactiveStudents: StudentContext[] = [];

  inactiveCardName = "Inactive";
  inactiveCardSubText = "(no assignments attempted in the last 14 days)";
  inactiveCardIcon = "/assets/skills_practice_inactive.svg";
}
