import { Injectable } from '@angular/core';
import { GraphqlService } from '../graphql/graphql.service';
import { AssessmentBenchmark, AssessmentSummaryCategory } from 'src/app/shared/models/assessment-benchmarks.model';
import { BarColorOrders } from './benchmark-bar-colors';

@Injectable({
  providedIn: 'root'
})
export class BenchmarksService {

  private assessmentCategories: AssessmentSummaryCategory[] | undefined;
  constructor(
    private graphql: GraphqlService
  ) { }

  public async getBenchmarkCategories(): Promise<AssessmentSummaryCategory[]> {
    if(!this.assessmentCategories) {

        const response = await this.graphql.getAssessmentBenchmarks();
        const benchmarks = await response.assessmentBenchmarks;
        this.assessmentCategories = this.getCategoriesFromBenchmarks(benchmarks);
    }
    return this.assessmentCategories;
  }

  public async getBenchmarkCategoryFromPercentileRank(percentileRank: number) {
    let categories = await this.getBenchmarkCategories();
    for (let category of categories) {
      if (percentileRank >= category.minPercentileRank && percentileRank <= category.maxPercentileRank) {
        return category.title;
      }
    };
    return '';
  }

  private getCategoriesFromBenchmarks(benchmarks: AssessmentBenchmark[]): AssessmentSummaryCategory[] {
    let categories: AssessmentSummaryCategory[] = [];
    benchmarks.forEach(benchmark => {
      let category: AssessmentSummaryCategory = {
        title: benchmark.categoryName,
        subtitle: this.getSubtitle(benchmark.minPercentileRank, benchmark.maxPercentileRank),
        color: this.getColor(benchmark.numberOfCategoryLevels, benchmark.categoryLevel),
        minPercentileRank: benchmark.minPercentileRank,
        maxPercentileRank: benchmark.maxPercentileRank,
        isProficient: benchmark.proficient,
        categoryOrder: benchmark.categoryLevel,
        productId: benchmark.productId,
        students: []
      }
      categories.push(category);
    });
    categories.sort((a, b) => this.compareCategories(a, b));
    return categories;
  }

  private compareCategories(a: AssessmentSummaryCategory, b: AssessmentSummaryCategory) {
    if (a.categoryOrder < b.categoryOrder) {
      return -1
    }
    if (a.categoryOrder > b.categoryOrder) {
      return 1
    }
    return 0;
  }

  private getSubtitle(minPr: number, maxPr: number) {
    return `(PR ${minPr}-${maxPr})`
  }

  private getColor(numCategories: number, categoryLevel: number) {
    let barColorOrder = BarColorOrders[numCategories as keyof BarColorOrders];
    return barColorOrder[categoryLevel-1];
  }
}
