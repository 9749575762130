<div class="searchbox-container" [ngClass]="{'searchbox-container-shadow': isSearchboxScroll}" id="search-input-container">
  <button *ngIf="showBackButton" id="skip-nav" class="search-back-button" (click)="backClicked()">
    <span class="glyph-chevron-previous"></span>
    <span>{{searchConstants.SEARCH_RESULTS.BACK}}</span>
  </button>
  <div class="search-input-container">
    <input #searchInput
      (keyup.enter)="searchService.updateQuery(searchInput.value)"
      [(ngModel)]="query"
      [ngClass]="{'start-typing' : query.length}"
      [placeholder]="searchConstants.SEARCHBOX.PLACEHOLDER"
      aria-label="Search"
      class="search-input"
      id="pendo-search-query"
      type="text">
    <button type="button"
      (click)="searchService.updateQuery(searchInput.value)"
      (keyup.enter)="searchService.updateQuery(searchInput.value)"
      aria-label="Search"
      class="search-button"
      data-component="search-button">
      <span class="glyph-search2"></span>
    </button>
    <div class="search-help-text-outside" *ngIf="query && !isSearchboxScroll">
      <div>{{searchConstants.SEARCHBOX.PLACEHOLDER}}</div>
    </div>
    <!-- TODO: Learn More Dialog is not zoom accessible. -->
    <p class="learn-more" *ngIf="showLearnMore">
      <a (click)="$event.preventDefault()"
        class="learn-more-text"
        href="#"
        id="pendo-guide-learn-more-search"
        role="button">
        {{searchConstants.SEARCHBOX.LEARN_MORE}}
      </a>
    </p>
  </div>
</div>
