import { Injectable } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';

@Injectable({
  providedIn: 'root'
})
export class SkillsPracticeSortingService {

  constructor() { }

  public sortBySkillShortName(skillsPracticeCards: SkillsPracticeCard[]): SkillsPracticeCard[] {
    return skillsPracticeCards.slice().sort((a, b) => {
      // ascending a - b
      let shortNameProgressionOrderA = a.skillMetadata.skillShortName + a.skillMetadata.skillProgressionOrder;
      let shortNameProgressionOrderB = b.skillMetadata.skillShortName + b.skillMetadata.skillProgressionOrder;

      return shortNameProgressionOrderA.localeCompare(shortNameProgressionOrderB);
    });
  }

  public sortByPracticing(skillsPracticeCards: SkillsPracticeCard[]): SkillsPracticeCard[] {
    return skillsPracticeCards.slice().sort((a, b) => {
      let sumB = b.justStartingStudents.length + b.stuckStudents.length + b.practiceMakesPerfectStudents.length + b.readyForChallengeStudents.length;
      let sumA = a.justStartingStudents.length + a.stuckStudents.length + a.practiceMakesPerfectStudents.length + a.readyForChallengeStudents.length;

      if (sumB - sumA === 0) {
        return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
      }
      return sumB - sumA;
    });
  }

  public sortByStuck(skillsPracticeCards: SkillsPracticeCard[]): SkillsPracticeCard[] {
    const skillsPracticeCardsToReturn = skillsPracticeCards.slice();
    skillsPracticeCardsToReturn.sort((a, b) => {
      // first sort alphabetically
      return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
    });
    skillsPracticeCardsToReturn.sort((a,b) => {
      // sort by # of stuck students
      if (b.stuckStudents.length !== 0 || a.stuckStudents.length !== 0) {
        return b.stuckStudents.length - a.stuckStudents.length;
      }
      // if neither have stuck students
      else  {
        // if one has practice makes perfect and the other doesn't, the one with it is first
        let bHasPracticeMakesPerfect = b.practiceMakesPerfectStudents.length !== 0;
        let aHasPracticeMakesPerfect = a.practiceMakesPerfectStudents.length !== 0;
        if (bHasPracticeMakesPerfect && !aHasPracticeMakesPerfect) {
          return 1;
        }
        else if (!bHasPracticeMakesPerfect && aHasPracticeMakesPerfect) {
          return -1;
        }
        // if neither have practice makes perfect students
        // if one has ready for challenge and the other doesn't, the one with it is first
        else if (!bHasPracticeMakesPerfect && !aHasPracticeMakesPerfect) {
          let bHasReadyForChallenge = b.readyForChallengeStudents.length !== 0;
          let ahasReadyForChallenge = a.readyForChallengeStudents.length !== 0;
          if (bHasReadyForChallenge && !ahasReadyForChallenge) {
            return 1;
          }
          else if (!bHasReadyForChallenge && ahasReadyForChallenge) {
            return -1;
          }
        }
        return 0;
      }
    });

    return skillsPracticeCardsToReturn;
  }

  public getTopStuckCards(skillsPracticeCards: SkillsPracticeCard[], recommendedSkillsToShow: number): SkillsPracticeCard[] { 
    // only operate on stuck cards
    const skillsPracticeCardsToReturn = skillsPracticeCards.slice().filter(card => card.stuckStudents.length !== 0);

    skillsPracticeCardsToReturn.sort((a, b) => {
      // first sort alphabetically
      return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
    });

    return skillsPracticeCardsToReturn
      .sort((a, b) => {
        // Most stuck students first
        if ((b.stuckStudents.length !== 0 || a.stuckStudents.length !== 0)
          && b.stuckStudents.length !== a.stuckStudents.length
        ) {
          return b.stuckStudents.length - a.stuckStudents.length;
        }

        const standardProgressionOrderA = (a.skillMetadata.standardProgressionOrder !== undefined) ? a.skillMetadata.standardProgressionOrder : -1;
        const standardProgressionOrderB = (b.skillMetadata.standardProgressionOrder !== undefined) ? b.skillMetadata.standardProgressionOrder : -1;
        const skillProgressionOrderA = (a.skillMetadata.skillProgressionOrder !== undefined) ? a.skillMetadata.skillProgressionOrder : -1;
        const skillProgressionOrderB = (b.skillMetadata.skillProgressionOrder !== undefined) ? b.skillMetadata.skillProgressionOrder : -1;

        // Followed by the skill with the lowest standardProgression order
        if (standardProgressionOrderA > standardProgressionOrderB) {
          return 1;
        }
        if (standardProgressionOrderA < standardProgressionOrderB) {
          return -1;
        }
    
        // Followed by the skill with the lowest skill progression order
        if (skillProgressionOrderA > skillProgressionOrderB) {
          return 1;
        }
        if (skillProgressionOrderA < skillProgressionOrderB) {
          return -1;
        }
    
        return 0;
      })
      // Then only take the amount you're looking for
      .slice(0, recommendedSkillsToShow);
  }

  public getTopReadyForAChallengeCards(skillsPracticeCards: SkillsPracticeCard[], recommendedSkillsToShow: number): SkillsPracticeCard[] { 
    // only operate on ready for a challenge cards
    const skillsPracticeCardsToReturn = skillsPracticeCards.slice().filter(card => card.readyForChallengeStudents.length !== 0);

    skillsPracticeCardsToReturn.sort((a, b) => {
      // first sort alphabetically
      return a.skillMetadata.skillShortName.localeCompare(b.skillMetadata.skillShortName);
    });

    return skillsPracticeCardsToReturn
      .sort((a, b) => {
        // Most rfc students first
        if ((b.readyForChallengeStudents.length !== 0 || a.readyForChallengeStudents.length !== 0)
            && b.readyForChallengeStudents.length !== a.readyForChallengeStudents.length
        ) {
          return b.readyForChallengeStudents.length - a.readyForChallengeStudents.length;
        }

        const standardProgressionOrderA = (a.skillMetadata.standardProgressionOrder !== undefined) ? a.skillMetadata.standardProgressionOrder : -1;
        const standardProgressionOrderB = (b.skillMetadata.standardProgressionOrder !== undefined) ? b.skillMetadata.standardProgressionOrder : -1;
        const skillProgressionOrderA = (a.skillMetadata.skillProgressionOrder !== undefined) ? a.skillMetadata.skillProgressionOrder : -1;
        const skillProgressionOrderB = (b.skillMetadata.skillProgressionOrder !== undefined) ? b.skillMetadata.skillProgressionOrder : -1;

        // Followed by the skill with the highest standardProgression order
        if (standardProgressionOrderA < standardProgressionOrderB) {
          return 1;
        }
        if (standardProgressionOrderA > standardProgressionOrderB) {
          return -1;
        }
    
        // Followed by the skill with the highest skill progression order
        if (skillProgressionOrderA < skillProgressionOrderB) {
          return 1;
        }
        if (skillProgressionOrderA > skillProgressionOrderB) {
          return -1;
        }
    
        return 0;
      })
      // Then only take the amount you're looking for
      .slice(0, recommendedSkillsToShow);
  }
}
