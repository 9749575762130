<div class="source-container">
  <div class="top-text">
    <img [src]="productSettings?.logoPath" [alt]="productSettings?.logoAltText" class="product-logo" />
    <h2 class="product-text">{{ productSettings?.displayName }}</h2>
    <div class="tags">
      <div *ngFor="let tag of filteredTags">
        <ng-container *ngIf="!productSettings?.resultUISettings?.descriptionTags?.includes(tag.key) &&
          searchConstants.SEARCH_RESULTS_ALLOWED_TAG_KEYS.includes(tag.key)">
          <div *ngIf="tag.key === 'grades'; else other_filter_tags" class="tag">
            {{ formatTag(tag.key, tag.value) }}
          </div>
          <ng-template #other_filter_tags>
            <div *ngFor="let value of tag.value" class="tag">
              {{ formatTag(tag.key, [value]) }}
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<h3 class="top-text padding-left-5px" [innerHTML]="searchResult.name"></h3>
<div *ngIf="productSettings?.resultUISettings?.hasStandardName" class="bottom-text padding-left-5px">
  {{ formatStandardName(searchResult) }}
</div>
<div *ngIf="productSettings?.resultUISettings?.hasDescription" class="bottom-text padding-left-5px" [innerHTML]="searchResult.description"></div>
<div class="description-tags-container bottom-text">
  <div class="description-tag" *ngFor="let tag of descriptionTags">
    <b>{{ tag.key }}:</b> {{ tag.value }}
  </div>
</div>
<ng-container *ngIf="productSettings?.resultUISettings?.hasActivityAdminTemplate">
  <ng-container *ngIf="!adminHandlerService.isAdmin(); else adminTemplate">
    <a (click)="activityNavigation()"
      (keydown.enter)="activityNavigation()"
      [attr.data-test]="productSettings?.resultUISettings?.dataTest"
      [id]="productSettings?.resultUISettings?.id"
      class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action"
      tabindex="0">
      <ng-container *ngTemplateOutlet="activityTextTemplate"></ng-container>
    </a>
  </ng-container>
  <ng-template #adminTemplate>
    <div class="view-activity-margins">
      <available-to-teachers
        [data-test]="productSettings?.displayName?.toLowerCase() + '-available-to-teachers-text'"
        class="search"
        id="pendo-available-to-teachers-text-search-result">
      </available-to-teachers>
    </div>
  </ng-template>
</ng-container>
<a *ngIf="productSettings?.resultUISettings?.hasActivityLink"
  (click)="activityNavigation()"
  (keydown.enter)="activityNavigation()"
  [attr.data-test]="productSettings?.resultUISettings?.dataTest"
  [id]="productSettings?.resultUISettings?.id"
  class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action"
  tabindex="0">
  <ng-container *ngTemplateOutlet="activityTextTemplate"></ng-container>
</a>
<a *ngIf="productSettings?.resultUISettings?.hasActivityUrl"
  (click)="activityNavigation()"
  (keydown.enter)="activityNavigation()"
  [attr.data-test]="productSettings?.resultUISettings?.dataTest"
  [href]="searchResult.url + productSettings?.urlSettings?.urlSuffix"
  [id]="productSettings?.resultUISettings?.id"
  [target]="productSettings?.urlSettings?.target"
  class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action">
  <ng-container *ngTemplateOutlet="activityTextTemplate"></ng-container>
</a>
<ng-template #activityTextTemplate>
  <span class="activity-text">
    {{ productSettings?.resultUISettings?.label }}
    <ng-container *ngIf="productSettings?.resultUISettings?.hasCustomActivityButton">
      <span class="activity-text">{{ activityButtonLabel }}</span>
    </ng-container>
    <span class="glyph-chevron-next"></span>
  </span>
</ng-template>
<hr class="divider">
