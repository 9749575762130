<ol class="student-container" role="list">
  <ng-container *ngFor="let student of students">
    <li>
      <button *ngIf="enablePopup" class="student-item show-hover-border" role="button" alt=""
        title="{{student.firstName}} {{student.lastName}}" attr.aria-label="{{student.firstName}} {{student.lastName}}"
        (click)="openModal(student)">

        <app-student-icon [student]="student"></app-student-icon>
      </button>

      <div *ngIf="!enablePopup" class="inactive-student-item">
        <app-student-icon [student]="student"></app-student-icon>
      </div>


    </li>
  </ng-container>
</ol>