<div class="container">
  <div class="header">
    <app-student-bubble [student]="data.student"></app-student-bubble>
    <h2 tabindex="0">Recommendations for {{data.student.firstName}}</h2>
  </div>

  <div class="body" *ngIf="isLoaded">
    <div data-test="assessment-section" *ngIf="data.student.latestAssessment && isShortTestTime()">
      <h3 class="assessment-header">Assessment</h3>
      <div class="short-test-time-description-container">
        <img class="star-logo-graphic" alt="" src="../../../../../../assets/logos/star_logo.svg">
        <p>{{data.student.firstName}}'s Star {{getSubjectString()}} test was completed in {{getTestTimeString()}}.
          We recommend a retest, with emphasis on taking time and trying hard.</p>
      </div>
    </div>

    <no-recommendation [recommendations]="recommendations" [classProducts]="data.classProducts" [studentFirstName]="data.student.firstName"></no-recommendation>

    <div class="skills-practice" *ngIf="hasAnyRecommendations(recommendations)">
      <h3 class="practice-header" *ngIf="allRecommendationsHaveLinks()">Skills Practice & Instruction</h3>
      <div id="pendo-skill-{{recommendation}}" *ngFor="let recommendation of recommendationsWithLinks"
        class="take-action-skill-list">
        <!-- Skill recommendation -->
        <div class="rationale" [innerHTML]="recommendation.rationale"></div>
        <div class="recommendation-audit"> {{recommendation.recommendationAudit}} </div>
        <div class="skill-description">
          <app-skill-description [skillMetadata]="recommendation.skillMetadata!"
            [hasAmbiguousShortName]="isSkillNameAmbiguous(recommendation)" [isTakeActionSum]="false"></app-skill-description>
          <div *ngIf="recommendationIsTypeNeedsHelp(recommendation)" class="struggling-container">
            <div class="student-bubble-container">
              <app-student-bubble-stack [students]="stuckStudents"></app-student-bubble-stack>
            </div>
            <div class="struggling-text" [innerHTML]="getStrugglingStudentsString()"></div>
          </div>
        </div>
        <app-seen-before [studentIds]="getStuckStudentIds()"
          [skillId]="recommendation.skillMetadata?.contentActivityId!">
        </app-seen-before>
        <take-action-links-v2 [recommendation]="recommendation" (actionClick)="updateCurrentActionInfo($event)" [category]="data.category">
        </take-action-links-v2>
        <br>
      </div>
    </div>

    <div *ngIf="hasMyONMadeRecommendation()">
      <h3 class="practice-header">Reading Practice</h3>
        <div class="rationale">{{myonMadeRecommendation!.rationale}}</div>
        <app-skill-description [hasAmbiguousShortName]="false" [isTakeActionSum]="false"></app-skill-description>
          <app-seen-before [skillId]="myonMadeRecommendation!.projectId" [studentIds]="getMyONMadeStudentIds()"></app-seen-before>
        <myon-made-take-action-link [recommendation]="myonMadeRecommendation!" (actionClick)="updateCurrentActionInfo($event)" [category]="data.category">
        </myon-made-take-action-link>
        <br>
    </div>


  </div>
  <div class="body" *ngIf="!isLoaded">
    <mat-spinner mode="indeterminate" diameter="40" class="mat-spinner"> </mat-spinner>
  </div>
</div>

<div class="footer">
  <hr aria-hidden="true" />
  <div class="footer-button">
    <button class="ren-link" (click)="close($event)">Close</button>
  </div>
</div>
