import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlapSkillRecommendationService {

  private readonly freckleLaliloOverlapSkills = [
    'hftdojap',
    'ulapqkyv',
    'xjubzjrf',
    'ecualoyo',
    'zxjgtmwe',
    'wejebbdc',
    'kcixhavi',
    'ndqyayqv',
    'wsyzggdv',
    'dsnrjogq',
    'vytfbugh',
    'isjflhgd',
    'dsrxinaz',
    'acffxdwy',
    'wmzzvjcm',
    'jkikeuyp',
    'ndhmfvsh',
    'ihgwkdbl',
    'ppwelsxi',
    'pgjmdavp',
    'iwdbldhr',
    'jxmtgokm',
    'rsjhphxz',
    'excryhij',
    'kakzdzsm',
    'valsiduk',
    'afvrzylo',
    'dnvkjdxa',
    'yuiosacj',
    'tsonicxz',
    'pjsihash',
    'ukaelisi',
    'qedsyrkp',
    'hseixazy',
    'jsimdxdh',
    'rdmuofsw',
    'knbopztm',
    'wojjgzmb',
    'mrifqmos',
    'sooyxngs',
    'mxwadwjg',
    'pkjpfxrt',
    'ysefcfgb',
    'xjvldeew',
    'apxnrvub',
    'motwoamg',
    'ecjwkobz',
    'ywukuxzd',
    'paqboppm',
    'ksoqpeos',
    'plepqijh',
    'dfsgjhkl',
    'asdfghjk',
    'zxcvbnml',
    'kjhgfdsa',
    'yurtewqy',
    'qwertyui',
    'mfdcoquf',
    'jedcsxsq',
    'nfddawtp',
    'gsawpxeh',
    'dtboahoe',
    'kgylxmjh',
    'poedtyuw',
    'zxcvbnnm',
    'jsoquenx',
    'lkoijkuh',
    'b96c97fa-10c8-4f97-9565-80e3c5e05987',
    '1011f61e-e69f-e311-9503-005056801da1',
    '1511f61e-e69f-e311-9503-005056801da1',
    '7411f61e-e69f-e311-9503-005056801da1',
    '7611f61e-e69f-e311-9503-005056801da1',
    '7711f61e-e69f-e311-9503-005056801da1',
    'd311f61e-e69f-e311-9503-005056801da1',
    '1110f61e-e69f-e311-9503-005056801da1',
    '1510f61e-e69f-e311-9503-005056801da1',
    '1610f61e-e69f-e311-9503-005056801da1',
    '1210f61e-e69f-e311-9503-005056801da1',
    '1310f61e-e69f-e311-9503-005056801da1',
    '1410f61e-e69f-e311-9503-005056801da1',
    '6e10f61e-e69f-e311-9503-005056801da1',
    '1711f61e-e69f-e311-9503-005056801da1',
    '1911f61e-e69f-e311-9503-005056801da1',
    '1b11f61e-e69f-e311-9503-005056801da1',
    '1c11f61e-e69f-e311-9503-005056801da1',
    '7b11f61e-e69f-e311-9503-005056801da1',
    '7911f61e-e69f-e311-9503-005056801da1',
    '7a11f61e-e69f-e311-9503-005056801da1',
    '7c11f61e-e69f-e311-9503-005056801da1',
    'd511f61e-e69f-e311-9503-005056801da1',
    'd611f61e-e69f-e311-9503-005056801da1',
    '1c10f61e-e69f-e311-9503-005056801da1',
    '1a10f61e-e69f-e311-9503-005056801da1',
    '1b10f61e-e69f-e311-9503-005056801da1',
    '1710f61e-e69f-e311-9503-005056801da1',
    '1810f61e-e69f-e311-9503-005056801da1',
    '1910f61e-e69f-e311-9503-005056801da1',
    '12334c9c-a69f-e311-9503-005056801da1',
    '7010f61e-e69f-e311-9503-005056801da1',
    '7410f61e-e69f-e311-9503-005056801da1',
    '7610f61e-e69f-e311-9503-005056801da1',
    '7310f61e-e69f-e311-9503-005056801da1',
    '7110f61e-e69f-e311-9503-005056801da1'
  ];

  public isFreckleLaliloOverlapSkill(skillId: string): boolean {
    return this.freckleLaliloOverlapSkills.includes(skillId);
  }
}
