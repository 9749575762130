import { Injectable } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { BaseRecommendationSkill, RecommendationType, Recommendation } from '../../../shared/models/recommendation.model';
import { HttpHeaders, HttpParams, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { firstValueFrom, take, catchError, of } from 'rxjs';
import { UserService } from '../../user/user.service';
import { ProductIds } from '../../product-info/product-info.service';
import { SubjectService } from '../../subject/subject.service';
import { SkillBasedActionRecommendationService } from '../action/skill-based-action-recommendation.service';

@Injectable({
  providedIn: 'root'
})
export class StarRecommendationService {
  public readonly starEarlyLiteracyAssessmentType = 'earlyliteracy';
  constructor(
    private userService: UserService,
    private http: HttpClient,
    private subjectService: SubjectService,
    private skillBasedActionRecommendationService: SkillBasedActionRecommendationService
  ) { }

  public async getStarSkillRecommendation(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<Recommendation | null> {
    let starSkillRecommendation: Recommendation | null = null;

    // Get Star skill recommendation
    if (SkillTests.studentHasAssessmentActivity(selectedStudent)
      && DateHelper.isWithinThreeMonthsOfToday(selectedStudent.latestAssessment.completedDate)) {
      let starBaseRecommendationSkill = await this.getStarBaseRecommendationSkill(selectedStudent);
      if (starBaseRecommendationSkill) {

        // Get take action links
        if (starBaseRecommendationSkill.recommendationType === RecommendationType.NeedsHelp) {
          let stuckStudents = SkillTests.getStuckStudentsForSkill(allStudents, starBaseRecommendationSkill.skillId);
          starSkillRecommendation = await this.skillBasedActionRecommendationService.getSkillBasedRecommendation(starBaseRecommendationSkill, stuckStudents, selectedStudent.classProductUsage);
        }
        else {
          starSkillRecommendation = await this.skillBasedActionRecommendationService.getSkillBasedRecommendation(starBaseRecommendationSkill, [selectedStudent], selectedStudent.classProductUsage);
        }

        // Set product ID and rationale
        if (starSkillRecommendation) {
          starSkillRecommendation.productId = selectedStudent.latestAssessment.productId;

          starSkillRecommendation.rationale = `Based on their recent <b>Star</b> score, we recommend seeing if this skill is appropriate for ${selectedStudent.firstName}:`;
        }
      }
    }

    return starSkillRecommendation;
  }

  public async getStarBaseRecommendationSkill(student: StudentContext): Promise<BaseRecommendationSkill | null> {
    const latestAssessmentProductId = student.latestAssessment?.productId;
    const subject = this.subjectService.selectedSubject$.value;

    // No assessments -> no recommendation
    if (!latestAssessmentProductId) {
      return null;
    }

    let baseRecommendationSkillId: string | null = null;

    // Product is Star Early Literacy -> use SEL assessment type
    if (latestAssessmentProductId === ProductIds.StarEarlyLiteracy || latestAssessmentProductId === ProductIds.StarEarlyLiteracySpanish) {
      baseRecommendationSkillId = await this.getStarBaseRecommendationSkillIdForAssessmentType(student, this.starEarlyLiteracyAssessmentType);
    }

    // No SEL assessments (or no recommended skill found for SEL) -> use subject
    if (!baseRecommendationSkillId) {
      baseRecommendationSkillId = await this.getStarBaseRecommendationSkillIdForAssessmentType(student, subject);

      // No recommended skill found for subject -> no recommendation
      if (!baseRecommendationSkillId) {
        return null;
      }
    }

    let baseRecommendationSkill: BaseRecommendationSkill = {
      recommendationType: RecommendationType.Star,
      skillId: baseRecommendationSkillId,
      subject: subject
    };

    return baseRecommendationSkill;
  }

  private async getStarBaseRecommendationSkillIdForAssessmentType(student: StudentContext, assessmentType: string): Promise<string | null> {
    let baseRecommendationSkillId: string | null = null;

    let headers = new HttpHeaders({
      'access-token': this.userService.getRawJwt() ?? ''
    });
    let params = new HttpParams()
      .set('assessmentType', assessmentType);

    // Get recommended skill from RGP
    baseRecommendationSkillId = await firstValueFrom(this.http.get(this.userService.getStarSkillsUrl() + student.renaissanceId, {
      headers: headers,
      params: params,
      responseType: 'text'
    }).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => of(null))
    ));

    return baseRecommendationSkillId;
  }
}
