import { StudentContext } from "src/app/shared/models/student.model";
import { MyONMadeRecommendation } from "./myonMadeRecommendation";
import { SkillAggregate } from "src/app/util/skill-aggregate/skill-aggregate";
import { SkillTests } from "src/app/util/skill-tests/skill-tests";
import { ProductIds } from "../../product-info/product-info.service";
import { Skill } from "src/app/shared/models/skill.model";
import { SkillMetadata } from "src/app/shared/models/skill-metadata.model";
import { GraphqlService } from "../../graphql/graphql.service";
import { Randomizer } from "../../randomizer/randomizer.service";
import { MyONActionRecommendationService } from "../action/myon-action/myon-action-recommendation.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class SkillBasedMyONMadeRecommendationService {
  constructor(
    private graphqlService: GraphqlService,
    private randomizer: Randomizer,
    private myONActionRecommendationService: MyONActionRecommendationService
  ) { }

  public async getFreckleBasedMyONMadeRecommendation(student: StudentContext): Promise<MyONMadeRecommendation | null> {
    if (!SkillTests.studentHasPracticeActivity(student) || !SkillTests.studentHasFreckleActivity(student)) {
      return null;
    }

    const freckleReadingSkills = student.skills.filter(skill => skill.productId == ProductIds.FreckleReading);
    if (freckleReadingSkills.length === 0) {
      return null;
    }

    const aggregatedSkills = SkillAggregate.aggregate(freckleReadingSkills);

    // Don't recommend reading if any skills are stuck
    const stuckSkills = aggregatedSkills.filter(skill => SkillTests.isStuckSkill(skill));
    if (stuckSkills.length > 0) {
      return null;
    }

    const relevantSkills = aggregatedSkills.filter(skill => SkillTests.hasThreeOrMoreItems(skill));
    if (relevantSkills.length == 0) {
      return null;
    }

    const highestSkill = this.getHighestSkillOnProgression(relevantSkills);

    if (highestSkill.standardSetMetadata == null) {
      throw new Error('skill metadata not found');
    }

    let projectsWithSkill = await this.graphqlService.getProjectBySkillId(highestSkill.renaissanceSkillId);

    if (projectsWithSkill.length === 0) {
      return null;
    }

    let selectedProject = this.randomizer.selectRandomInArray(projectsWithSkill);

    let myONActionLink = await this.myONActionRecommendationService.getMyONActionForRecommendation(selectedProject.projectId, student.renaissanceId);

    let recommendation: MyONMadeRecommendation = {
      actionLink: myONActionLink,
      projectId: selectedProject.projectId,
      rationale: `Recommended for ${student.firstName} based on reading practice, skills practice, or assessment results`,
      students: [student]
    };

    return recommendation;
  }

  private getHighestSkillOnProgression(skills: Skill[]): Skill {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrder(a.standardSetMetadata, b.standardSetMetadata))[skills.length - 1];
  }

  // Sort skill/metadata by standard progression order, then skill progression order
  private compareSkillsByProgressionOrder(a: SkillMetadata | undefined, b: SkillMetadata | undefined) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }

    if (a.standardProgressionOrder > b.standardProgressionOrder) {
      return 1;
    }
    if (a.standardProgressionOrder < b.standardProgressionOrder) {
      return -1;
    }

    if (a.skillProgressionOrder > b.skillProgressionOrder) {
      return 1;
    }
    if (a.skillProgressionOrder < b.skillProgressionOrder) {
      return -1;
    }

    return 0;
  }
}
