import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { UserService } from 'src/app/services/user/user.service';
import { Assessment } from 'src/app/shared/models/assessment.model';
import { Student } from 'src/app/shared/models/student.model';
import { DeepLinkContext } from '../product-deep-link/product-deep-link.component';
import { ProductInfoService } from 'src/app/services/product-info/product-info.service';

export interface AssessmentDetailData {
  student: Student;
  assessments: Assessment[];
}

@Component({
  selector: 'app-assessment-detail-modal',
  templateUrl: './assessment-detail-modal.component.html',
  styleUrls: ['./assessment-detail-modal.component.scss']
})
export class AssessmentDetailModalComponent implements OnInit {

  public window: Window;
  public zoneUri: string = '';
  public selectedClass: string | undefined;
  public selectedSubject: string | undefined;

  private subscriptions: Subscription[] = [];

  public assessments: Assessment[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: AssessmentDetailData,
    public ref: MatDialogRef<AssessmentDetailModalComponent>,
    public classesService: ClassesService,
    public subjectService: SubjectService,
    public contextDataService: ContextDataService,
    private productInfoService: ProductInfoService,
    private _userService: UserService
  ) {
    this.window = this.document.defaultView as Window;
  }

  async ngOnInit() {
    let productsToExclude: string[] = [
      'AR'
    ];

    this.assessments = this.data.assessments.filter(x => !productsToExclude.includes(x.productId));
    this.zoneUri = this._userService.getZoneUri();

    let classSubscription = this.classesService.selectedClass$.subscribe(selectedClass => {
      this.selectedClass = selectedClass?.renaissanceClassId;
    });
    this.subscriptions.push(classSubscription);

    let subjectSubscription = this.subjectService.selectedSubject$.subscribe(selectedSubject => {
      this.selectedSubject = selectedSubject.toLowerCase();
    });
    this.subscriptions.push(subjectSubscription);
  }

  get context(): DeepLinkContext {
    return {
      subject: this.selectedSubject ?? '',
      studentRenaissanceId: this.data.student.renaissanceId,
      classRenaissanceId: this.selectedClass ?? '',
      schoolRenaissanceId: this.data.student.schoolRenaissanceId
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public close() {
    this.ref.close();
  }

  getProductName(assessment: Assessment) {
    return this.productInfoService.GetProductNameForAssessment(assessment);
  }
}