import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, firstValueFrom, map, Subscription } from 'rxjs';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { StandardSet } from 'src/app/services/graphql/standard-set-response';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { PendoService } from 'src/app/services/pendo/pendo.service';
import { ProductAppTags } from 'src/app/services/product-info/product-info.service';
import { environment } from 'src/environments/environment';
import { KeyValues } from '../models/key-values.model';
import { ProductSettings } from '../models/products-settings.model';
import { SearchFilter, SearchFilterOptions } from '../models/search-filters';
import { PendoResult, PendoSearchResult, SearchResult } from '../models/search-result.model';
import { PRODUCT_SETTINGS } from '../settings/products.constants';
import * as CONSTANTS from '../settings/search.constants';
import { ProductNavigationService } from './product-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService implements OnDestroy {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageNumber: number = 1;
  public productSettings = PRODUCT_SETTINGS;
  public query$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchApiUrl: string = environment.searchLambdaUrl;
  public searchFilters$: BehaviorSubject<SearchFilter[]> = new BehaviorSubject<SearchFilter[]>(CONSTANTS.SEARCH_FILTERS_SETTINGS);
  public searchResults$: BehaviorSubject<SearchResult[]> = new BehaviorSubject<SearchResult[]>([]);

  private previousSearchFilters: SearchFilter[] = [];
  private previousQuery: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private classesService: ClassesService,
    private featureToggleService: FeatureToggleService,
    private http: HttpClient,
    private licenseInfoService: LicenseInfoService,
    private pendoService: PendoService,
    private productNavigationService: ProductNavigationService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  public setupSearchParameters(): void {
    this.subscriptions.push(combineLatest([this.searchFilters$, this.query$]).pipe(
      debounceTime(100),
      map(([searchFilters, query]) => ({ searchFilters, query })),
      distinctUntilChanged((prev, curr) => {
        const didSearchFiltersChange = !this.deepEqual(this.previousSearchFilters, curr.searchFilters);
        const didQueryChange = prev.query !== curr.query;
        return !(didSearchFiltersChange || didQueryChange);
    })
    ).subscribe(({ searchFilters, query }) => {
      if (this.canSearchResultsBeUpdated(searchFilters, query)) {
        this.isLoading$.next(true);
        this.pageNumber = 1;
        this.getSortedSearchResults();
        this.previousSearchFilters = JSON.parse(JSON.stringify(searchFilters));
        this.previousQuery = query;
      }
    }));
  }

  public setLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  private deepEqual(previousValue: any, currentValue: any): boolean {
    if (previousValue === currentValue) {
      return true;
    }

    if (typeof previousValue !== 'object' || previousValue === null || typeof currentValue !== 'object' || currentValue === null) {
      return false;
    }

    const previousKey = Object.keys(previousValue);
    const currentKey = Object.keys(currentValue);

    if (previousKey.length !== currentKey.length) {
      return false;
    }

    for (const key of previousKey) {
      if (!currentKey.includes(key) || !this.deepEqual(previousValue[key], currentValue[key])) {
        return false;
      }
    }

    return true;
  }

  public subscribeToQueryParams(): void {
    const urlParamsSub = this.route.queryParams.subscribe(params => {
      const query = params['q'];
      if (query?.length > 0) {
        this.query$.next(query);
      }
    });

    this.subscriptions.push(urlParamsSub);
  }

  public getQueryValue(): string {
    return this.query$.value;
  }

  public getFilterOptionsFromSettings(filterDisplayName: string): SearchFilterOptions[] {
    return CONSTANTS.SEARCH_FILTERS_SETTINGS.find(filter =>
      filter.settings.filterDisplayName === filterDisplayName)?.options || [];
  }

  public updateSearchFilters(searchFilters: SearchFilter[]): void {
    this.searchFilters$.next(searchFilters);
    this.clearQueryParamsThenSearchNavigate();
  }

  public clearQueryParamsThenSearchNavigate(route: string = `/` + CONSTANTS.ROUTER.SEARCH): void {
    this.router.navigate([], { relativeTo: this.route, queryParams: {} }).then(() => {
      const searchFiltersQueryParams = this.formatSearchFiltersForQueryParams();
      const queryParams: Params | null = route === `/` + CONSTANTS.ROUTER.SEARCH ?
        { q: this.query$.value, ...searchFiltersQueryParams } : null;
      this.router.navigate([route], { relativeTo: this.route, queryParams, queryParamsHandling: 'merge' });
    });
  }

  public formatSearchFiltersForQueryParams(): { [key: string]: any; } {
    return this.searchFilters$.value.reduce((filters: { [key: string]: any }, filter) => {
      const key = filter.settings.formControlName;
      const selectedValues = filter.options.filter(option => option.isSelected).map(option => option.displayName);
      const availableOptions = filter.options.filter(option => option.isAvailable);
      const allSelected = availableOptions.length > 0 && availableOptions.every(option => option.isSelected);
      const noneSelected = availableOptions.every(option => !option.isSelected);

      if (!allSelected && !noneSelected) {
        filters[key] = selectedValues;
      }

      return filters;
    }, {});
  }

  public canSearchResultsBeUpdated(searchFilters: SearchFilter[], query: string): boolean {
    const hasSearchFiltersChanged = !this.deepEqual(this.previousSearchFilters, searchFilters);
    const hasQueryChanged = this.previousQuery !== query;
    return hasSearchFiltersChanged || hasQueryChanged;
  }

  public async getSortedSearchResults(appendNewResults = false): Promise<void> {
    if (appendNewResults) {
      this.pageNumber++;
    }

    const sortedSearchResults = await this.formatSearchResults();
    this.searchResults$.next(sortedSearchResults);
    this.sendSearchResultPendoEvent(sortedSearchResults);
  }

  public async formatSearchResults(): Promise<SearchResult[]> {
    const standardSets = await this.classesService.getStandardSetsForClass();
    const tags = this.convertFiltersToTags();
    const searchQuery = { 'textQuery': this.query$.value, 'standardSets': standardSets, tags, 'pageNumber': this.pageNumber };
    const searchResponse = await this.getSearchResponse(searchQuery);
    const productResults = this.initializeProductResults();
    const uniqueResults = new Set<string>();

    for (const result of searchResponse) {
      if (!uniqueResults.has(result.product_skill_id)) {
        uniqueResults.add(result.product_skill_id);
        await this.addResultToProductResults(result, productResults);
      }
    }

    const searchResults = Object.values(productResults).flat();
    const sortedSearchResults = searchResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 20);

    sortedSearchResults.forEach((result, index) => {
      result.resultOrder = index + 1;
    });

    return sortedSearchResults;
  }

  public sendSearchResultPendoEvent(sortedSearchResults: SearchResult[]): void {
    const formattedSearchResults = this.formatPendoSearchResults(sortedSearchResults);

    this.pendoService.sendEvent('SearchQuery', {
      searchFilters: this.formatSearchFilters(),
      searchOrderedResults: formattedSearchResults.orderedSearchResults,
      searchProductPercentages: formattedSearchResults.productPercentages,
      searchQuery: this.query$.value,
    });
  }

  public formatSearchFilters(): string[] {
    const groupedFilters = this.searchFilters$.value.reduce((acc: { [key: string]: string[] }, filter) => {
      const selectedOptions = filter.options.filter(option => option.isSelected && option.isAvailable).map(option => option.displayName);
      if (selectedOptions.length > 0) {
        const key = filter.settings.filterDisplayName;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(...selectedOptions);
      }
      return acc;
    }, {});

    return Object.entries(groupedFilters).map(([key, values]) => `${key} ${values.join(',')}`);
  }

  public searchResultClickedNavigationPendo(searchResult: SearchResult): void {
    const formattedSearchResults = this.formatPendoSearchResults(this.searchResults$.value);
    const formattedClickedResult = formattedSearchResults.orderedSearchResults.find(result => result.name === searchResult.name);

    this.pendoService.sendEvent('SearchResultClicked', {
      searchClickedResult: formattedClickedResult,
      searchFilters: this.formatSearchFilters(),
      searchOrderedResults: formattedSearchResults.orderedSearchResults,
      searchProductPercentages: formattedSearchResults.productPercentages,
      searchQuery: this.query$.value,
    });
  }

  public formatPendoSearchResults(searchResults: SearchResult[]): PendoSearchResult {
    const pendoResults: PendoResult[] = searchResults.map(result => ({
      distance: result.distance,
      name: result.name,
      product_skill_id: result.product_skill_id,
      renaissance_skill_id: result.renaissance_skill_id,
      resultOrder: result.resultOrder,
      resultPage: result.resultPage,
      source: result.source,
      url_deep_link: result.url
    }));

    const productCounts: { [key: string]: number } = {};
    const totalResults = pendoResults.length;

    pendoResults.forEach(async result => {
      const productKey = Object.keys(this.productSettings).find(key =>
        this.productSettings[key as keyof typeof PRODUCT_SETTINGS].sourceId === result.source
      );
      if (productKey) {
        productCounts[productKey] = (productCounts[productKey] || 0) + 1;
      }

      result.resultPage = this.pageNumber;
      result.url_deep_link = await this.pendoGetUrl(result);
    });

    const productPercentages = Object.entries(productCounts).map(([productKey, count]) => {
      const percentage = ((count / totalResults) * 100).toFixed(2);
      return `${productKey}: ${percentage}%`;
    });

    const pendoFormattedSearchResults: PendoSearchResult = {
      orderedSearchResults: pendoResults,
      productPercentages,
    };

    return pendoFormattedSearchResults;
  }

  public async pendoGetUrl(searchResult: PendoResult): Promise<string> {
    const productSettingsArray = Object.values(this.productSettings);
    const productSetting = productSettingsArray.find(setting => setting.sourceId === searchResult.source);
    const clickHandler = productSetting?.resultUISettings?.clickHandler;
    let deepLink = searchResult.url_deep_link;

    if (clickHandler && productSetting.sourceId !== 'SOURCE_NEARPOD') {
      const deepLinkHolder = await (this.productNavigationService[clickHandler as keyof ProductNavigationService] as Function)(searchResult, true);
      if (deepLinkHolder instanceof Object) {
        deepLink = deepLinkHolder.__zone_symbol__value;
      } else {
        deepLink = deepLinkHolder;
      }
    }

    return deepLink;
  }

  private async getSearchResponse(searchQuery: {
    textQuery: string;
    standardSets: StandardSet[];
    tags: KeyValues[];
    pageNumber: number;
  }): Promise<SearchResult[]> {
    // TODO: Remove this feature toggle when we remove the old search service
    const useOpenSearchApi = await this.featureToggleService.isTrueAsync('nrd-65-setup-new-search-api');
    this.searchApiUrl = useOpenSearchApi ? environment.openSearchUrl : environment.searchLambdaUrl;

    return await firstValueFrom(this.http.post<SearchResult[]>(this.searchApiUrl, searchQuery));
  }

  public convertFiltersToTags(): KeyValues[] {
    return this.searchFilters$.value.reduce((acc: any, filter) => {
      if (filter.options.length === 0) {
        return acc;
      }

      const selectedOptions = filter.options.filter((option: SearchFilterOptions) => option.isSelected && option.isAvailable)
        .map((option: SearchFilterOptions) => {
          // TODO: With API v2, should be able to switch to use exclusively displayName. Does not work with v1.
          return filter.settings.formControlName === 'grades' ? option.displayName : option.value;
        });

      if (selectedOptions.length > 0) {
        acc.push({ key: filter.settings.formControlName, value: selectedOptions });
      }

      return acc;
    }, []);
  }

  public updateQuery(query: string): void {
    query = query.trim();
    if (query?.length > 0) {
      this.query$.next(query);
      this.clearQueryParamsThenSearchNavigate();
    }
  }

  public initializeProductResults(): { [key: string]: SearchResult[] } {
    const productResults: { [key: string]: SearchResult[] } = {};

    for (const productKey in PRODUCT_SETTINGS) {
      productResults[productKey] = [];
    }

    return productResults;
  }

  public async addResultToProductResults(result: SearchResult, productResults: { [key: string]: SearchResult[] }): Promise<void> {
    const productKey = Object.keys(this.productSettings).find((key) =>
      this.productSettings[key as keyof typeof PRODUCT_SETTINGS].sourceId === result.source);
    if (productKey) {
      const productSetting = this.productSettings[productKey as keyof typeof PRODUCT_SETTINGS];

      if (productSetting.licensedAppTags && productSetting.licensedAppTags.length > 0) {
        const hasLicense = await this.classesLicensedToUseProducts(productSetting.licensedAppTags);
        if (hasLicense) {
          productResults[productKey].push(result);
        }
      } else {
        productResults[productKey].push(result);
      }
    }
  }

  private compareResultsByDistance(a: SearchResult, b: SearchResult): number {
    return a.distance - b.distance;
  }

  private async classesLicensedToUseProducts(tags: ProductAppTags[]): Promise<boolean> {
    const classes = await this.classesService.getClasses();
    return classes!.some(c => tags.some(tag => c.classAppTags.includes(tag.toString())));
  }

  public async productAvailable(productSetting: ProductSettings, isFeatureToggle: boolean): Promise<boolean> {
    const productLicensing = isFeatureToggle ?
      productSetting?.productLicensing?.featureToggle : productSetting?.productLicensing?.licenseMethod;
    let productAvailable = true;

    if (productLicensing && productLicensing.length > 0) {
      try {
        productAvailable = await Promise.all(productLicensing.map(licensing => {
          if (isFeatureToggle) {
            return this.featureToggleService.isTrueAsync(licensing);
          } else {
            return this.licenseInfoService[licensing as keyof LicenseInfoService]();
          }
        })).then(results => results.every(result => result));
      } catch (error) {
        productAvailable = false;
      }
    }

    return productAvailable;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
