<div class="container">
  <div class="header">
    <app-student-bubble [student]="data.student"></app-student-bubble>
    <h2>Recommendations for {{data.student.firstName}}</h2>
  </div>

  <div class="body" *ngIf="isLoaded">
    <div data-test="assessment-section" *ngIf="data.student.latestAssessment && isShortTestTime()">
      <h3 class="assessment-header">Assessment</h3>
      <div class="short-test-time-description-container">
        <img class="star-logo-graphic" alt="" src="../../../../../../assets/logos/star_logo.svg">
        <p>{{data.student.firstName}}'s Star {{getSubjectString()}} test was completed in {{getTestTimeString()}}.
          We recommend a retest, with emphasis on taking time and trying hard.</p>
      </div>
    </div>

    <div class="skills-practice">
      <h3 class="skills-practice-header">Skills Practice & Instruction</h3>
      <div id="pendo-skill-{{skill}}" *ngFor="let skill of actionableSkills"  class="take-action-skill-list">
        <div class="rationale" [innerHTML]="skill.rationale"></div>
        <div class="recommendation-audit"> {{skill.recommendationAudit}} </div>
        <div class="skill-description">
          <app-skill-description
            [skillMetadata]="skill.skillMetadata!"
            [hasAmbiguousShortName]="isSkillNameAmbiguous(skill)"
          ></app-skill-description>
          <div *ngIf="skill.recType==recommendationType.NeedsHelp" class="struggling-container">
            <div class="student-bubble-container">
              <app-student-bubble-stack [students]="stuckStudents"></app-student-bubble-stack>
            </div>
            <div class="struggling-text" [innerHTML]="getStrugglingStudentsString()"></div>
          </div>
        </div>
        <app-seen-before [studentIds]="getStuckStudentIds()" [skillId]="skill.skillMetadata?.renaissanceSkillId!"></app-seen-before>
        <take-action-links
          [skill]="skill"
          [freckleRecType]="skill.freckleRecType"
          [nearpodRecType]="skill.nearpodRecType"
        ></take-action-links>
        <br>
      </div>

      <!-- No skills found -->
      <ng-container>
        <div *ngIf="actionableSkills.length === 0">
          <div *ngIf="studentHasNoAssessments()" class="no-skill-student">
            {{getNoRecentActivityText()}}
          </div>
          <div class="no-skill-recommendation">
            {{getNoSkillRecommendationText()}}
          </div>
          <div class="no-skill-recommendation-reading-note" *ngIf="displaySkillProductHelperText()">
            Note: Lalilo is the right tool for students who are <b>learning to read</b>. Freckle is the tool for students
            who are <b>reading to learn</b>.
          </div>
        </div>
      </ng-container>
    </div>

    <!-- teacher tips placeholder -->
    <div class="color-block"></div>
  </div>

  <div class="body" *ngIf="!isLoaded">
    <mat-spinner mode="indeterminate" class="mat-spinner"> </mat-spinner>
  </div>
</div>

<div class="footer">
  <hr aria-hidden="true" />
  <div class="footer-button">
    <button class="ren-link" (click)="close($event)">Close</button>
  </div>
</div>
