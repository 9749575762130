<div class="container">
  <div *ngIf="isCBM(assessment.productId)" class="result-container">
    <div class="swatch round" [ngStyle]="{'background-color':getCBMColor(assessment.cbmBenchmarkCategory)}"></div>
    <div class="text">
      <div>{{generateCBMCategory(assessment.cbmBenchmarkCategory)}}</div>
      <div>Correct Per Minute: {{assessment.correctPerMinute}}</div>
      <div>Accuracy: {{toPercent(assessment.accuracy)}}</div>
    </div>
  </div>
  <div *ngIf="!isCBM(assessment.productId)" class="result-container">
    <div class="swatch" [ngStyle]="{'background-color': getStudentCategoryColor(assessment)}"></div>
    <div class="text">
      <div>{{getStudentCategoryTitle(assessment)}}</div>
      <div>Scaled Score: {{assessment.scaledScore}}</div>
      <div>Percentile Rank: {{assessment.percentileRank}}</div>
      <div>Current SGP: {{getSGPText(assessment.currentSGP)}} {{getGrowthCategory(assessment.currentSGP)}}</div>
    </div>
  </div>
</div>
