
<div class="container">
  <span aria-hidden="true" (click)="toggle()">{{leftName}}</span>
  <label class="switch" for="toggle-switch">
    <span class="sr-only">{{leftName}} or {{rightName}}</span>
    <input type="checkbox" role="switch" id="toggle-switch" (change)="toggle()" [checked]="this.rightSelected">
    <span class="slider round"></span>
  </label>
  <span aria-hidden="true" (click)="toggle()">{{rightName}}</span>
</div>
