<div class="header-container">
  <app-skill-description *ngIf="hasLoaded" [skillMetadata]="skill.skillMetadata!"
    [hasAmbiguousShortName]="skill.hasAmbiguousShortName!" [isModalHeading]="true"></app-skill-description>
</div>
<mat-divider aria-hidden="true"
  class="category-divider">
</mat-divider>
<div class="body-container" *ngIf="hasLoaded">
  <div class="category-header-container">
      <img class="category-icon" alt="icon" [src]=data.categoryIcon>
      <p class="category-name">
        {{data.categoryName}}
        <span class="category-student-count">{{getStudentCountText()}}</span>
      </p>
  </div>
  <div class="student-bubble-container">
    <app-student-bubble-stack
      [students]="data.students"
    ></app-student-bubble-stack>
  </div>
  <take-action-links
    [skill]="skill"
    [students]="data.students"
    [freckleRecType]="skill.freckleRecType!"
    [nearpodRecType]="skill.nearpodRecType!">
  </take-action-links>
</div>


<div class="body-container" *ngIf="!hasLoaded">
  <mat-spinner mode="indeterminate" class="mat-spinner"> </mat-spinner>
</div>

<mat-divider aria-hidden="true"
  class="category-divider">
</mat-divider>
<div class="footer">
  <div class="footer-button">
    <button class="ren-link" (click)="close($event)">Close</button>
  </div>
</div>
