<div class="opt-out-dialog-container renaissance-style-scoped">
  <div data-test="opt-out" class="opt-out-dialog renaissance-style-scoped" *ngIf="!showLeaveDialog && !showStayDialog">
    <p class="opt-out-dialog-explain">
      Switching back? We'd love to hear what worked for you and what didn't.
      Any feedback you can provide will help us tailor Renaissance Next to meet your needs,
      so you can help every student succeed.
    </p>
    <div class="feedback-container">
      <textarea id="pendo-feedback-area" class="feedback-area"></textarea>
    </div>
    <div class="opt-out-bottom">
      <div class="dialog-buttons-container">
        <button type="button" class="ren-btn ren-btn--secondary left-btn dialog-btn" data-text="button"(click)="updateDialog(false)">Keep Using Renaissance Next</button>
        <button type="button" class="ren-btn dialog-btn" (click)="updateDialog(true)">Switch Back</button>
      </div>
    </div>
  </div>

  <div data-test="stay-dialog" class="stay-dialog renaissance-style-scoped" *ngIf="showStayDialog">
    <p class="stay-header">
      So glad you decided to stay!
    </p>
    <p class="stay-text">
      With your help, we'll keep improving this experience. Send us feedback anytime by selecting the
      <span>Send Feedback</span> button in the upper-left corner of the page.
    </p>
    <button type=button class='ren-btn stay-btn' (click)="closeDialog()">Got it!</button>
  </div>

  <div data-test="leave-dialog"class="leave-dialog" *ngIf="showLeaveDialog">
    <mat-spinner diameter="80" mode="indeterminate" class="mat-spinner"></mat-spinner>
    <p class="leave-header">
      Come back any time!
    </p>
    <p class="leave-text">
      We'd love your help as we continue to improve.
    </p>
  </div>
</div>

