import { Injectable } from '@angular/core';
import { GraphqlService } from '../graphql/graphql.service';
import { Skill } from 'src/app/shared/models/skill.model';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { ActionableSkill, LinkProductKeys, ProductKeys } from 'src/app/home/activity-summary/activity-summary-category/student-table/take-action-modal/take-action-modal.component';
import { RecommendationLinkType } from 'src/app/home/activity-summary/activity-summary-category/student-table/take-action-links/take-action-links.component';
import { NearpodLinkGeneratorService } from '../link-generators/nearpod-link-generator.service';
import { FreckleLinkGeneratorService } from '../link-generators/freckle-link-generator.service';
import { ClassProductUsage, Student, StudentContext } from 'src/app/shared/models/student.model';
import { Link } from 'src/app/util/link/link';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserService } from '../user/user.service';
import { catchError, filter, firstValueFrom, last, of, take, throwError } from 'rxjs';
import { SkillPracticeCategory } from 'src/app/home/skills-practice/skills-practice-categories';
import { SkillMetadataByStandardSetsRequest } from '../graphql/skill-metadata-request';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { ProductIds } from '../product-info/product-info.service';
import { RecommendationType } from './RecommendationType';
import { SkillRecommendation } from './SkillRecommendation';
import { ClassesService } from '../classes/classes.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {
  constructor(private graphql: GraphqlService,
    private userService: UserService,
    private http: HttpClient,
    private nearpodLinkGenService: NearpodLinkGeneratorService,
    private freckleLinkGenService: FreckleLinkGeneratorService,
    private classesService: ClassesService
  ) { }

  public async getSkillRecommendations(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<ActionableSkill[]> {
    let actionableSkills: ActionableSkill[] = [];

    let freckleHighlightedSkill: ActionableSkill | null = null;
    let starHighlightedSkill: ActionableSkill | null = null;

    // Practice skill recommendations
    if (SkillTests.studentHasPracticeActivity(selectedStudent)) {
      // Get Freckle recommendation
      if (SkillTests.studentHasFreckleActivity(selectedStudent)) {
        let freckleSkillRec = await this.getFreckleHighlightedSkillRec(selectedStudent);
        if (freckleSkillRec) {

          // Get take action links
          if (freckleSkillRec.recommendationType === RecommendationType.NeedsHelp) {
            let stuckStudents = this.getStuckStudents(allStudents, freckleSkillRec.skillId);
            freckleHighlightedSkill = await this.getActionsForSkillRec(freckleSkillRec, stuckStudents, selectedStudent.classProductUsage);
          }
          else {
            freckleHighlightedSkill = await this.getActionsForSkillRec(freckleSkillRec, [selectedStudent], selectedStudent.classProductUsage);
          }

          // Set rationale
          if (freckleSkillRec?.recommendationType === RecommendationType.NeedsChallenge) {
            freckleHighlightedSkill.rationale = `${selectedStudent.firstName} is ready to move on to this skill:`;
          }
          else {
            freckleHighlightedSkill.rationale =`Based on ${selectedStudent.firstName}'s latest work in Freckle:`;
          }
        }
      }
    }

    // Get Star skill recommendation
    if (SkillTests.studentHasAssessmentActivity(selectedStudent)
      && DateHelper.isWithinThreeMonthsOfToday(selectedStudent.latestAssessment.completedDate)) {
      let starRec = await this.getStarHighlightedSkillRec(selectedStudent);
      if (starRec) {

        // Get take action links
        if (starRec.recommendationType === RecommendationType.NeedsHelp) {
          let stuckStudents = this.getStuckStudents(allStudents, starRec.skillId);
          starHighlightedSkill = await this.getActionsForSkillRec(starRec, stuckStudents, selectedStudent.classProductUsage);
        }
        else {
          starHighlightedSkill = await this.getActionsForSkillRec(starRec, [selectedStudent], selectedStudent.classProductUsage);
        }

        // Set rationale
        if (starHighlightedSkill) {
          starHighlightedSkill!.rationale = `Based on their recent <b>Star</b> score, we recommend seeing if this skill is appropriate for ${selectedStudent.firstName}:`;
        }
      }
    }

    // Combination of actionable skills

    // Freckle / No Star
    if (freckleHighlightedSkill && !starHighlightedSkill) {

      // If Star too old, it wasn't considered
      if (SkillTests.studentHasAssessmentActivity(selectedStudent) &&
          !DateHelper.isWithinThreeMonthsOfToday(selectedStudent.latestAssessment.completedDate)) {
        freckleHighlightedSkill.rationale = `Based on ${selectedStudent.firstName}'s latest work in Freckle. ${selectedStudent.firstName}'s most recent Star score is over 3 months old`;
      }
      actionableSkills.push(freckleHighlightedSkill);
    }

    // Star / No Freckle
    if (!freckleHighlightedSkill && starHighlightedSkill) {

      // If Freckle too old, it wasn't considered
      const lastSkillPracticedDate = selectedStudent.lastSkillPracticedDate
      if (lastSkillPracticedDate && !DateHelper.isWithinTwoWeeksOfToday(lastSkillPracticedDate)) {
        const shownDate = DateHelper.snowflakeDateToDisplayDate(selectedStudent.latestAssessment.completedDate)

        starHighlightedSkill.rationale = `Based on ${selectedStudent.firstName}'s most recent Star test on ${shownDate} and typical growth based on ${selectedStudent.firstName}'s peers, we recommend this skill.`
      }
      actionableSkills.push(starHighlightedSkill);
    }

    // Freckle and Star
    if (freckleHighlightedSkill && starHighlightedSkill) {

      // Star too old to recommend above Freckle
      if (!DateHelper.isWithinTwoWeeksOfToday(selectedStudent.latestAssessment.completedDate)) {
        freckleHighlightedSkill.rationale = `Based on ${selectedStudent.firstName}'s latest work in Freckle. ${selectedStudent.firstName}'s most recent Star score is over 2 weeks old.`;
        actionableSkills.push(freckleHighlightedSkill);
      }

      // Recommendations the same
      else if (freckleHighlightedSkill.skillMetadata?.renaissanceSkillId == starHighlightedSkill.skillMetadata?.renaissanceSkillId) {
        freckleHighlightedSkill.rationale = `Based on their recent <b>Star</b> score and <b>Freckle</b> practice, ${selectedStudent.firstName} should continue working on this skill:`
        actionableSkills.push(freckleHighlightedSkill);
      }

      // Recommendations different
      else {
        actionableSkills.push(freckleHighlightedSkill);
        actionableSkills.push(starHighlightedSkill);
      }
    }

    // If No practice/star activity -> show nothing, the list will be empty
    return actionableSkills;
  }

  public async getSkillRecommendationForStudentsWithSkill(students: StudentContext[], skillMetadata: SkillMetadata, category: SkillPracticeCategory, subject: string): Promise<ActionableSkill> {
    let recType: RecommendationType = RecommendationType.None;
    switch (category) {
      case SkillPracticeCategory.Stuck:
        recType = RecommendationType.NeedsHelp;
        break;
      case SkillPracticeCategory.PracticeMakesPerfect:
        recType = RecommendationType.Appropriate;
        break;
      case SkillPracticeCategory.ReadyForChallenge:
        recType = RecommendationType.NeedsChallenge;
        break;
    }

    let skillRec: SkillRecommendation = {
      recommendationType: recType,
      skillId: skillMetadata.renaissanceSkillId,
      subject: subject
    };

    let classProductUsage = students?.[0].classProductUsage;

    let skillActions = await this.getActionsForSkillRec(skillRec, students, classProductUsage);

    return skillActions!;
  }

  public async getStarHighlightedSkillRec(student: StudentContext): Promise<SkillRecommendation | null> {
    const subject = student.latestAssessment?.subject;

    // No assessment -> no recommendation
    if (!subject) {
      return null;
    }

    let headers = new HttpHeaders({
      'access-token': this.userService.getRawJwt() ?? ''
    });
    let params = new HttpParams()
      .set('assessmentType', subject);

    // Get recommended skill from RGP
    let highlightedSkillId = await firstValueFrom(this.http.get(this.userService.getStarSkillsUrl() + student.renaissanceId, {
      headers: headers,
      params: params,
      responseType: 'text'
    }).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => of(null))
    ));

    if (!highlightedSkillId) {
      return null;
    }

    let recommendation: SkillRecommendation = {
      recommendationType: RecommendationType.Star,
      skillId: highlightedSkillId,
      subject: subject
    };

    return recommendation;

  }

  public async getFreckleHighlightedSkillRec(student: StudentContext): Promise<SkillRecommendation | null> {
    let recAudit = 'Freckle highlighted skill recommendation audit\n';


    // Stopgap for Lalilo V1. Refactor when we implement take action for Lalilo
    let freckleSkills = student.skills.filter(skill => skill.productId === ProductIds.FreckleReading || skill.productId === ProductIds.FreckleMath);

    let aggregatedSkills = SkillAggregate.aggregate(freckleSkills);
    if (aggregatedSkills.length == 0) {
      return null;
    }

    // Determine which skill to base recommendation
    let stuckSkills = aggregatedSkills.filter(skill => SkillTests.isStuckSkill(skill));

    // Stuck skills -> Needs helping hand
    if (stuckSkills.length !== 0) {
      let activeDomain = this.getDomainWithMostActivity(stuckSkills);
      let activeDomainSkills = stuckSkills.filter(skill => skill.domainId === activeDomain);
      let lowestSkill = this.getLowestSkillOnProgression(activeDomainSkills);
      let skillRecommendation: SkillRecommendation = {
       skillId: lowestSkill.renaissanceSkillId!,
       recommendationType: RecommendationType.NeedsHelp,
       subject: lowestSkill.subject
      }

      // audit
      recAudit += `Has stuck skills:`;
      for (var stuckSkill of stuckSkills) {
        recAudit += `${stuckSkill.renaissanceSkillId}(${stuckSkill.skillShortName})|`
      }
      recAudit += '\n';
      recAudit += `Most active domain of stuck skills: ${activeDomain}`;
      recAudit += '\n';
      recAudit += `Stuck Skills in domain:`;
      for (var skillInDomain of activeDomainSkills) {
        const skillMetaData = skillInDomain.standardSetMetadata;
        recAudit += `${skillInDomain.renaissanceSkillId}(${skillInDomain.skillShortName})-standardProgOrder:${skillMetaData?.standardProgressionOrder}-skillProgOrder:${skillMetaData?.skillProgressionOrder}|`
      }
      recAudit += '\n';
      recAudit += `Needs help - recommending lowest skill among stuck skills:${lowestSkill.renaissanceSkillId}(${lowestSkill.skillShortName})`;

      skillRecommendation.recommendationAudit = recAudit;

      return skillRecommendation;
    }

    // No stuck skills
    else {
      let relevantSkills = aggregatedSkills.filter(skill => SkillTests.hasThreeOrMoreItems(skill));

      // No skills with sufficient items
      if (relevantSkills.length == 0) {
        return null;
      }

      // Check if working at appropriate level
      let appropriateLevelSkills = relevantSkills.filter(skill => {
        let accuracyRate = SkillTests.getSkillAccuracyRate(skill);
        return SkillTests.isWorkingAtAppropriateLevelBasedOnAccuracyRate(accuracyRate) &&
          !SkillTests.isReadyForChallengeBasedOnAccuracyRate(accuracyRate);
      });

      if (appropriateLevelSkills.length > 0) {
        relevantSkills = appropriateLevelSkills;
      }

      let activeDomain = this.getDomainWithMostActivity(relevantSkills);
      let activeDomainSkills = relevantSkills.filter(skill => skill.domainId === activeDomain);

      let highestSkill = this.getHighestSkillOnProgression(activeDomainSkills);

      let highestSkillAccuracyRate = SkillTests.getSkillAccuracyRate(highestSkill);

        // audit
        recAudit += `Skills with enough practice to consider:`;
        for (var relevantSkill of relevantSkills) {
          recAudit += `${relevantSkill.renaissanceSkillId}(${relevantSkill.skillShortName})|`
        }
        recAudit += '\n';
        if (appropriateLevelSkills.length == 0) {
          recAudit += 'All skills are ready for a challenge - all are relevant.'
        }
        else {
          recAudit += `Skills that are working at appropriate level but NOT ready for a challenge - only these are relevant:`;
          for (var appropriateLevelSkill of appropriateLevelSkills) {
            recAudit += `${appropriateLevelSkill.renaissanceSkillId}(${appropriateLevelSkill.skillShortName})|`
          }
        }
        recAudit += '\n';
        recAudit += `Most active domain of relevant skills: ${activeDomain}\n`;
        recAudit += `Relevant skills in domain:`;
        for (var skillInDomain of activeDomainSkills) {
          const skillMetaData = skillInDomain.standardSetMetadata;
          recAudit += `${skillInDomain.renaissanceSkillId}(${skillInDomain.skillShortName})-standardProgOrder:${skillMetaData?.standardProgressionOrder}-skillProgOrder:${skillMetaData?.skillProgressionOrder}|`
        }
        recAudit += '\n';
        recAudit += `Relevant skill highest in progression in domain: ${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})-AccuracyRate: ${highestSkillAccuracyRate}\n`;

      // Ready for challenge
      if (SkillTests.isReadyForChallengeBasedOnAccuracyRate(highestSkillAccuracyRate)) {
        let skillProgressionMetadata = await this.getSkillProgressionMetadata(highestSkill);

        if (skillProgressionMetadata.length === 0) {
          return null;
        }

        let highestSkillIndex = skillProgressionMetadata.findIndex(metadata => {
          if (metadata.standardProgressionOrder == highestSkill.standardSetMetadata?.standardProgressionOrder &&
              metadata.skillProgressionOrder == highestSkill.standardSetMetadata?.skillProgressionOrder) {
            return true;
          }
          return false;
        });

        var skillRecommendation: SkillRecommendation;

        // Student has reached the end of the progression!
        if (highestSkillIndex === skillProgressionMetadata.length - 1) {
          skillRecommendation = {
            skillId: highestSkill.renaissanceSkillId,
            recommendationType: RecommendationType.EndOfProgression,
            subject: highestSkill.subject
          }
          recAudit += `Student is at the end of the progression. Can only recommend highest skill.${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})\n`;
        }

        // Find next skill in the progression to recommend
        else {
          let recommendedSkillMetaData = skillProgressionMetadata[highestSkillIndex + 1];
          skillRecommendation = {
            skillId: recommendedSkillMetaData.renaissanceSkillId,
            recommendationType: RecommendationType.NeedsChallenge,
            subject: highestSkill.subject
          }
          recAudit += `Ready for challenge - recommending skill after the highest ready for challenge level skill:${recommendedSkillMetaData.renaissanceSkillId}(${recommendedSkillMetaData.skillShortName})\n`;
        }

        skillRecommendation.recommendationAudit = recAudit;

        return skillRecommendation;
      }

      // Working at appropriate level
      else {
        let skillRecommendation: SkillRecommendation = {
         skillId: highestSkill.renaissanceSkillId,
         recommendationType: RecommendationType.Appropriate,
         subject: highestSkill.subject
        }

        recAudit += `Recommending to continue practice on highest level skill in domain that is not yet ready for challenge: ${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})})\n`;

        skillRecommendation.recommendationAudit = recAudit;

        return skillRecommendation;
      }
    }

  }

  public async getActionsForSkillRec(rec: SkillRecommendation, students: Student[], classProductUsage: ClassProductUsage): Promise<ActionableSkill> {
    let standardSets = await this.classesService.getStandardSetsForClass();
    let standardSetIdForSubject = standardSets.filter(x => this.mapStandardSetSubjectToSubject(x.subject) === rec.subject)?.[0]?.standardSetId;
    let standardSetIds = [];
    if (standardSetIdForSubject) {
      standardSetIds.push(standardSetIdForSubject);
    }

    let skillMetadataRequest: SkillMetadataByStandardSetsRequest = {
      renaissanceSkillId: rec.skillId,
      standardSetIds: standardSetIds
    }
    let skillMetadataByStandardSetsProductsResponse = await this.graphql.getSkillMetadataByStandardSetsMultipleIdFormats(skillMetadataRequest);
    let skillMetadataByStandardSetsProducts = skillMetadataByStandardSetsProductsResponse.skillMetadata;

    let freckleMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Freckle) ?? null;
    let nearpodMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Nearpod) ?? null;
    let starMetadata = skillMetadataByStandardSetsProducts.find( x => x.productName === ProductKeys.Star) ?? null;

    let displayedSkillMetadata = freckleMetadata ?? starMetadata ?? null;

    let actionableSkill: ActionableSkill = {
      skillMetadata: displayedSkillMetadata,
      hasAmbiguousShortName: false,
      rationale: '',
      freckleRecType: RecommendationLinkType.None,
      nearpodRecType: RecommendationLinkType.None,
      recType: rec.recommendationType,
      productUrls: { [LinkProductKeys.Nearpod]: new Link(''), [LinkProductKeys.Freckle]: new Link('') }
    };

    if (rec.recommendationType === RecommendationType.NeedsHelp) {
      const nearpodUrl = await this.nearpodLinkGenService.getSearchLink(nearpodMetadata, freckleMetadata);
      actionableSkill.nearpodRecType = nearpodUrl.type === 'search' ? RecommendationLinkType.None : RecommendationLinkType.Stuck,
      actionableSkill.productUrls[LinkProductKeys.Nearpod] = nearpodUrl;

      if (classProductUsage.hasPracticeProductAssigned) {
        if (freckleMetadata && rec.subject !=='READING') {
          let freckleUrl = this.freckleLinkGenService.getAssignPrereqSkillLink(freckleMetadata, students);
          actionableSkill.freckleRecType = RecommendationLinkType.Stuck;
          actionableSkill.productUrls[LinkProductKeys.Freckle] = new Link(freckleUrl);
        }
      }
    }
    else if (rec.recommendationType === RecommendationType.Appropriate) {
      const nearpodUrl = await this.nearpodLinkGenService.getSearchLink(nearpodMetadata, freckleMetadata);
      actionableSkill.nearpodRecType = nearpodUrl.type === 'search' ? RecommendationLinkType.None : RecommendationLinkType.Okay,
      actionableSkill.productUrls = { [LinkProductKeys.Nearpod]: nearpodUrl, [LinkProductKeys.Freckle]: new Link('') };
    }
    else if (rec.recommendationType === RecommendationType.NeedsChallenge) {
      const nearpodUrl = await this.nearpodLinkGenService.getSearchLink(nearpodMetadata, freckleMetadata);
      actionableSkill.nearpodRecType = nearpodUrl.type === 'search' ? RecommendationLinkType.None : RecommendationLinkType.Proficient;
      actionableSkill.productUrls[LinkProductKeys.Nearpod] = nearpodUrl;

      if (classProductUsage.hasPracticeProductAssigned) {
        if (freckleMetadata && rec.subject !== 'READING') {
          let freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
          actionableSkill.freckleRecType = RecommendationLinkType.Proficient;
          actionableSkill.productUrls[LinkProductKeys.Freckle] = new Link(freckleUrl);
        }
      }
    }
    else if (rec.recommendationType === RecommendationType.EndOfProgression) {
      const nearpodUrl = await this.nearpodLinkGenService.getSearchLink(nearpodMetadata, freckleMetadata);
      actionableSkill.nearpodRecType = RecommendationLinkType.Okay;
      actionableSkill.productUrls[LinkProductKeys.Nearpod] = nearpodUrl;
    }
    else if (rec.recommendationType === RecommendationType.Star) {
      const nearpodUrl = await this.nearpodLinkGenService.getSearchLink(nearpodMetadata, freckleMetadata);
      actionableSkill.nearpodRecType = RecommendationLinkType.Okay;
      actionableSkill.productUrls[LinkProductKeys.Nearpod] = nearpodUrl;

      if (classProductUsage.hasPracticeProductAssigned) {
        if (freckleMetadata && rec.subject === 'MATH') {
          const freckleUrl = this.freckleLinkGenService.getAssignSkillLink(freckleMetadata, students);
          actionableSkill.freckleRecType = RecommendationLinkType.Okay;
          actionableSkill.productUrls[LinkProductKeys.Freckle]= new Link(freckleUrl);
        }
        if (freckleMetadata && rec.subject === 'READING') {
          const freckleUrl = this.freckleLinkGenService.getAssignElaSkillsPracticeLink(freckleMetadata, students);
          actionableSkill.freckleRecType = RecommendationLinkType.Okay;
          actionableSkill.productUrls[LinkProductKeys.Freckle]= new Link(freckleUrl);
        }
      }
    }

    actionableSkill.recommendationAudit = rec.recommendationAudit;

    return actionableSkill;
  }

  public getLowestSkillOnProgression(skills: Skill[]): Skill {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrder(a.standardSetMetadata, b.standardSetMetadata))[0];
  }

  public getHighestSkillOnProgression(skills: Skill[]): Skill {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrder(a.standardSetMetadata, b.standardSetMetadata))[skills.length-1];
  }

  public getDomainWithMostActivity(skills: Skill[]): string {

    // Get domain frequency counts
    const domainFrequencies: { [key: string]: number } = {};

    skills.forEach(skill => {
      if (domainFrequencies[skill.domainId]) {
        domainFrequencies[skill.domainId]++;
      }
      else {
        domainFrequencies[skill.domainId] = 1;
      }
    });

    // Find domains that occur most frequently
    let mostCommonDomains: string[] = [];
    let highestFrequency = Math.max(...Object.values(domainFrequencies));

    for (const domain in domainFrequencies) {
      if (domainFrequencies[domain] === highestFrequency) {
        mostCommonDomains.push(domain);
      }
    }

    // Return most common domain
    if (mostCommonDomains.length === 1) {
      return mostCommonDomains[0];
    }

    // Tie in domain frequency -> tiebreak by most recent practice activity
    else {
      let mostCommonDomainsSkills = skills.filter(skill => mostCommonDomains.includes(skill.domainId))
      return this.getMostRecentPracticedDomain(mostCommonDomainsSkills);
    }
  }

  private getMostRecentPracticedDomain(skills: Skill[]): string {
    let orderedSkills = skills.sort((a, b) => this.compareSkillsByDate(a, b));
    return orderedSkills[0].domainId;
  }

  private compareSkillsByDate(a: Skill, b: Skill) {
    let aDate = DateHelper.parseDate(a.lastPracticedDate);
    let bDate = DateHelper.parseDate(b.lastPracticedDate);

    if (aDate > bDate) {
      return -1;
    }
    if (aDate < bDate) {
      return 1;
    }
    return 0;
  }

  // Sort skill/metadata by standard progression order, then skill progression order
  private compareSkillsByProgressionOrder(a: SkillMetadata | undefined, b: SkillMetadata | undefined) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }

    if (a.standardProgressionOrder > b.standardProgressionOrder) {
      return 1;
    }
    if (a.standardProgressionOrder < b.standardProgressionOrder) {
      return -1;
    }

    if (a.skillProgressionOrder > b.skillProgressionOrder) {
      return 1;
    }
    if (a.skillProgressionOrder < b.skillProgressionOrder) {
      return -1;
    }

    return 0;
  }

  private async getSkillProgressionMetadata(skill: Skill): Promise<SkillMetadata[]> {
    let standardSetId = skill.standardSetId;
    let domainId = skill.domainId;

    // Copying object since response is immutable
    let skillMetadata = [...(await this.graphql.getSkillProgression(standardSetId, domainId)).skillMetadata];
    return skillMetadata.sort((a, b) => this.compareSkillsByProgressionOrder(a, b));
  }

  private mapStandardSetSubjectToSubject(standardSetSubject: string): string {
    // TODO: once we update the database values for the teacher standard we can remove this call
    switch (standardSetSubject) {
      case 'math': {
        return 'MATH';
      }
      case 'ela': {
        return 'READING';
      }
    }
    return standardSetSubject;
  }

  private getStuckStudents(students: StudentContext[], skillRenaissanceId: string) {
    let stuckStudents: Student[] = [];
    students.forEach(student => {
      let skill = SkillAggregate.aggregate(student.skills).find(skill => {
        return skill.standardSetMetadata?.renaissanceSkillId === skillRenaissanceId && SkillTests.isStuckSkill(skill)});
      if (skill){
        stuckStudents.push(student);
      }
    })
    return stuckStudents;
  }
}
