<div *ngIf="showIntroAnimation" [class.fade-out-ani]="fadeOut" class="fade-in-ani">
  <div class="animation-textbox">
    <div class="text-container">
      <div class="welcome-header">Welcome...</div>
      <div class="products-message">Let's start by finding your products</div>
    </div>
    <object
      *ngIf="showIntroAnimationSVG"
      role="figure"
      class="product-animation"
      alt="Welcome Teacher Loading Animation"
      aria-label="Welcome Teacher Loading Animation"
      data="../../../../assets/intro-animations/leftnav-intro-animation.svg">
    </object>
  </div>
</div>
