import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  /**
   * Milliseconds since unix expoch
   * @returns
   */
  now(): number {
    const now = new Date();
    return now.getTime();
  }
}
