import { Injectable } from '@angular/core';
import { AvailableAppNavV2Service } from '../app-nav-v2/available-app-nav-v2.service';
import { ProductAppCodes } from '../product-info/product-info.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherLicenseInfoService {

  constructor(private availableApppNavServiceV2: AvailableAppNavV2Service) { }

  public async teacherHasMyon() {
    await this.availableApppNavServiceV2.isLoaded();
    let appNav = this.availableApppNavServiceV2.getAppNavV2();
    return appNav.some(app => app.appCode === ProductAppCodes.Myon);
  }
}
