import { ProductAppTags } from '../../../services/product-info/product-info.service';
import { ProductsEnum } from "../enums/products.enum";
import { ProductSettingsMap } from "../models/products-settings.model";
import * as CONSTANTS from '../settings/search.constants';


// This contains the settings for product to enable features to be dynamically generated for each product.

const BASE_LOGO_PATH = `../../../../../assets/logos/`;
const constants = CONSTANTS;

export const PRODUCT_SETTINGS: ProductSettingsMap = {
  [ProductsEnum.FRECKLE]: {
    displayName: 'Freckle',
    licensedAppTags: [ProductAppTags.FreckleReading, ProductAppTags.FreckleMath],
    logoPath: `${BASE_LOGO_PATH}freckle_logo.svg`,
    productLicensing: {
      licenseMethod: ['currentClassesHaveFreckle']
    },
    productTagName: 'Student Practice',
    resultUISettings: {
      dataTest: 'freckle-assign-button',
      hasActivityAdminTemplate: true,
      id: 'pendo-search-assignFreckle',
      label: constants.SEARCH_RESULT.PREVIEW_ASSIGN,
      clickHandler: 'goToFreckle'
    },
    sourceId: 'SOURCE_FRECKLE',
    sourceName: ''
  },
  [ProductsEnum.LALILO]: {
    displayName: 'Lalilo',
    licensedAppTags: [ProductAppTags.Lalilo],
    logoPath: `${BASE_LOGO_PATH}lalilo_logo.svg`,
    productLicensing: {
      licenseMethod: ['currentClassesHaveLalilo']
    },
    productTagName: 'Student Practice',
    resultUISettings: {
      dataTest: 'lalilo-assign-button',
      hasDescription: true,
      hasActivityLink: true,
      id: 'pendo-search-assignLalilo',
      label: constants.SEARCH_RESULT.ASSIGN,
      clickHandler: 'goToLalilo'
    },
    sourceId: 'SOURCE_LALILO',
  },
  [ProductsEnum.MYON]: {
    displayName: 'myON Made Assignments',
    licensedAppTags: [ProductAppTags.Myon],
    logoPath: `${BASE_LOGO_PATH}myon_logo.svg`,
    productLicensing: {
      featureToggle: ['nrd-19-enable-myon-mades-in-search'],
      licenseMethod: ['currentClassesHaveMyOn']
    },
    productTagName: 'Student Practice',
    resultUISettings: {
      dataTest: 'myon-view-button',
      descriptionTags: ['ATOS Score', 'Lexile Level'],
      hasDescription: true,
      hasActivityLink: true,
      id: 'pendo-search-assignMyOn',
      label: constants.SEARCH_RESULT.VIEW_ACTIVITY,
      clickHandler: 'goToMyOn'
    },
    sourceId: 'SOURCE_MYON',
  },
  [ProductsEnum.NEARPOD]: {
    displayName: 'Nearpod',
    logoPath: `${BASE_LOGO_PATH}nearpod_logo.svg`,
    productTagName: 'Teacher-led',
    resultUISettings: {
      dataTest: 'nearpod-view-button',
      hasActivityUrl: true,
      hasCustomActivityButton: true,
      hasDescription: true,
      id: 'pendo-search-assignNearpod',
      label: `${constants.SEARCH_RESULT.VIEW}`,
    },
    sourceId: 'SOURCE_NEARPOD',
    urlSettings: {
      target: '_blank',
      urlSuffix: '/?referrer=ren_next'
    }
  }
}

