<div class="container renaissance-style-scoped" *ngIf="data">
  <div class="header-container">
    <div class="header">
      <div class="header-text">
        <app-student-bubble [student]="data.student"></app-student-bubble>
        <div class="header-student-name"  tabindex="0"
          [attr.aria-label]="'Student Name: ' + data.student.firstName + ' ' + data.student.lastName">
          <span class="header-student-name-text">{{data.student.firstName}}</span>
          <span class="header-student-name-text">{{data.student.lastName}}</span>
        </div>
        <div class="header-title">
          <div class="header-title-text">
            <h2 class="header-sub-text ">Reading Practice</h2>
          </div>
          <div class="header-title-subtext">Last 14 days</div>
        </div>
      </div>
      <div class="ar-myon-links-container">
        <product-deep-link *ngIf="hasAr()" type="ar" [zoneHomeUri]="zoneUri" [context]="context"></product-deep-link>
        <product-deep-link *ngIf="hasMyon()" type="myon" [zoneHomeUri]="zoneUri"
          [context]="context"></product-deep-link>
      </div>
    </div>
    <hr class="header-footer-hr" aria-hidden="true" />
  </div>

  <div class="subheader-container desktop-layout">
    <div class="sub-header">
      <div class="atos-lexile-container">
        <span class="scale-text">Scale:</span>
        <app-toggle-switch [value]="readingScale" (toggleChanged)="changeReadingScale($event)"></app-toggle-switch>
      </div>
      <div class="wordcount-container">
        <div class="wordcount-tooltip-container">
          <app-tooltip class="wordcount-tooltip" [tooltipLabel]="wordCountTooltipTitle"
            [tooltipDescription]="wordCountTooltipDescription"></app-tooltip>
        </div>
        <div class="wordcounts">
          <div class="current-count">{{currentWordCount.toLocaleString()}} words read in last 14 days</div>
          <div class="count-difference"><span class ="glyph" [class]="getArrowDirection()" [attr.aria-label]="getArrowLabel()" role="img"></span>{{getWordCountDifference()}} words compared to previous 14 days</div>
        </div>
      </div>
    </div>
    <hr class="header-footer-hr" aria-hidden="true" />
    <table class="reading-practice">
      <thead>
        <tr>
          <th>
            <app-tooltip [tooltipLabel]="dateTooltipTitle" [tooltipDescription]="dateTooltipDescription">
            </app-tooltip>
          </th>
          <th>Book or Article</th>
          <th>Book Level</th>
          <th>AR Quiz Score</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let readingPractice of readingPractices">
          <td class="padding-right">{{readingPractice.mostRecentPracticedDate | date: 'shortDate'}}</td>
          <td class="padding-right book-title">
            <div>{{readingPractice.contentTitle}}</div>
            <div class="author">{{readingPractice.author}}</div>
            <div class="myon-text">{{getMyonCompletedText(readingPractice)}}</div>
          </td>
          <td data-test="book-level" class="padding-right book-level">
            <ng-container *ngIf="hasReadingLevel(readingPractice); else noReadingLevel">
              <div>{{formatReadingLevel(readingPractice)}}</div>
            </ng-container>
            <ng-template #noReadingLevel>
              <div>--</div>
              <div class="unavailable">Book Level unavailable</div>
            </ng-template>
          </td>
          <td data-test="quiz-score" class="padding-right-last quiz-score"
            [class.non-quiz-score]="hasNoArQuizScore(readingPractice.percentCorrect)">
            <div [class.alert]="hasStuckArQuizScore(readingPractice)">
              <img *ngIf="hasStuckArQuizScore(readingPractice)" [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" />
              {{getArQuizScoreText(readingPractice)}}
            </div>
            
          </td>
        </tr>
      </tbody>
    </table>
  </div>


 

  <div class="reading-practice mobile-layout">
    <table class="header-container-mobile" style="border-collapse: collapse;">
      <tr>
        <th class="subheader-mobile">Scale:</th>
        <td class="subheader-mobile-value-axiom">
          <app-toggle-switch [value]="readingScale" (toggleChanged)="changeReadingScale($event)"></app-toggle-switch>
        </td>
      </tr>
      <tr>
        <th class="subheader-mobile">
          <app-tooltip class="wordcount-tooltip" [tooltipLabel]="wordCountTooltipTitle"
            [tooltipDescription]="wordCountTooltipDescription"></app-tooltip>
        </th>
        <td class="subheader-mobile-value">
          <span class="quanity-head">{{currentWordCount.toLocaleString()}} words read in last 14 days</span><br>
          <span class="glymph-head"> 
            <span class ="glyph" [class]="getArrowDirection()" [attr.aria-label]="getArrowLabel()" role="img"></span>{{getWordCountDifference()}} words compared to previous 14 days
          </span>
        </td>
      </tr>
      
    </table>
    <hr class="header-footer-hr" aria-hidden="true" />
    <ul>
      <li *ngFor="let readingPractice of readingPractices; index as index">
        <table class="mobile-assessments"  role="table">
          <tr>
            <th class="item-content-head" scope="row">
              <app-tooltip [tooltipLabel]="dateTooltipTitle" [tooltipDescription]="dateTooltipDescription"></app-tooltip>
            </th>
            <td class="item-content">
              
              {{readingPractice.mostRecentPracticedDate | date: 'shortDate'}}
            </td>
          </tr>
          <tr>
            <th class="item-content-head">Book or Article</th>
            <td class="item-content">
              <div>{{readingPractice.contentTitle}}</div>
              <div class="author">{{readingPractice.author}}</div>
              <div class="myon-text">{{getMyonCompletedText(readingPractice)}}</div>
            </td>
          </tr>
          <tr>
            <th class="item-content-head">Book Level</th>
            <td class="item-content">
              <ng-container *ngIf="hasReadingLevel(readingPractice); else noReadingLevel">
                {{formatReadingLevel(readingPractice)}}
              </ng-container>
              <ng-template #noReadingLevel>
                <div>--</div>
                <div class="unavailable">Book Level unavailable</div>
              </ng-template>
            </td>
          </tr>
          <tr>
            <th class="item-content-head" scope="row">AR Quiz Score</th>
            <td class="item-content" [class.non-quiz-score]="hasNoArQuizScore(readingPractice.percentCorrect)">
              <div [class.alert]="hasStuckArQuizScore(readingPractice)">
                <img *ngIf="hasStuckArQuizScore(readingPractice)" [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert" />
                {{getArQuizScoreText(readingPractice)}}
              </div>
            </td>
          </tr>
        </table>
        
        <hr *ngIf="index != readingPractices.length-1" class="list-item-hr" aria-hidden="true" />
      </li>
    </ul>
  </div>

  <div class="footer">
    <hr class="header-footer-hr" aria-hidden="true" />
    <div class="footer-button">
      <button class="ren-link" (click)="close()">Close</button>
    </div>
  </div>
</div>