import { Component } from '@angular/core';

@Component({
  selector: 'available-to-teachers',
  templateUrl: './available-to-teachers.component.html',
  styleUrls: ['./available-to-teachers.component.scss']
})
export class AvailableToTeachersComponent {

}
