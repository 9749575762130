<div *ngIf="showIntroAnimation" [class.fade-out-ani]="fadeOut" class="fade-in-ani">
  <div class="animation-textbox">
    <div class="text-container">
      <div class="search-message">Now we’re setting up the enhanced large language model search engine.</div>
    </div>
    <object
      role="figure"
      class="search-animation"
      alt="Welcome Teacher Loading Animation"
      aria-label="Welcome Teacher Loading Animation"
      data="../../../../assets/intro-animations/search-intro-animation.svg">
    </object>
  </div>
</div>
