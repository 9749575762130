import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TakeActionData } from './take-action-data';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { Student } from 'src/app/shared/models/student.model';
import {TransformedRecommendationForPendo} from 'src/app/shared/models/pendo-recommendation-track.model';
import { AmbiguousSkillShortNameFinder } from 'src/app/util/ambiguous-skill-short-name-finder/ambiguous-skill-short-name-finder';
import { RecommendationType, Recommendation } from 'src/app/shared/models/recommendation.model';
import { RecommendationServiceV2 } from 'src/app/services/recommendation-v2/recommendation-v2.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { DOCUMENT } from '@angular/common';
import { PendoService } from 'src/app/services/pendo/pendo.service';
import { CategorizationService} from 'src/app/services/categorization/categorization.service';
import { Category } from 'src/app/shared/models/category.model';

@Component({
  selector: 'take-action-modal-v2',
  templateUrl: './take-action-modal.component.html',
  styleUrls: ['./take-action-modal.component.scss'],
})
export class TakeActionModalComponentV2 implements OnInit {
  public recommendations: Recommendation[] = [];
  public stuckStudents: Student[] = [];
  public ambiguousShortNameSkillIds: string[] = [];
  public isLoaded: boolean = false;
  public recommendationsWithLinks: Recommendation[] = [];
  public recommendationDataForPendo: TransformedRecommendationForPendo[] = [];
  currentActionInfo:{ type: string; isClicked: boolean } = {type: '', isClicked: false};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: TakeActionData,
    public ref: MatDialogRef<TakeActionModalComponentV2>,
    public recommendationService: RecommendationServiceV2,
    private subjectService: SubjectService,
    public featureToggleService: FeatureToggleService,
    private pendoService: PendoService,
    private categorizationService: CategorizationService
  ) { }

  async ngOnInit() {
    this.recommendations = await this.getRecommendations();
    this.stuckStudents = this.getStuckStudents();
    this.ambiguousShortNameSkillIds = AmbiguousSkillShortNameFinder.getAmbiguousShortNameSkillIds(this.data.student.skills);
    this.recommendationsWithLinks = this.recommendations.filter(x => x.productActionLinks && Object.keys(x.productActionLinks).length > 0);
    this.isLoaded = true;
    this.ref.backdropClick().subscribe(() => { 
      this.sendRecommendationDataToPendo();
     });
  }

  public async close(event: Event) {
    event.preventDefault();
    this.ref.close();
    await this.sendRecommendationDataToPendo();
  }

  async sendRecommendationDataToPendo() {
    this.recommendationDataForPendo = await this.transformRecommendationsForPendo(this.recommendations, { renaissanceStudentId: this.data.student.renaissanceId });
    this.pendoService.sendEvent("TakeActionRecommendationData", { takeActionModalCloseEvent: this.recommendationDataForPendo });
    this.currentActionInfo = { type: '', isClicked: false };
  }

  public async getRecommendations(): Promise<Recommendation[]> {
    return await this.recommendationService.getSkillRecommendations(this.data.student, this.data.students, this.data.classProducts);
  }

  public recommendationIsTypeNone(recommendation: Recommendation): boolean {
    return recommendation.recommendationType == RecommendationType.None;
  }

  public recommendationIsTypeNeedsHelp(recommendation: Recommendation): boolean {
    return recommendation.recommendationType == RecommendationType.NeedsHelp;
  }

  public getStuckStudents(): Student[] {
    let stuckSkill = this.recommendations.find(skill => {
      return skill.recommendationType == RecommendationType.NeedsHelp
    });
    let contentActivityId = stuckSkill?.skillMetadata?.contentActivityId;

    return SkillTests.getStuckStudentsForSkill(this.data.students, contentActivityId);
  }

  public isSkillNameAmbiguous(recommendation: Recommendation): boolean {
    if (recommendation.skillMetadata?.contentActivityId) {
      return this.ambiguousShortNameSkillIds.indexOf(recommendation.skillMetadata?.contentActivityId) !== -1;
    }
    return false;
  }

  public isShortTestTime() {
    return SkillTests.isShortTestTime(this.data.student.latestAssessment.subject, this.data.student.latestAssessment.assessmentTimeInSeconds);
  }

  public getStrugglingStudentsString() {
    let studentName = this.data.student.firstName;
    let numStudents = this.stuckStudents.length;
    if (numStudents == 1) {
      return `${studentName} is <b>struggling</b> on this skill`;
    }
    if (numStudents == 2) {
      return `${studentName} and one other are <b>struggling</b> on this skill`;
    }
    if (numStudents > 2) {
      return `${studentName} and ${numStudents - 1} others are <b>struggling</b> on this skill`;
    }
    return '';
  }

  public studentHasNoAssessments() {
    if (this.data.student.assessments && this.data.student.assessments.length > 0) {
      return false;
    }
    return true;
  }

  public getSubjectString() {
    let subject = this.data.student?.latestAssessment?.subject;

    if (subject === undefined) {
      return '';
    }

    return subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase();
  }

  public getTestTimeString() {
    let duration = this.data.student.latestAssessment.assessmentTimeInSeconds;
    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;

    let minutesLabel = "minutes";
    if (minutes === 1) minutesLabel = "minute";

    let secondsLabel = "seconds";
    if (seconds === 1) secondsLabel = "second";

    if (minutes != 0) {
      if (seconds != 0) {
        return `${minutes} ${minutesLabel} and ${seconds} ${secondsLabel}`;
      }
      return `${minutes} ${minutesLabel}`;
    }
    return `${seconds} ${secondsLabel}`;
  }

  public getStuckStudentIds() {
    return this.stuckStudents.map(x => x.renaissanceId);
  }

  public displaySkillProductHelperText(): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING && this.data.classProducts.skillsPracticeProducts.length > 1;
  }

  public hasAnyRecommendations(recommendations: Recommendation[]): boolean {
    return recommendations.some(x => Object.keys(x.productActionLinks).length > 0);
  }

  public allRecommendationsHaveLinks(): boolean {
    return this.recommendations.every(x => Object.keys(x.productActionLinks).length > 0);
  }

  // Transform recommended data before sending to pendo for tracking
  async transformRecommendationsForPendo(recommendations: Recommendation[], studentIds: { renaissanceStudentId: string }): Promise<TransformedRecommendationForPendo[]> {
    return Promise.all(recommendations.map(async (rec: Recommendation) => {
      let recommendingSkill = null;
      if (rec.skillMetadata) {
        const skillPracticeType = rec.practiceProductType || null;
        const studentCategory = Category[await this.categorizationService.categorizeStudent(this.data.student)] || null
        const { standardSetId, standardId, renaissanceSkillId } = rec.skillMetadata;
        recommendingSkill = { standardSetId, standardId, renaissanceSkillId, productId: rec.productId, skillPracticeType, studentCategory }; ;
      }
      return {
        recommendedSkillProduct: Object.keys(rec.productActionLinks || {}),
        recommendingSkill,
        readingPracticeProducts: rec.studentHasReadingPractice ? rec.readingPracticeProducts || [] : [],
        studentId: { renaissanceId: studentIds.renaissanceStudentId },
        action: { productType: this.currentActionInfo.type, isClicked: this.currentActionInfo.isClicked },
      };
    }));
  };

  updateCurrentActionInfo(event: { type: string; isClicked: boolean }) {
    this.currentActionInfo = event;
  }
}

