import { Injectable } from '@angular/core';
import { ProductAppCodes } from '../product-info/product-info.service';
import { AppNavV2HelperService } from '../app-nav-v2/app-nav-v2-helper.service';

@Injectable({
  providedIn: 'root'
})
export class NextLeaderLinkGeneratorV2Service {

  constructor(
    private appNavV2HelperService: AppNavV2HelperService
  ) { }

  public getNextLeaderAppCode(): string {
    let hasRenAnalytics = this.appNavV2HelperService.isLicensedAppCode(ProductAppCodes.RenAnalytics);
    if (hasRenAnalytics) {
      return ProductAppCodes.RenAnalytics;
    }

    let hasSZStarter = this.appNavV2HelperService.isLicensedAppCode(ProductAppCodes.SchoolZillaStarter);
    if (hasSZStarter) {
      return ProductAppCodes.SchoolZillaStarter;
    }

    //TODO: error handling when we get a chance
    return ProductAppCodes.SchoolZillaStarter;
  }
}
