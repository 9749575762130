import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { KeyValues } from '../../models/key-values.model';
import { ProductSettings } from '../../models/products-settings.model';
import { SearchFilterOptions } from '../../models/search-filters';
import { SearchResult } from '../../models/search-result.model';
import { ProductNavigationService } from '../../services/product-navigation.service';
import { SearchService } from '../../services/search.service';
import { PRODUCT_SETTINGS } from '../../settings/products.constants';
import * as CONSTANTS from '../../settings/search.constants';

@Component({
  selector: '[search-result-v2]',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnChanges, OnInit {
  @Input() public searchResult!: SearchResult;

  public activityButtonLabel: string = 'Lesson';
  public descriptionTags: KeyValues[] = [];
  public filteredTags: KeyValues[] = [];
  public productSettings: ProductSettings | undefined;
  public searchGrades: SearchFilterOptions[] = [];
  public settings = PRODUCT_SETTINGS;

  constructor(
    private productNavigationService: ProductNavigationService,
    private searchService: SearchService,
    public adminHandlerService: AdminHandlerService,
    public featureToggleService: FeatureToggleService,
  ) { }

  public ngOnInit(): void {
    this.initializeGrades();
    this.getFilteredTags();
    this.updateActivityButtonLabelFromLessonTypes();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchResult']) {
      this.setProductSetting();
      this.getDescriptionTags();
    }
  }

  public initializeGrades(): void {
    this.searchGrades = this.searchService.getFilterOptionsFromSettings(CONSTANTS.SEARCH_FILTERS.GRADES);
  }

  public getFilteredTags(): void {
    this.filteredTags = this.searchResult?.tags?.filter(tag =>
      tag.key &&
      tag.value.length > 0 &&
      !this.productSettings?.resultUISettings?.descriptionTags?.includes(tag.key)
    );
  }

  public updateActivityButtonLabelFromLessonTypes(): void {
    let lesson_types = this.filteredTags?.find(t => t.key == 'lesson_types');

    if (lesson_types && lesson_types.value.length > 0) {
      let activityButtonLabel = `${lesson_types.value}`;
      this.trimActivityButtonLabel(activityButtonLabel)
    }
  }

  public trimActivityButtonLabel(lesson_types: any): void {
    let trimText = lesson_types.trim();
    if (trimText) {
      this.activityButtonLabel = trimText;
    }
  }

  public setProductSetting(): void {
    const productSettingsArray = Object.values(this.settings);
    this.productSettings = productSettingsArray?.find((setting) => setting.sourceId === this.searchResult.source);
  }

  public getDescriptionTags() {
    const descriptionTags = this.productSettings?.resultUISettings?.descriptionTags;

    if (descriptionTags) {
      this.searchResult.tags.filter(tag => {
        if (descriptionTags.includes(tag.key)) {
          this.descriptionTags.push(tag);
          return false;
        }
        return true;
      });
    }
  }

  public formatStandardName(result: SearchResult): string {
    return `${result.standard_set_name}.${result.standard_name}`.trim();
  }

  public formatTag(key: string, value: string[]) {
    if (key === 'grades') {
      return this.formatGrades(value);
    }
    if (key === 'products') {
      return this.productSettings?.productTagName;
    }
    if (key === 'lesson_types') {
      return `${value}`;
    }

    return `${value}`;
  }

  public formatGrades(values: string[]) {
    values = values.map(x => {
      if (x === '0') return 'K';
      return x;
    });

    if (values.length === 1) {
      return `Grade ${values}`;
    }

    let valuesWithSortOrder = values.map(x => this.searchGrades.find(grade => grade.value === x));

    if (values.length > 1) {
      let sortedValues = valuesWithSortOrder.sort((a, b) => {
        if (a?.sortOrder === undefined) return -1;
        if (b?.sortOrder === undefined) return 1;
        return (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0);
      });

      let minGrade = sortedValues[0]?.value;
      let maxGrade = sortedValues[sortedValues.length - 1]?.value;
      return `Grades ${minGrade}-${maxGrade}`;
    }

    return '';
  }

  public activityNavigation() {
    const clickHandler = this.productSettings?.resultUISettings?.clickHandler;
    if (clickHandler) {
      (this.productNavigationService[clickHandler as keyof ProductNavigationService] as Function)(this.searchResult);
    }
  }
}
