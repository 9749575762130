import { Component } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-summary-data.component.html',
  styleUrls: ['./no-summary-data.component.scss']
})
export class NoSummaryDataComponent {

}
