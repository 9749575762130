import { Injectable } from "@angular/core";
import { StudentContext } from "src/app/shared/models/student.model";
import { SubjectService } from "../../subject/subject.service";
import { SubjectTypes } from "../../subject/subject-types";
import { LexileBasedMyONMadeRecommendationService } from "./lexile-based-myonmade-recommmendation.service";
import { MyONMadeRecommendation } from "./myonMadeRecommendation";
import { LicenseInfoService } from "../../license-info/license-info.service";
import { TeacherLicenseInfoService } from "../../teacher-license-info/teacher-license-info.service";
import { SkillBasedMyONMadeRecommendationService } from "./skill-based-myonmade-recommendation.service";

@Injectable({ providedIn: 'root' })
export class MyONMadeRecommendationService {
  constructor(
    private subjectService: SubjectService,
    private lexileBasedMyONMadeRecommendationService: LexileBasedMyONMadeRecommendationService,
    private licenseInfoService: LicenseInfoService,
    private teacherLicenseInfoService: TeacherLicenseInfoService,
    private skillBasedMyONMadeRecommendationService: SkillBasedMyONMadeRecommendationService

  ) { }

  public async getMyONMadeRecommendation(student: StudentContext): Promise<MyONMadeRecommendation | null> {
    const subject = this.subjectService.selectedSubject$.value;

    if (subject === SubjectTypes.MATH) {
      return null;
    }

    if (!await this.licenseInfoService.selectedClassHasMyon() || !await this.teacherLicenseInfoService.teacherHasMyon()) {
      return null;
    }

    if (student.lexileLevel != null) {
      return await this.lexileBasedMyONMadeRecommendationService.getLexileRecommendation(student);
    }

    return await this.skillBasedMyONMadeRecommendationService.getFreckleBasedMyONMadeRecommendation(student);
  }
}
