import { Component, Input, OnInit } from '@angular/core';
import { Student } from '../models/student.model';

const colorDictionary = [
  {number: 0, color: "#CA3642"},
  {number: 1, color: "#982832"},
  {number: 2, color: "#C45423"},
  {number: 3, color: "#696AC5"},
  {number: 4, color: "#4F5094"},
  {number: 5, color: "#343562"},
  {number: 6, color: "#C13688"},
  {number: 7, color: "#912866"},
  {number: 8, color: "#1D7376"},
  {number: 9, color: "#196962"},
  {number: 10, color: "#0076B3"},
  {number: 11, color: "#006599"},
  {number: 12, color: "#005480"},
  {number: 13, color: "#807310"},
  {number: 14, color: "#665C0C"},
  {number: 15, color: "#955F13"},
  {number: 16, color: "#7D4F10"},
  {number: 17, color: "#277559"},
  {number: 18, color: "#21624B"},
  {number: 19, color: "#1A4E3C"}
]

@Component({
  selector: 'app-student-bubble',
  templateUrl: './student-bubble.component.html',
  styleUrls: ['./student-bubble.component.scss']
})

export class StudentBubbleComponent implements OnInit {
  @Input() student!: Student;

  public color: string = "";
  public initials: string = "";
  public fullName: string = "";

  ngOnInit(): void {
    this.color = this.getColor(this.student.renaissanceId);
    this.initials = this.getInitials(this.student.firstName, this.student.lastName);
    this.fullName = this.getFullname(this.student.firstName, this.student.lastName);
  }

  getFullname(firstName: string, lastName: string){
    return firstName + " " + lastName;
  }
  getInitials(firstName: string, lastName: string){
    let firstInitial = firstName.charAt(0).toUpperCase();
    let lastInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  }

  getColor(guid: string){

    // Note: parseInt only converts part of the guid into an int up until the first hyphen
    let colorNumber = Number.parseInt(guid, 16) % 20;
    let color = colorDictionary.find(x => x.number === colorNumber)?.color;
    if (color != null) {
      return color;
    }
    return colorDictionary[0].color;
  }
}
