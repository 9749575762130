import { Injectable } from '@angular/core';
import { FreckleLinkGeneratorService } from './freckle-link-generator.service';
import { LaliloLinkGeneratorService } from './lalilo-link-generator.service';
import { StarPhonicsLinkGeneratorService } from './star-phonics-link-generator.service';
import { MyonLinkGeneratorService } from './myon-link-generator.service';
import { TileLinkType } from 'src/app/shared/models/app-nav-item.model';
import { ClassContext } from 'src/app/shared/models/class.model';

@Injectable({
  providedIn: 'root'
})
export class ReportLinkGeneratorService {

  constructor(
    private _freckleLinkGeneratorService: FreckleLinkGeneratorService,
    private _laliloLinkGeneratorService: LaliloLinkGeneratorService,
    private _starPhonicsLinkGeneratorService: StarPhonicsLinkGeneratorService,
    private _myonLinkGeneratorService: MyonLinkGeneratorService
  ) { }

  public async generateRedirectLink(tileLinkType: TileLinkType, currentClass: ClassContext, path: string = '') {
    let reportsPageLink = '';
    switch (tileLinkType) {
      case TileLinkType.FRECKLE_REPORTS_PAGE:
        if (path) {
          reportsPageLink = this._freckleLinkGeneratorService.getReportsPageLink(currentClass.students, path);
        }
        else {
          reportsPageLink = this._freckleLinkGeneratorService.getReportsPageLink(currentClass.students);
        }
        break;
      case TileLinkType.LALILO_REPORTS_PAGE:
        reportsPageLink = await this._laliloLinkGeneratorService.getLaliloPageLink(path);
        break;
      case TileLinkType.MYON_REPORTS_PAGE:
        reportsPageLink = this._myonLinkGeneratorService.getReportsPageLink();
        break;
      case TileLinkType.STARPHONICS_REPORTS_PAGE:
        reportsPageLink = this._starPhonicsLinkGeneratorService.getReportsPageLink();
        break;
      default:
        throw new Error('Invalid tile link type');
    }
    return reportsPageLink;
  }
}
