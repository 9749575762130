<div class="container">
  <div class="header">
    <app-student-icon [student]="data.student"></app-student-icon>
    <app-skill-description [skillMetadata]="data.skillMetadata" [hasAmbiguousShortName]="data.hasAmbiguousShortName" [isModalHeading]="true">
    </app-skill-description>
  </div>
  <hr aria-hidden="true" />

  <div class="body" *ngIf="isLoaded">
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Accuracy</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let skill of skills;">
          <td class="padding-right">
            {{toFriendlyDate(skill.lastPracticedDate)}}
          </td>
          <td>
            {{toPercent(skill.correctAnswers/skill.totalAnswers)}}% ({{skill.correctAnswers}} / {{skill.totalAnswers}}
            correct)
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td> Overall </td>
          <td data-test="overall-skill-metrics">{{toPercent(totalCorrect/totalAnswers)}}% ({{totalCorrect}} / {{totalAnswers}} correct)</td>
        </tr>
      </tfoot>
    </table>
  </div>

  <div class="body" *ngIf="!isLoaded">
    <mat-spinner mode="indeterminate" class="mat-spinner"> </mat-spinner>
  </div>

  <div class="footer">
    <hr aria-hidden="true" />
    <div class="footer-button">
      <button class="ren-link" (click)="close($event)">Close</button>
    </div>
  </div>
</div>
