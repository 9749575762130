import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchFilter, SearchFilterOptions } from 'src/app/home/search-v2/models/search-filters';
import { SearchService } from '../services/search.service';
import { PRODUCT_SETTINGS } from '../settings/products.constants';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'app-search-filters-v2',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnDestroy, OnInit {
  @ViewChildren(MatSelect) matSelects!: QueryList<MatSelect>;

  public areFiltersClear = false;
  public filterChangeDebounce$: Subject<{ [key: string]: string; }> = new Subject<{ [key: string]: string; }>();
  public formGroup: FormGroup = new FormGroup({});
  public productSettings = PRODUCT_SETTINGS;
  public searchConstants: any = CONSTANTS;
  public searchFilters: SearchFilter[] = JSON.parse(JSON.stringify(CONSTANTS.SEARCH_FILTERS_SETTINGS));

  private subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private searchService: SearchService,
    public fb: FormBuilder
  ) { }

  public async ngOnInit(): Promise<void> {
    this.initializeFormControls();
    this.updateFilterValuesFromQueryParams();
    this.productLicensingCheck();
    this.debounceSubscriptionUpdateFilters();
  }

  public initializeFormControls(): void {
    let formGroup: Record<string, FormControl> = {};
    formGroup = this.sortOptions(formGroup);
    this.formGroup = new FormGroup(formGroup);
  }

  public async productLicensingCheck(): Promise<void> {
    for (const filter of this.searchFilters) {
      if (filter.settings.formControlName === 'products') {
        filter.options = await Promise.all(
          filter.options.map(async option => {
            try {
              const productSetting = this.productSettings[option.value];
              const isLicensed = await this.searchService.productAvailable(productSetting, false);
              const isAllowed = await this.searchService.productAvailable(productSetting, true);

              option.isAvailable = isLicensed && isAllowed;
              return option;
            } catch (error) {
              option.isAvailable = false;
              return option;
            }
          })
        );
      }
    }
  }

  private sortOptions(formGroup: Record<string, FormControl>): Record<string, FormControl> {
    this.searchFilters?.forEach(filter => {
      const sortedOptions = filter.options.sort((a, b) => {
        if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
          return a.sortOrder - b.sortOrder;
        }
        return 0;
      }).filter(option => option.isSelected).map(option => option.value);

      formGroup[filter.settings.formControlName] = new FormControl(sortedOptions);
    });

    return formGroup;
  }

  public updateFilterValuesFromQueryParams(): void {
    const queryParams = this.route.snapshot.queryParams;

    Object.keys(queryParams).forEach(queryParamKey => {
      const formControl = this.formGroup.get(queryParamKey);

      if (formControl) {
        const formattedQueryParamValues = this.formatQueryParamValues(queryParams[queryParamKey]);
        const selectedValues = this.getSelectedValuesForKey(queryParamKey, formattedQueryParamValues);
        this.updateFilterOptions(queryParamKey, selectedValues);
        formControl.setValue(selectedValues.map(value => value.value));
      }
    });

    this.searchService.updateSearchFilters(this.searchFilters);
  }

  public formatQueryParamValues(queryParamValue: string[] | string): string[] {
    return Array.isArray(queryParamValue) ? queryParamValue : queryParamValue?.split(',');
  }

  public getSelectedValuesForKey(key: string, values: string[]): SearchFilterOptions[] {
    const filter = this.searchFilters?.find(filter => filter.settings.formControlName === key);

    if (!filter) {
      return [];
    }

    return values.map(value => {
      return filter.options.find(option => option.value === value.toLocaleLowerCase() || option.displayName === value);
    }).filter((item): item is SearchFilterOptions => item !== undefined);
  }

  public updateFilterOptions(queryParamKey: string, selectedValues: SearchFilterOptions[]): void {
    const filterIndex = this.searchFilters.findIndex(filter => filter.settings.formControlName === queryParamKey);
    if (filterIndex !== -1) {
      const filter = this.searchFilters[filterIndex];
      filter.options.forEach(option => {
        option.isSelected = selectedValues.some(selectedValue => selectedValue.value === option.value);
      });
      this.searchFilters[filterIndex] = filter;
    }
  }

  private debounceSubscriptionUpdateFilters(): void {
    this.subscriptions.push(this.filterChangeDebounce$.pipe(debounceTime(800)).subscribe(async () => {
      this.searchService.updateSearchFilters(this.searchFilters);
    }));
  }

  public getDisplayText(filter: SearchFilter): string {
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      const selectedValues = formControl.value;

      if (this.areAllAvailableItemsSelected(filter)) {
        return 'All';
      }

      const selectedNames = selectedValues?.map((value: string) => {
        const option = filter.options.find(option => option.value === value);
        return option && option?.displayName ? option.displayName : value;
      });

      return selectedNames?.join(', ');
    }

    return '';
  }

  public areAllAvailableItemsSelected(filter: SearchFilter): boolean {
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      const availableOptions = filter.options.filter(option => option.isAvailable);
      return availableOptions.every(option => formControl.value?.includes(option.value));
    }

    return false;
  }

  public setFilter(): void {
    const changedValues: { [key: string]: string } = {};

    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      if (control && control.dirty) {
        changedValues[key] = control.value ?? '';
      }
    });

    this.updateSelectedSearchFilters(changedValues);
    const allValuesEmpty = Object.values(changedValues).every(value => value.length === 0);
    this.areFiltersClear = allValuesEmpty;
    this.transformSelectedValuesToSearchFilter(this.searchFilters[0], changedValues);
  }

  public updateSelectedSearchFilters(changedValues: { [key: string]: string }): void {
    this.searchFilters = this.searchFilters.map(filter => {
      const key = filter.settings.formControlName;
      if (changedValues.hasOwnProperty(key)) {
        return {
          ...filter,
          options: filter.options.map(option => ({
            ...option,
            isSelected: changedValues[key].includes(option.value)
          }))
        };
      }
      return filter;
    });
  }

  public transformSelectedValuesToSearchFilter(filter: SearchFilter, selectedValues: { [key: string]: string }): void {
    const key = filter.settings.formControlName;
    const transformedSelectedValues = {
      ...filter,
      options: filter.options.map(option => {
        const isSelected = selectedValues[key]?.includes(option.value) || false;
        return {
          ...option,
          isSelected
        };
      })
    };

    const searchFilters = this.searchFilters.map(filter => filter.settings.formControlName === key ? transformedSelectedValues : filter);
    this.searchService.updateSearchFilters(searchFilters);
  }

  public toggleSelectAll(filter: SearchFilter, event?: Event): void {
    event?.stopPropagation();
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      if (this.areAllAvailableItemsSelected(filter)) {
        formControl.setValue([]);
      } else {
        formControl.setValue(filter.options.map(option => option.value));
      }
    }

    this.transformSelectedValuesToSearchFilter(filter, { [filter.settings.formControlName]: formControl?.value });
  }

  public clearFormControlValues(): void {
    this.formGroup.reset();

    this.searchFilters.forEach(filter => {
      filter.options.forEach(option => {
        option.isSelected = false;
      });
    });

    this.searchService.updateSearchFilters(this.searchFilters);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
