import { Injectable } from "@angular/core";
import { MyOnLinkGeneratorV2Service } from "src/app/services/link-generators-v2/myon-link-generator-v2.service";
import { ActionLink, ProductKeys } from "src/app/shared/models/recommendation.model";
import { Link } from "src/app/util/link/link";

@Injectable({ providedIn: 'root' })
export class MyONActionRecommendationService {

  private imageUrl = '../../../../../../assets/logos/myon_logo.svg';

  constructor(
    private myonLinkGeneratorV2Service: MyOnLinkGeneratorV2Service
  ) { }

  public async getMyONActionForRecommendation(projectId: string, studentId: string): Promise<ActionLink> {
    let link = this.myonLinkGeneratorV2Service.generateMyOnProjectLink(projectId, [studentId]);

    let result: ActionLink = {
      link: new Link(link),
      name: 'myON Made Assignment',
      description: `Assign independent reading and skill practice`,
      imageUrl: this.imageUrl,
      type: ProductKeys.MyON
    };

    return result;
  }
}
