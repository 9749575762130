<div class="week-head">
  <app-week-display></app-week-display>
</div>
<div class="context-selector">
  <context-selector [isSuperstar]="true"></context-selector>
</div>

<div class="tiles-container">

  <!-- Loading spinner -->
  <mat-spinner diameter="60" mode="indeterminate" class="mat-spinner superstar-spinner"
    *ngIf="!isLoaded || showSpinner"></mat-spinner>

  <!-- No Superstars -->
  <div *ngIf="isLoaded && !showSpinner && superstars.length === 0" class="superstar-card-container">
    <div class="image-container">
      <object type="image/svg+xml" role="presentation" alt="" class="awesome"
        data="assets/rennext-awards_empty_state-ani.svg"></object>
    </div>
    <div class="text-container">
      <p>Every week we will recommend which students you can celebrate for their effort and achievements in Renaissance
        products.</p>
      <p class="sup-center"><span class="sup-check">Check back</span>
        <span class="sup-cent-text"> after they have started working on activities, practice, or assessments.</span>
      </p>
    </div>
  </div>

  <!-- Superstar tiles -->
  <div *ngIf="isLoaded && !showSpinner && superstars.length > 0" class="tiles-container">
    <div *ngFor="let superstar of superstars" class="superstar-tile">
      <div class="superstar-head">
        <app-student-bubble class="superstar-bubble" [student]="superstar.student"></app-student-bubble>
        <div class="superstar-name">
          {{ superstar.student.firstName }} {{ superstar.student.lastName }}
        </div>
      </div>

      <div class="student-list-item">
        <ul class="student-details">
          <li *ngFor="let qualification of superstar.qualifications">
            {{ qualification }}
          </li>
        </ul>

      </div>
      <div class="s-tile">
        <div class="left-division">
          <img src="../../../../assets/superstar-card-images/{{getSuperstarCard(superstar.student)}}"
            alt="Placeholder Image" class="card-image">
          <button id="pendo-superstars-selectcard" class="ren-btn ren-btn--secondary select-button"
            (click)="openSuperstarSelectCardModal(superstar.student)">Select New
            Card</button>
        </div>
        <div class="right-division">
          <p>Add short message </p>
          <p> (256 characters)</p>
          <textarea id="txtArea_{{superstar.student.renaissanceId}}" placeholder="" maxlength="256"></textarea>
          <div class="inner-div"></div>
        </div>
      </div>

      <div id="printSection-{{superstar.student.renaissanceId}}" style="display:none">
        <app-superstar-print [student]="superstar.student" [svgIcon]="getSuperstarCard(superstar.student)"
          [message]="getStudentMessage(superstar.student)" [teacherName]="teacherName"  [toImgUrl]="getSuperstarCardToImg(superstar.student)"></app-superstar-print>
      </div>
      <div class="print-box">
        <button id="pendo-superstars-print" class="print-button ren-btn ren-btn--secondary"
          printSectionId="printSection-{{superstar.student.renaissanceId}}" [useExistingCss]="true"
          ngxPrint>Print</button>
      </div>
    </div>
</div>
