// Temporary file to hold text constants until a translation setup has been added to the repo.

import { SearchFilter } from "../models/search-filters";

export const SEARCH_FILTERS = {
  CLEAR_ALL: 'Clear All',
  CLEAR_FILTERS: 'Clear Filters',
  GRADES: 'Grades:',
  PRODUCTS: 'Products:',
  SELECT_ALL: 'Select All',
  SUBJECT: 'Subject:',
  TYPE: 'Type:'
}

export const SEARCH_FILTERS_SETTINGS: SearchFilter[] = [
  {
    options: [
      { isAvailable: true, displayName: 'Math', isSelected: true, value: 'Math' },
      { isAvailable: true, displayName: 'Reading', isSelected: true, value: 'Reading' }
    ],
    settings: {
      filterDisplayName: SEARCH_FILTERS.SUBJECT,
      formControlName: 'subjects',
      id: 'subject',
      isAvailable: true
    }
  },
  {
    options: [
      { isAvailable: true, displayName: 'Freckle', isSelected: true, value: 'freckle' },
      { isAvailable: true, displayName: 'Lalilo', isSelected: true, value: 'lalilo' },
      { isAvailable: true, displayName: 'myON', isSelected: true, value: 'myon' },
      { isAvailable: true, displayName: 'Nearpod', isSelected: true, value: 'nearpod' },
    ],
    settings: {
      filterDisplayName: SEARCH_FILTERS.PRODUCTS,
      formControlName: 'products',
      id: 'product',
      isAvailable: true,
    }
  },
  {
    options: [
      { isAvailable: true, displayName: 'PK', isSelected: true, sortOrder: -1, value: 'pk' },
      { isAvailable: true, displayName: 'K', isSelected: true, sortOrder: 0, value: 'k' },
      { isAvailable: true, displayName: '1', isSelected: true, sortOrder: 1, value: '1' },
      { isAvailable: true, displayName: '2', isSelected: true, sortOrder: 2, value: '2' },
      { isAvailable: true, displayName: '3', isSelected: true, sortOrder: 3, value: '3' },
      { isAvailable: true, displayName: '4', isSelected: true, sortOrder: 4, value: '4' },
      { isAvailable: true, displayName: '5', isSelected: true, sortOrder: 5, value: '5' },
      { isAvailable: true, displayName: '6', isSelected: true, sortOrder: 6, value: '6' },
      { isAvailable: true, displayName: '7', isSelected: true, sortOrder: 7, value: '7' },
      { isAvailable: true, displayName: '8', isSelected: true, sortOrder: 8, value: '8' },
      { isAvailable: true, displayName: '9', isSelected: true, sortOrder: 9, value: '9' },
      { isAvailable: true, displayName: '10', isSelected: true, sortOrder: 10, value: '10' },
      { isAvailable: true, displayName: '11', isSelected: true, sortOrder: 11, value: '11' },
      { isAvailable: true, displayName: '12', isSelected: true, sortOrder: 12, value: '12' },
      { isAvailable: false, displayName: 'Other', isSelected: true, sortOrder: 13, value: '13' },
      { isAvailable: false, displayName: 'Higher Ed', isSelected: true, sortOrder: 14, value: '14' },
    ],
    settings: {
      filterDisplayName: SEARCH_FILTERS.GRADES,
      formControlName: 'grades',
      id: 'grade',
      isAvailable: true,
    }
  },
  {
    options: [
      { isAvailable: true, displayName: 'Lesson', isSelected: true, value: 'Lesson' },
      { isAvailable: true, displayName: 'Activity', isSelected: true, value: 'Activity' },
      { isAvailable: true, displayName: 'Video', isSelected: true, value: 'Video' }
    ],
    settings: {
      filterDisplayName: SEARCH_FILTERS.TYPE,
      formControlName: 'lesson_types',
      id: 'type',
      isAvailable: true
    }
  }
]

export const SEARCH_RESULT = {
  ASSIGN: 'Assign',
  PREVIEW_ASSIGN: 'Preview and Assign',
  VIEW: 'View',
  VIEW_ACTIVITY: 'View Activity',
}

export const SEARCH_RESULTS = {
  BACK: 'Back',
  MORE_RESULTS: 'More Results',
  NO_RESULTS: 'Sorry, no results are available. Please adjust the selections above.',
  PROBLEM_SEARCH: 'We ran into a problem getting those search results.',
  SOMETHING_WRONG: 'Something Went Wrong',
  TROUBLE_FINDING: `Having trouble finding what you're looking for?`,
  TRY_AGAIN: 'Try Again',
  TRY_TIPS: 'Try these tips'
}

export const SEARCHBOX = {
  HEADER_WHAT_NEXT: 'What are you working on next?',
  LEARN_MORE: 'Learn More',
  PLACEHOLDER: 'Example: "third grade practice on finding perimeter"'
}

export const ROUTER = {
  SEARCH: 'search',
  SUMMARY: 'summary'
}
