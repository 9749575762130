// Formula per spec https://vnmay0.axshare.com/#id=ty3y59&p=skills_modal&c=1

import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MaxModalDimensionsService {

  constructor(public breakpointObserver: BreakpointObserver) { }
  public maxWidth() {
    let maxWidth = '800px';
    const isSmallWidthScreen = this.breakpointObserver.isMatched('(max-width: 800px)');
    const isSmallHeightScreen = this.breakpointObserver.isMatched('(max-height: 800px)');
    if (isSmallWidthScreen || isSmallHeightScreen) {
      maxWidth = (window.innerWidth - 40) + 'px';
    }
    return maxWidth;
  }

  public maxHeight() {
    let maxHeight = '800px';
    const isSmallWidthScreen = this.breakpointObserver.isMatched('(max-width: 800px)');
    const isSmallHeightScreen = this.breakpointObserver.isMatched('(max-height: 800px)');
    if (isSmallWidthScreen || isSmallHeightScreen) {
      maxHeight = (window.innerHeight - 40) + 'px';
    }
    return maxHeight;
  }
}
