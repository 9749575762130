<mat-card class="inactive-card">
  <mat-card-header class="inactive-card-header">
    <mat-card-title>
      <div class="inactive-card-title">
        <img class="inactive-card-icon" alt="" [src]="inactiveCardIcon">
        <p class="inactive-card-title-text">{{inactiveCardName}}
        <span class="inactive-card-title-subtext">{{inactiveCardSubText}}</span></p>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="inactive-card-content">
    <mat-divider aria-hidden="true"></mat-divider>
    <app-student-grouping [students]="inactiveStudents" [enablePopup]="false"></app-student-grouping>
  </mat-card-content>
</mat-card>
