<div class="context-selector renaissance-style-scoped">
  <div class="dropdown subject-picker" *ngIf="!isSuperstar">
    <mat-label>Subject</mat-label>
    <mat-form-field id="pendo-subject-selector" appearance="outline" class="accessible-dropdown">
      <mat-select panelClass="accessible-selector" disableOptionCentering [value]="selectedSubject"
        (selectionChange)="updateSelectedSubject($event.value)">
        <mat-option [value]="subject" id="pendo-subject-{{subject}}" *ngFor="let subject of subjects">{{subject.displayValue}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="schools.length > 1" class="dropdown school-picker">
    <mat-label>School</mat-label>
    <mat-form-field id="pendo-school-selector" appearance="outline" class="accessible-dropdown">
      <mat-select panelClass="accessible-selector" disableOptionCentering [value]="selectedSchool"
      (selectionChange)="updateCurrentSchool($event.value)">
        <mat-option [value]="scl" *ngFor="let scl of schools">{{scl.schoolName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="dropdown class-picker">
    <mat-label>Class</mat-label>
    <mat-form-field id="pendo-class-selector" appearance="outline" class="accessible-dropdown">
      <mat-select panelClass="accessible-selector" disableOptionCentering [value]="selectedTJClass"
      (selectionChange)="updateCurrentClass($event.value)">
        <mat-option [value]="cls" *ngFor="let cls of tjClasses">{{cls.className}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
