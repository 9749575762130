import { Injectable } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SuperstarCategory, Superstar, SuperstarResponse } from 'src/app/shared/models/superstar.model';
import { SuperstarHistoryService } from './history/superstar.history.service';
import { ClassesService } from '../classes/classes.service';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { SuperstarQualificationService } from './qualification/superstar.qualification.service';

@Injectable({
  providedIn: 'root'
})
export class SuperstarService {
  private readonly schoolYearWeeks: number = 32;

  constructor(
    private superstarQualificationService: SuperstarQualificationService,
    private superstarHistoryService: SuperstarHistoryService,
    private classesService: ClassesService
  ) { }

  public async getSuperstars(students: StudentContext[]): Promise<Superstar[]> {

    const classId = this.classesService.selectedClass$.getValue()?.renaissanceClassId;

    // No class found
    if (!classId || students.length === 0)
      return [];

    // Check dynamoDB for previous/current superstars (if any)
    const previousSuperstars = await this.superstarHistoryService.getSuperstarHistory(classId);

    const currentSuperstars = await this.getCurrentSuperstars(students, previousSuperstars);
    if (currentSuperstars.length > 0) {
      return currentSuperstars;
    }

    // No students have activity
    if (!this.classHasActivity(students[0])) {
      return [];
    }

    // Get count of superstars to recognize
    let numSuperStarsLeftToAdd = this.getSuperstarCount(students);

    // Exclude students already recognized and randomize order
    let unrecognizedStudents = this.sortByRenaissanceId(students.filter(student =>
      !previousSuperstars.students.some(superstar => superstar.studentId === student.renaissanceId)
    ));

    let superstars: Superstar[] = this.superstarQualificationService.qualifyStudents(unrecognizedStudents, numSuperStarsLeftToAdd);

    // Not enough superstars -> delete class from dynamoDB and get more superstars
    if (superstars.length < numSuperStarsLeftToAdd) {
      await this.superstarHistoryService.deleteSuperstars(students.filter(student =>
        !superstars.some(superstar => superstar.student.renaissanceId === student.renaissanceId)
        ), classId);

      let remainingStudents = this.sortByRenaissanceId(students.filter(student =>
        !superstars.some(superstar => superstar.student.renaissanceId === student.renaissanceId)
      ));

      numSuperStarsLeftToAdd -= superstars.length;
      superstars.push(...this.superstarQualificationService.qualifyStudents(remainingStudents, numSuperStarsLeftToAdd));
    }

    // Save superstars to dynamoDB
    await this.superstarHistoryService.saveSuperstars(superstars, classId);

    return superstars;
  }

  private async getCurrentSuperstars(students: StudentContext[], previousSuperstars: SuperstarResponse): Promise<Superstar[]> {

    // No previous superstars
    if (!previousSuperstars || previousSuperstars.students.length === 0) {
      return [];
    }

    // Find superstars associated with this week
    const lastMondayDate = DateHelper.getCurrentWeekMondayDateString();

    const superstarStudentResponses = previousSuperstars.students.filter(student =>
      student.mostRecentMonday === lastMondayDate
    );

    let superstars: Superstar[] = [];
    for (let studentResponse of superstarStudentResponses) {
      let student = students.find(student => student.renaissanceId === studentResponse.studentId);
      if (!student) {
        continue;
      }

      let superstar: Superstar = {
        student: student,
        category: studentResponse.category as SuperstarCategory,
        qualifications: studentResponse.qualifications
      };

      superstars.push(superstar);
    }

    return superstars;
  }

  public getSuperstarCount(students: StudentContext[]): number {
    if (!students || students.length === 0) {
      return 0;
    }
    if (students.length === 1) {
      return 1;
    }

    const superstarCount = Math.ceil(students.length / this.schoolYearWeeks);
    return superstarCount < 2 ? 2 : superstarCount;
  }

  public classHasActivity(student: StudentContext): boolean {
    return student.classProductUsage.hasAssessmentActivity || student.classProductUsage.hasPracticeActivity;
  }

  public sortByRenaissanceId(students: StudentContext[]): StudentContext[] {
    // Randomize student order by sorting on renaissanceId
    return students.sort((a,b) => a.renaissanceId.localeCompare(b.renaissanceId));
  }
}
