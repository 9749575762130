import { Component } from '@angular/core';

@Component({
  selector: 'superstars',
  templateUrl: './superstars.component.html',
  styleUrls: ['./superstars.component.scss']
})

export class SuperstarsComponent {
}
