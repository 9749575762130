<div class="no-data">
  <div class="hen-wrapper">
    <object type="image/svg+xml" role="presentation" alt="" class="hen"
      data="assets/hero-no_data-chicken-ani.svg"></object>
  </div>
  <h3>No Data Here Yet</h3>
  <div class="text-info">
    <p>Once your students start working in your Renaissance products this page will help you track their progress.</p>
  </div>
</div>
