import { Injectable } from '@angular/core';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SubjectService } from '../../subject/subject.service';
import { NudgeTextService } from './nudge-text/nudge-text.service';

@Injectable({
  providedIn: 'root'
})
export class NoSkillsRecommendationService {

  constructor(
    private subjectService: SubjectService,
    private nudgeTextService: NudgeTextService
  ) { }

  public getNoSkillsRecommendation(selectedStudent: StudentContext, classProducts: ClassProducts): Recommendation {
    let noRecentActivityText = this.getNoRecentActivityText(selectedStudent, classProducts);

    let subject = this.subjectService.selectedSubject$.getValue();
    let nudgeText = this.nudgeTextService.getNudgeText(selectedStudent, classProducts, subject);

    let noSkillsRecommendation: Recommendation = {
      rationale: noRecentActivityText,
      nudgeText: nudgeText,
      skillMetadata: null,
      recommendationType: RecommendationType.None,
      productActionLinks: {},
      skillsPracticeProducts: classProducts.skillsPracticeProducts,
      readingPracticeProducts: classProducts.readingPracticeProducts,
      studentHasReadingPractice: this.studentHasReadingPractice(selectedStudent)
    };

    return noSkillsRecommendation;
  }

  private getNoRecentActivityText(student: StudentContext, classProducts: ClassProducts): string {
    let assessmentProducts = classProducts.assessmentProducts;
    let skillsPracticeProducts = classProducts.skillsPracticeProducts;

    if (assessmentProducts.length > 0) {
      if (skillsPracticeProducts.length > 1) {
        return `${student.firstName} has no recent activity in Star, ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
      if (skillsPracticeProducts.length > 0) {
        return `${student.firstName} has no recent activity in Star or ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
      return `${student.firstName} has no recent activity in Star`;
    }
    if (skillsPracticeProducts.length > 0) {
      return `${student.firstName} has no recent activity in ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
    }
    return '';
  }

  private getSkillProductsDisplayString(skillProducts: string[]): string {
    return skillProducts.join(' or ');
  }

  private studentHasReadingPractice(student: StudentContext): boolean {
    return student.readingPractices.length > 0;
  }
}
