import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppNavItem, AppNavItemIds } from '../../models/app-nav-item.model';
import { MatAccordion } from '@angular/material/expansion';
import { ReportSearchComponent } from './report-search/report-search.component';

@Component({
  selector: 'app-link-selector',
  templateUrl: './app-link-selector.component.html',
  styleUrls: ['./app-link-selector.component.scss']
})
export class AppLinkSelectorComponent implements OnInit {
  @ViewChild(MatAccordion) viewChildMatAccordion!: MatAccordion;
  @ViewChild(ReportSearchComponent) viewChildReportSearch!: ReportSearchComponent;

  @Input() selectedApp: AppNavItem = {
    displayName: '',
    children: []
  };
  @Input() appCategories: AppNavItem[] = [];
  @Input() expandDisabled: boolean = false;
  @Output() closeSidenavEvent: EventEmitter<void> = new EventEmitter();

  public appNavItems: AppNavItem[] = [];
  public accordionShown: boolean = true;

  constructor(
  ) { }

  async ngOnInit() {
    this.appNavItems = this.selectedApp.children ?? [this.selectedApp];
  }

  public getHeaderText(app: AppNavItem) {
    if (app.longDisplayName) {
      return app.longDisplayName;
    }
    return app.displayName;
  }

  public isReports(app: AppNavItem): boolean {
    return app.id === AppNavItemIds.REPORTS;
  }

  public hideAccordion() {
    this.accordionShown = false;
  }

  public showAccordion() {
    this.accordionShown = true;
  }

  public closeSidenav() {
    this.closeSidenavEvent.emit();
  }

  public clearPanelState() {
    if (!this.expandDisabled) {
      this.viewChildMatAccordion.closeAll();
    }
    this.viewChildReportSearch.clear();
  }
}
