import { Injectable } from '@angular/core';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { Skill } from 'src/app/shared/models/skill.model';
import { Student } from 'src/app/shared/models/student.model';

export class ContentType {
  public static MathSkills: string = 'math_skills';
  public static ElaSkillsPractice: string = 'ela_skills_practice';
  public static WordStudy: string = 'word_study';
}
@Injectable({
  providedIn: 'root'
})
export class FreckleLinkGeneratorV2Service {

  constructor() { }
  public getReportsPageDeepLink(students: Student[], url: string = 'reports-overview?tab=math'): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);

    //Example:
    //https://classroom.freckle.com/courses/416/math/reports

    let freckleDeepLink = `https://${domain}/${courseParam}/${url}`;

    return freckleDeepLink;
  }

  public getActivityFeedDeepLink(students: Student[], activityFeedPath: string = 'activity-feed'): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);

    //Example:
    //https://classroom.freckle.com/courses/13459555/activity-feed

    let freckleDeepLink = `https://${domain}/${courseParam}/${activityFeedPath}`;

    return freckleDeepLink;
  }

  public getAssignSkillDeepLink(freckleMetadata: SkillMetadata, students: Student[]): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);
    let standardId = freckleMetadata.standardId;
    let studentSlug = this.getStudentSlug(students);
    let skillId = freckleMetadata.renaissanceSkillId

    // Examples:
    // https://classroom.freckle.com/courses/11199169/assignments/targeted?students=|34493722|34493723|34493724&numQuestions=10&subType=practice&standardId=tuxlwnye&skillId=67a3bfaa479de311b77c005056801da1
    // https://classroom.freckle.com/courses/13452964/assignments/targeted?students=|44256445&numQuestions=10&subType=practice&standardId=mqvgekpj

    let freckleDeepLink = `https://${domain}/${courseParam}/assignments/targeted?students=${studentSlug}&subType=practice&standardId=${standardId}&skillId=${skillId}`;

    return freckleDeepLink;
  }

  public getSearchResultAssignSkillDeepLink(students: Student[], standardId: string, skillId: string, contentType: string): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);
    let freckleDeepLink = '';

    switch (contentType) {
      case ContentType.MathSkills: {
        freckleDeepLink = `https://${domain}/${courseParam}/assignments/targeted?subType=practice&standardId=${standardId}&skillId=${skillId}`;
        break;
      }
      case ContentType.ElaSkillsPractice: {
        freckleDeepLink = `https://${domain}/${courseParam}/ela/targeted-skill-practice/assign?elaSkillUspId=${skillId}`;
        break;
      }
      case ContentType.WordStudy: {
        freckleDeepLink = `https://${domain}/${courseParam}/ela/word-study?elaSkillUspId=${skillId}`;
        break;
      }
    }

    return freckleDeepLink;
  }

  public getAssignElaSkillsPracticeDeepLink(freckleMetadata: SkillMetadata, students: Student[]): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);
    let freckleSkillId = freckleMetadata.renaissanceSkillId;
    let studentSlug = this.getStudentSlug(students);

    // Example:
    // https://classroom.freckle.com/courses/13459235/ela/targeted-skill-practice/assign?elaSkillUspId=1e10f61e-e69f-e311-9503-005056801da1
    let freckleDeepLink = `https://${domain}/${courseParam}/ela/targeted-skill-practice/assign?students=${studentSlug}&elaSkillUspId=${freckleSkillId}`;

    return freckleDeepLink;
  }

  public getAssignPrereqSkillDeepLink(freckleMetadata: SkillMetadata, students: Student[]): string {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);
    let standardId = freckleMetadata.standardId;
    let studentSlug = this.getStudentSlug(students);

    // Examples:
    // https://classroom.freckle.com/courses/13452964/assignments/targeted?students=|44256445&subType=prerequisite&standardId=mqvgekpj

    let freckleDeepLink = `https://${domain}/${courseParam}/assignments/targeted?students=${studentSlug}&subType=prerequisite&standardId=${standardId}`;

    return freckleDeepLink;
  }

  public getDepthOfKnowledgeSkillDeepLink(skill: Skill, students: Student[]) {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);

    // this freckle page does not yet support query parms
    // the link will need updates to incorporate them when they are in place

    // Examples:
    // https://classroom-staging.freckle.com/courses/1529/assignments/targeted-depth-of-knowledge-practice

    let freckleDeepLink = `https://${domain}/${courseParam}/assignments/targeted-depth-of-knowledge-practice`;

    return freckleDeepLink;
  }

  public getAssignTargetedPracticeDeepLink(students: Student[]) {
    let domain = this.getFreckleDomain();
    let courseParam = this.getFreckleCourseParam(students);

    let freckleDeepLink = `https://${domain}/${courseParam}/assignments/targeted`;

    return freckleDeepLink;
  }

  private getStudentSlug(students: Student[]) {
    let slug = students
      .map(student => `|${student.studentIds.freckleStudentId}`)
      .join('');

    return slug;
  }

  public getFreckleCourseParam(students: Student[]): string {
    let freckleCourseId = '';
    let foundMultipleFreckleCourseIds = false;

    students.forEach(student => {
      let studentFreckleClassId = student.studentClassIds.freckleClassId;
      if (studentFreckleClassId) {
        if (freckleCourseId && studentFreckleClassId !== freckleCourseId) {
          var errMessage = `Multiple freckle course Ids: ${studentFreckleClassId} and ${freckleCourseId} (student ${student.renaissanceId})`;
          console.log(errMessage);
          foundMultipleFreckleCourseIds = true;
        }
        freckleCourseId = studentFreckleClassId;
      }
    });

    // No freckle course ID found -> return default course param
    if (!freckleCourseId || foundMultipleFreckleCourseIds) {
      return 'default-course';
    }

    // Return freckle course ID as param
    return 'courses/' + freckleCourseId;
  }

  private getFreckleDomain(): string {
    return `classroom.freckle.com`;
  }
}
