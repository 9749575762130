<html>

<head>
  <title>Print</title>
</head>

<body>
  <div class="certificate">
    <div class="certificate-header">
      <img src="../../../../assets/superstar-card-images/{{svgIcon}}" alt="Placeholder Image" alt="Placeholder Image"
        class="superstar-image">
    </div>
    <div class="certificate-body" *ngIf="student">
      <div class="recipient">
        <span class="student-name"> {{ student.firstName }} {{ student.lastName }}</span>
        <span class="to-label"><img src="{{ toImgUrl }}"></span>
        <span class="date">{{ formattedDate }}</span>
      </div>
      <p class="certificate-text">
        {{ message }}
      </p>
    </div>
    <div class="certificate-footer">
      <span class="teacher-name">{{ teacherName }}</span>
    </div>
    <div class="certificate-footer-note">
      <p class="footer-note">© 2024 Renaissance Learning, Inc. All rights reserved.</p>
    </div>
  </div>

</body>

</html>