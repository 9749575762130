import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { UserResponse } from './user-response';
import { Enrollment, StudentActivityResponse } from './student-activity-response';
import { SkillMetadataResponse } from './skill-metadata-response';
import { SkillPracticeResponse } from './skill-practice-response';
import { SkillMetadataByStandardSetsRequest, SkillMetadataForLaliloRequest } from './skill-metadata-request';
import { AssessmentBenchmarksResponse } from './assessment-benchmarks-response';
import { environment } from 'src/environments/environment';
import { StandardSet, StandardSetResponse } from './standard-set-response';
import { SkillDetail } from 'src/app/shared/models/skill.model';
import { ReadingPracticeDetail, ReadingPracticeSummary, ReadingPracticeSummaryDetail } from 'src/app/shared/models/reading-practice.model';
import { AssessmentDetail } from 'src/app/shared/models/assessment.model';
import { StudentGradesResponse } from './student-grades-response';
import { DistinctSchoolsResponse } from './distinct-schools-response';
import { DistinctClassesResponse } from './distinct-classes-response';
import { School } from 'src/app/shared/models/school.model';
import { TJClass } from 'src/app/shared/models/class.model';
import { StandardSetMap, StandardSetMapResponse } from './standard-set-map-response';
import { SkillProgressionMetadata, SkillProgressionMetadataResponse } from './skill-progression-metadata-response';
import { DateTime } from 'luxon';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { SkillPracticeSummary } from 'src/app/shared/models/skill-practice-summary.model';

const CACHE_TIME = environment.apiCacheTtl;

const Get_UserData = gql`
  query GetUserData @cached(ttl:${CACHE_TIME}) {
    userDetail {
      firstName
      lastName
    }
  }
`

const Get_StandardSets = gql`
  query GetStandardSets($userRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    teacherStandardSets(
      where: {
        teacherRenaissanceId: {_eq: $userRenaissanceId}
      })
    {
      product
      subject
      standardSetId
    }
  }
`;

const Get_Schools = gql`
  query GetSchools @cached(ttl:${CACHE_TIME}) {
    distinctSchools: schoolsByTenant
    {
      schoolName
      schoolRenaissanceId
    }
  }
`;

const Get_Classes = gql`
  query GetClasses($schoolRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    distinctClasses: classesByTenant(where: { schoolRenaissanceId: {_eq: $schoolRenaissanceId} })
    {
      className
      classRenaissanceId
      classAppTags
      userRenaissanceId
    }
  }
`;

const Get_Student_Enrollments = gql`
  query GetStudentEnrollments($classRenaissanceId: String, $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentEnrollments(
      where: {
        classRenaissanceId: {_eq: $classRenaissanceId},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      title
      appTags
      givenName
      familyName
      grade
      studentRenaissanceId
      classRenaissanceId
      schoolRenaissanceId
      schoolName
      studentIds: renaissanceStudentIds
      studentClassIds: renaissanceStudentClassIds
      teacherRenaissanceId
    }
  }
`;

const Get_Student_Skill_Detail = gql`
  query GetStudentSkillDetail($studentRenaissanceIds: [String], $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentSkillDetail(
      where: {
        studentRenaissanceId: {_in: $studentRenaissanceIds},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      correctAnswers
      durationInMinutes
      skillShortName
      product
      totalAnswers
      lastPracticedDate
      productId
      subject
      standardId
      standardSetId
      domainId
      renaissanceSkillId
      contentActivityId
      studentRenaissanceId
      standardSetMetadata: skillMetadata
    }
  }
`;

const Get_Student_Skill_Practice_Summary = gql`
  query GetStudentSkillPracticeSummary($studentRenaissanceIds: [String], $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentSkillPracticeSummary(
      where: {
        studentRenaissanceId: {_in: $studentRenaissanceIds},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      lastSkillPracticedDate
      subject
      studentRenaissanceId
    }
  }
`;

const Get_Student_Reading_Practice_Detail = gql`
  query GetStudentReadingPracticeDetail($studentRenaissanceIds: [String], $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentReadingPracticeDetail(
      where: {
        studentRenaissanceId: {_in: $studentRenaissanceIds},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      teacherRenaissanceId
      studentRenaissanceId
      contentTitle
      author
      atosLevel
      lexileLevel
      lexileLabel
      contentLanguage
      percentCorrect
      arQuizNumber
      product
      mostRecentPracticedDate
      finishedBook
      totalSecondsRead
    }
  }
`;

const Get_Student_Assessment_Detail = gql`
  query GetStudentAssessmentDetail($dateString: datetime, $studentRenaissanceIds: [String], $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentAssessmentDetail (
      where: {
        completedDate: {_gt: $dateString},
        studentRenaissanceId: {_in: $studentRenaissanceIds},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      accuracy
      assessmentTimeInSeconds
      assessmentType
      cbmAssessmentId
      cbmBenchmarkCategory
      cbmGradeId
      completedDate
      correctPerMinute
      currentSGP
      districtIsProficient
      percentileRank
      productId
      renaissanceIsProficient
      scaledScore
      studentRenaissanceId
      subject
      teacherRenaissanceId
    }
  }
`;

const Get_Student_Reading_Practice_Summary_Detail = gql`
  query GetStudentReadingPracticeSummary($studentRenaissanceIds: [String], $teacherRenaissanceId: String) @cached(ttl:${CACHE_TIME}) {
    studentReadingPracticeSummary(
      where: {
        studentRenaissanceId: {_in: $studentRenaissanceIds},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId}
      }
    ) {
      studentRenaissanceId
      teacherRenaissanceId
      averageQuizScore
      currentWordCount
      previousWordCount
      wordsReadWithinSevenDays
      wordsReadWithinEightToFourteenDays
      secondsReadWithinSevenDays
      secondsReadWithinEightToFourteenDays
    }
  }
`;

const Get_Skill_Progression = gql`
  query GetSkillProgression($standardSetId: String, $domainId: String) @cached(ttl:${CACHE_TIME}) {
    skillMetadata(where: {standardSetId: {_eq: $standardSetId}, domainId: {_eq: $domainId}}) {
      skillName
      skillShortName
      skillProgressionOrder
      standardProgressionOrder
      skillIsFocus
      renaissanceSkillId
      standardId
      productName
      productUrl
    }
  }
`;

const Get_Skill_Metadata_By_Standard_Sets_Multiple_Ids = gql`
query GetSkillMetadataByStandardSets($standardSetIds: [String!], $renaissanceSkillIds: [String!]) @cached(ttl: ${CACHE_TIME}) {
  skillMetadata(where: {_and: [
    {renaissanceSkillId: {_in: $renaissanceSkillIds}}
  	{_or: [
      {_and: [
        {productName: {_eq: "FRECKLE"}}
        {standardSetId: {_in: $standardSetIds}}
      ]},
      {_and: [
        {productName: {_eq: "NEARPOD"}}
      ]},
      {_and: [
        {productName: {_eq: "STAR"}}
      ]}
    ]}
  ]}) {
      skillName
      skillShortName
      skillProgressionOrder
      standardProgressionOrder
      skillIsFocus
      renaissanceSkillId
      contentActivityId
      standardId
      standardSetId
      productName
      productUrl
  }
}
`

const Get_Skill_Metadata_For_Lalilo = gql`
query LaliloSkillMetadataQuery($contentActivityId: String) @cached(ttl:${CACHE_TIME}) {
  skillMetadata(where: {contentActivityId: {_eq: $contentActivityId}}) {
      skillName
      skillShortName
      skillProgressionOrder
      standardProgressionOrder
      skillIsFocus
      renaissanceSkillId
      contentActivityId
      standardId
      standardSetId
      productName
      productUrl
  }
}
`

const Get_Skill_Metadata_By_Standard_Set_Multiple_Ids = gql`
  query GetSkillMetadataByStandardSet($standardSetId: String, $renaissanceSkillIds: [String!]) @cached(ttl:${CACHE_TIME}) {
    skillMetadata(where: {standardSetId: {_eq: $standardSetId}, renaissanceSkillId: {_in: $renaissanceSkillIds}}) {
      skillName
      skillShortName
      skillProgressionOrder
      standardProgressionOrder
      skillIsFocus
      renaissanceSkillId
      contentActivityId
      standardId
      productName
      productUrl
    }
  }
`;

const Get_Assessment_Benchmarks = gql`
  query GetAssessmentBenchmarks @cached(ttl:${CACHE_TIME}) {
    assessmentBenchmarks {
      categoryLevel
      categoryName
      maxPercentileRank
      minPercentileRank
      minProficiencyLevel
      numberOfCategoryLevels
      productId
      proficient
      subject
    }
  }
`

const Get_Skill_Practice = gql`
  query GetSkillPractice($studentRenaissanceId: String, $teacherRenaissanceId: String, $contentActivityId: String) @cached(ttl:${CACHE_TIME}) {
    skillDetail: studentSkillDetail(
      where: {
        studentRenaissanceId: {_eq: $studentRenaissanceId},
        teacherRenaissanceId: {_eq: $teacherRenaissanceId},
        contentActivityId: {_eq: $contentActivityId}
      }
    ) {
      correctAnswers
      lastPracticedDate
      skillShortName
      totalAnswers
      studentRenaissanceId
      renaissanceSkillId
      contentActivityId
    }
  }
`;

const Get_StudentGradesData = gql`
  query getStudentGradesData($userRenaissanceId: String, $grades: [String!]) @cached(ttl:${CACHE_TIME}) {
    studentEnrollments(where: {teacherRenaissanceId: {_eq: $userRenaissanceId}, grade: {_in: $grades}}) {
      studentRenaissanceId,
      grade
    }
  }
`;

const Get_Branded_Standard_Set_To_Standard_Set_Map = gql`
  query getStudentGradesData($brandedStandardSetId: String) @cached(ttl:${CACHE_TIME}) {
    standardSetMap(where: {brandedStandardSetId: {_eq: $brandedStandardSetId}}) {
      brandedStandardSetId,
      standardSetId
    }
  }
`;

const Get_Skill_Progression_Metadata = gql`
  query getSkillProgressionMetadata($standardSetId: String, $skillId: String) {
    skillProgressionMetadata(where: {standardSetTagId: {_eq: $standardSetId}, renaissanceSkillId: {_eq: $skillId}}) {
      renaissanceSkillId
      standardSetTagId
      progressionOrder
      prerequisiteSkillId
    }
  }
`;

const Get_Skills_Progression_Metadata = gql`
  query getSkillProgressionMetadata($standardSetId: String, $skillIds: [String!]) {
    skillProgressionMetadata(where: {standardSetTagId: {_eq: $standardSetId}, renaissanceSkillId: {_in: $skillIds}}) {
      renaissanceSkillId
      standardSetTagId
      progressionOrder
      prerequisiteSkillId
    }
  }
`;

const Get_Skills_Progression_Metadata_By_Progression_Order = gql`
  query getSkillProgressionMetadata($standardSetId: String, $progressionOrder: Int) {
    skillProgressionMetadata(where: {standardSetTagId: {_eq: $standardSetId}, progressionOrder: {_eq: $progressionOrder}}) {
      renaissanceSkillId
      standardSetTagId
      progressionOrder
      prerequisiteSkillId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private apollo: Apollo) { }

  public async getUserData(): Promise<UserResponse> {
    const client = await this.getClient()
    const user = (await firstValueFrom(client.query<UserResponse>({
      query: Get_UserData
    }))).data;

    return user;
  }

  public async getTeacherStandardSets(userRenaissanceId: string): Promise<StandardSet[]> {
    const client = await this.getClient()
    const standardSets = (await firstValueFrom(client.query<StandardSetResponse>({
      query: Get_StandardSets,
      variables: {
        userRenaissanceId
      }
    }))).data;

    return standardSets.teacherStandardSets;
  }

  public async getSchools(): Promise<School[]> {
    const client = await this.getClient();
    return (
      await firstValueFrom(client.query<DistinctSchoolsResponse>({
        query: Get_Schools,
      }))
    ).data.distinctSchools;
  }

  public async getClasses(schoolRenaissanceId: String): Promise<TJClass[]> {
    const client = await this.getClient();
    return (
      await firstValueFrom(client.query<DistinctClassesResponse>({
        query: Get_Classes,
        variables: {
          schoolRenaissanceId,
        }
      }))
    ).data.distinctClasses;
  }

  public async getStudentActivity(classRenaissanceId: string, teacherRenaissanceId: string): Promise<StudentActivityResponse> {
    const client = await this.getClient();
    const dateString = DateTime.now().minus({ days: 90 }).toFormat(DateHelper.DISPLAY_DATE_FORMAT);

    const studentEnrollments = client.query<any>({
      query: Get_Student_Enrollments,
      variables: {
        classRenaissanceId,
        teacherRenaissanceId
      },
    });

    // Get enrollments first and filter by students
    let enrollments: Enrollment[] = (await firstValueFrom(studentEnrollments)).data.studentEnrollments;
    const studentRenaissanceIds = enrollments.map(enrollment => enrollment.studentRenaissanceId);

    const studentSkillDetail = client.query<SkillDetail>({
      query: Get_Student_Skill_Detail,
      variables: { studentRenaissanceIds, teacherRenaissanceId },
    });
    const studentSkillPracticeSummary = client.query<SkillPracticeSummary>({
      query: Get_Student_Skill_Practice_Summary,
      variables: { studentRenaissanceIds, teacherRenaissanceId },
    });
    const studentReadingPracticeDetail = client.query<ReadingPracticeDetail>({
      query: Get_Student_Reading_Practice_Detail,
      variables: { studentRenaissanceIds, teacherRenaissanceId },
    });
    const studentAssessmentDetail = client.query<AssessmentDetail>({
      query: Get_Student_Assessment_Detail,
      variables: { dateString, studentRenaissanceIds, teacherRenaissanceId },
    });
    const studentReadingPracticeSummaryDetail = client.query<ReadingPracticeSummaryDetail>({
      query: Get_Student_Reading_Practice_Summary_Detail,
      variables: { studentRenaissanceIds, teacherRenaissanceId },
    });

    const Get_Student_Activity_queries = [
      studentSkillDetail,
      studentSkillPracticeSummary,
      studentReadingPracticeDetail,
      studentAssessmentDetail,
      studentReadingPracticeSummaryDetail,
    ];

    // Execute all queries in parallel
    const promises = Get_Student_Activity_queries.map(
      async (query: any) => ((await firstValueFrom(query)) as any).data
    );

    // reduce results into a single object to match the previous response shape
    let response = (await Promise.all(promises)).reduce((acc, val) => {
      return { ...acc, ...val };
    }, StudentActivityResponse);

    response.studentEnrollments = enrollments;
    return response;
  }

  public async getSkillProgression(standardSetId: string, domainId: string) {
    const client = await this.getClient();
    return (
      await firstValueFrom(
        client.query<SkillMetadataResponse>({
          query: Get_Skill_Progression,
          variables: {
            standardSetId,
            domainId,
          },
        })
      )
    ).data;
  }

  public async getSkillPractice(studentId: string, teacherRenaissanceId: string, skillId: string) {
    const client = await this.getClient();
    return (
      await firstValueFrom(
        client.query<SkillPracticeResponse>({
          query: Get_Skill_Practice,
          variables: {
            studentRenaissanceId: studentId,
            teacherRenaissanceId: teacherRenaissanceId,
            contentActivityId: skillId
          },
        })
      )
    ).data;
  }

  public async getSkillMetadataByStandardSetMultipleIdFormats(
    standardSetId: string,
    renaissanceSkillId: string
  ) {
    const client = await this.getClient();
    let renaissanceSkillIds =
      this.getRenaissanceSkillIdAlternateFormats(renaissanceSkillId);
    return (
      await firstValueFrom(
        client.query<SkillMetadataResponse>({
          query: Get_Skill_Metadata_By_Standard_Set_Multiple_Ids,
          variables: {
            standardSetId,
            renaissanceSkillIds,
          },
        })
      )
    ).data;
  }

  public async getSkillMetadataByStandardSetsMultipleIdFormats({
    standardSetIds,
    renaissanceSkillId,
  }: SkillMetadataByStandardSetsRequest) {
    const client = await this.getClient();
    let renaissanceSkillIds =
      this.getRenaissanceSkillIdAlternateFormats(renaissanceSkillId);
    return (
      await firstValueFrom(
        client.query<SkillMetadataResponse>({
          query: Get_Skill_Metadata_By_Standard_Sets_Multiple_Ids,
          variables: {
            standardSetIds,
            renaissanceSkillIds,
          },
        })
      )
    ).data;
  }

  public async getSkillMetadataForLalilo({
    contentActivityId
  }: SkillMetadataForLaliloRequest) {
    const client = await this.getClient();
    return (
      await firstValueFrom(
        client.query<SkillMetadataResponse>({
          query: Get_Skill_Metadata_For_Lalilo,
          variables: {
            contentActivityId
          }
        })
      )
    ).data;
  }
  public async getAssessmentBenchmarks(): Promise<AssessmentBenchmarksResponse> {
    const client = await this.getClient();
    return (
      await firstValueFrom(
        client.query<AssessmentBenchmarksResponse>({
          query: Get_Assessment_Benchmarks,
        })
      )
    ).data;
  }

  private getRenaissanceSkillIdAlternateFormats(
    renaissanceSkillId: string
  ): string[] {
    let idFormats: string[] = [renaissanceSkillId];
    if (renaissanceSkillId.includes('-')) {
      const idNoHyphens = renaissanceSkillId.split('-').join('');
      idFormats.push(idNoHyphens);
    } else {
      const idGuid = this.guidify(renaissanceSkillId);
      idFormats.push(idGuid);
    }

    return idFormats;
  }

  public async getStudentGradesData(
    userRenaissanceId: string,
    grades: string[] = ['02', '03', '2', '3']
  ): Promise<StudentGradesResponse> {
    const client = await this.getClient();
    const student = (
      await firstValueFrom(
        client.query<StudentGradesResponse>({
          query: Get_StudentGradesData,
          variables: {
            userRenaissanceId,
            grades,
          },
        })
      )
    ).data;

    return student;
  }

  public async getBrandedStandardSetToStandardSetMap(brandedStandardSetId: string): Promise<StandardSetMap> {
    const client = await this.getClient();
    const standardSetResponse = (
      await firstValueFrom(
        client.query<StandardSetMapResponse>({
          query: Get_Branded_Standard_Set_To_Standard_Set_Map,
          variables: {
            brandedStandardSetId
          }
        })
      )
    ).data;

    return standardSetResponse.standardSetMap[0];
  }

  public async getSkillProgressionMetadata(standardSetId: string, skillId: string): Promise<SkillProgressionMetadata[]> {
    const client = await this.getClient();
    const skillProgressionMetadataResponse = (
      await firstValueFrom(
        client.query<SkillProgressionMetadataResponse>({
          query: Get_Skill_Progression_Metadata,
          variables: {
            standardSetId,
            skillId
          }
        })
      )
    ).data;

    return skillProgressionMetadataResponse.skillProgressionMetadata;
  }

  public async getSkillsProgressionMetadata(standardSetId: string, skillIds: string[]): Promise<SkillProgressionMetadata[]> {
    const client = await this.getClient();
    const skillProgressionMetadataResponse = (
      await firstValueFrom(
        client.query<SkillProgressionMetadataResponse>({
          query: Get_Skills_Progression_Metadata,
          variables: {
            standardSetId,
            skillIds
          }
        })
      )
    ).data;

    return skillProgressionMetadataResponse.skillProgressionMetadata;
  }

  public async getSkillProgressionMetadataByProgressionOrder(standardSetId: string, progressionOrder: number): Promise<SkillProgressionMetadata | null> {
    const client = await this.getClient();
    const skillProgressionMetadataResponse = (
      await firstValueFrom(
        client.query<SkillProgressionMetadataResponse>({
          query: Get_Skills_Progression_Metadata_By_Progression_Order,
          variables: {
            standardSetId,
            progressionOrder
          }
        })
      )
    ).data;

    if (skillProgressionMetadataResponse.skillProgressionMetadata.length === 0) {
      return null;
    }

    return skillProgressionMetadataResponse.skillProgressionMetadata[0];
  }


  guidify(renaissanceSkillId: string): string {
    if (!renaissanceSkillId.includes('-') && renaissanceSkillId.length === 32) {
      let guid = renaissanceSkillId.split('');
      guid.splice(8, 0, '-');
      guid.splice(8 + 4 + 1, 0, '-');
      guid.splice(8 + 4 + 4 + 2, 0, '-');
      guid.splice(8 + 4 + 4 + 4 + 3, 0, '-');
      let renSkillGuid = guid.join('');
      return renSkillGuid;
    }
    return renaissanceSkillId;
  }

  async getClient() {

    return this.apollo.use('cloud');
  }
}
