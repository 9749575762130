import { Injectable } from "@angular/core";
import { MyONBook } from "../graphql/myon-books-response";
import { MyONProject } from "../graphql/myon-project-response";

@Injectable({ providedIn: 'root' })
export class Randomizer {
  public selectRandomInArray(items: MyONBook[] | MyONProject[]): MyONBook | MyONProject {
    let today = new Date();
    let seed = (today.getMonth() + 1) * today.getDate() ** (today.getDay() + 1) + today.getFullYear();
    let randomIndex = seed % items.length;
    return items[randomIndex];
  }
}
