import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeoutModalService } from '../timeout-modal.service';
import { IdleModalData } from '../idle-modal.data.model';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';

@Component({
  selector: 'app-idle-timeout-modal',
  templateUrl: './idle-timeout-modal.component.html',
  styleUrls: ['./idle-timeout-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdleTimeoutModalComponent {

  public warningIntervalParagraph: string = '';

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IdleModalData,
    private timeoutModalService: TimeoutModalService,
    private ref: MatDialogRef<IdleTimeoutModalComponent>,
    private localstorage: LocalStorageService
    ) {
      let interval = data.timeLeft || "5";
      this.updateWarningInterval(interval);

      timeoutModalService.timeLeftMinutes$.subscribe(data => {
        this.updateWarningInterval(data);
      })
    }

  public logout() {
    this.localstorage.removeItem('token');
    this.timeoutModalService.doLogout();
    this.ref.close();
  }

  public returnToScreen() {
    this.timeoutModalService.doReturnToScreen();
    this.ref.close();
  }

  public updateWarningInterval(interval: any) {
    var messageSpan = '';
    var testInterval = parseFloat(interval) || 0.5;
    if (testInterval <= 1) {
      messageSpan = 'less than a minute';
    }
    else {
      messageSpan = `${testInterval} minutes`;
    }

    var message = `You will be logged out in <span class="warning-interval"> ${messageSpan} </span> and might lose unsaved changes.`;
    this.warningIntervalParagraph = message;
  }
}
