import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { UserService } from "src/app/services/user/user.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(@Inject(DOCUMENT) private document: Document, private userService: UserService) { }

  public pendoTrackFeedback(event: Event) {
    event.preventDefault();
    const window = this.document.defaultView as any;
    if (window.pendo) {
      const pendo = window.pendo;
      pendo.track("Feedback");
    }
  }

  public getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  public getSystemStatusLink() {
    return environment.systemStatusRedirectUrl;
  }
}
