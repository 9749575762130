import { Injectable } from '@angular/core';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { SavvasLinkGeneratorService } from 'src/app/services/link-generators-v2/savvas-link-generator.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { PreferenceKey, UserPreferenceService } from 'src/app/services/user-preference/user-preference.service';
import { ActionLink, BaseRecommendationSkill, ProductKeys } from 'src/app/shared/models/recommendation.model';
import { Student } from 'src/app/shared/models/student.model';
import { Link } from 'src/app/util/link/link';

@Injectable({
  providedIn: 'root'
})
export class SavvasActionRecommendationService {
  public readonly SAVVAS_IMAGE_PATH = `../../../../../../assets/logos/savvas_logo.svg`
  private validGrades = ['KG', '01', '02', '03', '04', '05', '06', '07', '08'];

  constructor(
    private featureToggleService: FeatureToggleService,
    private savvasLinkGeneratorService: SavvasLinkGeneratorService,
    private userPreferenceService: UserPreferenceService
  ) { }

  public async getSavvasActionForRecommendation(skill: BaseRecommendationSkill, students: Student[]): Promise<ActionLink | null> {
    let actionLink = null;
    const isSavvasPreferenceEnabled = await this.userPreferenceService.getPreferenceValue(PreferenceKey.SavvasEnabled);
    const isSkillMath = skill.subject === SubjectTypes.MATH;
    const studentInGradeRange = this.validGrades.includes(students[0].grade);

    if (isSavvasPreferenceEnabled && isSkillMath && studentInGradeRange && await this.featureToggleService.isTrueAsync('nrd-47-core-publisher-enabled')) {
      const savvasUrl = '/';
      let link = new Link(savvasUrl, 'search');

      if (savvasUrl) {
        actionLink = {
          link: link,
          name: 'Resources in Savvas',
          description: 'Discover additional practice and instructional resources.',
          imageUrl: this.SAVVAS_IMAGE_PATH,
          type: ProductKeys.Savvas
        } as ActionLink;
      }
    }

    return actionLink;
  }
}
