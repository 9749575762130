<hr class="accordion-divider" aria-hidden="true">
<!-- Accordion if app has children -->
<mat-expansion-panel class="mat-elevation-z0" *ngIf="appNavItem.children" (opened)="open()" (closed)="close()"
  [(expanded)]="isExpanded">
  <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'" [attr.aria-expanded]="isExpanded">
    <div class="accordion-header-container">
      <img *ngIf="appNavItem.imagePrefix" class="link-logo outline" alt=""
        src="../../../assets/logos/{{appNavItem.imagePrefix}}.svg">
      <span *ngIf="appNavItem.imagePrefix === undefined" class="non-logo-slight-indent"></span>
      <div class="accordion-header">
        {{appNavItem.displayName}}
        <div class="accordion-sub-header">
          {{appNavItem.subHeader}}
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>
  <ul class="accordion-list">
    <div *ngFor="let child of appNavItem.children">
      <li>
        <a class="accordion-link" tabindex="0" (click)="navigateByLaunchControl(child)" (keydown.enter)="navigateByLaunchControl(child)">
          <span *ngIf="appNavItem.imagePrefix" class="link-logo-spacer"></span>
          {{child.displayName}}
        </a>
      </li>
    </div>
  </ul>
  <div class="bottom-spacer"></div>
</mat-expansion-panel>

<!-- Direct link if app has no children -->
<div
  [attr.collapsedHeight]="'70px'"
  [attr.expandedHeight]="'70px'"
  (click)="appEnabledForUser(appNavItem) ? navigateByLaunchControl(appNavItem) : $event.preventDefault()"
  (keydown.enter)="appEnabledForUser(appNavItem) ? navigateByLaunchControl(appNavItem) : $event.preventDefault()"
  *ngIf="!appNavItem.children"
  class="accordion-link left-nav-report-add-link-no-child"
  [class.disabled]="!appEnabledForUser(appNavItem)"
  tabindex="0"
  role="link"
  >
  <div class="accordion-header-container" aria-disabled="false">
    <img *ngIf="appNavItem.imagePrefix" class="link-logo outline" alt=""
      src="../../../assets/logos/{{appNavItem.imagePrefix}}.svg">
    <span *ngIf="appNavItem.imagePrefix === undefined" class="non-logo-slight-indent"></span>
    <div class="accordion-header" aria-disabled="false">
      {{appNavItem.displayName}}
      <div class="accordion-sub-header">
        {{appNavItem.subHeader}}
      </div>
      <a tabindex="-1" *ngIf="!appEnabledForUser(appNavItem)" id="pendo-available-to-teachers-text-reports-search-desktop"
        class="accordion-sub-header reports" href="#" (click)="$event.preventDefault()"
        (keydown.enter)="$event.stopPropagation()">
        <available-to-teachers data-test="available-to-teachers-component">
        </available-to-teachers>
      </a>
    </div>
  </div>
</div>
