// Short test time theshold comes from here:
// https://star-help.renaissance.com/hc/en-us/articles/11945788261019-Star-Test-Activity-Report-Short-and-Long-Test-Times

import { Skill } from "../../shared/models/skill.model";
import { SubjectTypes } from "src/app/services/subject/subject-types";
import { Student, StudentContext } from "src/app/shared/models/student.model";
import { SkillAggregate } from "../skill-aggregate/skill-aggregate";
import { ReadingPractice } from "src/app/shared/models/reading-practice.model";
import { ProductIds } from "src/app/services/product-info/product-info.service";

const mathShortTestTimeThreshold = 136;
const readingShortTestTimeThreshold = 230;
const minAttemptsToCountAsStuck = 3; // don't count a skill if there are less than this many answered questions

export abstract class SkillTests {

  public static isShortTestTime(subject: string, testTimeInSeconds: number | null): boolean {
    if (testTimeInSeconds !== null) {
      if (subject == SubjectTypes.MATH && testTimeInSeconds < mathShortTestTimeThreshold) {
        return true;
      }
      if (subject == SubjectTypes.READING && testTimeInSeconds < readingShortTestTimeThreshold) {
        return true;
      }
    }
    return false;
  }

  public static getEffectiveMinSkillAccuracyRate(skillSessions: Skill[] | null): number {
    if (!skillSessions || skillSessions?.length == 0) {
      return 1;
    }

    let skills = SkillAggregate.aggregate(skillSessions);

    let minSkillAccuracyRate = 1;
    skills?.forEach(skill => {
      let skillAccuracyRate = skill.correctAnswers / skill.totalAnswers;
      if (skill.totalAnswers < minAttemptsToCountAsStuck) {
        return;
      }
      if (skillAccuracyRate < minSkillAccuracyRate) {
        minSkillAccuracyRate = skillAccuracyRate;
      }
    });

    return minSkillAccuracyRate;
  }

  public static getRawMinSkillAccuracyRate(skillSessions: Skill[] | null): number {
    if (!skillSessions || skillSessions?.length == 0) {
      return 1;
    }

    let skills = SkillAggregate.aggregate(skillSessions);

    let minSkillAccuracyRate = 1;
    skills?.forEach(skill => {
      let skillAccuracyRate = skill.correctAnswers / skill.totalAnswers;

      if (skillAccuracyRate < minSkillAccuracyRate) {
        minSkillAccuracyRate = skillAccuracyRate;
      }
    });

    return minSkillAccuracyRate;
  }

  public static isStuckSkill(skill: Skill): boolean {
    let skillAccuracyRate = skill.correctAnswers / skill.totalAnswers;

    if (skill.totalAnswers < minAttemptsToCountAsStuck) {
      return false;
    }

    return this.isStuckSkillAccuracyRate(skillAccuracyRate);
  }

  public static isStuckSkillAccuracyRate(skillAccuracyRate: number): boolean {
    if (skillAccuracyRate < 0.5) {
      return true;
    }
    return false;
  }

  public static isReadyForChallengeBasedOnAccuracyRate(skillAccuracyRate: number): boolean {
    if (skillAccuracyRate >= 0.8) {
      return true;
    }
    return false;
  }

  public static isWorkingAtAppropriateLevelBasedOnAccuracyRate(skillAccuracyRate: number): boolean {
    if (skillAccuracyRate >= 0.5) {
      return true;
    }
    return false;
  }

  public static isJustStarting(totalAnswers: number): boolean {
    if (totalAnswers < minAttemptsToCountAsStuck) {
      return true;
    }
    return false;
  }


  public static getAccuracyRate(correctAnswers: number, totalAnswers: number): number {
    return correctAnswers / totalAnswers;
  }

  public static getSkillAccuracyRate(skill: Skill): number {
    return this.getAccuracyRate(skill.correctAnswers, skill.totalAnswers);
  }

  public static getMostRecentSkill(skills: Skill[]): Skill {
    return skills.reduce((a, b) => new Date(a.lastPracticedDate) > new Date (b.lastPracticedDate) ? a : b);
  }

  public static studentHasPracticeActivity(student: StudentContext): boolean {
    return student.skills.length > 0;
  }

  public static studentHasFreckleActivity(student: StudentContext): boolean {
    let hasFreckleSkills = !!student.skills.find(skill =>
        skill.productId === ProductIds.FreckleReading
        || skill.productId === ProductIds.FreckleMath
    );
    return hasFreckleSkills;
  }

  public static studentHasLaliloActivity(student: StudentContext): boolean {
    return !!student.skills.find(skill => skill.productId === ProductIds.Lalilo);
  }

  public static studentHasAssessmentActivity(student: StudentContext): boolean {
    return !!student.latestAssessment;
  }

  public static hasPracticeWithThreeOrMoreItems(skills: Skill[]): boolean {
    return skills.filter(skill => this.hasThreeOrMoreItems(skill)).length > 0;
  }

  public static hasThreeOrMoreItems(skill: Skill): boolean {
    return skill.totalAnswers >= 3;
  }

  public static isStuckReadingPracticeActivity(readingPractice: ReadingPractice): boolean {
    return readingPractice.percentCorrect != null && readingPractice.percentCorrect < 0.75;
  }

  public static hasStuckReadingPractice(averageQuizScore: number): boolean {
    return averageQuizScore != null && averageQuizScore < 0.75;
  }

  public static hasWorkingAtAppropriateLevelReadingPractice(averageQuizScore: number): boolean {
    return averageQuizScore != null && averageQuizScore < 0.85;
  }

  public static getStuckStudentsForSkill(students: StudentContext[], contentActivityId: string | undefined) {
    let stuckStudents: Student[] = [];
    students.forEach(student => {
      let skill = SkillAggregate.aggregate(student.skills).find(skill => {
        return skill.standardSetMetadata?.contentActivityId === contentActivityId && SkillTests.isStuckSkill(skill)});
      if (skill){
        stuckStudents.push(student);
      }
    })
    return stuckStudents;
  }
}
