import { Injectable } from "@angular/core";
import { UserService } from "../user/user.service";

export enum AdminRole {
    districtadmin = 'districtadmin',
    schooladmin = 'schooladmin',
}

@Injectable({
  providedIn: 'root'
})
export class AdminHandlerService {
    public constructor(private userService: UserService) {}

    public isAdmin(): boolean {
        let role = this.userService.getNextRole();
        return role === AdminRole.districtadmin ||
            role === AdminRole.schooladmin;
    }
}
