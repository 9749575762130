import { Injectable } from '@angular/core';
import { Assessment } from 'src/app/shared/models/assessment.model';

export class ProductIds {
  public static StarReading: string = 'STAR_READING';
  public static StarMath: string = 'STAR_MATH';
  public static StarEarlyLiteracy: string = 'STAR_EARLY_LITERACY';
  public static StarReadingSpanish: string = 'STAR_READING_SPANISH';
  public static StarMathSpanish: string = 'STAR_MATH_SPANISH';
  public static StarEarlyLiteracySpanish: string = 'STAR_EARLY_LITERACY_SPANISH';
  public static StarCbmMath: string = 'STAR_CBM_MATH';
  public static StarCbmReading: string = 'STAR_CBM_READING';
  public static StarCbmLectura: string = 'STAR_CBM_LECTURA';

  public static FreckleReading: string = 'FRECKLE_LANGUAGEARTS';
  public static FreckleMath: string = 'FRECKLE_MATH';
  public static Freckle: string = 'FRECKLE';
  public static Lalilo: string = 'LALILO';

  public static AR: string = 'AR';
  public static Myon: string = 'MYON';
}

export class ProductIdsByGroup {

  public static readonly StarCATProductIds: string[] = [
    ProductIds.StarReading,
    ProductIds.StarReadingSpanish,
    ProductIds.StarMath,
    ProductIds.StarMathSpanish,
    ProductIds.StarEarlyLiteracy,
    ProductIds.StarEarlyLiteracySpanish
  ];

  public static readonly StarCBMProductIds: string[] = [
    ProductIds.StarCbmReading,
    ProductIds.StarCbmMath,
    ProductIds.StarCbmLectura
  ];

  public static readonly PracticeProductIds: string[] = [
    ProductIds.FreckleMath,
    ProductIds.FreckleReading,
    ProductIds.Lalilo
  ];

  public static readonly FreckleProductIds: string[] = [
    ProductIds.FreckleMath,
    ProductIds.FreckleReading
  ];

  public static readonly ReadingPracticeProductIds: string[] = [
    ProductIds.AR,
    ProductIds.Myon
  ];

}

export class ProductTypeGroups {
  public static readonly TargetedPracticeIds: string[] = [
    'standard',
    'targeted_skills_practice',
    'Lalilo Targeted Practice'
  ];
  public static readonly AdaptivePracticeIds: string[] = [
    'adaptive',
    'adaptive_skills_practice',
    'Lalilo Adaptive Practice'
  ];
}

export class ProductAppTags {
  public static StarReading: string = 'APPS_SR';
  public static StarMath: string = 'APPS_SM';
  public static StarEarlyLiteracy: string = 'APPS_SEL';
  public static StarReadingSpanish: string = 'APPS_SRS2';
  public static StarMathSpanish: string = 'APPS_SMS';
  public static StarEarlyLiteracySpanish: string = 'APPS_SELS';
  public static StarCbmMath: string = 'APPS_CBMM';
  public static StarCbmReading: string = 'APPS_CBMR';
  public static StarCbmLectura: string = 'APPS_CBMRES';

  public static FreckleReading: string = 'APPS_FRELA';
  public static FreckleMath: string = 'APPS_FRMATH';
  public static Lalilo: string = 'APPS_LALILO';

  public static AR: string = 'APPS_AR';
  public static Myon: string = 'APPS_MYON';
}

// Added new class to support AppNavV2
export class ProductAppCodes {
  public static StarReading: string = 'APPS_SR';
  public static StarMath: string = 'APPS_SM';
  public static StarEarlyLiteracy: string = 'APPS_SEL';
  public static StarReadingSpanish: string = 'APPS_SRS2';
  public static StarMathSpanish: string = 'APPS_SMS';
  public static StarEarlyLiteracySpanish: string = 'APPS_SELS';
  public static StarCbmMath: string = 'APPS_CBMM';
  public static StarCbmReading: string = 'APPS_CBMR';
  public static StarCbmLectura: string = 'APPS_CBMRES';

  public static FreckleReading: string = 'APPS_FRELA';
  public static FreckleMath: string = 'APPS_FRMATH';
  public static Lalilo: string = 'APPS_LALILO';

  public static AR: string = 'APPS_AR';
  public static Myon: string = 'APPS_MYON';
  public static Freckle: string = 'APPS_FR';
  public static Schoolzilla: string = 'APPS_SZ';
  public static Planner: string = 'APPS_PLNR';
  public static StarCustom: string = 'APPS_STARC';
  public static Saebrs: string = 'APPS_SAEBRS';
  public static StarPhonics: string = 'APPS_PHONICS';
  public static StarPreschool: string = 'APPS_SPSLITENT';
  public static RENU: string = 'APPS_RENU';

  public static AdminReports = "APPS_ADMIN_REPORTS";
  public static MyCreatedReports = "APPS_CREATED_REPORTS";
  public static DataSharingReports = "APPS_DATA_SHARING";
  public static CustomReports = "APPS_CUSTOM_REPORTS";
  public static RenAnalytics = "APPS_ANALYTICS";
  public static SchoolZillaStarter = "APPS_SZSTARTER";
}

export class ProductAppImagePrefix {
  public static AR: string = 'ar_logo';
  public static Freckle: string = 'freckle_logo';
  public static Lalilo: string = 'lalilo_logo';
  public static Myon: string = 'myon_logo';
  public static Schoolzilla: string = 'schoolzilla_logo';
  public static Star: string = 'star_logo';
  public static RENU: string = 'ren_u_logo';
}

export class ProductAppDisplayNames {
  public static Star: string = 'Star';
  public static CBM: string = 'CBM';
  public static Freckle: string = 'Freckle';
  public static Lalilo: string = 'Lalilo';
  public static AR: string = 'AR';
  public static Myon: string = 'MyON';
  public static Schoolzilla: string = "Schoolzilla";
  public static RENU: string = "Renaissance-U";
}

export class ProductAppTagsByType {
  public static readonly SkillPracticeProductAppTags: string[] = [
    ProductAppTags.FreckleMath,
    ProductAppTags.FreckleReading,
    ProductAppTags.Lalilo
  ];
  public static readonly ReadingPracticeProductAppTags: string[] = [
    ProductAppTags.AR,
    ProductAppTags.Myon
  ];
  public static readonly AssessmentProductAppTags: string[] = [
    ProductAppTags.StarReading,
    ProductAppTags.StarMath,
    ProductAppTags.StarEarlyLiteracy,
    ProductAppTags.StarReadingSpanish,
    ProductAppTags.StarMathSpanish,
    ProductAppTags.StarEarlyLiteracySpanish,
    ProductAppTags.StarCbmReading,
    ProductAppTags.StarCbmMath,
    ProductAppTags.StarCbmLectura
  ];
}

@Injectable({
  providedIn: 'root'
})
export class ProductInfoService {
  constructor() { }

  public readonly ProductDisplayNames = {
    [ProductIds.StarReading]: 'Star Reading',
    [ProductIds.StarReadingSpanish]: 'Star Reading Spanish',
    [ProductIds.StarMath]: 'Star Math',
    [ProductIds.StarMathSpanish]: 'Star Math Spanish',
    [ProductIds.StarEarlyLiteracy]: 'Star Early Literacy',
    [ProductIds.StarEarlyLiteracySpanish]: 'Star Early Literacy Spanish',
    [ProductIds.StarCbmReading]: 'Star CBM Reading',
    [ProductIds.StarCbmMath]: 'Star CBM Math',
    [ProductIds.StarCbmLectura]: 'Star CBM Lectura',
    [ProductIds.FreckleMath]: 'Freckle Math',
    [ProductIds.FreckleReading]: 'Freckle Reading',
    [ProductIds.Lalilo]: 'Lalilo',
    [ProductIds.AR]: 'AR',
    [ProductIds.Myon]: 'MyON',
  };

  private readonly ProductNamesToAppCodes = {
    [ProductIds.StarReading]: ProductAppCodes.StarReading,
    [ProductIds.StarReadingSpanish]: ProductAppCodes.StarReadingSpanish,
    [ProductIds.StarMath]: ProductAppCodes.StarMath,
    [ProductIds.StarMathSpanish]: ProductAppCodes.StarMathSpanish,
    [ProductIds.StarEarlyLiteracy]: ProductAppCodes.StarEarlyLiteracy,
    [ProductIds.StarEarlyLiteracySpanish]: ProductAppCodes.StarEarlyLiteracySpanish,
    [ProductIds.StarCbmReading]: ProductAppCodes.StarCbmReading,
    [ProductIds.StarCbmMath]: ProductAppCodes.StarCbmMath,
    [ProductIds.StarCbmLectura]: ProductAppCodes.StarCbmLectura,
    [ProductIds.FreckleMath]: ProductAppCodes.FreckleMath,
    [ProductIds.FreckleReading]: ProductAppCodes.FreckleReading,
    [ProductIds.Freckle]: ProductAppCodes.Freckle,
    [ProductIds.Lalilo]: ProductAppCodes.Lalilo,
    [ProductIds.AR]: ProductAppCodes.AR,
    [ProductIds.Myon]: ProductAppCodes.Myon,
  }

  public GetProductName(productId: string) {
    return this.ProductDisplayNames[productId];
  }

  public GetProductAppCode(productName: string) {
    return this.ProductNamesToAppCodes[productName];
  }

  public readonly ProductAppTagsToDisplayNames = {
    [ProductAppTags.StarReading]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarReadingSpanish]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarMath]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarMathSpanish]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarEarlyLiteracy]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarEarlyLiteracySpanish]: ProductAppDisplayNames.Star,
    [ProductAppTags.StarCbmReading]: ProductAppDisplayNames.CBM,
    [ProductAppTags.StarCbmMath]: ProductAppDisplayNames.CBM,
    [ProductAppTags.StarCbmLectura]: ProductAppDisplayNames.CBM,
    [ProductAppTags.FreckleMath]: ProductAppDisplayNames.Freckle,
    [ProductAppTags.FreckleReading]: ProductAppDisplayNames.Freckle,
    [ProductAppTags.Lalilo]: ProductAppDisplayNames.Lalilo,
    [ProductAppTags.AR]: ProductAppDisplayNames.AR,
    [ProductAppTags.Myon]: ProductAppDisplayNames.Myon
  };

  public GetProductAppDisplayName(appTag: string) {
    return this.ProductAppTagsToDisplayNames[appTag];
  }

  public GetProductNameForAssessment(assessment: Assessment) {
    switch (assessment.productId) {
      case ProductIds.StarReading:
        return `Star Reading ${assessment.assessmentType || 'Enterprise'}`;
      case ProductIds.StarMath:
        return `Star Math ${assessment.assessmentType || 'Enterprise'}`;
      case ProductIds.StarReadingSpanish:
      case ProductIds.StarMathSpanish:
      case ProductIds.StarEarlyLiteracy:
      case ProductIds.StarEarlyLiteracySpanish:
        return this.GetProductName(assessment.productId);
      case ProductIds.StarCbmMath:
      case ProductIds.StarCbmReading:
      case ProductIds.StarCbmLectura:
        return this.cbmNameGenerator(assessment.productId, assessment.cbmAssessmentId, assessment.cbmGradeId);
      default:
        return '';
    }
  }

  private cbmNameGenerator(productId: string, assessmentId: string, gradeId: string | null) {
    let gradeString = '';

    if (gradeId != null) {
      gradeString = ` - ${gradeId}`;
    }

    switch (productId) {
      case ProductIds.StarCbmMath:
        return `Star CBM Math - ${assessmentId}${gradeString}`;
      case ProductIds.StarCbmReading:
        return `Star CBM Reading - ${assessmentId}${gradeString}`;
      case ProductIds.StarCbmLectura:
        return `Star CBM Lectura - ${assessmentId}${gradeString}`;
      default:
        return ''
    }
  }
}
