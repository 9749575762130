import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SubjectTypes } from "./subject-types";
import { LocalStorageService } from "../local-storage/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  private _selectedSubject$ = new BehaviorSubject(SubjectTypes.READING);
  public get selectedSubject$(): BehaviorSubject<string> { return this._selectedSubject$; };

  public constructor(localStorageService: LocalStorageService) {
    const selectedSubject = localStorageService.getItem('selectedSubject');
    if (selectedSubject) {
      this._selectedSubject$.next(selectedSubject);
    }

    this._selectedSubject$.subscribe((subject) => {
      localStorageService.setItem('selectedSubject', subject);
    });
  }

  public selectedSubjectIsReading(): boolean {
    return this._selectedSubject$.getValue() === SubjectTypes.READING;
  }

  public selectedSubjectIsMath(): boolean {
    return this._selectedSubject$.getValue() === SubjectTypes.MATH;
  }
}
