import { Injectable } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';

@Injectable({
  providedIn: 'root'
})
export class AppNavV2ItemUtilityService {

  constructor() { }

  public getAppNavV2ItemsByAppCode(appNavV2: AppNavV2[], appCode: string): AppNavItem[] {
    let appNavItems: AppNavItem[] = [];

    let items = appNavV2.filter(code => code.appCode == appCode);
    for (let i = 0; i < items.length; i++) {
      let appNavItem: AppNavItem = { displayName: "" };
      var isChildrenExists = items[i] != null && items[i].children && items[i].children!.length > 0 ? true : false;
      if (isChildrenExists) {
        appNavItem.children = [];
      }

      if (items[i] != null) {
        appNavItem.appCode = items[i].appCode;
        appNavItem.deepLinkId = items[i].deepLink;
        appNavItem.displayName = items[i].displayName;
        appNavItem.subHeader = items[i].subHeader;

        if (items[i].children && items[i].children!.length > 0) {
          items[i].children?.forEach(child => {
            let appNavChildItem: AppNavItem = { displayName: "" };
            appNavChildItem.appCode = child.appCode;
            appNavChildItem.deepLinkId = child.deepLink;
            appNavChildItem.displayName = child.displayName;
            appNavItem.children?.push(appNavChildItem)
          });
        }
      }
      appNavItems.push(appNavItem);
    }

    return appNavItems;
  }
}
