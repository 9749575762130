<div class="active-skill-category">
  <div class="active-skill-category-header">
    <img class="active-skill-category-icon" alt="" [src]=categoryIcon>
    <p class="active-skill-category-name">{{categoryName}}
      <span class="active-skill-category-subtext">{{categorySubText}}</span>
    </p>
  </div>
  <div data-test="take-action-button" *ngIf="showTakeActionButton()">
    <button
      type="button"
      id="pendo-skills-take-action"
      class="ren-btn ren-btn--secondary take-action"
      [ngClass]="categoryNameToClass()+'-take-action'"
      (click)="openTakeActionModal()"
      >Take Action
    </button>
  </div>
</div>
<app-student-grouping
  [students]="students"
  [skillMetadata]= "skillMetadata"
  [hasAmbiguousShortName]="hasAmbiguousShortName"
  [enablePopup]="true"
></app-student-grouping>
