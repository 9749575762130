/**
 * Determines link an exact match or a fuzzy search
 */
export type LinkType = 'search' | 'exact';

export class Link {
  constructor(public url: string, public type: LinkType = 'search') {}
  toString() {
    return this.url;
  }
}
