import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private _spinnerVisible$ = new BehaviorSubject<boolean>(false);
  public get spinnerVisible$(): BehaviorSubject<boolean> { return this._spinnerVisible$ }

  constructor() { }
}
