import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '../services/search.service';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'app-search-input-v2',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  public query: string = '';
  public searchConstants: any = CONSTANTS;

  @Input() isSearchboxScroll: boolean = false;
  @Input() showBackButton: boolean = false;
  @Input() showLearnMore: boolean = false;

  constructor(public searchService: SearchService) { }

  public ngOnInit(): void {
    this.query = this.searchService.getQueryValue();
  }

  public backClicked(): void {
    this.searchService.clearQueryParamsThenSearchNavigate('/' + CONSTANTS.ROUTER.SUMMARY);
  }
}
