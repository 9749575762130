import { Component, Input } from '@angular/core';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes, ProductAppDisplayNames } from 'src/app/services/product-info/product-info.service';

@Component({
  selector: 'app-skills-practice-app-link',
  templateUrl: './skills-practice-app-link.component.html',
  styleUrls: ['./skills-practice-app-link.component.scss']
})
export class SkillsPracticeAppLinkComponent {

  @Input() appName: string = '';
  @Input() appPage: string = '';
  @Input() appSvg: string = '';
  @Input() appUrl: string = '';

  constructor(
    public adminHandlerService: AdminHandlerService,
    public featureToggleService: FeatureToggleService,
    private launchControlService: LaunchControlService,
  ) { }

  public getAriaLabel(): string {
    return `${this.appName} ${this.appPage} link opens in a new tab`;
  }

  public enableSkillsPracticeAppLink() {
    if (this.appName == 'Freckle' && this.adminHandlerService.isAdmin()) {
      return false;
    }
    return true;
  }

  public navigateByLaunchControl(event: MouseEvent, appName: string, appUrl: string) {
    event.preventDefault();
    switch (appName) {
      case ProductAppDisplayNames.Freckle:
        this.launchControlService.launchApp(ProductAppCodes.Freckle, appUrl, true);
        break;
      case ProductAppDisplayNames.Lalilo:
        this.launchControlService.launchApp(ProductAppCodes.Lalilo, appUrl, true);
        break;
    }
  }
}
