import { Skill } from "../../shared/models/skill.model";

export abstract class SkillAggregate {

  // Return an array of skills that have been combined by contentActivityId
  // correctAnswers, totalAnswers, and durationInMinutes will be added together
  // to combine the skill data
  public static aggregate(skills: Skill[]): Skill[] {
    const skillMap = skills.reduce((acc: Record<string, Skill>, curr: Skill) => {
    //for the first occurance of a skill, add to the map and return
      if (!acc[curr.contentActivityId]) {
        //objects are readonly. Create a copy of the object incase we need to make modifications to it
        acc[curr.contentActivityId] = { ...curr }

        return acc
      }

      //if any additional occurances then aggregate the data
      acc[curr.contentActivityId].correctAnswers += curr.correctAnswers
      acc[curr.contentActivityId].totalAnswers += curr.totalAnswers
      acc[curr.contentActivityId].durationInMinutes += curr.durationInMinutes

      return acc
    }, {})

    return Object.values(skillMap)
  }
}
