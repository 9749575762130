<span class="student">
  <app-student-bubble
    [student]="student"
  >
  </app-student-bubble>
  <div class="cdk-visually-hidden">{{student.firstName}} {{student.lastName}}</div>
  <div aria-hidden="true">
    <div data-test="student-first-name">{{student.firstName}}</div>
    <div>{{student.lastName}}</div>
  </div>
</span>
