<div class='searchbox-container'>
  <ng-container>
    <search-intro-animation
      class="search-intro-animation"
    >
    </search-intro-animation>
  </ng-container>
  <ng-container
    *ngIf="isIntroAnimationComplete"
  >
  <div class="searchbox" [class.fade-in-ani]="playFadeInAnimation">
      <p id="search-header" class="header-text">What are you working on next?</p>
      <div class="search-input-container">
        <input #allyBox
        (keyup.enter)="onEnter(allyBox.value)"
        id="pendo-search-query"
        class="search-input"
        data-component="search-input"
        aria-label="Search"
        type="text" [placeholder]="placeholder" [(ngModel)]="searchValue"
        [ngClass]="{'start-typing' : searchValue.length}">
        <button (click)="onEnter(allyBox.value)" aria-label="Search" data-component="search-button"><span class="glyph-search2"></span></button>
        <div class="search-help-text-outside" *ngIf="searchValue">
          <div>{{placeholder}}</div>
        </div>
        <p class="examples-text examples-text-margin">
          <a id="pendo-guide-learn-more-search" class="learn-more-text" href="#" (click)="$event.preventDefault()" role="button">Learn more</a>
        </p>
      </div>
    </div>
  </ng-container>
</div>
