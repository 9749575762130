import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewRelicInstrumentationService } from 'src/app/services/new-relic-instrumentation/new-relic-instrumentation.service';
import { SearchResult } from '../models/search-result.model';
import { SearchService } from '../services/search.service';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'search-results-v2',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements AfterViewChecked, OnInit, OnDestroy {
  public isInitialLoad: boolean = true;
  public isSearchboxScroll: boolean = false;
  public loadingMoreResultsCounter: number = 0;
  public moreResults: boolean = true;
  public newlyAddedFirstElementID: string = '';
  public query: string = '';
  public searchConstants: any = CONSTANTS;
  public searchResults: SearchResult[] = [];
  public showOops: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private newRelicInstrumentationService: NewRelicInstrumentationService,
    public searchService: SearchService,
  ) { }

  // For search results table drop shadow.
  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    this.isSearchboxScroll = event.srcElement.scrollTop > 0;
  }

  ngOnInit(): void {
    this.setupSearch();
  }

  public ngAfterViewChecked(): void {
    this.focusNewlyAddedElement();
    this.cdr.detectChanges();
  }

  public async setupSearch(): Promise<void> {
    this.searchService.setupSearchParameters();

    this.subscriptions.push(this.searchService.searchResults$.subscribe(async (searchResults) => {
      if (this.isInitialLoad) {
        this.searchService.subscribeToQueryParams();
        this.isInitialLoad = false;
      }

      this.search(searchResults);
    }));
  }

  public search(searchResults: SearchResult[]): void {
    this.showOops = false;
    this.moreResults = true;

    try {
      if (searchResults.length > 0) {
        if (this.isLoadingMoreResults()) {
          this.loadingMoreResultsCounter++;
          this.searchResults = this.searchResults.concat(searchResults);
          this.searchService.searchResults$.next(this.searchResults);
        } else {
          this.loadingMoreResultsCounter = -1;
          this.searchResults = searchResults;
        }
        this.newlyAddedFirstElementID = searchResults[0].product_skill_id.toString();
      } else {
        if (!this.isLoadingMoreResults()) {
          this.searchResults = searchResults;
        }

        this.moreResults = false;
        this.loadingMoreResultsCounter = -1;
      }

      this.searchService.setLoading(false);
    } catch (error: any) {
      this.showOops = true;
      this.newRelicInstrumentationService.noticeError(error);
      this.newRelicInstrumentationService.recordSearchDegradedInfo(`search-error-${error.name}`);
      console.error(error);
    }
  }

  public focusNewlyAddedElement(): void {
    if (this.newlyAddedFirstElementID) {
      const element = document.getElementById(this.newlyAddedFirstElementID);
      if (element) {
        element.focus();
        this.newlyAddedFirstElementID = '';
      }
    }
  }

  public async getMoreResults(): Promise<void> {
    this.loadingMoreResultsCounter = 0;
    await this.searchService.getSortedSearchResults(true);
  }

  // loadingMoreResultsCounter = -1 -> we are not getting more results.
  // loadingMoreResultsCounter = 0 -> getting more results search and have not proccessed yet.
  // loadingMoreResultsCounter = 1 -> getting more results search and have proccessed, so don't process again.
  public isLoadingMoreResults(): boolean {
    return this.loadingMoreResultsCounter > -1 && this.loadingMoreResultsCounter < 1;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
