export enum BarColor {
  rti4 = "#006A16",
  rti3 = "#00A929",
  rti2 = "#0A628D",
  rti1 = "#FFCC00",
  rti0 = "#EC0000",
  gra2  = "#D3D3D3"
}

export class BarColorOrders {
  static 5 = [BarColor.rti4, BarColor.rti3, BarColor.rti2, BarColor.rti1, BarColor.rti0];
  static 4 = [BarColor.rti3, BarColor.rti2, BarColor.rti1, BarColor.rti0];
  static 3 = [BarColor.rti3, BarColor.rti2, BarColor.rti1];
  static 2 = [BarColor.rti3, BarColor.rti1];
}

export class CBMColor {
  static urgent = BarColor.rti0;
  static onWatch = BarColor.rti2;
  static atBenchmark = BarColor.rti3;
  static offGrade = BarColor.gra2
}

export const NoNormingDataColor = BarColor.gra2;
