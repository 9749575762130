import { Component, OnInit } from '@angular/core';
import { SavvasLinkGeneratorService, LTITargetURIs } from '../../services/link-generators-v2/savvas-link-generator.service';


@Component({
  selector: 'set-up-savvas',
  templateUrl: './set-up-savvas.component.html',
  styleUrls: ['./set-up-savvas.component.scss']
})
export class SetUpSavvasComponent implements OnInit {

  constructor(private savvasLinkGeneratorService: SavvasLinkGeneratorService) { }

  public async ngOnInit(): Promise<void> {
    const launchPath=LTITargetURIs.CONFIG
    const skillIds = ['']
    const newTab = false

    await this.savvasLinkGeneratorService.appLaunch(skillIds, launchPath, newTab);
  }
}
