import { Component, OnInit } from '@angular/core';
import { DateHelper } from 'src/app/util/date-helper/date-helper';

@Component({
  selector: 'app-week-display',
  templateUrl: './week-display.component.html',
  styleUrls: ['./week-display.component.scss']
})
export class WeekDisplayComponent implements OnInit {

  currentWeek: string ='';

  ngOnInit(): void {
    this.setCurrentWeek();
  }

  setCurrentWeek(): void {
    const mondayDate = DateHelper.getCurrentWeekMondayDate();
    const fridayDate = DateHelper.getCurrentWeekFridayDate();

    const startOfWeekFormatted = mondayDate.toFormat('MMMM d');
    const endOfWeekFormatted = mondayDate.month != fridayDate.month ?  fridayDate.toFormat('MMMM d') : fridayDate.toFormat('d');

    this.currentWeek = `Week of ${startOfWeekFormatted}-${endOfWeekFormatted}`;
  }
}
