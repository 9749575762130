import { Injectable } from "@angular/core";
import { ReadingPractice } from "src/app/shared/models/reading-practice.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { LexileHelperService } from "src/app/util/lexile-helper/lexile-helper.service";

@Injectable({ providedIn: 'root' })
export class MyONLexileCalculatorService {
  public calculateMyONLexile(readingPractices: ReadingPractice[]) {
    if (readingPractices.length === 0) {
      return null;
    }

    const recentMyonReadingPractices = readingPractices.filter(practice =>
      practice.product.toLowerCase() === 'myon' &&
      DateHelper.isWithinTwoWeeksOfToday(practice.mostRecentPracticedDate) &&
      practice.lexileLevel != null
    );

    if (recentMyonReadingPractices.length === 0) {
      return null;
    }

    const averageLexileLevel = LexileHelperService.getAverageLexileLevel(recentMyonReadingPractices);
    return averageLexileLevel;
  }
}
