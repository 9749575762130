import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-opt-out-dialog',
  templateUrl: './opt-out-dialog.component.html',
  styleUrls: ['./opt-out-dialog.component.scss']
})
export class OptOutDialogComponent {
  public showStayDialog: boolean = false;
  public showLeaveDialog: boolean = false;
  public window: Window;
  private optOutUri: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {optOutUri: string},
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<OptOutDialogComponent>,
    public dialog: MatDialog) {
    this.window = this.document.defaultView as Window;
    this.optOutUri = this.data.optOutUri;
  }

  updateDialog(optOut: boolean): void {
    this.pendoTrackFeedback(optOut);
    if (optOut) {
      this.showLeaveDialog = true;
      this.optOutRedirect();
    }
    else {
      this.showStayDialog = true;
    }
  }

  private pendoTrackFeedback(optOut: boolean) {
    const window = this.document.defaultView as any;
    if (window.pendo) {
      const pendo = window.pendo;
      const feedback = (<HTMLInputElement>this.document.getElementById('pendo-feedback-area')).value;
      if (feedback.trim().length > 0 ) {
        pendo.track("OptOutFeedback", {
          feedback: feedback,
          optedOut: optOut,
        });
      }
    }
  }

  public optOutRedirect() {
    setTimeout(() => {this.window.location.href = this.optOutUri}, 3500);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
