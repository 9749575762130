import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'empty-student-groups-animation',
  templateUrl: './empty-student-groups-animation.component.html',
  styleUrls: ['./empty-student-groups-animation.component.scss']
})
export class EmptyStudentGroupsAnimationComponent implements OnInit {
  public inView: boolean = false;

  public ngOnInit() {
    // This observer is used to trigger the animation only when the element is in view
    const elements = document.getElementsByClassName('animate');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.inView = true;
        }
      });
    });

    for (let i = 0; i < elements.length; i++) {
      observer.observe(elements[i]);
    }
  }
}
