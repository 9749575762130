import { Injectable } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';
import { AppNavV2ItemUtilityService } from './app-nav-v2-item-utility.service';

@Injectable({
  providedIn: 'root'
})
export class AppNavV2ItemService {

  constructor(private _appNavV2ItemUtilityService: AppNavV2ItemUtilityService,) { }

  public getAppNavV2Items(appNavV2: AppNavV2[], appCodes: string[]): AppNavItem[] {
    let appNavItems: AppNavItem[] = [];

    for (let i = 0; i < appCodes.length; i++) {
      let appNavV2ItemsByAppCode = this._appNavV2ItemUtilityService.getAppNavV2ItemsByAppCode(appNavV2, appCodes[i]);
      for (let j = 0; j < appNavV2ItemsByAppCode.length; j++) {
        if (appNavV2ItemsByAppCode != null && appNavV2ItemsByAppCode[j].appCode != undefined) {
          appNavItems.push(appNavV2ItemsByAppCode[j]);
        }
      }
    }

    return appNavItems;
  }

  public getAppNavV2Item(appNavV2: AppNavV2[], appCode: string): AppNavItem {
    let appNavItem: AppNavItem = { displayName: "" };

    let item = appNavV2.find(code => code.appCode == appCode);
    var isChildrenExists = item != null && item.children && item.children.length > 0 ? true : false;
    if (isChildrenExists) {
      appNavItem.children = [];
    }

    if (item != null) {
      appNavItem.appCode = item.appCode;
      appNavItem.deepLinkId = item.deepLink;
      appNavItem.displayName = item.displayName;
      appNavItem.subHeader = item.subHeader;

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          let appNavChildItem: AppNavItem = { displayName: "" };
          appNavChildItem.appCode = child.appCode;
          appNavChildItem.deepLinkId = child.deepLink;
          appNavChildItem.displayName = child.displayName;
          appNavItem.children?.push(appNavChildItem)
        });
      }
    }
    return appNavItem;
  }
}
