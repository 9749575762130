<div class="container renaissance-style-scoped" *ngIf="data">
  <div class="header-container">
    <div class="header scroll-margin">
      <app-student-bubble [student]="data.student"></app-student-bubble>
      <h2 tabindex="0">{{data.student.firstName}}'s Skills Practice</h2>
    </div>
    <hr class="scroll-margin" aria-hidden="true" />
  </div>

  <div class="skills-container">
    <div role="group" aria-label="skill" class="skill" *ngFor="let skill of practicedSkills; let i = index">
      <p class="skill-product" [attr.data-test]="skill.productId">{{formatProductType(skill.product)}}</p>
      <app-skill-description [skillMetadata]="skill.standardSetMetadata!"
        [hasAmbiguousShortName]="isSkillNameAmbiguous(skill)" [isSummary]="false"></app-skill-description>
      <div class="details" [class.alert]="isStuck(skill)">
        <div class="stuck" *ngIf="isStuck(skill)">
          Stuck:
        </div>
        <div class="metrics">
          {{skill.correctAnswers}} / {{skill.totalAnswers}}
          ({{formatCorrectPercent(skill.correctAnswers, skill.totalAnswers)}}),
          {{formatMinutes(skill.durationInMinutes)}}
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="littlePracticedSkills.length > 0 && practicedSkills.length > 0">
    <hr class="scroll-margin" aria-hidden="true" />
  </div>

  <div class="skills-container" *ngIf="littlePracticedSkills.length > 0">
    <h3 class="skills-heading">Skills with fewer than three items attempted:</h3>
    <div role="group" aria-label="skill" class="skill" *ngFor="let skill of littlePracticedSkills; let i = index">
      <p class="skill-product" [attr.data-test]="skill.productId">{{formatProductType(skill.product)}}</p>
      <app-skill-description [skillMetadata]="skill.standardSetMetadata!"
        [hasAmbiguousShortName]="isSkillNameAmbiguous(skill)" [isSkillsFewer]="false"></app-skill-description>
      <div class="details" [class.alert]="isStuck(skill)">
        <div class="stuck" *ngIf="isStuck(skill)">
          Stuck:
        </div>
        <div class="metrics">
          {{skill.correctAnswers}} / {{skill.totalAnswers}}
          ({{formatCorrectPercent(skill.correctAnswers, skill.totalAnswers)}}),
          {{formatMinutes(skill.durationInMinutes)}}
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <hr class="scroll-margin" aria-hidden="true" />
    <div class="footer-button">
      <button class="ren-link" (click)="close()">Close</button>
    </div>
  </div>
</div>

