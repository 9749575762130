import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Skill } from 'src/app/shared/models/skill.model';
import { Student } from 'src/app/shared/models/student.model';
import { AmbiguousSkillShortNameFinder } from 'src/app/util/ambiguous-skill-short-name-finder/ambiguous-skill-short-name-finder';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';

export interface SkillDetailData {
  student: Student;
  skills: Skill[];
}

@Component({
  selector: 'app-skill-detail-modal',
  templateUrl: './skill-detail-modal.component.html',
  styleUrls: ['./skill-detail-modal.component.scss']
})
export class SkillDetailModalComponent implements OnInit {

  public littlePracticedSkills: Skill[] = [];
  public practicedSkills: Skill[] = [];
  public ambiguousShortNameSkillIds: string[] = [];
  public skillsShowingFullSkillName: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SkillDetailData,
    public ref: MatDialogRef<SkillDetailModalComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.littlePracticedSkills = this.data.skills.filter(x => x.totalAnswers < 3);
      this.practicedSkills = this.data.skills.filter(x => x.totalAnswers >= 3);
      this.ambiguousShortNameSkillIds = AmbiguousSkillShortNameFinder.getAmbiguousShortNameSkillIds(this.data.skills);
    }
  }

  public isSkillNameAmbiguous(skill: Skill): boolean {
    return this.ambiguousShortNameSkillIds.indexOf(skill.standardSetMetadata!.contentActivityId) !== -1;
  }

  public formatMinutes(minutes: number) {
    if (minutes === 1) {
      return '1 minute';
    }
    if (minutes === null) {
      return '0 minutes';
    }
    return minutes.toFixed(0) + ' minutes';
  }

  public formatProductType(productCode: string): string {
    switch (productCode) {
      case 'adaptive_skills_practice': //ELA
      case 'adaptive': { //MATH
        return 'Freckle Adaptive Practice'
      }
      case 'targeted_skills_practice': //ELA
      case 'standard': { // MATH
        return 'Freckle Targeted Practice'
      }
      default: {
        return productCode;
      }
    }
  }

  public formatCorrectPercent(correct: number, total: number): string {
    if (total > 0) {
      return Math.round(correct / total * 100) + '%';
    }
    return "0%"
  }

  public isStuck(skill: Skill) {
    return SkillTests.isStuckSkill(skill);
  }

  public close() {
    this.ref.close();
  }
}
