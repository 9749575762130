import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TakeActionData } from './take-action-data';
import { RecommendationLinkType } from '../take-action-links/take-action-links.component';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { RecommendationService } from 'src/app/services/recommendation/recommendation.service';
import { RecommendationType } from 'src/app/services/recommendation/RecommendationType';
import { Student } from 'src/app/shared/models/student.model';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { AmbiguousSkillShortNameFinder } from 'src/app/util/ambiguous-skill-short-name-finder/ambiguous-skill-short-name-finder';
import { Link } from 'src/app/util/link/link';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { ProductAppTags, ProductIds, ProductInfoService } from 'src/app/services/product-info/product-info.service';

@Component({
  selector: 'take-action-modal',
  templateUrl: './take-action-modal.component.html',
  styleUrls: ['./take-action-modal.component.scss'],
})
export class TakeActionModalComponent implements OnInit {

  public actionableSkills: ActionableSkill[] = [];
  public stuckStudents: Student[] = [];
  public recommendationLinkType = RecommendationLinkType;
  public recommendationType = RecommendationType;
  public ambiguousShortNameSkillIds: string[] = [];
  public isLoaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TakeActionData,
    public ref: MatDialogRef<TakeActionModalComponent>,
    public recommendationService: RecommendationService,
    private subjectService: SubjectService,
    private productInfoService: ProductInfoService) { }

  async ngOnInit() {
    this.actionableSkills = await this.getActionableSkills();
    this.stuckStudents = this.getStuckStudents();
    this.ambiguousShortNameSkillIds = AmbiguousSkillShortNameFinder.getAmbiguousShortNameSkillIds(this.data.student.skills);
    this.isLoaded = true;
  }

  public close(event: Event) {
    event.preventDefault();
    this.ref.close();
  }

  public async getActionableSkills(): Promise<ActionableSkill[]> {
    return await this.recommendationService.getSkillRecommendations(this.data.student, this.data.students);
  }

  public getStuckStudents(): Student[] {
    let stuckSkill = this.actionableSkills.find(skill => {
      return skill.recType == RecommendationType.NeedsHelp
    });
    let skillRenaissanceId = stuckSkill?.skillMetadata?.renaissanceSkillId;

    let stuckStudents: Student[] = [];
    this.data.students.forEach(student => {
      let skill = SkillAggregate.aggregate(student.skills).find(skill => {
        return skill.standardSetMetadata?.renaissanceSkillId === skillRenaissanceId && SkillTests.isStuckSkill(skill)
      });
      if (skill) {
        stuckStudents.push(student);
      }
    })
    return stuckStudents;
  }

  public isSkillNameAmbiguous(actionableSkill: ActionableSkill): boolean {
    if (actionableSkill.skillMetadata?.renaissanceSkillId) {
      return this.ambiguousShortNameSkillIds.indexOf(actionableSkill.skillMetadata?.renaissanceSkillId) !== -1;
    }
    return false;
  }

  public isShortTestTime() {
    return SkillTests.isShortTestTime(this.data.student.latestAssessment.subject, this.data.student.latestAssessment.assessmentTimeInSeconds);
  }

  public getStrugglingStudentsString() {
    let studentName = this.data.student.firstName;
    let numStudents = this.stuckStudents.length;
    if (numStudents == 1) {
      return `${studentName} is <b>struggling</b> on this skill`;
    }
    if (numStudents == 2) {
      return `${studentName} and one other are <b>struggling</b> on this skill`;
    }
    if (numStudents > 2) {
      return `${studentName} and ${numStudents - 1} others are <b>struggling</b> on this skill`;
    }
    return '';
  }

  public studentHasNoAssessments() {
    if (this.data.student.assessments && this.data.student.assessments.length > 0) {
      return false;
    }
    return true;
  }

  public getSubjectString() {
    let subject = this.data.student?.latestAssessment?.subject;

    if (subject === undefined) {
      return '';
    }

    return subject.charAt(0).toUpperCase() + subject.slice(1).toLowerCase();
  }

  public getTestTimeString() {
    let duration = this.data.student.latestAssessment.assessmentTimeInSeconds;
    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;

    let minutesLabel = "minutes";
    if (minutes === 1) minutesLabel = "minute";

    let secondsLabel = "seconds";
    if (seconds === 1) secondsLabel = "second";

    if (minutes != 0) {
      if (seconds != 0) {
        return `${minutes} ${minutesLabel} and ${seconds} ${secondsLabel}`;
      }
      return `${minutes} ${minutesLabel}`;
    }
    return `${seconds} ${secondsLabel}`;
  }

  public getStuckStudentIds() {
    return this.stuckStudents.map(x => x.renaissanceId);
  }

  public getNoRecentActivityText(): string {
    var assessmentProducts = this.data.classProducts.assessmentProducts;
    var skillsPracticeProducts = this.data.classProducts.skillsPracticeProducts;

    // filter lalilo if there is lalilo activity
    let skills = this.data.student.skills;
    if (skills.find(skill => skill.productId == ProductIds.Lalilo)) {
      skillsPracticeProducts = skillsPracticeProducts.filter(product => product != this.productInfoService.GetProductAppDisplayName(ProductAppTags.Lalilo));
    }

    if (assessmentProducts.length > 0) {
      if(skillsPracticeProducts.length > 1) {
        return `${this.data.student.firstName} has no recent activity in Star, ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
       if(skillsPracticeProducts.length > 0) {
        return `${this.data.student.firstName} has no recent activity in Star or ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
      }
      return `${this.data.student.firstName} has no recent activity in Star`;
    }
    if(skillsPracticeProducts.length > 0) {
      return `${this.data.student.firstName} has no recent activity in ${this.getSkillProductsDisplayString(skillsPracticeProducts)}`;
    }
    return '';
  }

  public getNoSkillRecommendationText(): string {
    var studentName = this.data.student.firstName;
    var subject = this.subjectService.selectedSubject$.getValue();
    var skillsPracticeProducts = this.data.classProducts.skillsPracticeProducts;
    var readingPracticeProducts = this.data.classProducts.readingPracticeProducts;

    if (subject == SubjectTypes.MATH) {
      if (skillsPracticeProducts.includes('Freckle')) {
        return `Have ${studentName} log in to Freckle to start working on adaptive ${subject.toLocaleLowerCase()} practice.`;
      }
    }

    if (subject == SubjectTypes.READING) {
      if (readingPracticeProducts.length > 0) {
        if (skillsPracticeProducts.length > 0) {
          return `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}, `+
          `and log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`;
        }
        return `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}.`
      }
      if (skillsPracticeProducts.length == 1) {
        if (skillsPracticeProducts.includes('Freckle')) {
          return `Have ${studentName} log in to Freckle to start working on adaptive ELA practice.`; //special case for Freckle as it's ELA, not reading
        }
        return `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} `+
        `to start working on adaptive ${subject.toLocaleLowerCase()} practice.`
      }
      if (skillsPracticeProducts.length > 1) {
        return `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`
      }
    }
    return `Practice makes perfect. Encourage ${studentName} to practice their ${subject.toLocaleLowerCase()} skills regularly.`
  }

  private getReadingProductsDisplayString(readingProducts: string[]): string {
    return readingProducts.join(' and ');
  }

  private getSkillProductsDisplayString(skillProducts: string[]): string {
    return  skillProducts.join(' or ');
  }

  public displaySkillProductHelperText(): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING && this.data.classProducts.skillsPracticeProducts.length > 1;
  }
}

export type ActionableSkill = {
  rationale: string,
  skillMetadata: SkillMetadata | null,
  hasAmbiguousShortName: boolean,
  freckleRecType: RecommendationLinkType,
  nearpodRecType: RecommendationLinkType,
  recType: RecommendationType,
  productUrls: Record<LinkProductKeys, Link>,
  recommendationAudit?: string;
}

export enum LinkProductKeys {
  Freckle = "FRECKLE",
  Nearpod = "NEARPOD",
}

export enum ProductKeys {
  Freckle = "FRECKLE",
  Nearpod = "NEARPOD",
  Star = "STAR"
}
