import { Inject, Injectable } from "@angular/core";
import { UserService } from "../user/user.service";
import { DOCUMENT } from "@angular/common";
import { StudentActivityResponse } from "../graphql/student-activity-response";


@Injectable({
  providedIn: 'root'
})
export class NewRelicInstrumentationService {

  private _newrelic: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService) { }

  // Format for new relic ids - we want to be of the form {platformName}.{componentName}.{attributeName},
  // but where platform name can be omitted if the claim is a 'top level identifier' for the platform (like userId and tenantId)

  initializeNewRelicInstrumentation() {
    this.userService.isLoggedIn().then(() => {
      const window = this.document.defaultView as any;
      // ambient window reference is available to us see index.html
      if (window.newrelic) {
        this._newrelic = window.newrelic;
        this._newrelic.setCustomAttribute('renaissanceNext.userId', this.userService.getUserId() as string);
        this._newrelic.setCustomAttribute('renaissanceNext.tenantId', this.userService.getClientId() as string);
        this._newrelic.setCustomAttribute('renaissanceNext.zoneUri', this.userService.getZoneUri() as string);
      }
    })
  }

  recordStudentActivityLoadInfo(studentActivity: StudentActivityResponse) {
    if (!this._newrelic) return;

    const enrollmentCount = (studentActivity.studentEnrollments?.length as number);
    const assessmentCount = (studentActivity.studentAssessmentDetail?.length as number);
    const skillPracticeCount = (studentActivity.studentSkillDetail?.length as number);
    const readingPracticeCount = (studentActivity.studentReadingPracticeDetail?.length as number);

    this._newrelic.setCustomAttribute('renaissanceNext.studentActivityLoad.enrollmentCount', enrollmentCount);
    this._newrelic.setCustomAttribute('renaissanceNext.studentActivityLoad.assessmentCount', assessmentCount);
    this._newrelic.setCustomAttribute('renaissanceNext.studentActivityLoad.skillPracticeCount', skillPracticeCount);
    this._newrelic.setCustomAttribute('renaissanceNext.studentActivityLoad.readingPracticeCount', readingPracticeCount);
  }

  recordAppNavDegradedInfo(degradedReason: string) {
    if (!this._newrelic) return;

    this._newrelic.setCustomAttribute('renaissanceNext.appNav.degradedReason', degradedReason);
  }

  recordHomeDegradedInfo(degradedReason: string) {
    if (!this._newrelic) return;

    this._newrelic.setCustomAttribute('renaissanceNext.home.degradedReason', degradedReason);
  }

  recordSearchDegradedInfo(degradedReason: string) {
    if (!this._newrelic) return;

    this._newrelic.setCustomAttribute('renaissance.search.degradedReason', degradedReason);
  }

  recordTeacherNotReadyEvent(notReadyReason: string) {
    if (!this._newrelic) return;

    this._newrelic.setCustomAttribute('renaissanceNext.teacherNotReady', notReadyReason);
  }

  recordLaunchDarklyRelayProxyClient(clientId: string) {
    if (!this._newrelic) return;

    this._newrelic.setCustomAttribute('renaissanceNext.ldRelayProxyClient', clientId);
  }

  noticeError(error: any) {
    if (!this._newrelic) return;

    this._newrelic.noticeError(error);
  }
}
