import { Injectable } from '@angular/core';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';
import { MyOnLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/myon-link-generator-v2.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { SearchResult } from '../models/search-result.model';

@Injectable({
  providedIn: 'root'
})
export class ProductNavigationService {

  constructor(private contextDataService: ContextDataService,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private launchControlService: LaunchControlService,
    private myOnLinkGeneratorV2Service: MyOnLinkGeneratorV2Service
  ) { }

  public async goToFreckle(searchResult: SearchResult) {
    try {
      let students = this.contextDataService.classDataContext$.value?.students ?? [];
      const deeplink = await this.freckleLinkGeneratorV2Service.getSearchResultAssignSkillDeepLink(students, searchResult.standard_id, searchResult.renaissance_skill_id, searchResult.content_type);
      this.launchControlService.launchApp(ProductAppCodes.Freckle, deeplink, true);
    } catch (error) {
      console.error('Error navigating to Freckle:', error);
    }
  }

  public async goToLalilo(searchResult: SearchResult) {
    try {
      let matches = searchResult.product_skill_id.match(/LESSONID-(.*)__/);
      let lessonId = matches ? matches[1] : undefined;
      const deeplink = await this.laliloLinkGeneratorV2Service.getLaliloPageDeepLink('assignment-planning', lessonId);
      this.launchControlService.launchApp(ProductAppCodes.Lalilo, deeplink, true);
    } catch (error) {
      console.error('Error navigating to Lalilo:', error);
    }
  }

  public async goToMyOn(searchResult: SearchResult) {
    try {
      let deepLink = this.myOnLinkGeneratorV2Service.getMyOnPageDeepLink(searchResult);
      this.launchControlService.launchApp(ProductAppCodes.Myon, deepLink, true, false);
    } catch (error) {
      console.error('Error navigating to MyOn:', error);
    }
  }
}
