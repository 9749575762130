import { Component, Inject } from '@angular/core';
import { SuperstarSelectCardData, gradeCards } from './superstar-select-card-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-superstar-select-card-modal',
  templateUrl: './superstar-select-card-modal.component.html',
  styleUrls: ['./superstar-select-card-modal.component.scss']
})
export class SuperstarSelectCardModalComponent {
  hasLoaded: boolean = true;

  gradeCards: { grade: string, text: string, cards: SuperstarSelectCardData[] }[] = gradeCards;
  

  selectedCard: any;
  selectedGrade : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SuperstarSelectCardData,
    public ref: MatDialogRef<SuperstarSelectCardModalComponent>,
  ) {
    if (data)
      this.selectCard(data);
  }

  onCardSelect(card: SuperstarSelectCardData) {
    this.selectCard(card);
  }

  selectCard(student: any) {
    this.selectedCard = student.icon;
    this.gradeCards.map(grad => {
      grad.cards.map(c => {
        c.isSelected = c.icon == student.icon;
        if (c.isSelected)
        this.selectedGrade = grad.grade;
      });
    })
  }

  
  public close(event: Event) {
    event.preventDefault();
    let student = {
      renaissanceId: this.data.id,
      svgIcon: this.selectedCard,
      grade: this.selectedGrade
    }
    this.ref.close(student);
  }
}
