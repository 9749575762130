import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReadingPractice } from 'src/app/shared/models/reading-practice.model';
import { Student } from 'src/app/shared/models/student.model';
import { DeepLinkContext } from '../product-deep-link/product-deep-link.component';
import { UserService } from 'src/app/services/user/user.service';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { Subscription } from 'rxjs';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { AppNavItemService } from 'src/app/services/app-nav/app-nav-item.service';

export interface ReadingPracticeDetailData {
  student: Student;
  readingPractices: ReadingPractice[];
  currentWordCount: number;
  previousWordCount: number
}

export enum WordCountArrows {
  up = 'glyph-arrow-up4',
  down = 'glyph-arrow-down4'
}

@Component({
  selector: 'app-reading-practice-detail-modal',
  templateUrl: './reading-practice-detail-modal.component.html',
  styleUrls: ['./reading-practice-detail-modal.component.scss']
})

export class ReadingPracticeDetailModalComponent implements OnInit {

  public dateTooltipTitle: string = 'Date';
  public dateTooltipDescription: string = 'AR Quiz date or date of most recent reading in myON.';

  public wordCountTooltipTitle: string = 'Quantity of Reading';
  public wordCountTooltipDescription: string = 'The total number of words read based on passed AR quizzes and reading done online in myON.';

  public readingScale: 'atos' | 'lexile' = 'atos';
  private readonly arAppId: string = '1';
  private readonly myonAppId: string = '49';

  public window: Window;

  public readingPractices: ReadingPractice[] = [];
  public currentWordCount: number = 0;
  public previousWordCount: number = 0;

  public selectedClass?: string;

  public zoneUri: string = '';
  public currentUserId: string | null = null;

  private subscriptions: Subscription[] = [];
  public alertIconPath: string = '../../../../../../assets/red-alert-circle.svg';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: ReadingPracticeDetailData,
    private classesService: ClassesService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    public ref: MatDialogRef<ReadingPracticeDetailModalComponent>,
    public appNavItemService: AppNavItemService
  ) {
    this.window = this.document.defaultView as Window;
  }

  get context(): DeepLinkContext {
    return {
      subject: 'reading',
      studentRenaissanceId: this.data.student.renaissanceId,
      classRenaissanceId: this.selectedClass ?? '',
      schoolRenaissanceId: this.data.student.schoolRenaissanceId
    }
  }

  async ngOnInit() {
    await this.appNavItemService.initialize();

    this.currentWordCount = this.data.currentWordCount;
    this.previousWordCount = this.data.previousWordCount;
    this.readingPractices = this.data.readingPractices;
    this.zoneUri = this.userService.getHomeUri();
    this.currentUserId = this.userService.getUserId();
    this.getReadingScaleDefaultByUser(this.currentUserId)

    let classSubscription = this.classesService.selectedClass$.subscribe(selectedClass => {
      this.selectedClass = selectedClass?.renaissanceClassId;
    });
    this.subscriptions.push(classSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public close() {
    this.ref.close();
  }

  public getReadingScaleDefaultByUser(userId: string | null) {
    if (userId) {
      const maybeScale = this.localStorageService.getItem('tj-reading-scale-' + userId);
      if (maybeScale === 'atos' || maybeScale === 'lexile') {
        this.readingScale = maybeScale;
      }
    }
  }

  public setReadingScaleDefaultByUser(userId: string | null, scale: 'atos' | 'lexile') {
    if (userId) {
      this.localStorageService.setItem('tj-reading-scale-' + userId, scale);
    }
  }

  public getArQuizScoreText(readingPractice: ReadingPractice): string {
    if (readingPractice.percentCorrect != null) {
      return `${Math.round(readingPractice.percentCorrect * 100)}%`;
    }

    if (readingPractice.arQuizNumber) {
      if (readingPractice.finishedBook)
      {
        return 'Quiz not taken';
      }
      return '--';
    }
    return 'No quiz available';
  }

  public hasNoArQuizScore(quizScore: number | null): boolean {
    return quizScore == null;
  }

  public hasStuckArQuizScore(readingPractice: ReadingPractice): boolean {
    return SkillTests.isStuckReadingPracticeActivity(readingPractice);
  }

  public getMyonCompletedText(readingPractice: ReadingPractice): string {
    if (readingPractice.totalSecondsRead) {

      let minutesRead = Math.round(readingPractice.totalSecondsRead / 60);
      if (readingPractice.finishedBook) {
        return `Completed in myON (${minutesRead} min)`;
      }
      return `In Progress in myON (${minutesRead} min)`;
    }
    return '';
  }

  public changeReadingScale(scale: string) {
    if (scale === 'atos' || scale === 'lexile') {
      this.readingScale = scale;
      this.setReadingScaleDefaultByUser(this.currentUserId, scale);
    }
    else {
      throw Error(`Unknown reading scale ${scale}`);
    }
  }

  public hasReadingLevel(readingPractice: ReadingPractice): boolean {
    if (this.readingScale === 'atos') {
      return this.hasAtosLevel(readingPractice);
    } else {
      return this.hasLexileLevel(readingPractice);
    }
  }

  public formatReadingLevel(readingPractice: ReadingPractice) {
    if (this.readingScale === 'atos') {
      return this.formatAtosLevel(readingPractice);
    } else {
      return this.formatLexileLevel(readingPractice);
    }
  }

  public hasAtosLevel(readingPractice: ReadingPractice): boolean {
    return readingPractice.atosLevel != null;
  }

  public formatAtosLevel(readingPractice: ReadingPractice): string{
    return readingPractice.atosLevel.toFixed(1);
  }

  public hasLexileLevel(readingPractice: ReadingPractice): boolean {
    return readingPractice.lexileLevel != null;
  }

  public formatLexileLevel(readingPractice: ReadingPractice): string {
    return !!readingPractice.lexileLabel ? `${readingPractice.lexileLabel}` : `${readingPractice.lexileLevel.toFixed(0)}L`;
  }

  public getWordCountDifference(): string {
    let absoluteDifference = Math.abs(this.currentWordCount - this.previousWordCount);
    return absoluteDifference.toLocaleString();
  }

  public getArrowDirection(): string {
    if (this.currentWordCount > this.previousWordCount) {
      return WordCountArrows.up;
    }
    else {
      return WordCountArrows.down;
    }
  }
  public getArrowLabel(): string {
    if (this.currentWordCount > this.previousWordCount) {
      return 'Word count increased'; // Label for screen readers
    } else {
      return 'Word count decreased'; // Label for screen readers
    }
  }

  public hasAr(): boolean {
    return this.appNavItemService.hasApp([this.arAppId]);
  }

  public hasMyon(): boolean {
    return this.appNavItemService.hasApp([this.myonAppId]);
  }
}
