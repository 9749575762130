import { Injectable } from '@angular/core';
import { IdleTimeoutModalComponent } from './idle-timeout-modal/idle-timeout-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { IdleModalData } from './idle-modal.data.model';
import { Subject } from 'rxjs';

@Injectable()
export class TimeoutModalService {
  public logouts: Function[] = [];
  public returns: Function[] = [];

  private timeLeftMinutes = new Subject<string>();
  timeLeftMinutes$ = this.timeLeftMinutes.asObservable();

  constructor(private matDialog: MatDialog) { }

  public onLogout(cb: Function) {
    this.logouts.push(cb);
  }

  public doLogout() {
    this.logouts.forEach(cb => cb());
  }

  public onReturnToScreen(cb: Function) {
    this.returns.push(cb);
  }

  public doReturnToScreen() {
    this.returns.forEach(cb => cb());
  }

  public open(timeLeft: string) {
    this.matDialog.open<IdleTimeoutModalComponent, IdleModalData>(IdleTimeoutModalComponent, {
      data: {
        timeLeft
      }
    });
  }

  public updateWarningInterval(timeLeftMinutes: string) {
    this.timeLeftMinutes.next(timeLeftMinutes);
  }
}
