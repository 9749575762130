import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StarPhonicsLinkGeneratorV2Service {

  constructor() { }
  public getReportsPageDeepLink(): string {
    let domain = this.getStarphonicsDomain();
    let starPhonicsUrl = `https://${domain}/dashboard/reports`;
    return encodeURIComponent(starPhonicsUrl);

  }

  private getStarphonicsDomain(): string {
    return `starphonics.renaissance.com`;
  }
}
