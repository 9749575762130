<div class="synching-container renaissance-style-scoped">
  <object type="image/svg+xml" role="presentation" alt="" class="teacher-not-ready-image"
      data="../assets/teacher_not_ready-ani.svg"></object>
  <div class="teacher-not-ready-message-container">
    <div class="teacher-not-ready-message-header">
      We're Getting Things Ready
    </div>
    <div class="teacher-not-ready-message-content">
      Your student and class data aren't available yet, but should be ready tomorrow.
      <br><br>
      In the meantime, you can navigate to your Renaissance products using the menu on the left.
    </div>
  </div>
</div>
