import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { ProductAppCodes } from '../product-info/product-info.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LaunchControlService {
  constructor(
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public launchApp(appCode: string, deepLink?: string | any, isBlankTarget?: boolean, isSideNav: boolean = false) {
    let homeUri = this.userService.getHomeUri();

    //we always want to launch freckle, lalilo, and myon in prod unless it is the side nav
    if (this.userService.getClientId() === `lmb2ourotest` && (appCode === ProductAppCodes.Freckle || appCode === ProductAppCodes.Lalilo || appCode === ProductAppCodes.Myon) && !isSideNav) {
      homeUri = "https://global-zone05.renaissance-go.com/educatorportal/";
    }

    const launchUrl = environment.launchControlServiceUrl + appCode;
    const form = this.document.createElement('form');
    form.method = 'POST';
    form.action = launchUrl;
    if (isBlankTarget) {
      form.target = '_blank';
    }

    const homeUriField = this.document.createElement('input');
    homeUriField.type = 'hidden';
    homeUriField.name = "homeURI";
    homeUriField.value = homeUri;
    form.appendChild(homeUriField);

    if (deepLink) {
      const deepLinkField = this.document.createElement('input');
      deepLinkField.type = 'hidden';
      deepLinkField.name = 'deepLink';
      if (typeof deepLink === 'string') {
        deepLinkField.value = encodeURIComponent(deepLink);
      } else {
        try {
          deepLinkField.value = JSON.stringify(deepLink);
        } catch (e) {
          console.error('Unable to encode launch deepLink, possibly circular object?', e);
        }
      }
      form.appendChild(deepLinkField);
    }

    this.document.body.appendChild(form);
    form.submit();
    }
  }

