<div class="link-selector-container" role="dialog" aria-modal="true">
  <div class="link-selector-header">
    <img class="header-logo outline" alt="" src="../../../assets/logos/{{selectedApp.imagePrefix}}.svg">
    <h2 class="header-text" tabindex="0">{{getHeaderText(selectedApp)}}</h2>
    <div class="header-close-button"
      tabindex="0"
      (click)="closeSidenav()"
      (keydown.enter)="closeSidenav()"
      role="button">
      Close
    </div>
  </div>
  <report-search
    [class.hidden]="!isReports(selectedApp)"
    (hideAccordion)="hideAccordion()"
    (showAccordion)="showAccordion()">
  </report-search>
  <mat-accordion *ngIf="this.accordionShown" multi displayMode="flat">
    <div *ngFor="let appNavItem of appNavItems">
      <app-link-selector-option [appNavItem]="appNavItem" [expandDisabled]="expandDisabled"></app-link-selector-option>
    </div>
  </mat-accordion>
</div>
