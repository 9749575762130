import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';
import { FilterOptionValues, FilterOptions } from './filter-skills/filter-skills.component';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { SkillsPracticeExperimentToggleValue }  from 'src/app/home/skills-practice/skills-practice.component';
import { SkillPracticeCategory } from '../skills-practice-categories';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { SkillsPracticeSortingService } from './skills-practice-sorting.service';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { SubjectService } from 'src/app/services/subject/subject.service';

@Component({
  selector: 'skills-practice-list',
  templateUrl: './skills-practice-list.component.html',
  styleUrls: ['./skills-practice-list.component.scss']
})
export class SkillsPracticeListComponent implements OnInit, OnChanges {
  @Input() public skillsPracticeCards: SkillsPracticeCard[] = [];
  @Input() public inactiveStudents: StudentContext[] = [];
  public skillsPracticeExperimentToggleValue: SkillsPracticeExperimentToggleValue = SkillsPracticeExperimentToggleValue.Control;
  public sortStrategy: FilterOptionValues = FilterOptions.MostStuck;
  public originalSkillPracticeCards: SkillsPracticeCard[] = [];
  // clean up with skills-practice-experiment-toggle
  public useRecommendedSkillGroups: boolean = false;
  public showEmptyStudentGroups: boolean = false;
  public topStuckCards: SkillsPracticeCard[] = [];
  public topReadyForChallengeCards: SkillsPracticeCard[] = [];
  public recommendedSkillGroupProductText: string = '';
  public allSkillActivitySectionCollapsed: boolean = false;
  public activeSkillText: string = "Active Skills";

  public constructor(
    public featureToggleService: FeatureToggleService,
    public skillsPracticeSortingService: SkillsPracticeSortingService,
    private licenseInfoService: LicenseInfoService,
    private subjectService: SubjectService
  ) {}

  async ngOnInit(): Promise<void> {
    this.showEmptyStudentGroups =
      await this.selectedClassHasValidLicensesForEmptyStudentGroups() &&
      await this.featureToggleService.isTrueAsync('teach-3978-skills-practice-empty-student-groups');

    await this.setSkillsPracticeExperimentToggleProperties();

    // default sort
    this.sort(this.sortStrategy);
  }

  ngOnChanges() {
    this.originalSkillPracticeCards = this.skillsPracticeCards.slice();
    this.sort(this.sortStrategy);
  }

  isStudentStuck(student: StudentContext) {
    const minSkillAccuracyRate = SkillTests.getEffectiveMinSkillAccuracyRate(student.skills);
    return SkillTests.isStuckSkillAccuracyRate(minSkillAccuracyRate);
  }

  countStuckStudents(students: StudentContext[]): number {
    return students.filter(this.isStudentStuck).length;
  }

  sort(strategy: FilterOptionValues) {
    this.sortStrategy = strategy;

    switch(strategy) {
      case FilterOptions.SkillName: {
        this.skillsPracticeCards = this.skillsPracticeSortingService.sortBySkillShortName(this.originalSkillPracticeCards);
        break;
      }
      case FilterOptions.MostPracticing: {
        this.skillsPracticeCards = this.skillsPracticeSortingService.sortByPracticing(this.originalSkillPracticeCards);
        break;
      }
      case FilterOptions.MostStuck: {
        this.skillsPracticeCards = this.skillsPracticeSortingService.sortByStuck(this.originalSkillPracticeCards);
        break;
      }
      default:
        // We should never run this code
        // This trick is to fail compile time type check if there is ever a new FilterOption that is unhandled by the switch/case
        this._exhaustiveGuard(strategy);
    }
  }

  private _exhaustiveGuard(value: never): never {
    throw new Error(`Unknown sort option! ${value}`)
  }

  private async setSkillsPracticeExperimentToggleProperties() { 
    this.skillsPracticeExperimentToggleValue = await this.featureToggleService.getStringValue('skills-practice-experiment-toggle') as SkillsPracticeExperimentToggleValue;

    if (
      this.skillsPracticeExperimentToggleValue === SkillsPracticeExperimentToggleValue.NewGroupsShown || 
      this.skillsPracticeExperimentToggleValue === SkillsPracticeExperimentToggleValue.NewGroupsShownAllExpanded
    ) {
      this.useRecommendedSkillGroups = true;
      const recommendedSkillsToShow = 2;
      
      this.topStuckCards = this.skillsPracticeSortingService.getTopStuckCards(this.skillsPracticeCards, recommendedSkillsToShow);
      this.topReadyForChallengeCards = this.skillsPracticeSortingService.getTopReadyForAChallengeCards(this.skillsPracticeCards, recommendedSkillsToShow);

      const combinedCards = [...this.topStuckCards, ...this.topReadyForChallengeCards];
      const uniqueProductNames = this.getUniqueProductNames(combinedCards)

      this.recommendedSkillGroupProductText = this.createCommaSeparatedProductList(uniqueProductNames)
    }

    if (this.skillsPracticeExperimentToggleValue == SkillsPracticeExperimentToggleValue.NewGroupsShown) {
      if(this.topStuckCards.length > 0 || this.topReadyForChallengeCards.length > 0) {
        this.allSkillActivitySectionCollapsed = true;
      }

      this.activeSkillText = "All Skill Activity"
    }
  }

  private getUniqueProductNames(cards: SkillsPracticeCard[]): string[] {
    const uniqueProductNames = new Set<string>();
    
    cards.forEach(card => uniqueProductNames.add(card.skillMetadata.productName));
    return Array.from(uniqueProductNames);
  }

  private createCommaSeparatedProductList(unformattedProductNames: string[]): string {
    let formattedProductNames = [];

    for (let i = 0; i < unformattedProductNames.length; i++) {
      if (unformattedProductNames[i].toUpperCase() == 'MYON') {
        formattedProductNames.push('myON');
      }
      else {
        // capitalize the first letter of the product name
        formattedProductNames.push(unformattedProductNames[i].charAt(0).toUpperCase() + unformattedProductNames[i].slice(1).toLowerCase());
      }
    }

    return formattedProductNames.join(', ').replace(/,(?!.*,)/gmi, ' and');
  }

  // Clean these up along with skills-practice-experiment-toggle
  public showAllSkillActivitySection() {
    this.allSkillActivitySectionCollapsed = false;
  }

  public get skillsPracticeExperimentToggleEnumValue(): typeof SkillsPracticeExperimentToggleValue {
    return SkillsPracticeExperimentToggleValue; 
  }

  public get skillPracticeCategoryEnumValue(): typeof SkillPracticeCategory {
    return SkillPracticeCategory; 
  }

  private async selectedClassHasValidLicensesForEmptyStudentGroups(): Promise<boolean> {
    return (await this.licenseInfoService.selectedClassHasLalilo() && this.subjectService.selectedSubjectIsReading()) ||
      (await this.licenseInfoService.selectedClassHasFreckleMath() && this.subjectService.selectedSubjectIsMath()) ||
      (await this.licenseInfoService.selectedClassHasFreckleReading() && this.subjectService.selectedSubjectIsReading());
  }
}
