import { Injectable } from '@angular/core';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { StudentContext } from 'src/app/shared/models/student.model';
import { Recommendation, RecommendationType } from '../../shared/models/recommendation.model';
import { FreckleRecommendationService } from './freckle/freckle-recommendation.service';
import { StarRecommendationService } from './star/star-recommendation.service';
import { NoSkillsRecommendationService } from './no-skills/no-skills-recommendation.service';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { LaliloRecommendationService } from './lalilo/lalilo-recommendation.service';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { ProductIds, ProductIdsByGroup, ProductTypeGroups } from '../product-info/product-info.service';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { SubjectService } from '../subject/subject.service';
import { SubjectTypes } from '../subject/subject-types';

@Injectable({
  providedIn: 'root'
})
export class RecommendationServiceV2 {
  constructor(
    private freckleRecommendationService: FreckleRecommendationService,
    private starRecommendationService: StarRecommendationService,
    private laliloRecommendationService: LaliloRecommendationService,
    private noSkillsRecommendationService: NoSkillsRecommendationService,
    private featureToggleService: FeatureToggleService,
    private subjectService: SubjectService
  ) { }

  // Summary tab recommendations
  public async getSkillRecommendations(selectedStudent: StudentContext, allStudents: StudentContext[], classProducts: ClassProducts): Promise<Recommendation[]> {

    // Get skill recommendation for each product
    let freckleSkillRecommendation = await this.freckleRecommendationService.getFreckleSkillRecommendation(selectedStudent, allStudents);
    let starSkillRecommendation = await this.starRecommendationService.getStarSkillRecommendation(selectedStudent, allStudents);

    let recommendations: Recommendation[] = [];
    if (await this.featureToggleService.isTrueAsync('enable-lalilo-take-action-recommendations')) {
      let laliloSkillRecommendation = await this.laliloRecommendationService.getLaliloSkillRecommendation(selectedStudent, allStudents);
      recommendations = this.filterAndTagSkillRecommendationsWithLalilo(freckleSkillRecommendation, starSkillRecommendation, laliloSkillRecommendation, selectedStudent);
    }
    else {
      recommendations = this.filterAndTagSkillRecommendations(freckleSkillRecommendation, starSkillRecommendation, selectedStudent);
    }

    // No valid skill recommendations
    if (recommendations.length == 0) {
      recommendations.push(await this.noSkillsRecommendationService.getNoSkillsRecommendation(selectedStudent, classProducts));
    }

    return recommendations;
  }

  // Skills tab recommendations
  public async getRecommendationForStudentsWithSkill(students: StudentContext[], skillMetadata: SkillMetadata): Promise<Recommendation> {
    let recommendation = null;
    let selectedStudent = students[0];

    // Adaptive practice on any student -> base skill recommendation on that student's practice
    let filteredStudents = this.filterStudentSkillsByContentActivityId(students, skillMetadata.contentActivityId);
    let adaptiveStudent = filteredStudents.find(student => student.skills.find(skill => ProductTypeGroups.AdaptivePracticeIds.includes(skill.product)));

    if (adaptiveStudent) {
      selectedStudent = adaptiveStudent;
    }

    let classProductUsage = selectedStudent.classProductUsage;
    let aggregatedSkills = SkillAggregate.aggregate(selectedStudent.skills);
    let skill = aggregatedSkills.find(x => x.contentActivityId == skillMetadata.contentActivityId)!;

    // Get skill recommendation for Freckle
    if (ProductIdsByGroup.FreckleProductIds.includes(this.getProductIdForProductName(skillMetadata.productName))) {
      recommendation = await this.freckleRecommendationService.getFreckleSkillRecommendationForStudentsWithSkill(students, skill, classProductUsage);
    }

    // Get skill recommendation for Lalilo
    else if (skillMetadata.productName == ProductIds.Lalilo) {
      recommendation = await this.laliloRecommendationService.getLaliloSkillRecommendationForStudentsWithSkill(students, skill, classProductUsage);
    }

    // Add future product recommendations here

    return recommendation!;
  }

  private filterAndTagSkillRecommendations(freckleSkillRecommendation: Recommendation | null, starSkillRecommendation: Recommendation | null, selectedStudent: StudentContext): Recommendation[] {
    let recommendations: Recommendation[] = [];

    // Freckle / No Star
    if (freckleSkillRecommendation && !starSkillRecommendation) {
      recommendations.push(freckleSkillRecommendation);
    }

    // Star / No Freckle
    if (!freckleSkillRecommendation && starSkillRecommendation) {
      // If Freckle too old, it wasn't considered
      if (!DateHelper.isWithinTwoWeeksOfToday(selectedStudent.lastSkillPracticedDate)) {
        starSkillRecommendation.rationale = `Based on ${selectedStudent.firstName}'s most recent Star test on ${DateHelper.parseDate(selectedStudent.latestAssessment.completedDate).toLocaleString()} and typical growth based on ${selectedStudent.firstName}'s peers, we recommend this skill.`
      }
      recommendations.push(starSkillRecommendation);
    }

    // Freckle and Star
    if (freckleSkillRecommendation && starSkillRecommendation) {

      // Star too old to recommend above Freckle
      if (!DateHelper.isWithinTwoWeeksOfToday(selectedStudent.latestAssessment.completedDate)) {
        recommendations.push(freckleSkillRecommendation);
      }

      // Recommendations the same
      else if (freckleSkillRecommendation.skillMetadata?.renaissanceSkillId == starSkillRecommendation.skillMetadata?.renaissanceSkillId) {
        recommendations.push(freckleSkillRecommendation);
      }

      // Recommendations different
      else {
        recommendations.push(freckleSkillRecommendation);
        recommendations.push(starSkillRecommendation);
      }
    }

    return recommendations;
  }

  private filterAndTagSkillRecommendationsWithLalilo(freckleSkillRecommendation: Recommendation | null, starSkillRecommendation: Recommendation | null, laliloSkillRecommendation: Recommendation | null, selectedStudent: StudentContext): Recommendation[] {
    let recommendations: Recommendation[] = [];

    let practiceProductSkillRecommendation = this.filterPracticeProductRecommendation(freckleSkillRecommendation, laliloSkillRecommendation, selectedStudent);

    // Practice / No Star
    if (practiceProductSkillRecommendation && !starSkillRecommendation) {
      recommendations.push(practiceProductSkillRecommendation);
    }

    // Star / No Practice
    if (!practiceProductSkillRecommendation && starSkillRecommendation) {
      // If Skill Practice too old, it wasn't considered
      if (!DateHelper.isWithinTwoWeeksOfToday(selectedStudent.lastSkillPracticedDate)) {
        starSkillRecommendation.rationale = `Based on ${selectedStudent.firstName}'s most recent Star test on ${DateHelper.parseDate(selectedStudent.latestAssessment.completedDate).toLocaleString()} and typical growth based on ${selectedStudent.firstName}'s peers, we recommend this skill.`
      }
      recommendations.push(starSkillRecommendation);
    }

    // Practice and Star
    if (practiceProductSkillRecommendation && starSkillRecommendation) {

      // Star too old to recommend above Practice Product
      if (!DateHelper.isWithinTwoWeeksOfToday(selectedStudent.latestAssessment.completedDate)) {
        recommendations.push(practiceProductSkillRecommendation);
      }

      // Recommendations the same
      else if (practiceProductSkillRecommendation.skillMetadata?.renaissanceSkillId == starSkillRecommendation.skillMetadata?.renaissanceSkillId) {
        recommendations.push(practiceProductSkillRecommendation);
      }

      // Recommendations different
      else {
        recommendations.push(practiceProductSkillRecommendation);
        recommendations.push(starSkillRecommendation);
      }
    }

    return recommendations;
  }

  private filterPracticeProductRecommendation(freckleSkillRecommendation: Recommendation | null, laliloSkillRecommendation: Recommendation | null, selectedStudent: StudentContext): Recommendation | null {
    // Freckle / No Lalilo
    if (freckleSkillRecommendation && !laliloSkillRecommendation) {
      return freckleSkillRecommendation;
    }

    // Lalilo / No Freckle
    if (!freckleSkillRecommendation && laliloSkillRecommendation) {
      return laliloSkillRecommendation;
    }

    // Freckle and Lalilo
    if (freckleSkillRecommendation && laliloSkillRecommendation) {

      // Recommend based on lowest category
      if (freckleSkillRecommendation.recommendationType < laliloSkillRecommendation.recommendationType) {
        return freckleSkillRecommendation;
      }

      else if (laliloSkillRecommendation.recommendationType < freckleSkillRecommendation.recommendationType) {
        return laliloSkillRecommendation;
      }

      // Same category
      else if (freckleSkillRecommendation.recommendationType === laliloSkillRecommendation.recommendationType) {

        // Check if targeted practice
        let isFreckleTargeted = freckleSkillRecommendation.practiceProductType && ProductTypeGroups.TargetedPracticeIds.includes(freckleSkillRecommendation.practiceProductType);
        let isLaliloTargeted = laliloSkillRecommendation.practiceProductType && ProductTypeGroups.TargetedPracticeIds.includes(laliloSkillRecommendation.practiceProductType);

        // Lalilo Targeted / Freckle Not Targeted
        if (!isFreckleTargeted && isLaliloTargeted) {
          return laliloSkillRecommendation;
        }

        // Freckle Targeted / Lalilo Not Targeted
        if (isFreckleTargeted && !isLaliloTargeted) {
          return freckleSkillRecommendation;
        }

        // Freckle and Lalilo both targeted
        if (isFreckleTargeted && isLaliloTargeted) {
          return laliloSkillRecommendation;
        }
      }

      return laliloSkillRecommendation;
    }
    
    return null;
  }

  private filterStudentSkillsByContentActivityId(students: StudentContext[], contentActivityId: string): StudentContext[] {
    return students.map(student => {
      return {
        ...student,
        skills: student.skills.filter(skill => skill.contentActivityId === contentActivityId)
      };
    });
  }

  private getProductIdForProductName(productName: string): string {
    let productId = '';

    if (productName == 'FRECKLE') {
      if (this.subjectService.selectedSubject$.value == SubjectTypes.MATH) {
        productId = ProductIds.FreckleMath;
      }

      else if (this.subjectService.selectedSubject$.value == SubjectTypes.READING) {
        productId = ProductIds.FreckleReading;
      }
    }

    else {
      productId = productName;
    }

    return productId;
  }
}
