import { StudentContext } from "src/app/shared/models/student.model";
import { SkillTests } from "src/app/util/skill-tests/skill-tests";
import { Injectable } from "@angular/core";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { ProductIds, ProductIdsByGroup, ProductInfoService, ProductTypeGroups } from "../../product-info/product-info.service";

@Injectable({
  providedIn: 'root'
})
export class SuperstarPerformanceImprovementService {

  constructor(private productInfoService: ProductInfoService) { }

  public getPerformanceImprovementQualifications(student: StudentContext): string[] {
    let qualifications: string[] = [];

    // 4 qualifiers for Performance Improvement:
    // Most recent Star CAT (Reading, Math, SEL, Spanish) SGP >50%
    const starCATQualified = this.qualifyStarCAT(student);
    if (starCATQualified) {
      qualifications.push(starCATQualified);
    }

    // Star CBM score improvement
    const starCBMQualified = this.qualifyStarCBM(student);
    if (starCBMQualified) {
      qualifications.push(...starCBMQualified);
    }

    // Weekly AR quiz accuracy rate improvement
    const arQuizQualified = this.qualifyARQuiz(student);
    if (arQuizQualified) {
      qualifications.push(arQuizQualified);
    }

    // Weekly targeted practice accuracy rate improvement
    const targetedPracticeQualified = this.qualifyTargetedPractice(student);
    if (targetedPracticeQualified) {
      qualifications.push(targetedPracticeQualified);
    }

    return qualifications;
  }

  private qualifyStarCAT(student: StudentContext): string {
    let qualification: string = '';

    const mostRecentStarCATAssessment = student.assessments.find(assessment => ProductIdsByGroup.StarCATProductIds.includes(assessment.productId));
    const mostRecentStarSGP = mostRecentStarCATAssessment?.currentSGP;

    // Most recent Star CAT SGP above 50%
    if (mostRecentStarSGP && mostRecentStarSGP > 50) {
      const starAssessmentDisplayName = this.productInfoService.GetProductName(mostRecentStarCATAssessment.productId);

      qualification = `Had more than typical growth on recent ${starAssessmentDisplayName}, compared with academic peers nationwide`;
    }

    return qualification;
  }

  private qualifyStarCBM(student: StudentContext): string[] {
    let qualifications: string[] = [];

    const starCBMAssessments = student.assessments.filter(assessment => ProductIdsByGroup.StarCBMProductIds.includes(assessment.productId));
    if (starCBMAssessments.length > 0) {

      // Get all unique Star CBM product IDs
      let starCBMProductIds = new Set<string>();
      starCBMAssessments.forEach(assessment => {
        starCBMProductIds.add(assessment.productId);
      });

      starCBMProductIds.forEach(productId => {
        // Get most recent Star CBM assessment
        const mostRecentProductAssessment = starCBMAssessments.find(assessment => assessment.productId === productId);
        const mostRecentProductAccuracy = mostRecentProductAssessment?.accuracy;

        // Find a oldest assessment with same Star CBM product
        const oldestProductAssessment = starCBMAssessments.slice(1).reverse().find(assessment => assessment.productId === productId);
        const oldestProductAccuracy = oldestProductAssessment?.accuracy;

        // Star CBM product accuracy rate improvement
        if (mostRecentProductAccuracy && oldestProductAccuracy &&
          this.isPositiveAccuracyRateImprovement(mostRecentProductAccuracy, oldestProductAccuracy)) {
            const starCBMProductDisplayName = this.productInfoService.GetProductName(productId);
            const rateOfImprovement = this.getAccuracyRateImprovement(mostRecentProductAccuracy, oldestProductAccuracy).toString();

            qualifications.push(`${starCBMProductDisplayName} went up by ${rateOfImprovement}%`);
          }
      });
    }

    return qualifications;
  }

  private qualifyARQuiz(student: StudentContext): string {
    let qualification: string = '';

    const arQuizReadingPractices = student.readingPractices.filter(readingPractice => readingPractice.product === ProductIds.AR);
    if (arQuizReadingPractices.length > 0) {

      // Split AR quiz reading practices up by week
      const lastWeekARQuizReadingPractices = arQuizReadingPractices.filter(readingPractice => DateHelper.isWithinOneWeekOfToday(readingPractice.mostRecentPracticedDate, true));
      const oldARQuizReadingPractices = arQuizReadingPractices.filter(readingPractice =>
        !lastWeekARQuizReadingPractices.includes(readingPractice) && DateHelper.isWithinTwoWeeksOfToday(readingPractice.mostRecentPracticedDate, true)
      );

      if (lastWeekARQuizReadingPractices.length > 0 && oldARQuizReadingPractices.length > 0) {
        const lastWeekARQuizAccuracy = lastWeekARQuizReadingPractices.reduce((acc, readingPractice) =>
          acc + (readingPractice.percentCorrect ?? 0), 0) / lastWeekARQuizReadingPractices.length;
        const oldARQuizAccuracy = oldARQuizReadingPractices.reduce((acc, readingPractice) =>
          acc + (readingPractice.percentCorrect ?? 0), 0) / oldARQuizReadingPractices.length;

        // Weekly AR quiz accuracy rate improvement
        if (this.isPositiveAccuracyRateImprovement(lastWeekARQuizAccuracy, oldARQuizAccuracy)) {
          const percentImprovement = this.getAccuracyRateImprovement(lastWeekARQuizAccuracy, oldARQuizAccuracy).toString();

          qualification = `Weekly Accelerated Reader quiz performance went up by ${percentImprovement}% in the last 7 days`;
        }
      }
    }

    return qualification;
  }

  private qualifyTargetedPractice(student: StudentContext): string {
    let qualification: string = '';

    const targetedPractices = student.skills.filter(skill => ProductTypeGroups.TargetedPracticeIds.includes(skill.product));
    if (targetedPractices.length > 0) {

      // Get most recent targeted practice product
      const mostRecentTargetedPracticeProductId = targetedPractices[0].productId;

      // Split targeted practice up by week
      const lastWeekTargetedPractices = targetedPractices.filter(practice =>
        practice.productId === mostRecentTargetedPracticeProductId
        && DateHelper.isWithinOneWeekOfToday(practice.lastPracticedDate, true)
      );
      const oldTargetedPractices = targetedPractices.filter(practice =>
        practice.productId === mostRecentTargetedPracticeProductId
        && !lastWeekTargetedPractices.includes(practice)
        && DateHelper.isWithinTwoWeeksOfToday(practice.lastPracticedDate, true)
      );

      if (lastWeekTargetedPractices.length > 0 && oldTargetedPractices.length > 0) {
        const lastWeekTargetedPracticeAccuracy = lastWeekTargetedPractices.reduce((acc, practice) =>
          acc + SkillTests.getSkillAccuracyRate(practice), 0) / lastWeekTargetedPractices.length;
        const oldTargetedPracticeAccuracy = oldTargetedPractices.reduce((acc, practice) =>
          acc + SkillTests.getSkillAccuracyRate(practice), 0) / oldTargetedPractices.length;

        // Weekly targeted practice accuracy rate improvement
        if (this.isPositiveAccuracyRateImprovement(lastWeekTargetedPracticeAccuracy, oldTargetedPracticeAccuracy)) {
          const practiceProductDisplayName = this.productInfoService.GetProductName(mostRecentTargetedPracticeProductId);
          const percentImprovement = this.getAccuracyRateImprovement(lastWeekTargetedPracticeAccuracy, oldTargetedPracticeAccuracy).toString();

          qualification = `Weekly accuracy on assigned practice in ${practiceProductDisplayName} went up by ${percentImprovement}% in the last 7 days`;
        }
      }
    }

    return qualification;
  }

  private getAccuracyRateImprovement(accuracyRate: number, oldAccuracyRate: number): number {
    return Math.round((accuracyRate - oldAccuracyRate) * 100);
  }

  private isPositiveAccuracyRateImprovement(accuracyRate: number, oldAccuracyRate: number): boolean {
    return this.getAccuracyRateImprovement(accuracyRate, oldAccuracyRate) > 0;
  }
}
