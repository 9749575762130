import { AfterViewChecked, Component, HostListener, Inject, OnInit } from '@angular/core';
import { SearchResult } from './search-result.model';
import { HttpClient } from '@angular/common/http';
import { Subscription, debounceTime, Subject, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { DOCUMENT } from '@angular/common';
import { ClassesService } from 'src/app/services/classes/classes.service';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { NewRelicInstrumentationService } from 'src/app/services/new-relic-instrumentation/new-relic-instrumentation.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { SearchFilters } from 'src/app/shared/models/search-filters.model';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit, AfterViewChecked {
  public dynamicComponent: any;
  public searchResults: SearchResult[] = [];
  public query: string = '';
  public pageNumber: number = 1;
  public showOops: boolean = false;
  public isLoading: boolean = false;
  public moreResults: boolean = true;
  private newlyAddedFirstElementID: string = '';
  private subscriptions: Subscription[] = [];
  public filterChangeDebounceObs$: Subject<MatSelectChange> = new Subject<MatSelectChange>();
  public isSearchboxScroll: boolean = false;
  public isMyOnEnabled: boolean = false;
  public useOpenSearchApi: boolean = false;
  public searchApiUrl: string = environment.searchLambdaUrl;

  @HostListener('window:scroll', ['$event']) // for search results table drop shadow
  onScroll(event: any) {
    this.isSearchboxScroll = event.srcElement.scrollTop > 0;
  }

  public queryFilters: SearchFilters = {
    grades: [],
    lesson_types: [],
    products: [],
    subjects: []
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private featureToggleService: FeatureToggleService,
    private router: Router,
    private route: ActivatedRoute,
    private classesService: ClassesService,
    private licenseInfoService: LicenseInfoService,
    private newRelicInstrumentationService: NewRelicInstrumentationService
  ) {
  }

  ngAfterViewChecked(): void {
    if (this.newlyAddedFirstElementID) {
      let element = document.getElementById(this.newlyAddedFirstElementID);
      if (element) {
        element.focus();
        this.newlyAddedFirstElementID = '';
      }
    }
  }

  async ngOnInit() {
    this.dynamicComponent = this.route.snapshot.data['component'];
    this.isMyOnEnabled = await this.featureToggleService.isTrueAsync('nrd-19-enable-myon-mades-in-search');

    let urlParamsSub = this.route.queryParams.subscribe(async params => {
      let query = params['q'];

      if (query?.length > 0) {
        this.query = query;
      }
    });
    this.subscriptions.push(urlParamsSub);

    const { queryParams } = this.route.snapshot

    // convert url params to filters. the fitlers are expected to be an array of key, value objects
    this.queryFilters = Object.keys(this.queryFilters).reduce((acc: any, key: string) => {
      acc[key] = [];
      if (!queryParams[key]) {
        return acc;
      }

      if (Array.isArray(queryParams[key])) {
        acc[key] = queryParams[key].map((v: any) => ({ name: key, value: v }))
        return acc
      }

      acc[key].push({ name: key, value: queryParams[key] })

      return acc
    }, {});

    if (this.query) {
      await this.search(this.query);
    }

    //trigger a search each time a filter is updated. debounce the search to prevent multiple calls
    this.filterChangeDebounceObs$.pipe(debounceTime(800)).subscribe(async (v) => {
      this.pageNumber = 1;
      await this.search(this.query)
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async search(searchString: string) {
    if (searchString.trim().length > 0) {
      this.pendoTrackSearchQuery(searchString);
      this.searchResults = [];
      this.showOops = false;

      //covert filters to url safe query params
      const tags = Object.entries(this.queryFilters)
        .reduce((acc: any, [key, value]) => {
          acc[key] = (value as any[]).map((v: any) => v.value)
          return acc
        }, {}
        )

      this.query = searchString;
      const queryParams: Params = { q: searchString, ...tags };
      this.router.navigate([], { relativeTo: this.route, queryParams });

      this.pageNumber = 1;
      await this.handleSearch(searchString);
    }
  }

  public async getMoreResults() {
    this.pageNumber++;
    await this.handleSearch(this.query);
  }

  private async handleSearch(searchString: string) {
    this.isLoading = true;
    this.moreResults = true;

    let standardSets = await this.classesService.getStandardSetsForClass();

    // Convert filters into an array of objects for the search query
    const tags = Object.keys(this.queryFilters).reduce((acc: any, key) => {
      const val = this.queryFilters[key as keyof SearchFilters];
      if (val.length == 0) {
        return acc;
      }

      const values = val.map((v: { value: any; }) => v.value)

      return [...acc, { "key": key, "value": values }];
    }, []);

    let searchQuery = { 'textQuery': searchString, 'standardSets': standardSets, tags, 'pageNumber': this.pageNumber };

    try {
      //TODO: Remove this feature toggle when we remove the old search service 
      this.useOpenSearchApi = await this.featureToggleService.isTrueAsync('nrd-65-setup-new-search-api');
      if (this.useOpenSearchApi) {
        this.searchApiUrl = environment.openSearchUrl
      }

      let searchResponse = await firstValueFrom(this.http.post<SearchResult[]>(this.searchApiUrl, searchQuery));

      this.isLoading = false;
      let nearpodResults: SearchResult[] = [];
      let freckleResults: SearchResult[] = [];
      let laliloResults: SearchResult[] = [];
      let myOnResults: SearchResult[] = [];

      let currentClassesHaveFreckle = await this.licenseInfoService.currentClassesHaveFreckle();
      let currentClassesHaveLalilo = await this.licenseInfoService.currentClassesHaveLalilo();
      let currentClassesHaveMyOn = await this.licenseInfoService.currentClassesHaveMyOn();

      searchResponse!.forEach(result => {
        if (result.source == 'SOURCE_NEARPOD') {
          nearpodResults.push(result);
        }
        if (result.source == 'SOURCE_FRECKLE' && currentClassesHaveFreckle) {
          freckleResults.push(result);
        }
        if (result.source == 'SOURCE_LALILO' && currentClassesHaveLalilo) {
          laliloResults.push(result);
        }
        if (result.source == 'SOURCE_MYON' && currentClassesHaveMyOn) {
          myOnResults.push(result);
        }
      });

      // Get top 10 results
      nearpodResults = nearpodResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 5);
      freckleResults = freckleResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 5);
      laliloResults = laliloResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 5);
      myOnResults = myOnResults.sort((a, b) => this.compareResultsByDistance(a, b)).slice(0, 5);

      let newSearchResults = [...nearpodResults, ...freckleResults, ...laliloResults, ...myOnResults].sort((a, b) => this.compareResultsByDistance(a, b));

      if (newSearchResults.length > 0) {
        // Clear results if new query
        if (this.pageNumber == 1) {
          this.searchResults = newSearchResults;
        }

        // Append results if not new query
        else {
          this.searchResults = this.searchResults.concat(newSearchResults);
        }

        this.newlyAddedFirstElementID = newSearchResults[0].product_skill_id.toString();

      }

      // Hide button if no more results
      else {
        this.moreResults = false;
      }
    }
    catch (error: any) {
      this.isLoading = false;
      this.showOops = true;
      this.newRelicInstrumentationService.noticeError(error);
      this.newRelicInstrumentationService.recordSearchDegradedInfo(`search-error-${error.name}`);
      console.error(error);
    }
  }

  public async goBack() {
    this.router.navigate(['/summary']);
  }

  private pendoTrackSearchQuery(searchString: string) {
    const window = this.document.defaultView as any;
    if (window.pendo) {
      const pendo = window.pendo;
      pendo.track("SearchQuery", {
        searchQuery: searchString
      });
    }
  }

  private compareResultsByDistance(a: SearchResult, b: SearchResult) {
    return a.distance - b.distance;
  }

  public updateFilters({ values, event }: { values: SearchFilters, event: MatSelectChange }) {
    this.queryFilters = values;

    this.filterChangeDebounceObs$.next(event)
  }
}
