<mat-card class="recommended-skill-card">
    <mat-card-header class="recommended-skill-card-header">
      <mat-card-title>
        <div class="recommended-skill-card-status">
          <img class="recommended-skill-category-icon" alt="" [src]=icon>
          <p class="recommended-skill-category-name">{{categoryName}}
            <span class="recommended-skill-category-subtext">{{subText}}</span>
          </p>
      </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-divider aria-hidden="true"></mat-divider>
      <div class="recommended-skill-container">
          <div *ngFor="let skillsPracticeCard of skillsPracticeCards; let isLast = last">
            <active-skill-category
            [skillMetadata]="skillsPracticeCard.skillMetadata"
            [hasAmbiguousShortName]="skillsPracticeCard.hasAmbiguousShortName"
            [categoryId]="skillsPracticeCategory"
            [categoryName]="categoryName"
            [categorySubText]="subText"
            [categoryIcon]="icon"
            [students]="getStudents(skillsPracticeCard)"
            [showCategoryHeader]="false"
            [showSkillHeader]="true"
            [takeActionButtonText]="'View Recommendations'">
            </active-skill-category>
            <mat-divider class="reccomended-skill-student-divider" *ngIf="!isLast" aria-hidden="true"></mat-divider>
          </div>
      </div>
    </mat-card-content>
  </mat-card>
  