import { Component, Inject, OnInit, EventEmitter, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StudentSkillDetailModalData } from "./student-skill-detail-modal-data";
import { GraphqlService } from "src/app/services/graphql/graphql.service";
import { Skill } from "../../models/skill.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { ClassesService } from "src/app/services/classes/classes.service";

@Component({
  selector: 'student-skill-detail-modal',
  templateUrl: './student-skill-detail-modal.component.html',
  styleUrls: ['./student-skill-detail-modal.component.scss']
})
export class StudentSkillDetailModalComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter<void>();
  public skills: Skill[] = [];
  public totalAnswers: number = 0;
  public totalCorrect: number = 0;
  public isLoaded: boolean = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: StudentSkillDetailModalData,
    private selfRef: MatDialogRef<StudentSkillDetailModalComponent>,
    private graphQlService: GraphqlService,
    private classesService: ClassesService
  ) { }


  public async ngOnInit(): Promise<void> {
    let teacherRenaissanceId = this.classesService.selectedClass$.getValue()?.teacherRenaissanceId ?? '';

    let response = await this.graphQlService.getSkillPractice(this.data.student.renaissanceId, teacherRenaissanceId, this.data.skillMetadata.contentActivityId);
    let unfilteredSkills = [...response.skillDetail]; //response is frozen, need to copy it so sorting will work

    this.skills = unfilteredSkills.filter(skill => DateHelper.isWithinTwoWeeksOfToday(skill.lastPracticedDate));

    this.skills.sort((a, b) => {
      let aDate = new Date(a.lastPracticedDate).getTime();
      let bDate = new Date(b.lastPracticedDate).getTime();
      return aDate - bDate;
    });

    for (let skill of this.skills) {
      this.totalAnswers += skill.totalAnswers;
      this.totalCorrect += skill.correctAnswers;
    }

    this.isLoaded = true;
  }

  public close(event: Event) {
    this.closeModalEvent.emit(); 
    event.preventDefault();
    this.selfRef.close();
  }

  public toPercent(decimal: number) {
    if (isNaN(decimal)) {
      return 0;
    }
    return Math.round(decimal * 100);
  }

  public toFriendlyDate(dateToDisplay: string): string {
    return DateHelper.parseDate(dateToDisplay).toLocaleString();
  }
}
