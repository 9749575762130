<div *ngIf="haveAnySkillsPracticeRecommendations(recommendations)">
  <h3 class="header">Skills Practice & Instruction</h3>
  <div id="pendo-skill-{{recommendation}}" *ngFor="let recommendation of recommendations"
    class="take-action-skill-list">

    <!-- No skills recommendation Skills Practice -->
    <div *ngIf="isSkillsPracticeRecommendation(recommendation) && hasNoProductLinks(recommendation)">
      <div *ngIf="!displaySkillProductHelperText()">
        <div class="no-skill-recommendation flex">
          <img src="../../../../../../assets/logos/{{getProduct(recommendation.skillsPracticeProducts)}}_logo.svg" alt="" class="logo" />
          <div>{{recommendation.nudgeText.skillsPractice}} </div>
        </div>
      </div>
      <div *ngIf="displaySkillProductHelperText()">
        <div class="no-skill-recommendation"> {{recommendation.nudgeText.skillsPractice}} </div>
        <div class="no-skill-recommendation-reading-note">
          <div class="flex">
            <img src="../../../../../../assets/logos/lalilo_logo.svg" alt="" class="logo" />
            <div>Lalilo is the right tool for students who are <b>learning to read</b>.</div>
          </div>
          <div class="flex">
            <img src="../../../../../../assets/logos/freckle_logo.svg" alt="" class="logo" />
            <div>Freckle is the tool for students who are <b>reading to learn</b>.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="haveAnyReadingPracticeRecommendations(recommendations) && !studentHasAnyReadingPractice(recommendations)">
  <h3 class="header">Reading Practice</h3>
  <div id="pendo-skill-{{recommendation}}" *ngFor="let recommendation of recommendations"
    class="take-action-skill-list">

    <!-- No skills recommendation Reading Practice -->
    <div *ngIf="isReadingPracticeRecommendation(recommendation) && hasNoProductLinks(recommendation)">
      <div *ngIf="!displayReadingProductHelperText()">
        <div class="no-skill-recommendation flex">
          <img src="../../../../../../assets/logos/{{getProduct(recommendation.readingPracticeProducts)}}_logo.svg" alt="" class="logo" />
          <div>{{recommendation.nudgeText.readingPractice}} </div>
        </div>
      </div>
      <div *ngIf="displayReadingProductHelperText()">
        <div class="no-skill-recommendation"> {{recommendation.nudgeText.readingPractice}} </div>
        <div class="no-skill-recommendation-reading-note">
          <div class="flex">
            <img src="../../../../../../assets/logos/ar_logo.svg" alt="" class="logo" />
            <div>AR's <b>quizzes</b> motivate and engage readers to help build reading stamina and a love of reading.
            </div>
          </div>
          <div class="flex">
            <img src="../../../../../../assets/logos/myon_logo.svg" alt="" class="logo" />
            <div>myON's interactive <b>digital texts</b> address a variety of interests and reading levels.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Math: Show if class has no Skill Practice Products -->
<!-- Reading: Show if class has no Skill Practice Products and Student has reading practice in AR/myON -->
<div *ngIf="showEncourageRecommendation()">
  <h3 class="header" >Skills Practice & Instruction</h3>
  <div class="take-action-skill-list">
    <div class="no-skill-recommendation"> Encourage {{studentFirstName}} to keep going with their classwork! </div>
    <div class="no-skill-recommendation-reading-note">
      <div class="flex">
        <img src="../../../../../../assets/logos/nearpod_logo.svg" alt="" class="logo" />
        <div>Check out <a class="link" href="https://nearpod.com">Nearpod</a> for teacher-led lessons and activities.</div>
      </div>
    </div>
  </div>
</div>
