<div class="smart-group -oval-bg">
  <p>
    <span class="line animate" [class.in-view]="inView">
      After they practice, students will be automatically
    </span>
    <span class="line animate" [class.in-view]="inView">
      grouped based on their performance.
    </span>
  </p>
  <object
    aria-label="an animation showing students grouped by skill categories"
    class="hero-image"
    data="../../../../../../assets/empty-student-groups-ani.svg">
  </object>
</div>
