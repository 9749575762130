import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PendoService } from '../pendo/pendo.service';
import { UserService } from '../user/user.service';

export interface LTIServiceResponse {
  auth_url: string;
  form_data: { state: string, id_token: string };
}

export interface LTIServiceRequest {
  skill_ids: string[];
  rid: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  launch_path: LTITargetURIs;
}

export enum LTITargetURIs {
  SEARCH_OR_NAV = '/launch/search',
  CONFIG = '/config'
}

@Injectable({
  providedIn: 'root'
})
export class SavvasLinkGeneratorService {
  public window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private pendoService: PendoService,
    private userService: UserService) {
    this.window = this.document.defaultView as Window;
  }

  async appLaunch(
    skillIds: string[] = [''],
    launchPath: LTITargetURIs = LTITargetURIs.SEARCH_OR_NAV,
    newTab = true,
  ) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    const userId = this.userService.getUserId() || 'invalid_user_id';
    const { email, firstName, lastName } = await this.userService.getUserDetail();

    const formData: LTIServiceRequest = {
      'skill_ids': skillIds,
      'rid': userId,
      //@TODO: the fields below should be retrieved from the JWT after the JWT changes from Ouroboros
      'email': email,
      'name': firstName + ' ' + lastName,
      'given_name': firstName,
      'family_name': lastName,
      'launch_path': launchPath,
    };

    const body = new URLSearchParams();
    Object.keys(formData).forEach(key =>
      body.append(key, formData[key as keyof LTIServiceRequest] as string));

    const authData = await firstValueFrom(this.http.post(
        environment.ltiServiceIntegrationUrl,
        body.toString(),
        {headers}
    )) as LTIServiceResponse;

    const form = this.document.createElement('form');
    form.method = 'POST';
    form.action = authData.auth_url;
    form.target = newTab ? '_blank' : '_self';

    Object.entries(authData.form_data).forEach(([key, value]) => {
      const formField = this.document.createElement('input');
      formField.type = 'hidden';
      formField.name = key;
      formField.value = value as string;
      form.appendChild(formField);
    });

    this.document.body.appendChild(form);

    this.pendoService.sendEvent("SavvasNavigation", this.document);
    form.submit();
  }
}
