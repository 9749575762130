import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ProductAppDisplayNames } from 'src/app/services/product-info/product-info.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { TimeToPracticeRecommendation } from 'src/app/shared/models/time-to-practice-recommendation.model';

export const ProductLogoMapping: {[key: string]: string} = {
  "AR": "../../../../../../assets/logos/ar_logo.svg",
  "Freckle": "../../../../../../assets/logos/freckle_logo.svg",
  "Lalilo": "../../../../../../assets/logos/lalilo_logo.svg",
  "MyON": "../../../../../../assets/logos/myon_logo.svg",
  "Nearpod": "../../../../../../assets/logos/nearpod_logo.svg"
};

export const ProductNameMapping: {[key: string]: string} = {
  "AR": "AR",
  "Freckle": "Freckle",
  "Lalilo": "Lalilo",
  "MyON": "myON",
  "Nearpod": "Nearpod"
};

export enum PracticeProductType {
  SkillsPractice = "SkillsPractice",
  ReadingPractice = "ReadingPractice"
}

@Component({
  selector: 'time-to-practice-recommendation',
  templateUrl: './time-to-practice-recommendation.component.html',
  styleUrls: ['./time-to-practice-recommendation.component.scss']
})
export class TimeToPracticeRecommendationComponent implements OnInit, OnChanges {

  @Input() public classProducts!: ClassProducts;


  public showMultipleProductTypesRecommendation: boolean = false;
  public showSingleProductTypeMultipleProductsRecommendation: boolean = false;
  public showSingleProductRecommendation: boolean = false;
  public showNoProductRecommendation: boolean = false;

  public productRecommendations: TimeToPracticeRecommendation[] = [];

  constructor(private subjectService: SubjectService) { }

  ngOnInit() {
    if (this.subjectService.selectedSubject$.getValue() == SubjectTypes.MATH) {
      this.getRecommendationForMath();
    }
    else {
      this.getRecommendationForReading();
    }
  }

  ngOnChanges()  {
    // reset recommendation on changes
    this.productRecommendations = [];
    this.showMultipleProductTypesRecommendation = false;
    this.showSingleProductTypeMultipleProductsRecommendation = false;
    this.showSingleProductRecommendation = false;
    this.showNoProductRecommendation = false;

    if (this.subjectService.selectedSubject$.getValue() == SubjectTypes.MATH) {
      this.getRecommendationForMath();
    }
    else {
      this.getRecommendationForReading();
    }
  }

  public getRecommendationForMath() {
    let skillsPracticeProducts = this.classProducts.skillsPracticeProducts;

    // The only skills practice product that math has is Freckle
    if (skillsPracticeProducts.find(product => product === 'Freckle')) {
      this.showSingleProductRecommendation = true;
      this.productRecommendations.push(this.getSingleProductRecommendation(skillsPracticeProducts[0]));
    }
    else {
      this.showNoProductRecommendation = true;
      this.productRecommendations.push(this.getSingleProductRecommendation(null));
    }
  }

  public getRecommendationForReading() {
    let readingPracticeProducts = this.classProducts.readingPracticeProducts;
    let skillsPracticeProducts = this.classProducts.skillsPracticeProducts;

    if (readingPracticeProducts.length == 0 && skillsPracticeProducts.length == 0) {
      this.showNoProductRecommendation = true;
      this.productRecommendations.push(this.getSingleProductRecommendation(null));
    }
    else if (readingPracticeProducts.length == 1 && skillsPracticeProducts.length == 0) {
      this.showSingleProductRecommendation = true;
      this.productRecommendations.push(this.getSingleProductRecommendation(readingPracticeProducts[0]));
    }
    else if (skillsPracticeProducts.length == 1 && readingPracticeProducts.length == 0) {
      this.showSingleProductRecommendation = true;
      this.productRecommendations.push(this.getSingleProductRecommendation(skillsPracticeProducts[0]));
    }
    else if (readingPracticeProducts.length > 1 && skillsPracticeProducts.length == 0) {
      this.showSingleProductTypeMultipleProductsRecommendation = true;
      this.productRecommendations = this.getMultipleProductsRecommendations(readingPracticeProducts);
    }
    else if (skillsPracticeProducts.length > 1 && readingPracticeProducts.length == 0) {
      this.showSingleProductTypeMultipleProductsRecommendation = true;
      this.productRecommendations = this.getMultipleProductsRecommendations(skillsPracticeProducts);
    }
    else if (readingPracticeProducts.length > 0 && skillsPracticeProducts.length > 0) {
      this.showMultipleProductTypesRecommendation = true;
      this.productRecommendations = this.getMultipleProductsRecommendations([...skillsPracticeProducts, ...readingPracticeProducts]);
    }
  }

  private getSingleProductRecommendation(product: string | null): TimeToPracticeRecommendation {

    if (product == ProductAppDisplayNames.Freckle || product == ProductAppDisplayNames.Lalilo) {
      return {
        recommendationLogo: ProductLogoMapping[product],
        recommendationText: `<strong>Recommendation:</strong> have these students log into ${ProductNameMapping[product]} to work on adaptive practice.`,
        recommendationProduct: PracticeProductType.SkillsPractice
      };
    }
    if (product == ProductAppDisplayNames.AR || product == ProductAppDisplayNames.Myon) {
      return {
        recommendationLogo: ProductLogoMapping[product],
        recommendationText: `<strong>Recommendation:</strong> have these students practice independent reading in ${ProductNameMapping[product]}.`,
        recommendationProduct: PracticeProductType.ReadingPractice
      };
    }
    return {
      recommendationLogo: ProductLogoMapping['Nearpod'],
      recommendationText: `Check out <a target="_blank" aria-label="Nearpod opens in a new tab "href="https://nearpod.com/">Nearpod</a> for teacher-led lessons and activities.`
    }
  }

  private getMultipleProductsRecommendations(products: string[]): TimeToPracticeRecommendation[] {

    let recommendations: TimeToPracticeRecommendation[] = [];

    products.forEach(product => {

      if (product == ProductAppDisplayNames.Freckle) {
        recommendations.push({
          recommendationLogo: ProductLogoMapping[product],
          recommendationText: `Freckle: for students who are <strong>reading to learn</strong>.`,
          recommendationProduct: PracticeProductType.SkillsPractice
        });
      }
      else if (product == ProductAppDisplayNames.Lalilo) {
        recommendations.push({
          recommendationLogo: ProductLogoMapping[product],
          recommendationText: `Lalilo: for students who are <strong>learning to read</strong>.`,
          recommendationProduct: PracticeProductType.SkillsPractice
        });
      }
      else if (product == ProductAppDisplayNames.AR) {
        recommendations.push({
          recommendationLogo: ProductLogoMapping[product],
          recommendationText: `AR's <strong>quizzes</strong> motivate and engage readers to help build reading stamina and a love of reading.`,
          recommendationProduct: PracticeProductType.ReadingPractice
        })
      }
      else if (product == ProductAppDisplayNames.Myon) {
        recommendations.push({
          recommendationLogo: ProductLogoMapping[product],
          recommendationText: `myON's interactive digital texts address a variety of interests and reading levels.`,
          recommendationProduct: PracticeProductType.ReadingPractice
        });
      }
    });

    return recommendations;
  }

  public getMultipleProductsRecommendationHeaderText() {
    let recommendationText = 'have these students ';
    if (this.productRecommendations.find(recommendation => recommendation.recommendationProduct == PracticeProductType.SkillsPractice)) {
      let productDisplayNames = this.classProducts.skillsPracticeProducts.map(product => ProductNameMapping[product]);
      recommendationText += `log into ${productDisplayNames.join(' or ')} to work on adaptive practice`

      if (this.productRecommendations.find(recommendation => recommendation.recommendationProduct == PracticeProductType.ReadingPractice)) {
        recommendationText += ', and '
      }
      else {
        recommendationText += '.'
      }
    }
    if (this.productRecommendations.find(recommendation => recommendation.recommendationProduct == PracticeProductType.ReadingPractice)) {
      let productDisplayNames = this.classProducts.readingPracticeProducts.map(product => ProductNameMapping[product]);
      recommendationText += `practice their independent reading in ${productDisplayNames.join(' and ')}.`;
    }
    return recommendationText;
  }
}
