import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpIdService {

  public helpUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private routeToHelpIdMap: { [key: string] : string } = {
    '/summary'    : 'help-setup-22151',
    '/skills'     : 'help-setup-22152',
    '/assessment' : 'help-setup-22153',
    '/reading'    : 'help-setup-22154',
    '/superstars' : 'help-setup-22155'
  }

  constructor(
    private router: Router
  ) {}

  public initHelpIdService(culture: string) {

    this.setHelpUrl(this.router.url, culture);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => (event as NavigationEnd).url),
      )
      .subscribe(routeUrl => {
        if (this.routeToHelpIdMap[routeUrl]) {
          this.setHelpUrl(routeUrl, culture)
        }
      });
  }

  private setHelpUrl(routeUrl: string, culture: string) {
    var helpId = '';
    if (this.routeToHelpIdMap[routeUrl]) {
      helpId = this.routeToHelpIdMap[routeUrl];
    }
    let helpDebugPrefix = environment.production ? '' : 'sg-';
    let helpUrl = `${environment.helpRedirectBaseUrl}${helpDebugPrefix}${culture.toLowerCase()}-${helpId}`
    this.helpUrl$.next(helpUrl);
  }
}
