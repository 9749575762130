import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, take } from 'rxjs';
import { AppNav } from 'src/app/shared/models/appnav.model';
import { UserService } from '../user/user.service';
import { Future } from 'src/app/util/future';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { NewRelicInstrumentationService } from '../new-relic-instrumentation/new-relic-instrumentation.service';

@Injectable({
  providedIn: 'root'
})
export class AvailableAppNavService {
  private appnav: AppNav[] = [];
  private _isLoaded: Future<boolean> = new Future<boolean>();
  private _isDegraded = false;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private newRelicService: NewRelicInstrumentationService,
    private featureToggleService: FeatureToggleService
  ) { }

  async loadAppNav() {
    if (await this.featureToggleService.isTrueAsync("tj-sabotage-force-app-nav-load-fail")) {
      this._isDegraded = true;
      this.newRelicService.recordAppNavDegradedInfo('appnav-sabotage-toggle');
      this._isLoaded.resolve(true);
      return;
    }

    if (this.userService.getSkipNavAccessCheck()) {
      this._isLoaded.resolve(true);
      return;
    }

    // Update appnav from session as needed
    if (this.appnav.length == 0) {
      let headers = new HttpHeaders({
        'access-token': this.userService.getRawJwt() ?? ''
      });

      // Get user appnav from session
      try {
        let sessionAppNav = await firstValueFrom(this.http.get(this.userService.getAppNavUrl(), {
          headers: headers
        }).pipe(take(1)));
        this.appnav = sessionAppNav as AppNav[];
      }
      catch(err) {
        let httpError = err as HttpErrorResponse;
        this.newRelicService.recordAppNavDegradedInfo(`appnav-api-error-${httpError.status}`);

        // failed to load app nav
        this._isDegraded = true;
      }
    }

    this._isLoaded.resolve(true);
  }

  getAppNav() {
    return this.appnav;
  }

  getIsDegraded() {
    return this._isDegraded;
  }

  isLoaded(): Promise<boolean> {
    return this._isLoaded.promise;
  }
}
