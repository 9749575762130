<div class="active-skill-category" [ngClass]="{'category-with-take-action': showSkillHeader}">
  <div *ngIf="showCategoryHeader" class="active-skill-category-header">
    <img class="active-skill-category-icon" alt="" [src]=categoryIcon>
    <p class="active-skill-category-name">{{categoryName}}
      <span class="active-skill-category-subtext">{{categorySubText}}</span>
    </p>
  </div>
  <!--clean up with skills-practice-experiment-toggle if unneeded-->
  <div *ngIf="showSkillHeader" class="active-skill-category-header">
    <app-skill-description
      [skillMetadata]="skillMetadata"
      [hasAmbiguousShortName]="hasAmbiguousShortName"
      [isSummary]="false">
    </app-skill-description>
  </div>
  <div data-test="take-action-button" *ngIf="showTakeActionButton()">
    <button
      type="button"
      id="pendo-skills-take-action"
      class="ren-btn ren-btn--secondary take-action"
      [ngClass]="categoryNameToClass()+'-take-action'"
      (click)="openTakeActionModal()"
      >{{takeActionButtonText}}
    </button>
  </div>
</div>
<app-student-grouping
  [students]="students"
  [skillMetadata]= "skillMetadata"
  [hasAmbiguousShortName]="hasAmbiguousShortName"
  [enablePopup]="true"
></app-student-grouping>
