import { Injectable } from '@angular/core';
import { ProductAppTags } from '../product-info/product-info.service';
import { ClassesService } from '../classes/classes.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseInfoService {
  constructor(private classesService: ClassesService) { }

  public async currentClassesHaveLalilo() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.Lalilo));
  }

  public async currentClassesHaveFreckle() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.FreckleMath) || c.classAppTags.includes(ProductAppTags.FreckleReading));
  }

  public async currentClassesHaveMyOn() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.Myon));
  }

  private async selectedClassHasProduct(appTag: string): Promise<boolean> {
    let selectedClass = await this.classesService.getSelectedClass();
    if (!selectedClass) {
      return false;
    }

    return selectedClass.classAppTags.includes(appTag);
  }

  public async selectedClassHasMyon() {
    return this.selectedClassHasProduct(ProductAppTags.Myon);
  }

  public async selectedClassHasLalilo(): Promise<boolean> {
    return this.selectedClassHasProduct(ProductAppTags.Lalilo);
  }

  public async selectedClassHasFreckleMath(): Promise<boolean> {
    return this.selectedClassHasProduct(ProductAppTags.FreckleMath);
  }

  public async selectedClassHasFreckleReading(): Promise<boolean> {
    return this.selectedClassHasProduct(ProductAppTags.FreckleReading);
  }
}
