import { Injectable } from "@angular/core";
import { ReadingPractice } from "src/app/shared/models/reading-practice.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { LexileHelperService } from "src/app/util/lexile-helper/lexile-helper.service";
import { SkillTests } from "src/app/util/skill-tests/skill-tests";

@Injectable({ providedIn: 'root' })
export class ARLexileCalculatorService {
  public calculateARLexile(readingPractices: ReadingPractice[]): number | null {
    if (readingPractices.length === 0) {
      return null;
    }

    const recentPassedReadingPractices = readingPractices.filter(practice =>
      practice.product.toLowerCase() === 'ar' &&
      DateHelper.isWithinTwoWeeksOfToday(practice.mostRecentPracticedDate) &&
      !SkillTests.isStuckReadingPracticeActivity(practice)
    );

    if (recentPassedReadingPractices.length === 0) {
      return null;
    }

    const averageLexileLevel = LexileHelperService.getAverageLexileLevel(recentPassedReadingPractices);
    return averageLexileLevel;
  }


}
