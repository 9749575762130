import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { StudentContext } from 'src/app/shared/models/student.model';
import { ClassProducts } from 'src/app/shared/models/class.model';

@Component({
  selector: 'student-table',
  templateUrl: './student-table.component.html',
  styleUrls: ['./student-table.component.scss'],
})

export class StudentTableComponent implements OnInit, OnChanges {
  @Input() public students: StudentContext[] = [];
  @Input() public category: string = '';
  @Input() public classProducts!: ClassProducts;
  @Input() public showTakeAction: boolean = true;

  private readonly productNameToProductHeader: {[key: string]: string} = {
    'Freckle': 'Freckle',
    'Lalilo': 'Lalilo',
    'AR': 'AR',
    'MyON': 'myON',
    'Star': 'Star Adaptive',
    'CBM': 'CBM'
  }
  private readonly sortOrder = ['Freckle', 'Lalilo', 'AR', 'myON', 'Star Adaptive', 'CBM'];

  private readonly needsHelpingHandCategory: string = 'category-need-a-helping-hand';

  public skillsPracticeProductsHeader: string = '';
  public readingPracticeProductsHeader: string = '';
  public assessmentProductsHeader: string = '';
  public alertIconPath: string = '../../../../../../assets/red-alert-circle.svg';

  public constructor(
    private subjectService: SubjectService) { }

  async ngOnInit() {
    this.skillsPracticeProductsHeader = this.getSkillsPracticeProducts();
    this.readingPracticeProductsHeader = this.getReadingPracticeProducts();
    this.assessmentProductsHeader = this.getAssessmentProducts();
  }

  async ngOnChanges() {
    this.skillsPracticeProductsHeader = this.getSkillsPracticeProducts();
    this.readingPracticeProductsHeader = this.getReadingPracticeProducts();
    this.assessmentProductsHeader = this.getAssessmentProducts();
  }

  public categoryIsNeedsHelpingHand(): boolean {
    return this.category === this.needsHelpingHandCategory;
  }

  public selectedSubjectIsReading(): boolean {
    return this.subjectService.selectedSubject$.getValue() == SubjectTypes.READING;
  }

  private getSkillsPracticeProducts(): string {
    let apps: string[] = [];
    apps = this.classProducts.skillsPracticeProducts
                .map(app => this.productNameToProductHeader[app])
                .sort((a,b) => this.sortOrder.indexOf(a) - this.sortOrder.indexOf(b));

    if (apps.length > 0) {
      return apps.join(", ");
    }
    return 'No Skills Practice Products';
  }

  private getReadingPracticeProducts(): string {
    let apps: string[] = [];
    apps = this.classProducts.readingPracticeProducts
                .map(app => this.productNameToProductHeader[app])
                .sort((a,b) => this.sortOrder.indexOf(a) - this.sortOrder.indexOf(b));

    if (apps.length > 0) {
      return apps.join(", ");
    }
    return 'No Reading Practice Products';
  }

  private getAssessmentProducts(): string {
    let apps: string[] = [];
    apps = this.classProducts.assessmentProducts
                .map(app => this.productNameToProductHeader[app])
                .sort((a,b) => this.sortOrder.indexOf(a) - this.sortOrder.indexOf(b));
    if (apps.length > 0) {
      return apps.join(", ");
    }
    return 'No Assessment Products';
  }
}
