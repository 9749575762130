import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AvailableAppNavV2Service } from '../app-nav-v2/available-app-nav-v2.service';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class PendoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService,
    private availableAppNavV2Service: AvailableAppNavV2Service,
    private featureToggleService: FeatureToggleService) { }

  async initialize() {
    await this.userService.isLoggedIn();
    let userEntitlements = await this.getUserApps();
    const window = this.document.defaultView as any;
    // ambient pendo reference is available to us see index.html
    // login to pendo -> Subscription Settings -> Install Settings for documentation
    // type pendo.validateInstall() in the console to validate install
    if (window.pendo) {
      const pendo = window.pendo;
      pendo.initialize({
        visitor: {
          id: this.userService.getUserId(),
          userType: this.userService.getNextRole().toUpperCase(),
          entitlements: userEntitlements
        },
        account: {
          id: this.userService.getClientId()
        }
      });
      console.log('Pendo initialized');
    }
  }

  private async getUserApps() {
    if (await this.featureToggleService.isTrueAsync('use-new-launch')) {
      await this.availableAppNavV2Service.isLoaded();
      let appNav = this.availableAppNavV2Service.getAppNavV2();
      let appsSet = new Set<string>(appNav.map(app => app.appCode));
      let apps = [...appsSet];
      return apps;
    }

    else {
      return [];
    }
  }

  sendEvent(eventName: string, data: any) {
    const window = this.document.defaultView as any;
    if (window.pendo) {
      window.pendo.track(eventName, data);
    }
  }
}
