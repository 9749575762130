import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { GraphQLMiddlewareService } from './services/graphql-middleware/graphql-middleware.service';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { ContextSelector } from './home/context-selector/context-selector.component';
import { MaterialModule } from './shared/material/material.module';
import { ActivitySummaryComponent } from './home/activity-summary/activity-summary.component';
import { ActivitySummaryCategoryComponent } from './home/activity-summary/activity-summary-category/activity-summary-category.component';
import { StudentBubbleComponent } from './shared/student-bubble/student-bubble.component';
import { StudentBubbleStackComponent } from './shared/student-bubble-stack/student-bubble-stack.component';
import { StudentTableComponent } from './home/activity-summary/activity-summary-category/student-table/student-table.component';
import { StudentTableRowComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/student-table-row.component';
import { SkillDetailModalComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/skill-detail-modal/skill-detail-modal.component';
import { NoSummaryDataComponent } from './home/activity-summary/no-summary-data/no-summary-data.component';
import { LoadingAnimationComponent } from './shared/loading-animation/loading-animation.component';
import { TabSelectorComponent } from './home/tab-selector/tab-selector.component';
import { SkillsPracticeComponent } from './home/skills-practice/skills-practice.component';
import { SkillsPracticeListComponent } from './home/skills-practice/skills-practice-list/skills-practice-list.component';
import { InactiveCardComponent } from './home/skills-practice/skills-practice-list/inactive-card/inactive-card.component';
import { ActiveSkillCardComponent } from './home/skills-practice/skills-practice-list/active-skill-card/active-skill-card.component';
import { FilterSkillsComponent } from './home/skills-practice/skills-practice-list/filter-skills/filter-skills.component';
import { ActiveSkillCategoryComponent } from './home/skills-practice/skills-practice-list/active-skill-card/active-skill-category/active-skill-category.component';
import { IdleTimeoutModule } from './idle/idle-timeout.module';
import { StudentGroupingComponent } from './shared/student-grouping.component/student-grouping.component';
import { StudentIconComponent } from './shared/student-icon.component/student-icon.component';
import { SeenBeforeComponent } from './shared/seen-before/seen-before.component';
import { SkillDescriptionComponent } from './shared/skill-description/skill-description.component';
import { StudentSkillDetailModalComponent } from './shared/student-grouping.component/student-skill-detail-modal/student-skill-detail-modal.component';
import { SearchboxComponent } from './home/search/searchbox/searchbox.component';
import { SearchboxComponent as SearchboxV2Component } from './home/search-v2/searchbox/searchbox.component';
import { SearchFiltersComponent } from './home/search/search-filters/search-filters.component';
import { SearchFiltersComponent as SearchFiltersV2Component} from './home/search-v2/search-filters/search-filters.component';
import { SearchResultsComponent } from './home/search/search-results/search-results.component';
import { SearchResultsComponent as SearchResultsV2Component } from './home/search-v2/search-results/search-results.component';
import { SearchResultComponent } from './home/search/search-results/search-result/search-result.component';
import { SearchResultComponent as SearchResultV2Component } from './home/search-v2/search-results/search-result/search-result.component';
import { LeftNavComponent } from './shared/left-nav/left-nav.component';
import { AppLinkSelectorComponent } from './shared/left-nav/app-link-selector/app-link-selector.component';
import { AppLinkSelectorOptionComponent } from './shared/left-nav/app-link-selector/app-link-selector-option/app-link-selector-option.component';
import { LazyElementsModule } from '@angular-extensions/elements';
import { LogOutComponent } from './home/logout/logout.component';
import { OptOutDialogComponent } from './shared/left-nav/opt-out-dialog/opt-out-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterComponent } from './shared/footer/footer.component';
import { AssessmentDetailModalComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/assessment-detail-modal/assessment-detail-modal.component';
import { AssessmentDetailResultComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/assessment-detail-modal/assessment-detail-result/assessment-detail-result.component';
import { ReportSearchComponent } from './shared/left-nav/app-link-selector/report-search/report-search.component';
import { ProductDeepLinkComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/product-deep-link/product-deep-link.component';
import { ReadingPracticeDetailModalComponent } from './home/activity-summary/activity-summary-category/student-table/student-table-row/reading-practice-detail-modal/reading-practice-detail-modal.component';
import { TooltipComponent } from './shared/tooltip/tooltip.component';
import { ToggleSwitchComponent } from './shared/toggle-switch/toggle-switch.component';
import { SkillsPracticeAppLinkComponent } from './home/skills-practice/skills-practice-app-link/skills-practice-app-link.component';
import { AccessDeniedDialogComponent } from './shared/access-denied-dialog/access-denied-dialog.component';
import { DegradedComponent } from './home/degraded/degraded.component';
import { GlobalErrorHandler } from './services/global-error-handler/global-error-handler.service';
import { HeaderLoadingComponent } from './shared/header-loading/header-loading.component';
import { SuperstarsComponent } from './home/superstars/superstars.component';
import { SummerMathComponent } from './home/summer-math/summer-math.component';
import { SuperstarDataComponent } from './home/superstars/superstar-data/superstar-data.component';
import { SuperstarSelectCardModalComponent } from './home/superstars/superstar-select-card-modal/superstar-select-card-modal.component';
import { WeekDisplayComponent } from './shared/week-display/week-display.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { SuperstarPrintComponent } from './home/superstars/superstar-print/superstar-print.component';
import { NgxPrintModule } from 'ngx-print';
import { LeftNavIntroAnimationComponent } from './shared/intro-animation/left-nav-intro-animation/left-nav-intro-animation.component';
import { SearchIntroAnimationComponent } from './shared/intro-animation/search-intro-animation/search-intro-animation.component';
import { ActivitySummaryIntroAnimationComponent } from './shared/intro-animation/activity-summary-intro-animation/activity-summary-intro-animation.component';
import { AvailableToTeachersComponent } from './shared/available-to-teachers/available-to-teachers.component';
import { TakeActionModalComponentV2 } from './home/activity-summary/activity-summary-category/student-table/take-action-modal-v2/take-action-modal.component';
import { TakeActionLinksComponentV2 } from './home/activity-summary/activity-summary-category/student-table/take-action-links-v2/take-action-links.component';
import { SkillsPracticeTakeActionModalComponentV2 } from './home/skills-practice/skills-practice-list/active-skill-card/active-skill-category/skills-practice-take-action-modal-v2/skills-practice-take-action-modal.component';
import { NoRecommendationComponent } from './home/activity-summary/activity-summary-category/student-table/take-action-modal-v2/no-recommendation/no-recommendation.component';
import { TimeToPracticeRecommendationComponent } from './home/activity-summary/activity-summary-category/time-to-practice-recommendation/time-to-practice-recommendation/time-to-practice-recommendation.component';
import { ActivitySummaryTabComponent } from './home/activity-summary/activity-summary-tab/activity-summary-tab.component';
import { SetUpSavvasComponent } from './home/set-up-savvas/set-up-savvas.component';
import { RecommendedSkillCardComponent } from './home/skills-practice/skills-practice-list/recommended-skill-card/recommended-skill-card.component';
import { SearchInputComponent } from './home/search-v2/search-input/search-input.component';
import { SearchboxWrapperComponent } from './home/search/searchbox-wrapper.component';
import { SearchResultsWrapperComponent } from './home/search/search-results-wrapper.component';
import { MyONMadeTakeActionComponent } from './home/activity-summary/activity-summary-category/student-table/take-action-links-v2/myonMade-take-action-link.component';
import { EmptyStudentGroupsComponent } from './home/skills-practice/skills-practice-list/empty-student-groups/empty-student-groups.component';
import { EmptyStudentGroupsAnimationComponent } from './home/skills-practice/skills-practice-list/empty-student-groups/empty-student-groups-animation/empty-student-groups-animation.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LogOutComponent,
    ContextSelector,
    ActivitySummaryComponent,
    SkillsPracticeComponent,
    ActivitySummaryCategoryComponent,
    StudentBubbleComponent,
    StudentBubbleStackComponent,
    StudentIconComponent,
    StudentGroupingComponent,
    StudentTableComponent,
    StudentTableRowComponent,
    SkillDetailModalComponent,
    NoSummaryDataComponent,
    LoadingAnimationComponent,
    TabSelectorComponent,
    SkillsPracticeListComponent,
    InactiveCardComponent,
    ActiveSkillCardComponent,
    FilterSkillsComponent,
    ActiveSkillCategoryComponent,
    TakeActionModalComponentV2,
    TakeActionLinksComponentV2,
    SeenBeforeComponent,
    SkillsPracticeTakeActionModalComponentV2,
    SkillDescriptionComponent,
    StudentSkillDetailModalComponent,
    SearchboxComponent,
    SearchboxV2Component,
    SearchFiltersComponent,
    SearchFiltersV2Component,
    SearchResultsComponent,
    SearchResultsV2Component,
    SearchResultComponent,
    SearchResultV2Component,
    LeftNavComponent,
    HeaderLoadingComponent,
    AppLinkSelectorComponent,
    AppLinkSelectorOptionComponent,
    OptOutDialogComponent,
    FooterComponent,
    AssessmentDetailModalComponent,
    AssessmentDetailResultComponent,
    ReportSearchComponent,
    ProductDeepLinkComponent,
    ReadingPracticeDetailModalComponent,
    TooltipComponent,
    ToggleSwitchComponent,
    SkillsPracticeAppLinkComponent,
    AccessDeniedDialogComponent,
    DegradedComponent,
    SuperstarsComponent,
    SuperstarDataComponent,
    SummerMathComponent,
    SuperstarSelectCardModalComponent,
    WeekDisplayComponent,
    SuperstarPrintComponent,
    LeftNavIntroAnimationComponent,
    SearchIntroAnimationComponent,
    ActivitySummaryIntroAnimationComponent,
    AvailableToTeachersComponent,
    NoRecommendationComponent,
    TimeToPracticeRecommendationComponent,
    ActivitySummaryTabComponent,
    SetUpSavvasComponent,
    RecommendedSkillCardComponent,
    EmptyStudentGroupsComponent,
    EmptyStudentGroupsAnimationComponent,
    SearchInputComponent,
    SearchboxWrapperComponent,
    SearchResultsWrapperComponent,
    MyONMadeTakeActionComponent
  ],
  imports: [
    BrowserModule,
    LazyElementsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ApolloModule,
    IdleTimeoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxPrintModule
  ],
  providers: [
    { provide  : DatePipe },
    { provide  : TitleCasePipe },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink, mw: GraphQLMiddlewareService) => {
        return {
          default: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: environment.graphqlUrl,
            }),
          },
          cloud: {
            cache: new InMemoryCache(),
            link: mw.cachedDirectiveMiddleware.concat(
              httpLink.create({ uri: environment.cloudGraphqlUrl })
            ),
          },
        };
      },
      deps: [HttpLink, GraphQLMiddlewareService]
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
