import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SkillDetailData, SkillDetailModalComponent } from 'src/app/home/activity-summary/activity-summary-category/student-table/student-table-row/skill-detail-modal/skill-detail-modal.component';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { Skill } from 'src/app/shared/models/skill.model';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { AssessmentDetailData, AssessmentDetailModalComponent } from 'src/app/home/activity-summary/activity-summary-category/student-table/student-table-row/assessment-detail-modal/assessment-detail-modal.component';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { MaxModalDimensionsService } from 'src/app/services/max-modal-dimensions/max-modal-dimensions.service';
import { Assessment } from 'src/app/shared/models/assessment.model';
import { StarService } from 'src/app/services/star/star.service';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { ReadingPractice, ReadingPracticeSummary } from 'src/app/shared/models/reading-practice.model';
import { ReadingPracticeDetailData, ReadingPracticeDetailModalComponent } from './reading-practice-detail-modal/reading-practice-detail-modal.component';
import { ClassProducts } from 'src/app/shared/models/class.model';
import { TakeActionModalComponentV2 } from '../take-action-modal-v2/take-action-modal.component';
import { TakeActionDataV2 } from '../take-action-modal-v2/take-action-data';

@Component({
  selector: '[student-table-row]',
  templateUrl: './student-table-row.component.html',
  styleUrls: ['./student-table-row.component.scss'],
})
export class StudentTableRowComponent implements OnInit {
  @Input() public classProducts!: ClassProducts;
  @Input() public template: string = 'desktopLayout';
  @Input() public skillsPracticeProductsHeader: string = '';
  @Input() public readingPracticeProductsHeader: string = '';
  @Input() public assessmentProductsHeader: string = '';
  @Input() public category: string = '';
  @Input() public students: StudentContext[] = [];
  @Input() public studentContext: StudentContext = {
    latestAssessment: {
      completedDate: '',
      districtIsProficient: false,
      renaissanceIsProficient: false,
      assessmentTimeInSeconds: 0,
      subject: '',
      productId: '',
      assessmentType: '',
      percentileRank: 0,
      scaledScore: 0,
      currentSGP: 0,
      cbmAssessmentId: '',
      cbmGradeId: '',
      accuracy: 0,
      correctPerMinute: 0,
      cbmBenchmarkCategory: '',
      lexile: null
    },
    assessments: [],
    skills: [],
    lastSkillPracticedDate: undefined,
    minutesSpentPerDay: 0,
    classProductUsage: {
      hasPracticeActivity: false,
      hasAssessmentActivity: false,
      hasPracticeProductAssigned: false,
      hasReadingActivity: false
    },
    firstName: '',
    lastName: '',
    grade: '',
    renaissanceId: '',
    schoolRenaissanceId: '',
    studentIds: {
      freckleStudentId: ''
    },
    studentClassIds: {
      freckleClassId: ''
    },
    readingPractices: [],
    readingPracticeSummary: {} as ReadingPracticeSummary,
    lexileLevel: null
  };
  @Input() public showTakeAction: boolean = true;
  public aggregateSkills: Skill[] = [];
  public subject$ = new BehaviorSubject(SubjectTypes.MATH);
  public useAssessmentPopup: boolean = false;
  public useReadingPracticeColumn: boolean = false;
  public alertIconPath: string = '../../../../../../assets/red-alert-circle.svg';

  public constructor(
    public subjectService: SubjectService,
    public breakpointObserver: BreakpointObserver,
    public matDialog: MatDialog,
    public featureToggleService: FeatureToggleService,
    public maxModalDimensions: MaxModalDimensionsService,
    private starService: StarService) { }

  ngOnInit() {
    this.subject$ = this.subjectService.selectedSubject$;
    //aggregate the student's practiced skills
    this.aggregateSkills = SkillAggregate.aggregate(this.studentContext.skills);
  }

  public async openSkillDetail() {
    // if we get no skills don't open the modal
    if (this.aggregateSkills.length > 0) {

      let sortedSkills: Skill[] = [...this.aggregateSkills].sort((a: Skill, b: Skill) => {
        const aAccuracy = a.correctAnswers / a.totalAnswers;
        const bAccuracy = b.correctAnswers / b.totalAnswers;

        // primary sort by accuracy (lowest to highest)
        if (aAccuracy < bAccuracy) {
          return -1;
        }
        else if (aAccuracy > bAccuracy) {
          return 1;
        }

        // secondary sort by # items attempted (highest to lowest)
        if (a.totalAnswers < b.totalAnswers) {
          return 1;
        }
        else if (a.totalAnswers > b.totalAnswers) {
          return -1;
        }

        // tertiary sort by alphabetical skill title
        if (a.skillShortName < b.skillShortName) {
          return -1;
        }
        else if (a.skillShortName > b.skillShortName) {
          return 1;
        }

        // no other sort
        return 0;
      })

      await this.featureToggleService.sendTrackEvent('skill-detail-button-click');
      this.matDialog.open<SkillDetailModalComponent, SkillDetailData>(SkillDetailModalComponent, {
        maxWidth: this.maxModalDimensions.maxWidth(),
        maxHeight: this.maxModalDimensions.maxHeight(),
        restoreFocus: true,
        data: {
          student: this.studentContext,
          skills: sortedSkills
        }
      });
    }
  }

  public async openAssessmentDetail() {
    await this.featureToggleService.sendTrackEvent('assessment-detail-button-click');
    this.matDialog.open<AssessmentDetailModalComponent, AssessmentDetailData>(AssessmentDetailModalComponent, {
      maxHeight: this.maxModalDimensions.maxHeight(),
      restoreFocus: true,
      data: {
        student: this.studentContext,
        assessments: this.studentContext.assessments
      }
    });
  }

  public async openReadingPracticeModal() {
    await this.featureToggleService.sendTrackEvent('reading-practice-detail-button-click');
    this.matDialog.open<ReadingPracticeDetailModalComponent, ReadingPracticeDetailData>(ReadingPracticeDetailModalComponent, {
      maxHeight: this.maxModalDimensions.maxHeight(),
      restoreFocus: true,
      data: {
        student: this.studentContext,
        readingPractices: this.studentContext.readingPractices,
        currentWordCount: this.studentContext.readingPracticeSummary.currentWordCount,
        previousWordCount: this.studentContext.readingPracticeSummary.previousWordCount
      }
    });
  }

  public async openTakeActionModal(students: StudentContext[]) {
    await this.featureToggleService.sendTrackEvent('take-action-button-click');

    this.matDialog.open<TakeActionModalComponentV2, TakeActionDataV2>(TakeActionModalComponentV2, {
      maxWidth: this.maxModalDimensions.maxWidth(),
      maxHeight: this.maxModalDimensions.maxHeight(),
      restoreFocus: true,
      data: {
        student: this.studentContext,
        students: students,
        classProducts: this.classProducts,
        category: this.category
      }
    });
  }

  public displayNoSkillsPractice(): string {
    let productsToDisplay = this.classProducts.skillsPracticeProducts;

    if (productsToDisplay.length > 0) {
      return 'No ' + productsToDisplay.join(' or ') + ' logins';
    }
    return '--';
  }

  public assessmentWithinTwoWeeks(): boolean {
    const { completedDate } = this.studentContext.latestAssessment
    return DateHelper.isWithinTwoWeeksOfToday(completedDate);
  }

  public assessmentShortTestTime(): boolean {
    const { assessmentTimeInSeconds } = this.studentContext.latestAssessment
    return SkillTests.isShortTestTime(this.subject$.getValue(), assessmentTimeInSeconds);
  }

  public assessmentProficient(): boolean {
    const { latestAssessment: assessment } = this.studentContext;
    return this.starService.isAssessmentProficient(assessment);
  }

  public assessmentAlert(): boolean {
    return !this.assessmentProficient() && this.assessmentWithinTwoWeeks();
  }

  public studentHasAssessments() {
    const { latestAssessment: assessment } = this.studentContext;
    if (assessment == null) {
      return false;
    }

    return (assessment.districtIsProficient != null || assessment.renaissanceIsProficient != null);
  }

  public studentHasReadingPractice() {
    const { readingPractices: readingPractices } = this.studentContext;
    return readingPractices != null && readingPractices.length > 0;
  }

  public studentHasSkillsPractice() {
    return this.studentContext.skills.length > 0;
  }

  public classHasReadingPracticeProducts() {
    return this.classProducts.readingPracticeProducts.length > 0;
  }

  public classHasAssessmentProducts(): boolean {
    return this.classProducts.assessmentProducts.length > 0;
  }

  public classHasSkillsPracticeProducts(): boolean {
    return this.classProducts.skillsPracticeProducts.length > 0;
  }

  public readingPracticeAlert() {
    const { averageQuizScore: averageQuizScore } = this.studentContext.readingPracticeSummary;
    return SkillTests.hasStuckReadingPractice(averageQuizScore);
  }

  public displayAverageReadingPracticePercentCorrect(averageQuizScore: number): string {
    if (averageQuizScore == null) {
      return 'No quizzes';
    }
    return `${Math.round(averageQuizScore * 100)}% average`;
  }

  public displayBookCountText(readingPractices: ReadingPractice[]): string {
    let bookCount = readingPractices.length;

    if (bookCount == 1) {
      return `${bookCount} book`;
    }
    else {
      return `${bookCount} books`;
    }
  }

  public getNumStuckReadingPracticesText(readingPractices: ReadingPractice[]): string {
    let numStuckReadingPractices = 0;

    readingPractices?.forEach(practice => {
      if (SkillTests.isStuckReadingPracticeActivity(practice)) {
        numStuckReadingPractices++;
      }
    });

    return this.getReadingPracticeText(numStuckReadingPractices);
  }

  private getReadingPracticeText(numStuckReadingPractices: number): string {
    if (numStuckReadingPractices == 1) {
      return `${numStuckReadingPractices} quiz`;
    }
    else {
      return `${numStuckReadingPractices} quizzes`;
    }
  }

  public practiceAlert(): boolean {
    return this.hasStuckSkill();
  }

  public hasStuckSkill(): boolean {
    if (this.aggregateSkills.length == 0) {
      return false;
    }
    const minSkillAccuracyRate = SkillTests.getEffectiveMinSkillAccuracyRate(this.aggregateSkills);
    return SkillTests.isStuckSkillAccuracyRate(minSkillAccuracyRate);
  }

  public getNumStuckSkillsText(skills: Skill[] | null): string {
    let numStuckSkills = 0;

    skills?.forEach(skill => {
      if (SkillTests.isStuckSkill(skill)) {
        numStuckSkills++;
      }
    });

    return this.getSkillsText(numStuckSkills);
  }

  public getSkillsText(numSkills: number): string {
    if (numSkills == 1) {
      return `${numSkills} skill`;
    }
    else {
      return `${numSkills} skills`;
    }
  }

  public displayDate(dateToDisplay: string | null): string {
    if (dateToDisplay != null) {
      return DateHelper.parseDate(dateToDisplay).toLocaleString();
    }
    return '';
  }

  public displayAssessments(assessments: Assessment[]): string {
    assessments = assessments.filter(x => x.productId !== 'AR');

    if (assessments.length == 1) {
      return '1 test';
    }
    return `${assessments.length} tests`;
  }

  public displayMinutesSpentPerDay(minutesSpentPerDay: number): string {
    if (minutesSpentPerDay == null || minutesSpentPerDay == 0) {
      return '<1';
    }
    return minutesSpentPerDay.toString();
  }

  public selectedSubjectIsReading(): boolean {
    return this.subject$.getValue() == SubjectTypes.READING;
  }
}
