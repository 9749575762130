import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { AppNavItemIds, AppNavItem } from '../models/app-nav-item.model';
import { DOCUMENT } from '@angular/common';
import { AppLinkSelectorComponent } from './app-link-selector/app-link-selector.component';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { MatDialog } from '@angular/material/dialog';
import { OptOutDialogComponent } from './opt-out-dialog/opt-out-dialog.component';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';
import { Subscription } from 'rxjs';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { AppNavV2Service } from 'src/app/services/app-nav-v2/app-nav-v2.service';
import { NextLeaderLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/nextleader-link-generator-v2.service';
import { AppNavDynamicLinkingService } from 'src/app/services/app-nav-v2/app-nav-dynamic-linking.service';

export interface SideNavItem {
  appNavItem: AppNavItem;
  stateIsOpen: boolean;
  disableLinkSelectorAccordions?: boolean;
}

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit, OnDestroy {
  @ViewChildren(AppLinkSelectorComponent)
  viewChildren!: AppLinkSelectorComponent[];
  @Input() sendFeedbackId = 'send-feedback';

  public availableApps: AppNavItem[] = [];
  public availableData: AppNavItem[] = [];
  public window: Window;
  public homeUri: string = '';
  public optOutUri: string = '';
  public sideNavs: SideNavItem[] = [];
  public appNavDegraded: boolean = false;
  public hideOptOut: boolean = false;
  public isIntroAnimationComplete: boolean = false;
  public playFadeInAnimation: boolean = false;
  public isAdmin: boolean = false;
  public availableV2Apps: AppNavItem[] = [];
  public otherAvailableApps: AppNavItem[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private _userService: UserService,
    public featureToggleService: FeatureToggleService,
    private _adminHandlerService: AdminHandlerService,
    private _introAnimationService: IntroAnimationService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _appNavV2Service: AppNavV2Service,
    private _launchControlService: LaunchControlService,
    private _nextLeaderLinkGeneratorV2Service: NextLeaderLinkGeneratorV2Service,
    private appNavDynamicLinking: AppNavDynamicLinkingService,
  ) {
    this.window = this.document.defaultView as Window;
  }

  async ngOnInit() {
    let subscription = this._introAnimationService.leftNavAnimationStatus$.subscribe((animationStatus: IntroAnimationStatus | undefined) => {
      if (animationStatus) {
        this.checkAnimationStatus(animationStatus);
      }
    });
    this.subscriptions.push(subscription);
    //Forces a change detection on animation status to prevent NG100: ExpressionChangedAfterItHasBeenCheckedError
    this._changeDetectorRef.detectChanges();

    await this.setAppV2Data();

    this.otherAvailableApps = await this._appNavV2Service.getOtherAvailableApps();

    this.homeUri = this._userService.getHomeUri();

    this.optOutUri = `${this.homeUri}api/beta/optout`;
    //change optOutUri if renaissance next is released
    let betaIsOver = this._userService.getBetaIsOver();
    if (betaIsOver) {
      this.optOutUri = `${this.homeUri}api/next/optout`;
    }
    this.hideOptOut = this._userService.getHideOptOut();
    this.isAdmin = this._adminHandlerService.isAdmin();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public pendoTrackFeedback() {
    const window = this.document.defaultView as any;
    if (window.pendo) {
      const pendo = window.pendo;
      pendo.track('Feedback');
    }
  }

  public showOptOutModal(event: MouseEvent) {
    event.preventDefault();

    this.dialog.open(OptOutDialogComponent, {
      data: {
        optOutUri: this.optOutUri,
      },
    });
  }

  public directAppLinkClick(app: AppNavItem, type: string) {
    if (type === 'v1') {
      this.navigate(app);

    } else if (type === 'v2') {
      this.navigateV2(app);
    } else if (type === 'other' && app?.navigateServiceName && app?.navigateMethodName) {
      this.appNavDynamicLinking.navigateToDynamicApp(app);
    }
  }

  public navigate(app: AppNavItem) {
    // Sidenav
    let maybeSideNav = this.sideNavs.find((x) => x.appNavItem === app);
    if (maybeSideNav) {
      maybeSideNav.stateIsOpen = true;
    } else if ((!app.taskId || !app.applicationId) && app.url) {
      this.window.location = app.url;
    } else {
      this.window.location = `${this.homeUri}launch?task=${app.taskId}&applicationId=${app.applicationId}`;
    }
  }

  public isRenU(app: AppNavItem): boolean {
    return app.id === AppNavItemIds.RENU;
  }

  public closeSidenav() {
    // This moves focus to the default on page load so the sidenav icon doesn't stay in color
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    let currentSideNavId;;
    // Close all side navs
    for (let sideNav of this.sideNavs) {
      if (sideNav.stateIsOpen) {
        currentSideNavId = sideNav.appNavItem.id;
      }
      sideNav.stateIsOpen = false;
    }

    // Close all open panels
    if (this.viewChildren && this.viewChildren.length > 0) {
      this.viewChildren.forEach((child) => {
        child.clearPanelState();
      });
    }

    // Move focus to the current button
    document.getElementById(`${currentSideNavId}`)?.focus();
  }

  public hasSidenav(app: AppNavItem): boolean {
    if (this.sideNavs.find((x) => x.appNavItem === app)) {
      return true;
    }
    return false;
  }

  public isSidenavExpanded(app: AppNavItem): boolean {
    for (let sideNav of this.sideNavs) {
      if (sideNav.appNavItem.id === app.id) {
        return sideNav.stateIsOpen;
      }
    }
    return false;
  }

  public goToLeaderRedirectUrl(): void {
    let appCode = this._nextLeaderLinkGeneratorV2Service.getNextLeaderAppCode();
    this._launchControlService.launchApp(appCode);
  }

  private getSideNavBlades(apps: AppNavItem[], dataApps: AppNavItem[]) {
    let sideNavItems: SideNavItem[] = [];
    let allApps = [...apps, ...dataApps];
    for (let topLevelAppNav of allApps) {
      if (topLevelAppNav.children) {
        // AR and REPORTS disable accordions
        let disableLinkSelectorAccordions =
          topLevelAppNav?.id === AppNavItemIds.AR ||
          topLevelAppNav?.id === AppNavItemIds.REPORTS;
        let sideNavItem: SideNavItem = {
          appNavItem: topLevelAppNav,
          stateIsOpen: false,
          disableLinkSelectorAccordions: disableLinkSelectorAccordions,
        };
        sideNavItems.push(sideNavItem);
      }
    }

    return sideNavItems;
  }

  public optOutRedirect() {
    this.window.location.href = this.optOutUri;
  }

  public checkAnimationStatus(animationStatus: IntroAnimationStatus) {
    if (animationStatus.complete) {
      this.isIntroAnimationComplete = true;
    }
    if (animationStatus.enabled) {
      this.playFadeInAnimation = true;
      //turn off animation after it has been played
      setTimeout(() => { this.playFadeInAnimation = false }, 400);
    }
  }

  // offsets each element by its order in the list and the height of the element
  // They will all start at the position of the first element before sliding down to their final spot
  public getAppNavTranslateOffset(appNavItem: AppNavItem) {
    let linkLogoContainer = this.document.getElementsByClassName('link-logo-container');
    let linkLogoStyle = getComputedStyle(linkLogoContainer[0]);
    let totalHeight = parseInt(linkLogoStyle.marginBottom) + parseInt(linkLogoStyle.height);
    let index = this.availableApps.findIndex(item => item == appNavItem);

    return `${index * -totalHeight}px`;
  }

  // App nav v2 functions
  public navigateV2(app: AppNavItem) {
    // Sidenav
    let maybeSideNav = this.sideNavs.find((x) => x.appNavItem === app);
    if (maybeSideNav) {
      maybeSideNav.stateIsOpen = true;
    }
    else if (app.url) {
      this.window.location = app.url;
    }
    else {
      if (app.appCode) {
        this._launchControlService.launchApp(app.appCode, app.deepLinkId, false, true);
      }
    }
  }

  private async setAppV2Data() {
    await this._appNavV2Service.initializeAppNavV2();
    this.appNavDegraded = this._appNavV2Service.getIsDegraded();
    if (this.appNavDegraded == false) {
      this.availableV2Apps = await this._appNavV2Service.getAvailableV2Apps();
      this.availableData = this._appNavV2Service.getAvailableV2MyData();
      this.generateLeftNav();
    }
    else {
      this.availableV2Apps = this._appNavV2Service.getAppsForDegradedMode();
      this.generateLeftNav();
    }
  }
  private generateLeftNav() {
    this.sideNavs = this.getSideNavBlades(
      this.availableV2Apps,
      this.availableData
    );
  }
}
