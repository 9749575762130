import { Component } from '@angular/core';

@Component({
  selector: 'app-summer-math',
  templateUrl: './summer-math.component.html',
  styleUrls: ['./summer-math.component.scss'],
})
export class SummerMathComponent {
  courseId: string = '';
  summerPlanData: any[] = [];

  constructor() {
    this.setSummerPlanData();
  }

  setSummerPlanData() {
    this.summerPlanData = [
      {
        weekData: { sno: 1, data: 'Addition and Subtraction' },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Rounding to 10 & 100',
              pendoId: 'pendo-rounding-to-10-and-100',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Fluently add within 1,000',
              pendoId: 'pendo-fluently-add-within-1000',
            },
            frackleActivity2: { data1: 'Adaptive Practice:', data2: 'Base 10' },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Fluently subtract within 1,000',
              pendoId: 'pendo-fluently-subtract-within-1000',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Base 10',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Throughout the day, identify numbers around you. Round those numbers to the nearest 10 or 100.',
          option2Data:
            "Play Monopoly™ and be the banker! You'll have lots of opportunity to add and subtract.",
        },
      },
      {
        weekData: {
          sno: 2,
          data: 'Multiplication and Division',
          data2: 'Part 1',
        },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Intro to Multiplication',
              pendoId: 'pendo-intro-to-multiplication',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Basic Multiplication',
              pendoId: 'pendo-basic-multiplication',
            },
            frackleActivity2: { data1: 'Adaptive Practice:', data2: 'Base 10' },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Basic Division',
              pendoId: 'pendo-basic-division',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Operations and Algebraic Thinking',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Create a multiplication memory game. On one set of index cards, write a multiplication fact; on another set of cards, write the product. Put all the cards facedown and find facts and products in a memory-style game.',
          option2Data:
            'Look for opportunities to share a group of objects among friends. Consider sharing a handful of candy, pennies, or a snack, ensuring that you and several friends have the same amount.',
        },
      },
      {
        weekData: {
          sno: 3,
          data: 'Multiplication and Division',
          data2: 'Part 2',
        },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Mult/Division within 100',
              pendoId: 'pendo-mult-division-within-100',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Unknowns in Mult/Division',
              pendoId: 'pendo-unknowns-in-mult-division',
            },
            frackleActivity2: {
              data1: 'Adaptive Practice:',
              data2: 'Operations and Algebraic Thinking',
            },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Multiplication Properties',
              pendoId: 'pendo-multiplication-properties',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Operations and Algebraic Thinking',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Using a standard card deck (A:1, K:0, remove J, Q) You and a partner each flip over a card, the person who finds the product of the numbers first wins both cards.',
          option2Data:
            'Walk through the food store. Pick up a few of your favorite items and identify “how many per box/container”. For example, if one serving size of cookies is 3 and there are 12 servings per box, how many cookies can you expect per box?',
        },
      },
      {
        weekData: { sno: 4, data: 'Measurement/ Data and Geometry' },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Find Area by Tiling',
              pendoId: 'pendo-find-area-by-tiling',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Multiply to Find Area',
              pendoId: 'pendo-multiply-to-find-area',
            },
            frackleActivity2: {
              data1: 'Adaptive Practice:',
              data2: 'Measurement & Data',
            },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Perimeter',
              pendoId: 'pendo-perimeter',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Measurement & Data',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Draw a fun image on graph paper. Then find the area of that image.',
          option2Data:
            'Measure and find the perimeter of a room of your choice.',
        },
      },
      {
        weekData: { sno: 5, data: 'Introduction to Fractions' },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Partition Fractions',
              pendoId: 'pendo-partition-fractions',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'What is a Fraction?',
              pendoId: 'pendo-what-is-a-fraction',
            },
            frackleActivity2: {
              data1: 'Adaptive Practice:',
              data2: 'Fractions',
            },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'Unit Fractions on a Number Line',
              pendoId: 'pendo-unit-fractions-on-a-number-line',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Fractions',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Grab a handful of multi-color items (cereal/candy/buttons/Legos™). Identify what fraction of the total is represented by each color.',
          option2Data:
            'Go on a Fraction Scavenger Hunt. See how many objects around you include fractions. Set a goal, like finding 5 or 10 fractions in your house.',
        },
      },
      {
        weekData: { sno: 6, data: 'Bring it Together' },
        middleData: [
          {
            day: 1,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'DOK 3.OA.5',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 2,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'DOK 3.MD.7B',
            },
            frackleActivity2: {
              data1: 'Adaptive Practice:',
              data2: 'Measurement & Data',
            },
          },
          {
            day: 3,
            frackleActivity1: {
              data1: 'Targeted Practice:',
              data2: 'DOK 3.G.2',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
          {
            day: 4,
            frackleActivity1: {
              data1: 'Adaptive Practice:',
              data2: 'Base 10',
            },
            frackleActivity2: { data1: 'Fact Practice', data2: '' },
          },
        ],
        suggestionForFamilyData: {
          option1Data:
            'Domino addition/multiplication - Gather a group of dominos. Select one domino and find the sum and product of the numbers represented on each side. As you get more advanced, build computation problems using multiple dominoes.',
          option2Data:
            'Create a set of fraction manipulatives using whatever materials are handy (construction paper, building blocks, pool noodles). Identify the whole, then create fractions like 1/6, ¼, 1/3, ½.',
        },
      },
    ];
  }
}
