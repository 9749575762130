import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AppNavItemConfigurationService } from '../app-nav/app-nav-item-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class EducatorPortalInterappRedirectService {

  constructor(
    private userService: UserService,
    private appNavService: AppNavItemConfigurationService) { }

  public getLaunchAndRedirectUrl(taskId: string, applicationId: string, redirectUrl: string) {
    let encodedRedirectUrl = encodeURIComponent(redirectUrl);

    let educatorPortalBase = this.userService.getHomeUri();

    if (this.userService.getClientId() === `lmb2ourotest`) {
      educatorPortalBase = `https://global-zone05.renaissance-go.com/educatorportal/`
    }

    let launchUrl = `${educatorPortalBase}launch/?task=${taskId}&applicationId=${applicationId}&interAppContext=${encodedRedirectUrl}`;

    return launchUrl;
  }

  public getLaunchAndRedirectDeepLinkUrl(taskId: string, applicationId: string, deepLinkId: string) {
    let educatorPortalBase = this.userService.getHomeUri();

    if (this.userService.getClientId() === `lmb2ourotest`) {
      educatorPortalBase = `https://global-zone05.renaissance-go.com/educatorportal/`
    }

    let launchUrl = `${educatorPortalBase}launch/?task=${taskId}&applicationId=${applicationId}&deepLinkId=${deepLinkId}`;

    return launchUrl;
  }

  public getLaunchUrl(taskId: string, applicationId: string) {
    let educatorPortalBase = this.userService.getHomeUri();

    if (this.userService.getClientId() === `lmb2ourotest` && !this.isNextLeaderLaunch(applicationId)) {
      educatorPortalBase = `https://global-zone05.renaissance-go.com/educatorportal/`
    }

    let launchUrl = `${educatorPortalBase}launch/?task=${taskId}&applicationId=${applicationId}`;

    return launchUrl;
  }

  private isNextLeaderLaunch(applicationId: string) {
    return applicationId === this.appNavService.AppId.renAnalytics
      || applicationId === this.appNavService.AppId.schoolZillaStarter;
  }
}
