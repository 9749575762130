import { Injectable } from '@angular/core';
import { ClassContext } from 'src/app/shared/models/class.model';
import { ProductAppCodes } from '../product-info/product-info.service';
import { StarPhonicsLinkGeneratorV2Service } from './star-phonics-link-generator-v2.service';
import { MyonLinkGeneratorV2Service } from './myon-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from './lalilo-link-generator-v2.service';
import { FreckleLinkGeneratorV2Service } from './freckle-link-generator-v2.service';

@Injectable({
  providedIn: 'root'
})
export class ReportLinkGeneratorV2Service {

  constructor(
    private _starPhonicsLinkGeneratorV2Service: StarPhonicsLinkGeneratorV2Service,
    private _myonLinkGeneratorV2Service: MyonLinkGeneratorV2Service,
    private _laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private _freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service
  ) { }
  public async generateDeepLink(appCode: string, currentClass: ClassContext, url: string = '')
  {
    let deepLink = '';
    switch (appCode) {
      case ProductAppCodes.Freckle:
        if (url) {
          deepLink = this._freckleLinkGeneratorV2Service.getReportsPageDeepLink(currentClass.students, url);
        }
        else {
          deepLink = this._freckleLinkGeneratorV2Service.getReportsPageDeepLink(currentClass.students);
        }
        break;
      case ProductAppCodes.Lalilo:
        deepLink =  await this._laliloLinkGeneratorV2Service.getLaliloPageDeepLink(url);
        break;
      case ProductAppCodes.Myon:
        deepLink = this._myonLinkGeneratorV2Service.getReportsPageDeepLink();
        break;
      case ProductAppCodes.StarPhonics:
        deepLink = this._starPhonicsLinkGeneratorV2Service.getReportsPageDeepLink();
        break;
      default:
        throw new Error('Invalid tile link type');
    }
    return deepLink;
  }
}
