<div *ngIf="showIntroAnimation" [class.slide-up]="fadeOut" class="fade-in-ani">
  <div class="animation-textbox">
    <div class="text-container">
      <ng-container *ngIf="!readyToGo">
        <div class="summary-header">Almost done</div>
        <div class="summary-message">We’re getting your students’ activity and individualized recommendations..</div>
      </ng-container>
      <div *ngIf="readyToGo" class="ready-to-go-text ready-to-go-ani">You're ready to go!</div>
    </div>
    <object
      *ngIf="!readyToGo"
      role="figure"
      class="student-bubble-animation"
      alt="Welcome Teacher Loading Animation"
      aria-label="Welcome Teacher Loading Animation"
      data="../../../../assets/intro-animations/summary-intro-animation.svg">
    </object>
  </div>
</div>
