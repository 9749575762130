import { Component } from '@angular/core';
import { FeatureToggleService } from '../services/feature-toggle/feature-toggle.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  public teacherJourneyText = "Renaissance Teacher Insights";
  private teacherJourneyTitleText = "Renaissance Teacher Insights";
  private teacherJourneySearchResultsText = "Search Results";
  
  constructor(featureToggleService: FeatureToggleService, private router: Router) {
    featureToggleService.isTrueAsync('tj-sabotage-javascript-error').then(forceFail => {
      if (forceFail) {
        throw new Error('force fail from sabotage toggle');
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route: any) => {
        console.log(route?.url);
        if (route?.url) {
          let url = route.url;
          if (url.includes('search')) {
            this.teacherJourneyText = this.teacherJourneySearchResultsText;
          } else {
            this.teacherJourneyText = this.teacherJourneyTitleText;
          }
        }
      });
  }
}
