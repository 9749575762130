import { Injectable } from "@angular/core";
import { Assessment } from "src/app/shared/models/assessment.model";

@Injectable({
  providedIn: 'root'
})
export class StarService {
  public isAssessmentProficient(assessment: Assessment) : boolean {
    if (assessment == null) {
      return false;
    }

    if (this.isStarCbm(assessment.productId)) {
      return this.isCbmProficient(assessment);
    }

    //This check is for STAR assessments with no norming data
    if (!this.isStarCbm(assessment.productId) && assessment.percentileRank == null) {
      return true;
    }

    return (assessment.districtIsProficient == true) || (assessment.districtIsProficient == null && assessment.renaissanceIsProficient == true);
  }

  public isStarCbm(productId: string): boolean {
    let starCBMProducts = ['STAR_CBM_MATH', 'STAR_CBM_READING', 'STAR_CBM_LECTURA'];

    return starCBMProducts.includes(productId);
  }

  public isCbmProficient(assessment: Assessment): boolean {
    if (assessment.cbmBenchmarkCategory === 'At/Above Benchmark' || assessment.cbmBenchmarkCategory === 'Satisfactory/Optimal' || assessment.cbmBenchmarkCategory == null) {
      return true;
    }

    return false;
  }

}
