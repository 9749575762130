import { EducatorPortalInterappRedirectService } from '../educator-portal-interapp-redirect-service/educator-portal-interapp-redirect-service';
import { AppNavItemConfigurationService } from '../app-nav/app-nav-item-configuration.service';
import { Injectable } from '@angular/core';
import { AppNavItemService } from '../app-nav/app-nav-item.service';

@Injectable({
  providedIn: 'root'
})
export class NextLeaderLinkGeneratorService {

  constructor(
    private educatorPortalInterappRedirectService: EducatorPortalInterappRedirectService,
    private appNav: AppNavItemConfigurationService,
    private appNavItemService: AppNavItemService
  ) { }

 

  public getNextLeaderPageLink() {
    let hasRenAnalytics = this.appNavItemService.hasApp([this.appNav.AppId.renAnalytics]);
    if(hasRenAnalytics) {
        return this.getEducatorPortalRenAnalyticsRedirectUrl();
    }

    let hasSZStarter = this.appNavItemService.hasApp([this.appNav.AppId.schoolZillaStarter]);
    if(hasSZStarter) {
        return this.getEducatorPortalSchoolZillaStarterRedirectUrl();
    }

    //TODO: error handling when we get a chance
    return this.getEducatorPortalSchoolZillaStarterRedirectUrl();
  }

  private getEducatorPortalRenAnalyticsRedirectUrl() {
    let taskId = '4852';
    let applicationid = this.appNav.AppId.renAnalytics;

    return this.educatorPortalInterappRedirectService.getLaunchUrl(taskId, applicationid);
  }

  private getEducatorPortalSchoolZillaStarterRedirectUrl() {
    let taskId = '4850';
    let applicationid = this.appNav.AppId.schoolZillaStarter;

    return this.educatorPortalInterappRedirectService.getLaunchUrl(taskId, applicationid);
  }

}
