import { Injectable } from '@angular/core';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';
import { MyOnLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/myon-link-generator-v2.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { SearchResult } from '../models/search-result.model';

@Injectable({
  providedIn: 'root'
})
export class ProductNavigationService {

  constructor(private contextDataService: ContextDataService,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private launchControlService: LaunchControlService,
    private myOnLinkGeneratorV2Service: MyOnLinkGeneratorV2Service
  ) { }

  public async goToFreckle(searchResult: SearchResult, disableNavigation: boolean = false): Promise<string | void> {
    try {
      let students = this.contextDataService.classDataContext$.value?.students ?? [];
      const deepLink = await this.freckleLinkGeneratorV2Service.getSearchResultAssignSkillDeepLink(students, searchResult.standard_id, searchResult.renaissance_skill_id, searchResult.content_type);

      if (!disableNavigation) {
        this.launchControlService.launchApp(ProductAppCodes.Freckle, deepLink, true);
      } else {
        return deepLink;
      }
    } catch (error) {
      console.error('Error navigating to Freckle:', error);
    }
  }

  public async goToLalilo(searchResult: SearchResult, disableNavigation: boolean = false): Promise<string | void> {
    try {
      let matches = searchResult.product_skill_id.match(/LESSONID-(.*)__/);
      let lessonId = matches ? matches[1] : undefined;
      const deepLink = await this.laliloLinkGeneratorV2Service.getLaliloPageDeepLink('assignment-planning', lessonId);

      if (!disableNavigation) {
        this.launchControlService.launchApp(ProductAppCodes.Lalilo, deepLink, true);
      } else {
        return deepLink;
      }
    } catch (error) {
      console.error('Error navigating to Lalilo:', error);
    }
  }

  public async goToMyOn(searchResult: SearchResult, disableNavigation: boolean = false): Promise<string | void>{
    try {
      const deepLink = this.myOnLinkGeneratorV2Service.getMyOnPageDeepLink(searchResult);

      if (!disableNavigation) {
        this.launchControlService.launchApp(ProductAppCodes.Myon, deepLink, true, false);
      } else {
        return deepLink;
      }
    } catch (error) {
      console.error('Error navigating to MyOn:', error);
    }
  }
}
