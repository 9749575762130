import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccessDeniedDialogComponent } from 'src/app/shared/access-denied-dialog/access-denied-dialog.component';

@Component({
  selector: 'app-degraded',
  templateUrl: './degraded.component.html',
  styleUrls: ['./degraded.component.scss']
})
export class DegradedComponent {

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog) {}
  ngOnInit(): void {
    const task = this.route.snapshot.paramMap.get('task');
    const applicationId = this.route.snapshot.paramMap.get('applicationId');

    if (task && applicationId) {
      this.dialog.open(AccessDeniedDialogComponent, { panelClass: 'rounded-dialog'});
    }
  }
}
