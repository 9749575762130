import { ProductAppDisplayNames } from "../../../product-info/product-info.service";
import { SubjectTypes } from "../../../subject/subject-types";
import { ClassProducts } from "../../../../shared/models/class.model";
import { NudgeText } from "../../../../shared/models/recommendation.model";
import { StudentContext } from "../../../../shared/models/student.model";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NudgeTextService {
  public getNudgeText(student: StudentContext, classProducts: ClassProducts, subject: string): NudgeText {
    let studentName = student.firstName;
    let skillsPracticeProducts = classProducts.skillsPracticeProducts;
    let readingPracticeProducts = classProducts.readingPracticeProducts;

    if (subject == SubjectTypes.MATH) {
      if (skillsPracticeProducts.includes(ProductAppDisplayNames.Freckle)) {
        return { skillsPractice: `Have ${studentName} log in to Freckle to start working on adaptive ${subject.toLocaleLowerCase()} practice.` };
      }
    }

    if (subject == SubjectTypes.READING) {
      if (readingPracticeProducts.length > 0 && student.readingPractices.length == 0) {
        if (skillsPracticeProducts.length > 0) {
          return {
            readingPractice: `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}.`,
            skillsPractice: `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`
          };
        }
        return {
          readingPractice: `Have ${studentName} practice independent reading in ${this.getReadingProductsDisplayString(readingPracticeProducts)}.`
        };
      }
      if (skillsPracticeProducts.length == 1) {
        if (skillsPracticeProducts.includes(ProductAppDisplayNames.Freckle)) {
          return {
            skillsPractice: `Have ${studentName} log in to Freckle to start working on adaptive ELA practice.`
          }; //special case for Freckle as it's ELA, not reading
        }
        return {
          skillsPractice: `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} ` +
            `to start working on adaptive ${subject.toLocaleLowerCase()} practice.`
        };
      }
      if (skillsPracticeProducts.length > 1) {
        return {
          skillsPractice: `Have ${studentName} log in to ${this.getSkillProductsDisplayString(skillsPracticeProducts)} to work on adaptive practice.`
        };
      }
    }
    return {
      skillsPractice: `Practice makes perfect. Encourage ${studentName} to practice their ${subject.toLocaleLowerCase()} skills regularly.`
    };
  }

  private getReadingProductsDisplayString(readingProducts: string[]): string {
    return readingProducts.join(' and ');
  }

  private getSkillProductsDisplayString(skillProducts: string[]): string {
    return skillProducts.join(' or ');
  }
}
