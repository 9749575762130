import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AppNavItem, TileLinkType } from '../../../models/app-nav-item.model';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/services/user/user.service';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { ClassContext } from 'src/app/shared/models/class.model';
import { Subscription } from 'rxjs';
import { AdminHandlerService } from 'src/app/services/admin-handler/admin-handler.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ReportLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/report-link-generator-v2.service';

@Component({
  selector: 'app-link-selector-option',
  templateUrl: './app-link-selector-option.component.html',
  styleUrls: ['./app-link-selector-option.component.scss']
})
export class AppLinkSelectorOptionComponent implements OnInit, OnDestroy {
  @Input() appNavItem: AppNavItem = {
    displayName: ''
  }
  @Input() expandDisabled: boolean = false;
  public isExpanded: boolean = false;
  private window: Window;
  private homeUri: string = '';
  private currentClass: ClassContext | undefined;
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _adminHandlerService: AdminHandlerService,
    private _userService: UserService,
    private _contextDataService: ContextDataService,
    private _launchControlService: LaunchControlService,
    private _reportLinkGeneratorV2Service: ReportLinkGeneratorV2Service,
  ) {
    this.window = this.document.defaultView as Window;
    this.homeUri = this._userService.getHomeUri();
  }

  ngOnInit() {
    // If expanding is disabled -> open by default
    if (this.expandDisabled) {
      this.open();
    }
    let contextDataSub = this._contextDataService.classDataContext$.subscribe((classData: ClassContext | undefined) => {
      if (classData) {
        this.currentClass = classData;
      }
    });
    this.subscriptions.push(contextDataSub);
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public async navigateByLaunchControl(app: AppNavItem) {
    if (app.displayName == "Star Phonics Reports" ||
      app.displayName == "Freckle Reports" ||
      app.displayName == "myON Reports" ||
      app.displayName == "Lalilo Reports") {
      if (app.url) {
        let deepLink = await this._reportLinkGeneratorV2Service.generateDeepLink(app.appCode!, this.currentClass!, app.url);
        app.deepLinkId = deepLink.toString();
      }
      else {
        let deepLink = await this._reportLinkGeneratorV2Service.generateDeepLink(app.appCode!, this.currentClass!);
        app.deepLinkId = deepLink.toString();
      }
    }
    if (app.appCode) {
      this._launchControlService.launchApp(app.appCode, app.deepLinkId, false);
    }
  }

  public windowNavigate(location: string) {
    this.window.location = location;
  }

  public open() {
    this.isExpanded = true;
  }

  public close() {
    this.isExpanded = false;
  }

  public appEnabledForUser(app: AppNavItem): boolean {
    if (app.tileLinkType === TileLinkType.FRECKLE_REPORTS_PAGE && this._adminHandlerService.isAdmin()) {
      return false;
    }
    return true;
  }
}
