import { Component, Input, OnInit } from '@angular/core';
import { TabSelectorOption } from './tab-selector-option';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { AppNavV2HelperService } from 'src/app/services/app-nav-v2/app-nav-v2-helper.service';
import { UserService } from 'src/app/services/user/user.service';
import { GraphqlService } from 'src/app/services/graphql/graphql.service';
import { ProductAppCodes } from '../../services/product-info/product-info.service';
@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss'],
})
export class TabSelectorComponent implements OnInit {
  @Input() public selectedTab: string = '';
  public tabOptions: TabSelectorOption[] = [
    {
      displayName: 'Summary',
      id: 'summary',
      link: '/summary',
    },
    {
      displayName: 'Skills',
      id: 'skills',
      link: '/skills',
    },
    {
      displayName: 'Superstars of the Week',
      id: 'superstars',
      link: '/superstars',
    }
  ];

  public constructor(
    private featureToggleService: FeatureToggleService,
    private userService: UserService,
    private graphQlService: GraphqlService,
    private AppNavV2HelperService: AppNavV2HelperService
  ) { }

  public async ngOnInit(): Promise<void> {
    if (await this.featureToggleService.isTrueAsync('teach-3935-rename-skills-tab-to-student-groups')) {
      let tabOption = this.tabOptions.find(option => option.id == 'skills');
      if (tabOption) {
        tabOption.displayName = 'Student Groups';
      }
    }
    let showSummerMath = await this.featureToggleService.isTrueAsync('show-summer-math');

    if (showSummerMath) {
      let haveFreckleForMath = await this.checkHaveFreckleForMath();
      let haveStudentsWithGrade2Or3 = await this.checkHaveStudentsWithGrade2Or3();

      if (haveFreckleForMath && haveStudentsWithGrade2Or3) {
        this.tabOptions.push({
          displayName: 'Summer Math',
          id: 'math',
          link: '/math',
        });
      }
    }
    // filter out the "Skills" tab for teachers who don't have Freckle or Lalilo
    // TODO - Check students for practice data
    if (!await this.hasFreckleOrLaliloApp()) {
      this.tabOptions = this.tabOptions.filter(tab => tab.id !== 'skills');
    }
  }

  async checkHaveFreckleForMath(): Promise<boolean> {
    await this.AppNavV2HelperService.isInitialized();
    return this.AppNavV2HelperService.isLicensedAppCode(ProductAppCodes.Freckle)
  }

  async checkHaveStudentsWithGrade2Or3(): Promise<boolean> {
    let userRenaissanceId = this.userService.getUserId();
    if (userRenaissanceId) {
      let studentGradeData = await this.graphQlService.getStudentGradesData(
        userRenaissanceId
      );
      return studentGradeData!.studentEnrollments.length > 0 ? true : false;
    }
    return false;
  }

  private async hasFreckleOrLaliloApp(): Promise<boolean> {
    await this.AppNavV2HelperService.isInitialized();
    return this.AppNavV2HelperService.isLicensedAppCode(ProductAppCodes.Freckle) ||
            this.AppNavV2HelperService.isLicensedAppCode(ProductAppCodes.Lalilo);
  }

  isActiveTab(tabId: string): boolean {
    let isActiveTab = this.selectedTab === tabId;
    return isActiveTab;
  }

  async onTabSelect(tabId: string): Promise<void> {
    if (tabId == 'skills') {
      await this.featureToggleService.sendTrackEvent('skills-tab-click');
    }
    setTimeout(() => {
      let element = document.getElementById("tab_" + tabId);
      element?.focus();
    }, 0);
  }
}
