import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SearchFilters, SearchFilterValue } from 'src/app/shared/models/search-filters';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnChanges, OnInit {
  public isFilterClear = false;
  constructor(public fb: FormBuilder,
    private featureToggleService: FeatureToggleService,
    private licenseInfoService: LicenseInfoService) { }

  @Input() filters: SearchFilters = {
    grades: [],
    lesson_types: [],
    products: [],
    subjects: [],
  };
  @Output() updateFilters = new EventEmitter();

  public subjects: SearchFilterValue[] = [
    { name: 'Math', value: 'Math' },
    { name: 'Reading', value: 'Reading' }
  ];
  public products: SearchFilterValue[] = [
    { name: 'Nearpod', value: 'nearpod' }
  ];
  public lesson_types: SearchFilterValue[] = [
    { name: 'Lesson', value: 'Lesson' },
    { name: 'Activity', value: 'Activity' },
    { name: 'Video', value: 'Video' }
  ];
  public grades: SearchFilterValue[] = [
    { name: 'K', value: 'K' },
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
  ];

  public filterForm = new FormGroup({
    grades: new FormControl(),
    lesson_types: new FormControl(),
    products: new FormControl(),
    subjects: new FormControl(),
  });

  public async ngOnInit() {
    let currentClassesHaveFreckle = await this.licenseInfoService.currentClassesHaveFreckle();
    let currentClassesHaveLalilo = await this.licenseInfoService.currentClassesHaveLalilo();

    if (currentClassesHaveFreckle) {
      this.products.push({ name: 'Freckle', value: 'freckle' })
    }
    if (currentClassesHaveLalilo) {
      this.products.push({ name: 'Lalilo', value: 'lalilo' })
    }

    this.products = this.sortArrayAlphabetically(this.products, 'name');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const filterChange = changes['filters'].currentValue
    if (!filterChange) {
      return;
    }
    // This handles updating the mat-select filter fields with changes from the parent component (usually passing in Url params)
    Object.keys(filterChange).forEach(key => {
      let value = this[key as keyof SearchFilters].filter((v) =>
        this.filters[key as keyof SearchFilters].find((s) => s.value === v.value)
      );

      this.filterForm.patchValue({ [key]: value });
    })

    this.updateProductMyOn();
  }

  public async updateProductMyOn(): Promise<void> {
    const currentClassesHaveMyOn = await this.licenseInfoService.currentClassesHaveMyOn();
    if (!await this.featureToggleService.isTrueAsync('nrd-19-enable-myon-mades-in-search') || !currentClassesHaveMyOn) {
      return;
    }

    if (!this.hasArrayValue(this.products, 'myon')) {
      this.products.push({ name: 'myON', value: 'myon' });
      this.products = this.sortArrayAlphabetically(this.products, 'name');
    }
  }

  setFilter(event: MatSelectChange) {
    const { grades, lesson_types, products, subjects } = this.filterForm.controls
    if (event.value) {
      this.isFilterClear = false;
    }
    this.updateFilters.emit({
      values: {
        'grades': grades.value,
        'lesson_types': lesson_types.value,
        'products': products.value,
        'subjects': subjects.value,
      },
      event
    });
  }

  clear() {
    this.isFilterClear = true;
    Object.keys(this.filterForm.controls).forEach(key => {
      this.filterForm.controls[key as keyof SearchFilters].setValue([]);
    })

    this.setFilter({} as MatSelectChange)
  }

  private hasArrayValue(array: any[], value: string): boolean {
    return array.some(element => element.value === value);
  }

  private removeArrayValue<T>(array: T[], key: keyof T, value: any): T[] {
    return array.filter(item => item[key] !== value);
  }

  private sortArrayAlphabetically(array: any[], property: string): any[] {
    return array.sort((a, b) => a[property].localeCompare(b[property]));
  }
}
