<form [formGroup]="filterForm">
  <div class="search-filters">
    <div class="ren-list-filter">
      <mat-label class="ren-list-filter__label">Subject:</mat-label>
      <mat-form-field id="pendo-subject-filter-selector" appearance="outline" class="accessible-filters subject-filter accessible-dropdown">
        <mat-select panelClass="accessible-selector" placeholder="All" disableOptionCentering multiple
          formControlName="subjects" (selectionChange)="setFilter($event)">
          <mat-option [value]="item"  id="pendo-subject-filter-{{item.name}}"
            *ngFor="let item of subjects">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ren-list-filter">
      <mat-label class="ren-list-filter__label">Products:</mat-label>
      <mat-form-field id="pendo-product-filter-selector" appearance="outline" class="accessible-filters product-filter accessible-dropdown">
        <mat-select panelClass="accessible-selector" placeholder="All" disableOptionCentering multiple
          formControlName="products" (selectionChange)="setFilter($event)">
          <mat-option [value]="item" id="pendo-product-filter-{{item.name}}"
            *ngFor="let item of products">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ren-list-filter">
      <mat-label class="ren-list-filter__label">Grades:</mat-label>
      <mat-form-field id="pendo-grade-filter-selector" appearance="outline" class="accessible-filters grade-filter accessible-dropdown">
        <mat-select panelClass="accessible-selector" placeholder="All" disableOptionCentering multiple
          formControlName="grades" (selectionChange)="setFilter($event)">
          <mat-option [value]="item"  id="pendo-grade-filter-{{item.name}}"
            *ngFor="let item of grades">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ren-list-filter">
      <mat-label class="ren-list-filter__label">Type:</mat-label>
      <mat-form-field id="pendo-type-filter-selector" appearance="outline" class="accessible-filters type-filter accessible-dropdown">
        <mat-select panelClass="accessible-selector" placeholder="All" disableOptionCentering multiple
          formControlName="lesson_types" (selectionChange)="setFilter($event)">
          <mat-option [value]="item" id="pendo-type-filter-{{item.name}}"
            *ngFor="let item of lesson_types">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button class="clear-filters-button" (click)="clear()">
      <span> Clear filters</span>
    </button>
    <span role="alert" *ngIf="isFilterClear" aria-label="the filters are cleared"></span>
  </div>
</form>