import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @ViewChild('tooltipButton', { read: ElementRef }) tooltipButton: ElementRef | undefined;
  @ViewChild('toolTip', {read: ElementRef}) toolTipElement: ElementRef | undefined;

  public ShowTooltip = false;

  @Input() public tooltipLabel: string = '';
  @Input() public tooltipDescription: string = '';

  constructor(private changeDetector: ChangeDetectorRef) { }

  shouldShowTooltip(): void {
    if (this.ShowTooltip) {
      this.hideTooltip();
    }
    else {
      this.ShowTooltip = true;
      this.changeDetector.detectChanges();
      this.tooltipButton?.nativeElement.setAttribute('aria-expanded', 'true');

      let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
      if (zoom >= 249) {
        this.tooltipButton?.nativeElement.scrollIntoView({ behavior: "smooth", block: "start"});
      }
      if (zoom > 125 && zoom < 249) {
        this.tooltipButton?.nativeElement.scrollIntoView({ behavior: "smooth", block: "center"});
      }
    }
  }

  hideTooltip(): void {
    this.ShowTooltip = false;

    this.tooltipButton?.nativeElement.setAttribute('aria-expanded', 'false');

    this.tooltipButton?.nativeElement.focus();
  }

  @HostListener('document:click', ['$event'])
  handleClickEvent(event: MouseEvent) {
    if (this.ShowTooltip && !this.tooltipButton?.nativeElement.contains(event.target)) {
      this.hideTooltip();
    }
  }

  @HostListener('keydown.esc', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.hideTooltip();
  }

}
