<div class="student-bubbles-stack-container">
  <ul>
    <ng-template  ngFor let-student [ngForOf]=studentsToDisplay let-i="index">
      <li>
        <app-student-bubble
          [ngStyle]="{'z-index': studentsToDisplay.length-i}"
          class="stacked"
          [student]="student"
        >
        </app-student-bubble>
      </li>
    </ng-template>
    <li *ngIf="(additionalStudents > 0)"
      class="additional-students-text"
      aria-hidden="true">+{{ additionalStudents }}</li>
  </ul>
</div>
