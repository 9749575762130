<form [formGroup]="formGroup">
  <div class="search-filters">
    <div class="ren-list-filter" *ngFor="let filter of searchFilters">
      <ng-container *ngIf="filter.settings.isAvailable">
        <mat-label class="ren-list-filter__label">{{filter.settings.filterDisplayName}}</mat-label>
        <mat-form-field appearance="outline" class="accessible-filters accessible-dropdown"
          [ngClass]="filter.settings.formControlName + '-filter'" [id]="'pendo-'+ filter.settings.id + '-filter-selector'">
          <mat-select panelClass="accessible-selector" placeholder="All" disableOptionCentering multiple
            [formControlName]="filter.settings.formControlName" (closed)="setFilter()">
            <mat-select-trigger>
              {{ getDisplayText(filter) }}
            </mat-select-trigger>
            <ng-container *ngFor="let option of filter.options">
              <mat-option [selected]="option.isSelected" [value]="option.value"
                id="pendo-subject-filter-{{option.displayName}}" *ngIf="option.isAvailable" >
                {{option.displayName}}
              </mat-option>
            </ng-container>
            <mat-divider></mat-divider>
            <div class="sticky-row clear-all__text">
              <div class="all-clear-container" tabIndex="0" (click)="toggleSelectAll(filter)" (enter)="toggleSelectAll(filter)">
                {{ areAllAvailableItemsSelected(filter) ?
                  searchConstants.SEARCH_FILTERS.CLEAR_ALL : searchConstants.SEARCH_FILTERS.SELECT_ALL }}
              </div>
            </div>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>
    <button class="clear-filters-button" (click)="clearFormControlValues()">
      <span>{{searchConstants.SEARCH_FILTERS.CLEAR_FILTERS}}</span>
    </button>
    <span role="alert" *ngIf="areFiltersClear" aria-label="the filters are cleared"></span>
  </div>
</form>
