import { AssessmentDetail } from "src/app/shared/models/assessment.model";
import { SkillDetail } from "src/app/shared/models/skill.model";
import { StudentIds } from "src/app/shared/models/student-ids.model";
import { StudentClassIds } from "src/app/shared/models/student-class-ids.model";
import { ReadingPracticeDetail, ReadingPracticeSummaryDetail } from "src/app/shared/models/reading-practice.model";
import { SkillPracticeSummary } from "src/app/shared/models/skill-practice-summary.model";

export class StudentActivityResponse {
  public studentEnrollments: Enrollment[] = [];
  public studentSkillDetail: SkillDetail[] = [];
  public studentSkillPracticeSummary: SkillPracticeSummary[] = [];
  public studentAssessmentDetail: AssessmentDetail[] = [];
  public studentReadingPracticeSummary: ReadingPracticeSummaryDetail[] = [];
  public studentReadingPracticeDetail: ReadingPracticeDetail[] = [];
}

export interface Enrollment {
  title: string;
  appTags: string;
  givenName: string;
  familyName: string;
  grade: string;
  studentRenaissanceId: string;
  classRenaissanceId: string;
  schoolRenaissanceId: string;
  schoolName: string;
  studentIds: StudentIds;
  studentClassIds: StudentClassIds;
  teacherRenaissanceId: string;
}
