import { Injectable } from '@angular/core';
import { SubjectTypes } from 'src/app/services/subject/subject-types';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { SubjectService } from '../subject/subject.service';
import { Link } from 'src/app/util/link/link';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';

@Injectable({
  providedIn: 'root'
})
export class NearpodLinkGeneratorService {
  private subjectSlugs: Record<string, string> = {
    [SubjectTypes.MATH]: 'math-C261',
    [SubjectTypes.READING]: 'english-language-arts-C273'
  };

  private referrer = 'ren_next';
  private url = 'https://nearpod.com/t/'

  public constructor(
    private subjectService: SubjectService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getSearchLink(nearpodMetadata: SkillMetadata | null, freckleMetadata: SkillMetadata | null): Promise<Link> {

    // If the provided skillMetadata is Nearpod skill then build the product url from that
    if (nearpodMetadata) {
      return this.getProductUrl(nearpodMetadata, nearpodMetadata.skillShortName);
    }

    // Otherwise fetch a related Nearpod skill using the Skill Metadata endpoint
    if (freckleMetadata) {
      return this.getProductUrl(freckleMetadata, freckleMetadata.skillShortName);
    }

    // If there is no associated skillMetadata at all then return a link to nearpods blank search page
    return this.buildSearchUrl();
  };

  public async getSearchLinkV2(nearpodPremiumMetadata: SkillMetadata | null, nearpodMetadata: SkillMetadata | null, freckleMetadata: SkillMetadata | null, laliloMetadata: SkillMetadata | null): Promise<Link> {

    // If lalilo skillMetadata is provided, build URL with lalilo metadata
    if (laliloMetadata) {
      return this.getProductUrl(laliloMetadata, laliloMetadata.skillShortName);
    }

    // If the provided skillMetadata is Nearpod Premium skill then build the product url from that
    if (await this.featureToggleService.isTrueAsync('teach-4151-enable-nearpod-premium-recommendations') && nearpodPremiumMetadata) {
      return this.getProductUrl(nearpodPremiumMetadata, nearpodPremiumMetadata.skillShortName);
    }

    // If the provided skillMetadata is Nearpod skill then build the product url from that
    if (nearpodMetadata) {
      return this.getProductUrl(nearpodMetadata, nearpodMetadata.skillShortName);
    }

    // Otherwise fetch a related Nearpod skill using the Skill Metadata endpoint
    if (freckleMetadata) {
      return this.getProductUrl(freckleMetadata, freckleMetadata.skillShortName);
    }

    // If there is no associated skillMetadata at all then return a link to nearpods blank search page
    return this.buildSearchUrl();
  };

  private getProductUrl(skillMetadata: SkillMetadata | undefined, searchString: string): Link {
    // If the provided skillMetadata is undefined, then fall back to the provided search string
    if (!skillMetadata) {
      return this.buildSearchUrl(searchString);
    }

    const { productUrl, skillShortName } = skillMetadata;

    // If we have a product Url in the skillMetadata then return that URL
    // If we do not have a productUrl then construct a search URL
    //
    // Search URL Example:
    // https://nearpod.com/t/english-language-arts-C273?q=Identify%20nouns%20in%20context%20and%20their%20functions

    const urlQueryString = new URLSearchParams({ 'referrer': this.referrer }).toString();

    return productUrl ? new Link(`${productUrl}/?${urlQueryString}`, 'exact') : this.buildSearchUrl(skillShortName);
  }

  private buildSearchUrl(q?: string): Link {
    const subject = this.subjectService.selectedSubject$.getValue()
    const subjectSlug = this.subjectSlugs[subject];
    const baseUrlPath = `${this.url}${subjectSlug}`;

    if (!q) {
      const urlQueryString = new URLSearchParams({ 'referrer': this.referrer }).toString();
      return new Link(`${baseUrlPath}/?${urlQueryString}`);
    }

    const urlQueryString = new URLSearchParams({ q: q, 'referrer': this.referrer }).toString();

    return new Link(`${baseUrlPath}/?${urlQueryString}`);
  }
}
