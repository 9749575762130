import { Component, Input } from '@angular/core';
import { SkillsPracticeCard } from 'src/app/shared/models/skills-practice-card';
import { StudentContext } from 'src/app/shared/models/student.model';
import { SkillPracticeCategory } from '../../skills-practice-categories';

@Component({
  selector: 'recommended-skill-card',
  templateUrl: './recommended-skill-card.component.html',
  styleUrls: ['./recommended-skill-card.component.scss']
})

export class RecommendedSkillCardComponent {
  @Input() public skillsPracticeCards: SkillsPracticeCard[] = [];
  @Input() public skillsPracticeCategory = SkillPracticeCategory.Stuck;

  public categoryName: string = '';
  public subText: string = '';
  public icon: string = '';

  public ngOnInit() {
    this.setCategory();
  }

  public getStudents(skillsPracticeCard: SkillsPracticeCard): StudentContext[] {
    return this.skillsPracticeCategory === SkillPracticeCategory.Stuck ?
     skillsPracticeCard.stuckStudents : 
     skillsPracticeCard.readyForChallengeStudents;
  }

  private setCategory() {
    if (this.skillsPracticeCategory === SkillPracticeCategory.Stuck) {
      this.categoryName = 'Stuck';
      this.subText = '(<50%)';
      this.icon = '/assets/skills_practice_stuck.svg';
    }
    
    if (this.skillsPracticeCategory === SkillPracticeCategory.ReadyForChallenge) {
      this.categoryName = 'Ready for a Challenge';
      this.subText = '(>80%)';
      this.icon = '/assets/skills_practice_ready_for_a_challenge.svg';
    }
  }
}

