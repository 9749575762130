import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export const FilterOptions = {
  MostStuck: 'Most Students Stuck',
  MostPracticing: 'Most Students Practicing',
  SkillName: 'Skill Name',
} as const;

type FilterOptionKeys = keyof typeof FilterOptions;
export type FilterOptionValues = typeof FilterOptions[FilterOptionKeys];

@Component({
  selector: 'app-filter-skills',
  templateUrl: './filter-skills.component.html',
  styleUrls: ['./filter-skills.component.scss']
})
export class FilterSkillsComponent implements OnInit {
  public options = Object.values(FilterOptions);

  public selectedOption: FilterOptionValues = FilterOptions.MostStuck;

  @Output() updateSort = new EventEmitter<FilterOptionValues>();

  ngOnInit() {
    this.updateSort.emit(this.selectedOption);
  }
}
