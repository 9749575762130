import { Injectable } from "@angular/core";
import { StudentContext } from "src/app/shared/models/student.model";
import { MyONMadeRecommendation } from "./myonMadeRecommendation";
import { MyONActionRecommendationService } from "../action/myon-action/myon-action-recommendation.service";
import { GraphqlService } from "../../graphql/graphql.service";
import { Randomizer } from "../../randomizer/randomizer.service";

@Injectable({ providedIn: 'root' })
export class LexileBasedMyONMadeRecommendationService {
  constructor(
    private myONActionRecommendationService: MyONActionRecommendationService,
    private graphqlService: GraphqlService,
    private randomizer: Randomizer
  ) { }

  public async getLexileRecommendation(student: StudentContext): Promise<MyONMadeRecommendation | null> {
    const lowerLexile = student.lexileLevel! - 150;
    const upperLexile = student.lexileLevel! + 150;
    let booksInRange = await this.graphqlService.getBooksByLexileRange(lowerLexile, upperLexile);

    if (booksInRange.length === 0) {
      return null;
    }

    let selectedBook = this.randomizer.selectRandomInArray(booksInRange);

    let myONActionLink = await this.myONActionRecommendationService.getMyONActionForRecommendation(selectedBook.projectId, student.renaissanceId);

    let recommendation: MyONMadeRecommendation = {
      actionLink: myONActionLink,
      projectId: selectedBook.projectId,
      rationale: `Recommended for ${student.firstName} based on reading practice, skills practice, or assessment results`,
      students: [student]
    };

    return recommendation;
  }

}
