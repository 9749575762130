<inactive-card *ngIf="inactiveStudents.length > 0" [inactiveStudents]="inactiveStudents"></inactive-card>

<div class="active-skills-header-container">
  <h3 class="active-skills-header" *ngIf="skillsPracticeCards.length > 0">Active Skills</h3>

  <app-filter-skills *ngIf="skillsPracticeCards.length > 0" (updateSort)="sort($event)"></app-filter-skills>
</div>

<div class="active-skill-card" *ngFor="let skillsPracticeCard of skillsPracticeCards">
    <active-skill-card [skillsPracticeCard]="skillsPracticeCard"></active-skill-card>
</div>
