import { NgModule } from '@angular/core';
import { IdleTimeoutService } from './idle-timeout.service';
import { NgIdleModule } from '@ng-idle/core';
import { KeepaliveService } from './keepalive.service';
import { TimeoutModalService } from './timeout-modal.service';
import { WindowFacadeService } from './window.service';
import { IdleTimeoutModalComponent } from './idle-timeout-modal/idle-timeout-modal.component';

@NgModule({
  imports: [
    NgIdleModule.forRoot()
  ],
  providers: [
    IdleTimeoutService,
    KeepaliveService,
    TimeoutModalService,
    WindowFacadeService,
    IdleTimeoutModalComponent
  ],
  declarations: [
    IdleTimeoutModalComponent
  ]
})
export class IdleTimeoutModule { }