import { Injectable } from '@angular/core';
import { ClockService } from '../clock/clock.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SeenBeforeService {

  private _ttl = 90 * 24 * 60 * 60 * 1000; // 90 days in ms
  private _keyPrefix = 'tj-seen-'

  constructor(
    private clock: ClockService,
    private localStorage: LocalStorageService) {
    this.cleanUpOphanedKeys();
  }

  cleanUpOphanedKeys() {
    const keys = this.localStorage.getKeys();
    for (let key of keys) {
      if (key.startsWith(this._keyPrefix)) {
        const maybeSeenItem = this.localStorage.getItem(key);
        if (maybeSeenItem) {
          const seenItem = JSON.parse(maybeSeenItem);
          if (!seenItem?.expiry) {
            this.localStorage.removeItem(key);
            continue;
          }

          const now = this.clock.now();
          if (now > seenItem.expiry) {
            this.localStorage.removeItem(key);
          }
        }
      }
    }
  }

  private _constructKey(skillId: string, studentIds: string[]) {
    let studentIdKey = studentIds.join('_');
    return `${this._keyPrefix}${skillId}-${studentIdKey}`;
  }

  addToSeen(skillId: string, studentIds: string[]) {
    const now = this.clock.now();
    this.localStorage.setItem(this._constructKey(skillId, studentIds), JSON.stringify({
      expiry: now + this._ttl // ms since unix epoch
    }));
  }

  hasBeenSeenBefore(skillId: string, studentIds: string[]): boolean {
    const key = this._constructKey(skillId, studentIds);
    const maybeSeenItem = this.localStorage.getItem(key);
    // no key hasn't been seen
    if (!maybeSeenItem) {
      return false;
    }

    const seenParsed = JSON.parse(maybeSeenItem);

    // invalid, clean up and return false
    if (!seenParsed.expiry) {
      this.localStorage.removeItem(key);
      return false;
    }

    // expired case, return false
    const now = this.clock.now();
    if (now > seenParsed.expiry) {
      this.localStorage.removeItem(key);
      return false;
    }


    // re-add to update the expiry if it has been seen so it rolls
    this.addToSeen(skillId, studentIds);

    return true;
  }
}
