<app-searchbox></app-searchbox>
<div class='home-container' [class.fade-in-ani]="playFadeInAnimation">
  <ng-container>
    <div class="summary-intro-container">
      <activity-summary-intro-animation
        class="summary-intro-animation"
      >
      </activity-summary-intro-animation>
    </div>
  </ng-container>
  <ng-container *ngIf="isIntroAnimationComplete">
    <app-tab-selector [selectedTab]="'summary'"></app-tab-selector>
    <div class="activity-summary-container renaissance-style-scoped" aria-labelledby="skip-nav">
      <div class="activity-summary-header-container1">
        <h2 id="skip-nav" tabindex="-1">Activity Summary and Recommendations</h2>
        <p class="description">
          Here's how students are doing in your Renaissance programs, based on their work over the
          <span class="bolded">last two weeks</span> or the <span class="bolded">latest test.</span>
        </p>
        <a id="pendo-guide-learn-more" class="learn-more-text" href="#" (click)="$event.preventDefault()" role="button">Learn
          more.</a>
      </div>
      <div class="context-selector">
        <context-selector></context-selector>
      </div>
      <div class="summary-table-container">
        <!-- Loading spinner -->
        <div *ngIf="!didLoad || showSpinner">
          <mat-spinner diameter="60" mode="indeterminate" class="mat-spinner activity-summary-spinner"></mat-spinner>
        </div>
        <div *ngIf="didLoad && !hasData && !showSpinner">
          <app-no-data></app-no-data>
        </div>
        <div *ngIf="didLoad && hasData && !showSpinner" role="group" aria-label="Activity Summary Category">
          <mat-accordion multi hideToggle displayMode='flat'>
            <app-activity-summary-category [id]="'category-need-a-helping-hand'" headerTitle="Need a Helping Hand"
              description="These students may need support."
              [numTotalStudents]="numTotalStudents" [students]="needsAHelpingHandStudents"
              [classProducts]="classProducts">
            </app-activity-summary-category>
            <app-activity-summary-category [id]="'category-working-at-an-appropriate-level'"
              headerTitle="Working at an Appropriate Level"
              description="These students are applying themselves and getting their work done."
              [numTotalStudents]="numTotalStudents"
              [students]="workingAtAnAppropriateLevelStudents" [classProducts]="classProducts">
            </app-activity-summary-category>
            <app-activity-summary-category [id]="'category-ready-for-more'" headerTitle="Ready for More"
              description="These students are excelling and might benefit from a challenge."
              [numTotalStudents]="numTotalStudents"
              [students]="readyToBeChallengedStudents" [classProducts]="classProducts">
            </app-activity-summary-category>
            <app-activity-summary-category *ngIf="featureToggleService.isTrue('enable-time-to-practice')" [id]="'category-time-to-practice'" headerTitle="Time to Practice"
              description="These students haven't practiced or taken an assessment in a while."
              [numTotalStudents]="numTotalStudents"
              [students]="timeToPracticeStudents" [classProducts]="classProducts" [showTakeAction]="false">
            </app-activity-summary-category>
          </mat-accordion>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <div id="pendo-intro-animation-complete" class="pendo-only-element"
      *ngIf="showAnimationCompletePendoElement"></div>
  </ng-container>
</div>
