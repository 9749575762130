import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyonLinkGeneratorV2Service {

  constructor() { }
  public getReportsPageDeepLink(): string {
    return 'MYON_REPORTS';
  }

  public getDeepLink(studentRenaissanceId: string | undefined): string {
    return `admin-students-view-user-id=${studentRenaissanceId}`;
  }
}
