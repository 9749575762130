import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkillsPracticeTakeActionData } from './skills-practice-take-action-data';
import { ActionableSkill, ProductKeys } from 'src/app/home/activity-summary/activity-summary-category/student-table/take-action-modal/take-action-modal.component';
import { RecommendationService } from 'src/app/services/recommendation/recommendation.service';
import { SubjectService } from 'src/app/services/subject/subject.service';


@Component({
  selector: 'app-skills-practice-take-action-modal',
  templateUrl: './skills-practice-take-action-modal.component.html',
  styleUrls: ['./skills-practice-take-action-modal.component.scss']
})

export class SkillsPracticeTakeActionModalComponent {

  public skill!: ActionableSkill;

  public hasLoaded = false;
  constructor(
  @Inject(MAT_DIALOG_DATA) public data: SkillsPracticeTakeActionData,
  public ref: MatDialogRef<SkillsPracticeTakeActionModalComponent>,
  private recommendationService: RecommendationService,
  private subjectService: SubjectService) {
  }

  async ngOnInit() {
    let subject = await this.subjectService.selectedSubject$.getValue();
    this.skill = await this.getActionableSkill(this.data, subject);
    this.hasLoaded = true;
  }

  async getActionableSkill(data: SkillsPracticeTakeActionData, subject: string) {

    var actionableSkill = await this.recommendationService.getSkillRecommendationForStudentsWithSkill(data.students, data.skillMetadata, data.skillPracticeCategory, subject);

    return actionableSkill;
  }

  getStudentCountText() {
    let numStudents = this.data.students.length;

    if (numStudents == 1) {
      return `(${numStudents} student)`;
    }

    else {
      return `(${numStudents} students)`
    }
  }

  public close(event: Event) {
    event.preventDefault();
    this.ref.close();
  }
}
