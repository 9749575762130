import { Component, OnInit } from '@angular/core';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';

@Component({
  selector: 'left-nav-intro-animation',
  templateUrl: './left-nav-intro-animation.component.html',
  styleUrls: ['./left-nav-intro-animation.component.scss']
})
export class LeftNavIntroAnimationComponent implements OnInit {

  public showIntroAnimation: boolean = false;
  public showIntroAnimationSVG: boolean = false;
  public fadeOut: boolean = false;

  public readonly animationSVGDelayLengthMs = 1000;
  public readonly animationLengthMs = 5000;
  public readonly animationLengthAndFadeoutMs = 5400;

  constructor(
    private _introAnimationService: IntroAnimationService
  ) { }

  async ngOnInit() {
    await this.triggerIntroAnimation();
  }

  private async triggerIntroAnimation() {
    let kickOffAnimation = await this._introAnimationService.kickOffIntroAnimations();
    if (kickOffAnimation) {
      let animationStatus: IntroAnimationStatus = {
        enabled: true,
        complete: true
      };
      this.showIntroAnimation = true;
      setTimeout(() => { this.showIntroAnimationSVG = true}, this.animationSVGDelayLengthMs);
      setTimeout(() => { this.fadeOut = true }, this.animationLengthMs);
      setTimeout(() => {
        this.showIntroAnimation = false;
        this._introAnimationService.updateLeftNavAnimationStatus(animationStatus);
      }, this.animationLengthAndFadeoutMs);
    }
    else {
      let animationStatus: IntroAnimationStatus = {
        enabled: false,
        complete: true,
      }
      this._introAnimationService.updateLeftNavAnimationStatus(animationStatus);
    }
  }
}
