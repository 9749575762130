<app-searchbox></app-searchbox>
<div class='home-container'>
  <app-tab-selector [selectedTab]="'skills'"></app-tab-selector>
  <div class="skills-practice-container renaissance-style-scoped" aria-labelledby="skip-nav">
    <div class="skills-practice-header-container">
      <div class="skills-practice-header">

        <h2 id="skip-nav" tabindex="-1">{{ headerTitle }}</h2>
        <p class="description">
          {{ headerDescription }}
        </p>
        <a id="pendo-guide-skills-practice-learn-more" class="learn-more-text" href="#" (click)="$event.preventDefault()" role="button">
          Learn more.</a>
    </div>
    <div class="skills-practice-links-container">
        <app-skills-practice-app-link
          *ngIf="showActivityFeedLink"
          appName="Freckle"
          appPage="Activity Feed"
          appSvg="../../../assets/logos/freckle_logo.svg"
          [appUrl]="freckleActivityFeedLink"
        >
        </app-skills-practice-app-link>
        <app-skills-practice-app-link
          *ngIf="showLaliloSnapShotLink"
          appName="Lalilo"
          appPage="Class Snapshot"
          appSvg="../../../assets/logos/lalilo_logo.svg"
          [appUrl]="laliloClassSnapShotLink"
        >
        </app-skills-practice-app-link>
      </div>
    </div>
    <div class="context-selector">
      <context-selector ></context-selector>
    </div>

    <div *ngIf="!didLoad || showSpinner">
      <mat-spinner diameter="60" mode="indeterminate" class="mat-spinner skills-spinner"></mat-spinner>
    </div>
    <div *ngIf="didLoad && !hasData && !showSpinner">
      <app-no-data></app-no-data>
    </div>
    <div *ngIf="didLoad && hasData && !showSpinner">
      <skills-practice-list
        [inactiveStudents]="inactiveStudents"
        [skillsPracticeCards]="skillsPracticeCards"
      ></skills-practice-list>
    </div>
  </div>
  <app-footer></app-footer>
</div>
