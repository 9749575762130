import { Injectable } from '@angular/core';
import { AppNavV2 } from 'src/app/shared/models/app-nav-v2.model';
import { AppNavItem, AppNavItemIds } from 'src/app/shared/models/app-nav-item.model';
import { AppNavV2ItemConfigurationService } from './app-nav-v2-item-configuration.service';
import { ReportLinkGeneratorV2Service } from '../link-generators-v2/report-link-generator-v2.service';
import { ClassContext } from 'src/app/shared/models/class.model';
import { ContextDataService } from '../context-data/context-data.service';
import { AppNavV2HelperService } from './app-nav-v2-helper.service';
import { UserService } from '../user/user.service';
import { AvailableAppNavV2Service } from './available-app-nav-v2.service';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { ReportSearchFilter } from '../report/report-search-filter.service';
import { ProductAppCodes, ProductAppDisplayNames, ProductAppImagePrefix } from '../product-info/product-info.service';
import { PreferenceKey, UserPreferenceService } from '../user-preference/user-preference.service';

@Injectable({
  providedIn: 'root'
})

export class AppNavV2Service {
  public appNavV2: AppNavV2[] = [];
  private currentClass: ClassContext | undefined;
  private isInitialized = false;
  private isDegradedNav = false;

  constructor(
    private _appNavV2ItemConfigurationService: AppNavV2ItemConfigurationService,
    private _reportLinkGeneratorV2Service: ReportLinkGeneratorV2Service,
    private _appNavV2HelperService: AppNavV2HelperService,
    private _userService: UserService,
    private _appNavV2Service: AvailableAppNavV2Service,
    private _featureToggleService: FeatureToggleService,
    private _contextDataService: ContextDataService,
    private _reportSearchFilter: ReportSearchFilter,
    private _userPreferenceService: UserPreferenceService
  ) {
    this._contextDataService.classDataContext$.subscribe((classData: ClassContext | undefined) => {
      if (classData) {
        this.currentClass = classData;
      }
    });
  }

  public async initializeAppNavV2() {
    if (this.isInitialized) return;

    await this._userService.isLoggedIn();
    this.isDegradedNav = this._appNavV2Service.getIsDegraded();

    if (!this.isDegradedNav && this.appNavV2.length == 0) {
      await this._appNavV2Service.loadAppNavV2();
      this.appNavV2 = this._appNavV2Service.getAppNavV2();
      this.isDegradedNav = this._appNavV2Service.getIsDegraded();
      await this._appNavV2Service.isLoaded();
    }

    this.isInitialized = true;
    this._appNavV2HelperService.initialize(this.appNavV2);
  }

  public getIsDegraded() {
    return this.isDegradedNav;
  }

  public getAppsForDegradedMode() {
    return this._appNavV2ItemConfigurationService.getAppsForDegradedMode();
  }

  public async getReportDeepLink(appNavItem: AppNavItem) {
    let returnVal = '';
    if (appNavItem.appCode && this.currentClass) {
      let deepLink = await this._reportLinkGeneratorV2Service.generateDeepLink(appNavItem.appCode, this.currentClass, appNavItem.url);
      returnVal = deepLink.toString();
    }
    return returnVal;
  }

  public getAvailableV2MyData(): AppNavItem[] {
    var myReports = this._appNavV2HelperService.getAppNavDataByTag("report");
    return this._appNavV2HelperService.getMyDataNavsV2(myReports);
  }

  public async getAvailableV2Apps(): Promise<AppNavItem[]> {
    var leftNavItems = this._appNavV2HelperService.getAppNavDataByTag("leftnav");
    let appNavV2 = await this._appNavV2HelperService.getMyAppNavsV2(leftNavItems)
    let arIndex = appNavV2.findIndex(code => code.id == AppNavItemIds.AR);
    let MY_APPNAV_AR: AppNavItem = {
      appCode: ProductAppCodes.AR,
      id: AppNavItemIds.AR,
      displayName: ProductAppDisplayNames.AR,
      imagePrefix: ProductAppImagePrefix.AR,
    };
    appNavV2[arIndex] = MY_APPNAV_AR;
    return appNavV2;
  }

  public async getOtherAvailableApps(): Promise<AppNavItem[]> {
    let otherAppNavItems: AppNavItem[] = [];
    const isSavvasPreferenceEnabled = await this._userPreferenceService.getPreferenceValue(PreferenceKey.SavvasEnabled);

    if (isSavvasPreferenceEnabled && await this._featureToggleService.isTrueAsync('nrd-47-core-publisher-enabled')) {
      otherAppNavItems = this._appNavV2ItemConfigurationService.getOtherAppNavs();
    }

    return otherAppNavItems;
  }

  public async getAvailableV2SearchReports(): Promise<AppNavItem[]> {
    let searchReportItems = this._appNavV2HelperService.getAppNavDataByTag("searchreport");
    let reportItems = this._appNavV2HelperService.getAvailableV2SearchReportItems(searchReportItems);

    if (await this._featureToggleService.isTrueAsync('tj-dashboard-tile-hide-list-chicago')) {
      reportItems = this._reportSearchFilter.removeReportForChicago(reportItems);
    }

    if (await this._featureToggleService.isTrueAsync('tj-dashboard-tile-hide-custom-list')) {
      reportItems = this._reportSearchFilter.removeReportForCustom(reportItems);
    }

    if (await this._featureToggleService.isTrueAsync('reports-fl-terminology-replacement')) {
      reportItems = this._reportSearchFilter.getTranslationForFlorida(reportItems);
    }
    return reportItems;

  }

  public async resolveLinks(appNavItems: AppNavItem[]): Promise<AppNavItem[]> {
    let headerAppItems: AppNavItem[] = [];
    for (let appNavItem of appNavItems) {
      let resolvedItem = await this.resolveLinksRecursive(appNavItem);
      headerAppItems.push(resolvedItem!);
    }
    return headerAppItems;
  }

  private async resolveLinksRecursive(appNavItem: AppNavItem): Promise<AppNavItem> {
    let resolvedAppNavItem: AppNavItem = { ...appNavItem };
    if (appNavItem.appCode && this.currentClass) {
      let appCode = appNavItem.appCode;
      if (appCode == ProductAppCodes.Freckle || appCode == ProductAppCodes.Lalilo || appCode == ProductAppCodes.StarPhonics) {
        let reportsPageLink = await this._reportLinkGeneratorV2Service.generateDeepLink(appCode, this.currentClass, appNavItem.url);
        resolvedAppNavItem.deepLink = reportsPageLink;
      }
    }
    if (appNavItem.children) {
      resolvedAppNavItem.children = [];
      for (let child of appNavItem.children) {
        let resolvedChild = await this.resolveLinksRecursive(child);
        resolvedAppNavItem.children.push(resolvedChild!);
      }
    }
    return resolvedAppNavItem;
  }
}
