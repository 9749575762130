import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-logout',
  template: './logout.component.html'
})
export class LogOutComponent implements OnInit {
  private window: Window;
  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService) {
    this.window = this.document.defaultView as Window;
  }

  public async ngOnInit() {
    this.logOut();
  }


  public logOut() {
    localStorage.removeItem('token');
    this.window.location = this.userService.getLogoutUri();
  }
}
