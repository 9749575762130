import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Student } from '../models/student.model';

@Component({
  selector: 'app-student-bubble-stack',
  templateUrl: './student-bubble-stack.component.html',
  styleUrls: ['./student-bubble-stack.component.scss']
})

export class StudentBubbleStackComponent implements OnInit, OnChanges {
  @Input() students: Student[] = [];
  public studentsToDisplay: Student[] = [];
  public additionalStudents = 0;

  ngOnInit() {
    this.updateStudents();
  }

  ngOnChanges() {
    this.updateStudents();
  }

  private updateStudents() {
    if (this.students.length > 5) {
      // make list of only 4 bubbles and put the rest behind a +
      this.studentsToDisplay = this.students.slice(0, 4);
      this.additionalStudents = this.students.length - 4;
    }
    else {
      this.studentsToDisplay = this.students;
      this.additionalStudents = 0;
    }
  }
}
