import { Link } from "src/app/util/link/link";
import { SkillMetadata } from "./skill-metadata.model";
import { Category } from "./category.model";

export type Recommendation = {
  rationale: string;
  textContent?: string;
  nudgeText: NudgeText;
  skillMetadata: SkillMetadata | null;
  recommendationType: RecommendationType;
  productActionLinks: { [key: string]: ActionLink };
  productId?: string;
  studentCategory?: Category;
  practiceProductType?: string;
  recommendationAudit?: string;
  skillsPracticeProducts?: string[];
  readingPracticeProducts?: string[];
  studentHasReadingPractice?: boolean;
}

export interface NudgeText {
  skillsPractice?: string;
  readingPractice?: string;
}

export enum RecommendationType {
  None,
  Star,
  NeedsHelp,
  Appropriate,
  NeedsChallenge,
  EndOfProgression
}

export interface BaseRecommendationSkill {
  skillId?: string;
  recommendationType: RecommendationType;
  subject: string;
  prerequisiteSkillId?: string;
  practiceProductType?: string;
  recommendationAudit?: string;
}

export interface ActionLink {
  link: Link;
  name: string;
  description: string;
  imageUrl: string;
  type: ProductKeys;
}

export enum ProductKeys {
  Freckle = "FRECKLE",
  Nearpod = "NEARPOD",
  Star = "STAR",
  Lalilo = "LALILO",
  Savvas = "SAVVAS",
  MyON = "MYON"
}
