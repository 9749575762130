import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnInit, OnDestroy {

  public isIntroAnimationComplete = false;
  public playFadeInAnimation = false;
  placeholder = 'Example: "third grade practice on finding perimeter"';
  public searchValue = '';
  public subscriptions: Subscription[] = [];

  constructor(private router: Router,
    private _introAnimationService: IntroAnimationService,
    private changeDetectorRef: ChangeDetectorRef) { }

  async ngOnInit() {
    let subscription = this._introAnimationService.searchIntroAnimationStatus$.subscribe((animationStatus: IntroAnimationStatus | undefined) => {
      if (animationStatus) {
        return this.checkAnimationStatus(animationStatus);
      }
      return false;
    });
    this.subscriptions.push(subscription);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onEnter(value: string) {
    this.searchValue = value;
    if (value.trim().length > 0) {
      this.router.navigate([`/search`], { queryParams: { q: value } });
    }
  }

  public checkAnimationStatus(animationStatus: IntroAnimationStatus) {
    if (animationStatus.complete) {
      this.isIntroAnimationComplete = true;
    }
    if (animationStatus.enabled) {
      this.playFadeInAnimation = true;
      // turn off animation after it has been played
      setTimeout(() => {this.playFadeInAnimation = false}, 400);
    }
  }
}
