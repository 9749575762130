<div class="header-container">
  <h3 class="header-text">
    Get smart grouping recommendations when your students practice
  </h3>
  <p class="header-subtext">
    Students haven't practiced in the last 14 days.
  </p>
</div>

<div class="assign-practice-container">

  <div *ngIf="showLaliloAssignPracticeLink" class="assign-practice">
    <a class="assign-practice-button" aria-label="lalilo-assign-practice (link opens in a new tab)"
      (click)="launchLaliloAssignPractice()" tabindex="0">
      Assign Practice in Lalilo&nbsp;
      <span class="glyph glyph-externallink"></span>
    </a>
    <div class="assign-practice-text-container" [class.alt]="!showFreckleAssignPracticeLink">
      <p *ngIf="showFreckleAssignPracticeLink; else altLaliloText" class="assign-practice-text">
        For students who are&nbsp;
        <span class="assign-practice-text-bold">
          learning to read.
        </span>
      </p>
      <ng-template #altLaliloText>
        <p class="assign-practice-text">
          New to Lalilo?&nbsp;
        </p>
      </ng-template>
      <a [href]="this.laliloLearnMoreLink" class="assign-practice-learn-more" target="_blank"
        aria-label="Learn more link opens in a new tab" tabindex="0">
        Learn more
      </a>
    </div>
  </div>

  <div *ngIf="showFreckleAssignPracticeLink" class="assign-practice">
    <a class="assign-practice-button" aria-label="freckle-assign-practice (link opens in a new tab)"
      (click)="launchFreckleAssignPractice()" tabindex="0">
      Assign Practice in Freckle&nbsp;
      <span class="glyph glyph-externallink"></span>
    </a>
    <div class="assign-practice-text-container" [class.alt]="!showLaliloAssignPracticeLink">
      <p *ngIf="showLaliloAssignPracticeLink; else altFreckleText" class="assign-practice-text">
        For students who are&nbsp;
        <span class="assign-practice-text-bold">
          reading to learn.
        </span>
      </p>
      <ng-template #altFreckleText>
        <p class="assign-practice-text">
          New to Freckle?&nbsp;
        </p>
      </ng-template>
      <a [href]="this.freckleLearnMoreLink" class="assign-practice-learn-more" target="_blank"
        aria-label="Learn more link opens in a new tab" tabindex="0">
        Learn more
      </a>
    </div>
  </div>

</div>

<div class="animation-container">
  <empty-student-groups-animation></empty-student-groups-animation>
</div>
