import { Injectable } from '@angular/core';
import { ReadingPractice } from 'src/app/shared/models/reading-practice.model';

@Injectable({
  providedIn: 'root'
})
export class LexileHelperService {

  constructor() { }

  public convertLexileToNumber(lexileValueString: string | null): number | null {
    if (!lexileValueString) {
      return null;
    }

    if (lexileValueString == 'BR') {
      return 0;
    }

    const isBeginningReader = lexileValueString?.toLowerCase().includes('br');

    const numericValue = lexileValueString?.replace(/[^-\d]/g, '');

    return isBeginningReader ? -1 * parseInt(numericValue) : parseInt(numericValue);
  }

  public static getAverageLexileLevel(readingPractices: ReadingPractice[]) {
    return Math.trunc(readingPractices.reduce((total, practice) => total + practice.lexileLevel, 0) / readingPractices.length)
  }
}
