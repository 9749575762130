<div class="source-container">
  <div class="top-text">
    <img *ngIf="isNearpod(searchResult.source)" [src]="NEARPOD_IMAGE_PATH" alt="" class="product-logo"/>
    <img *ngIf="isFreckle(searchResult.source)" [src]="FRECKLE_IMAGE_PATH" alt="" class="product-logo"/>
    <img *ngIf="isLalilo(searchResult.source)" [src]="LALILO_IMAGE_PATH" alt="" class="product-logo"/>
    <h2 class="product-text">{{formatSource(searchResult.source)}}</h2>
    <div class="tags">
      <div *ngFor="let tag of filteredTags">
        <div *ngIf="tag.key === 'grades'; else other_filter_tags" class="tag">
          {{formatTag(tag.key, tag.value)}}
        </div>
        <ng-template #other_filter_tags>
          <div *ngFor="let t of tag.value" class="tag">
            {{formatTag(tag.key, [t])}}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<h3 class="top-text padding-left-5px" [innerHTML]="searchResult.name"></h3>
<div *ngIf="isFreckle(searchResult.source)" class="bottom-text padding-left-5px">
  {{formatStandard(searchResult)}}
</div>
<div *ngIf="isNearpod(searchResult.source)" class="bottom-text padding-left-5px" [innerHTML]="searchResult.description"></div>
<div *ngIf="isLalilo(searchResult.source)" class="bottom-text padding-left-5px" [innerHTML]="searchResult.description"></div>
<a
  *ngIf="isNearpod(searchResult.source)"
  [href]="searchResult.url+ '/?referrer=ren_next'"
  target="_blank"
  class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action"
>
  <span class="activity-text">View {{btnViewText}} </span>
  <span class="glyph-chevron-next"></span>
</a>
<ng-container *ngIf="isFreckle(searchResult.source)">
  <!-- only show Freckle preview & assign if user is a teacher -->
  <ng-container *ngIf="!adminHanlderService.isAdmin(); else adminTemplate">
    <a
      tabindex="0"
      (click)="goToFreckle(searchResult.standard_id)"
      (keydown.enter)="goToFreckle(searchResult.standard_id)"
      class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action"
      data-test="freckle-assign-button"
    >
      <span class="activity-text">Preview and Assign </span>
      <span class="glyph-chevron-next"></span>
    </a>
  </ng-container>
  <ng-template #adminTemplate>
    <div class="view-activity-margins">
      <available-to-teachers id="pendo-available-to-teachers-text-search-result"
        data-test="freckle-available-to-teachers-text"
        class="search"
      >
      </available-to-teachers>
    </div>
  </ng-template>
</ng-container>
<a
  *ngIf="isLalilo(searchResult.source)"
  tabindex="0"
  (click)="goToLalilo(searchResult.product_skill_id)"
  (keydown.enter)="goToLalilo(searchResult.product_skill_id)"
  class="view-activity view-activity-margins ren-btn ren-btn--secondary take-action"
  id="pendo-search-assignLalilo"
>
  <span class="activity-text">Assign </span>
  <span class="glyph-chevron-next"></span>
</a>
<hr class="divider">
