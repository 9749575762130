<app-searchbox-wrapper></app-searchbox-wrapper>
<div class='home-container'>
  <app-tab-selector [selectedTab]="'superstars'"></app-tab-selector>
  <div class="superstar-container renaissance-style-scoped" aria-labelledby="skip-nav">
  <div class="no-data">
    <h2 id="skip-nav" tabindex="-1" class="sup-text ">Superstars of the Week</h2>
    <p><span class="sub-head">Celebrate your students!</span></p>
  </div>
  <div class="super-data">
    <app-superstar-data></app-superstar-data>
  </div>
</div>
  <app-footer></app-footer>
</div>
