<div *ngIf="showLoadingAnimation" [class.fade-out]="fadeOut" class="loading-animation">
  <div class="image">
    <object
      role="figure"
      alt="Welcome Teacher Loading Animation"
      aria-label="Welcome Teacher Loading Animation"
      data="../../../assets/tx-hero-welcome_loader-ani.svg">
    </object>
  </div>
  <div class="second-text flexcenter">
    <h2 id="dailyMessage" class="drop-ani"><br>
      {{this.message}}
    </h2>
  </div>
</div>
<div *ngIf="showLoadingSpinner &&  (!spinnerMinimumDone || !isAppNavLoaded || !isLoggedIn)" class="loading-spinner">
  <mat-spinner diameter="66" mode="indeterminate" class="mat-spinner search-spinner"></mat-spinner>
</div>
