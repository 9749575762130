import { Injectable } from '@angular/core';
import { EducatorPortalInterappRedirectService } from '../educator-portal-interapp-redirect-service/educator-portal-interapp-redirect-service';
import { AppNavItemConfigurationService } from '../app-nav/app-nav-item-configuration.service';
import { ClassesService } from '../classes/classes.service';
import { ClassActivity, TJClass } from 'src/app/shared/models/class.model';
import { ProductAppTags } from '../product-info/product-info.service';
import { LicenseInfoService } from '../license-info/license-info.service';
import { Student } from 'src/app/shared/models/student.model';
import { LaliloInterAppContext } from 'src/app/shared/models/lalilo-interapp-context.model';

@Injectable({
  providedIn: 'root'
})
export class LaliloLinkGeneratorService {

  constructor(
    private educatorPortalInterappRedirectService: EducatorPortalInterappRedirectService,
    private appNav: AppNavItemConfigurationService,
    private classesService: ClassesService,
    private licenseInfoService: LicenseInfoService
  ) { }

  public async getLaliloPageLink(pageName: string, lessonId: string | undefined = undefined) {

    let classId = await this.getLaliloClass();

    let laliloInterAppContext: LaliloInterAppContext = {
      pageName: pageName,
      renaissanceClassId: classId
    };

    if (lessonId) {
      laliloInterAppContext.laliloLessonId = lessonId;
    }

    let redirectUrl = this.getEducatorPortalLaliloRedirectUrl(JSON.stringify(laliloInterAppContext));

    return redirectUrl;
  }

  public async getLaliloAssignLinkForStudents(lessonId: string, students: Student[]) {

    let classId = await this.getLaliloClass();

    let studentIds: string[] = students.map(student => student.renaissanceId);

    let laliloInterAppContext: LaliloInterAppContext = {
      pageName: 'assignment-planning',
      renaissanceClassId: classId,
      laliloLessonId: lessonId,
      renaissanceStudentIds: studentIds
    };

    let redirectUrl = this.getEducatorPortalLaliloRedirectUrl(JSON.stringify(laliloInterAppContext));

    return redirectUrl;
  }

  private getEducatorPortalLaliloRedirectUrl(laliloPayload: string) {
    let taskId = '6200';
    let applicationid = this.appNav.AppId.lalilo;

    return this.educatorPortalInterappRedirectService.getLaunchAndRedirectUrl(taskId, applicationid, laliloPayload);
  }

  private async getLaliloClass() {
    let classId = '';
    let selectedClass = this.classesService.selectedClass$.getValue();

    // Check if selected class has lalilo assigned
    if (this.classActivityHasLalilo(selectedClass!)) {
      return selectedClass!.renaissanceClassId;
    }

    // Check other classes if selected class does not have lalilo
    if (await this.licenseInfoService.currentClassesHaveLalilo()) {
      let laliloClasses = (await this.classesService.getClasses()).filter(c => this.tjClassHasLalilo(c));
      classId = laliloClasses[0].classRenaissanceId;
    }

    return classId;
  }

  private classActivityHasLalilo(classActivity: ClassActivity) {
    return classActivity.appTags.includes(ProductAppTags.Lalilo);
  }

  private tjClassHasLalilo(tjClass: TJClass) {
    return tjClass.classAppTags?.includes(ProductAppTags.Lalilo);
  }
}
