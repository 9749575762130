import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchFilter, SearchFilterOptions } from 'src/app/home/search-v2/models/search-filters';
import { SearchService } from '../services/search.service';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'app-search-filters-v2',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss']
})
export class SearchFiltersComponent implements OnDestroy, OnInit {
  public filterChangeDebounce$: Subject<{ [key: string]: string; }> = new Subject<{ [key: string]: string; }>();
  public formGroup: FormGroup = new FormGroup({});
  public areFiltersClear = false;
  public searchConstants: any = CONSTANTS;
  public searchFilters: SearchFilter[] = JSON.parse(JSON.stringify(CONSTANTS.SEARCH_FILTERS_SETTINGS));

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    public fb: FormBuilder
  ) { }

  public async ngOnInit(): Promise<void> {
    this.initializeFormControls();
    this.updateFilterValuesFromQueryParams();
    this.debounceSubscriptionUpdateFilters();
  }

  public initializeFormControls(): void {
    let formGroup: Record<string, FormControl> = {};
    formGroup = this.sortOptions(formGroup);
    this.formGroup = new FormGroup(formGroup);
  }

  private sortOptions(formGroup: Record<string, FormControl>): Record<string, FormControl> {
    this.searchFilters?.forEach(filter => {
      const sortedOptions = filter.options.sort((a, b) => {
        if (a.sortOrder !== undefined && b.sortOrder !== undefined) {
          return a.sortOrder - b.sortOrder;
        }
        return 0;
      }).filter(option => option.isSelected).map(option => option.value);

      formGroup[filter.settings.formControlName] = new FormControl(sortedOptions);
    });

    return formGroup;
  }

  public updateFilterValuesFromQueryParams(): void {
    const queryParams = this.route.snapshot.queryParams;

    Object.keys(queryParams).forEach(queryParamKey => {
      const formControl = this.formGroup.get(queryParamKey);

      if (formControl) {
        const formattedQueryParamValues = this.formatQueryParamValues(queryParams[queryParamKey]);
        const selectedValues = this.getSelectedValuesForKey(queryParamKey, formattedQueryParamValues);

        this.updateFilterOptions(queryParamKey, selectedValues);
        formControl.setValue(selectedValues.map(value => value.value));
      }
    });

    this.searchService.updateSearchFilters(this.searchFilters);
  }

  public formatQueryParamValues(queryParamValue: string[] | string): string[] {
    return Array.isArray(queryParamValue) ? queryParamValue : queryParamValue?.split(',');
  }

  public getSelectedValuesForKey(key: string, values: string[]): SearchFilterOptions[] {
    const filter = this.searchFilters?.find(filter => filter.settings.formControlName === key);

    if (!filter) {
      return [];
    }

    return values.map(value => filter.options.find(option => option.value === (value)))
      .filter((item): item is SearchFilterOptions => item !== undefined);
  }

  public updateFilterOptions(queryParamKey: string, selectedValues: SearchFilterOptions[]): void {
    const filterIndex = this.searchFilters.findIndex(filter => filter.settings.formControlName === queryParamKey);
    if (filterIndex !== -1) {
      const filter = this.searchFilters[filterIndex];
      filter.options.forEach(option => {
        option.isSelected = selectedValues.some(selectedValue => selectedValue.value === option.value);
      });
      this.searchFilters[filterIndex] = filter;
    }
  }

  private debounceSubscriptionUpdateFilters(): void {
    this.subscriptions.push(this.filterChangeDebounce$.pipe(debounceTime(800)).subscribe(async () => {
      this.searchService.updateSearchFilters(this.searchFilters);
    }));
  }

  public updateSelectedSearchFilters(changedValues: { [key: string]: string }): void {
    this.searchFilters = this.searchFilters.map(filter => {
      const key = filter.settings.formControlName;
      if (changedValues.hasOwnProperty(key)) {
        return {
          ...filter,
          options: filter.options.map(option => ({
            ...option,
            isSelected: changedValues[key].includes(option.value)
          }))
        };
      }
      return filter;
    });
  }

  public getDisplayText(filter: SearchFilter): string {
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      const selectedValues = formControl.value;

      if (this.areAllAvailableItemsSelected(filter)) {
        return 'All';
      }

      const selectedNames = selectedValues?.map((value: string) => {
        const option = filter.options.find(option => option.value === value);
        return option && option?.displayName ? option.displayName : value;
      });

      return selectedNames?.join(', ');
    }

    return '';
  }

  public areAllAvailableItemsSelected(filter: SearchFilter): boolean {
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      const availableOptions = filter.options.filter(option => option.isAvailable);
      return availableOptions.every(option => formControl.value?.includes(option.value));
    }

    return false;
  }

  public setFilter(): void {
    const changedValues: { [key: string]: string } = {};
    let changedKey = '';

    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      if (control && control.dirty) {
        changedKey = key;
        changedValues[key] = control.value ?? '';
      }
    });

    this.updateSelectedSearchFilters(changedValues);

    const allValuesEmpty = Object.values(changedValues).every(value => value.length === 0);
    this.areFiltersClear = allValuesEmpty;

    this.filterChangeDebounce$.next(changedValues);
  }

  public toggleSelectAll(filter: SearchFilter): void {
    const formControl = this.formGroup.get(filter.settings.formControlName);

    if (formControl) {
      if (this.areAllAvailableItemsSelected(filter)) {
        formControl.setValue([]);
      } else {
        formControl.setValue(filter.options.map(option => option.value));
      }

      this.transformSelectedValuesToSearchFilter({ [filter.settings.formControlName]: formControl.value });
      this.searchService.updateSearchFilters(this.searchFilters);
    }
  }

  public transformSelectedValuesToSearchFilter(selectedValues: { [key: string]: string }): void {
    this.searchFilters = this.searchFilters.map(filter => {
      const key = filter.settings.formControlName;
      const transformedSelectedValues = {
        ...filter,
        options: filter.options.map(option => ({
          ...option,
          isSelected: selectedValues[key]?.includes(option.value) || option.isSelected
        }))
      };
      return transformedSelectedValues;
    });
  }

  public clearFormControlValues(): void {
    this.searchFilters = JSON.parse(JSON.stringify(CONSTANTS.SEARCH_FILTERS_SETTINGS));
    this.formGroup.reset();
    this.searchService.updateSearchFilters(this.searchFilters);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
