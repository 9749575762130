import { StudentContext } from "./student.model";

export interface Superstar {
  student: StudentContext;
  category: SuperstarCategory;
  qualifications: string[];
}

export interface SuperstarResponse {
  students: SuperstarStudentResponse[];
}

export interface SuperstarStudentResponse {
  studentId: string;
  mostRecentMonday: string;
  qualifications: string[];
  category: string;
}

export enum SuperstarCategory {
  PerformanceImprovement = 'PerformanceImprovement',
  EffortImprovement = 'EffortImprovement',
  Performance = 'Performance',
  Effort = 'Effort',
  NoActivity = 'NoActivity'
}
