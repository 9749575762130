import { Injectable } from '@angular/core';
import { StudentContext } from 'src/app/shared/models/student.model';
import { Superstar, SuperstarCategory } from 'src/app/shared/models/superstar.model';
import { SuperstarEffortImprovementService } from '../effort-improvement/superstar.effort-improvement.service';
import { SuperstarEffortService } from '../effort/superstar.effort.service';
import { SuperstarPerformanceImprovementService } from '../performance-improvement/superstar.performance-improvement.service';
import { SuperstarPerformanceService } from '../performance/superstar.performance.service';

@Injectable({
  providedIn: 'root'
})
export class SuperstarQualificationService {

  private qualificationCategoriesToCalculate: SuperstarCategory[] = [
    SuperstarCategory.PerformanceImprovement,
    SuperstarCategory.EffortImprovement,
    SuperstarCategory.Performance,
    SuperstarCategory.Effort,
    SuperstarCategory.NoActivity
  ];

  constructor(
    private superstarPerformanceImprovementService: SuperstarPerformanceImprovementService,
    private superstarEffortImprovementService: SuperstarEffortImprovementService,
    private superstarPerformanceService: SuperstarPerformanceService,
    private superstarEffortService: SuperstarEffortService,
  ) { }

  public qualifyStudents(unrecognizedStudents: StudentContext[], superstarsTargetNumber: number): Superstar[] {
    let superstars: Superstar[] = [];
    let numSuperstarsLeftToAdd = superstarsTargetNumber;
    let currentUnrecognizedStudents = [...unrecognizedStudents];


    for (let category of this.qualificationCategoriesToCalculate) {
      if (numSuperstarsLeftToAdd === 0) {
        break;
      }
      let superstarsFromCategory: Superstar[] = this.getQualifiedStudentsForCategory(category, currentUnrecognizedStudents, numSuperstarsLeftToAdd);
      superstars.push(...superstarsFromCategory);
      numSuperstarsLeftToAdd = numSuperstarsLeftToAdd - superstarsFromCategory.length;
      currentUnrecognizedStudents = currentUnrecognizedStudents.filter( unrecognized =>
        !superstars.some(superstar => superstar.student.renaissanceId === unrecognized.renaissanceId)
      );
    }

    return superstars;
  }

  private getQualifiedStudentsForCategory(category: SuperstarCategory, students: StudentContext[], numSuperstarsLeftToAdd: number): Superstar[] {
    let superstars: Superstar[] = [];

    let i = 0;
    while (i < students.length && superstars.length < numSuperstarsLeftToAdd) {
      let student = students[i];
      let qualifications: string[] = [];

      switch (category) {
        case SuperstarCategory.PerformanceImprovement:
          qualifications = this.superstarPerformanceImprovementService.getPerformanceImprovementQualifications(student);
          break;
        case SuperstarCategory.EffortImprovement:
          qualifications = this.superstarEffortImprovementService.getEffortImprovementQualifications(student);
          break;
        case SuperstarCategory.Performance:
          qualifications = this.superstarPerformanceService.getPerformanceQualifications(student);
          break;
        case SuperstarCategory.Effort:
          qualifications = this.superstarEffortService.getEffortQualifications(student);
          break;
        case SuperstarCategory.NoActivity:
          qualifications = [`This student hasn't been recognized yet. Pick a card and send them encouragement!`];
          break;
      }

      if (qualifications.length > 0) {
        let superstar: Superstar = {
          student: student,
          category: category,
          qualifications: qualifications
        };

        superstars.push(superstar);
      }

      i++;
    };

    return superstars;
  }
}
