import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: 'app-header-loading',
  templateUrl: './header-loading.component.html',
  styleUrls: ['./header-loading.component.scss']
})
export class HeaderLoadingComponent {
}
