import { Component, Input, OnInit } from '@angular/core';
import { Assessment } from 'src/app/shared/models/assessment.model';
import { GrowthThresholds } from './GrowthThresholds';
import { BenchmarksService } from 'src/app/services/benchmarks/benchmarks.service';
import { AssessmentSummaryCategory } from 'src/app/shared/models/assessment-benchmarks.model';
import { BarColor, CBMColor, NoNormingDataColor } from 'src/app/services/benchmarks/benchmark-bar-colors';

@Component({
  selector: 'assessment-detail-result',
  templateUrl: './assessment-detail-result.component.html',
  styleUrls: ['./assessment-detail-result.component.scss']
})
export class AssessmentDetailResultComponent implements OnInit {
  @Input() assessment!: Assessment;
  public assessmentCategories: AssessmentSummaryCategory[] = [];

  public constructor(private benchmarksService: BenchmarksService) { }

  public async ngOnInit(): Promise<void> {
    await this.loadCategories();
  }

  public isCBM(productId: string) {
    let cbmProducts = ['STAR_CBM_MATH', 'STAR_CBM_READING', 'STAR_CBM_LECTURA'];

    return cbmProducts.includes(productId);
  }

  public getSGPText(currentSGP: number): string {
    if (currentSGP > 0) {
      return currentSGP.toString();
    }
    else {
      return '--';
    }
  }

  public getGrowthCategory(currentSGP: number): string {
    if (currentSGP >= GrowthThresholds.HIGH) {
      return "(High Growth)";
    }
    else if (currentSGP >= GrowthThresholds.MODERATE) {
      return "(Moderate Growth)";
    }
    else if (currentSGP >= GrowthThresholds.LOW) {
      return "(Low Growth)";
    }
    else {
      return "";
    }
  }

  public getStudentCategoryTitle(assessment: Assessment) {
    let filteredCategories = this.assessmentCategories.filter(x => x.productId === assessment.productId);

    let percentileRank = assessment.percentileRank;
    for (let category of filteredCategories) {
      if (percentileRank >= category.minPercentileRank && percentileRank <= category.maxPercentileRank) {
        return category.title;
      }
    };

    return 'No Norming Data';
  }

  public getStudentCategoryColor(assessment: Assessment) {
    let filteredCategories = this.assessmentCategories.filter(x => x.productId === assessment.productId);

    let percentileRank = assessment.percentileRank;
    for (let category of filteredCategories) {
      if (percentileRank >= category.minPercentileRank && percentileRank <= category.maxPercentileRank) {
        return category.color;
      }
    };

    return NoNormingDataColor;
  }

  public toPercent(decimal: number) {
    return Math.trunc(decimal * 100) + '%';
  }

  public generateCBMCategory(category: string | null) {
    if (category == null) {
      return 'Off-grade (no benchmark)';
    }
    return category;
  }

  public getCBMColor(category: string | null) {
    switch (category) {
      case 'Intervention':
      case 'Alert':
        return CBMColor.urgent;
      case 'At/Above Benchmark':
      case 'Satisfactory/Optimal':
        return CBMColor.atBenchmark;
      case 'On Watch':
        return CBMColor.onWatch;
      case null:
        return CBMColor.offGrade;
      default:
        return '';
    }
  }

  private async loadCategories() {
    let categories = await this.benchmarksService.getBenchmarkCategories();
    this.assessmentCategories = categories;
  }
}
