<div class="container renaissance-style-scoped" *ngIf="data">
  <div class="header-container">
    <div class="header">
      <div class="header-text">
        <app-student-bubble [student]="data.student"></app-student-bubble>
        <div class="header-student-name" tabindex="0" [attr.aria-label]="'Student Name: ' + data.student.firstName + ' ' + data.student.lastName">
          <div class="header-student-name-text">{{data.student.firstName}}</div>
          <div class="header-student-name-text">{{data.student.lastName}}</div>
        </div>
        <div class="header-title">
          <h2 class="header-title-text">Assessment Results</h2>
          <div class="header-title-subtext">Last 90 days</div>
        </div>
      </div>
      <product-deep-link type="star" [zoneHomeUri]="zoneUri" [context]="context"></product-deep-link>
    </div>
    <hr class="header-footer-hr" aria-hidden="true" />
  </div>

  <table class="assessments desktop-layout">
    <thead>
      <tr>
        <th>Date</th>
        <th>Assessment</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let assessment of assessments">
        <td class="padding-right">{{assessment.completedDate | date: 'shortDate'}}</td>
        <td class="padding-right">{{getProductName(assessment)}}</td>
        <td><assessment-detail-result [assessment]="assessment"></assessment-detail-result></td>
      </tr>
    </tbody>
  </table>

  <div class="assessments mobile-layout" role="list">
    <ul>
      <li *ngFor="let assessment of assessments; index as index">
        <table  class="mobile-assessments">
          <tr>
            <th class="item-content-head" style="vertical-align: top;">Date</th>
            <td class="item-content">{{assessment.completedDate | date: 'shortDate'}}</td>
          </tr>
          <tr>
            <th class="item-content-head" style="vertical-align: top;">Assessment</th>
            <td class="item-content">{{getProductName(assessment)}}</td>
          </tr>
          <tr>
            <th class="item-content-head" style="vertical-align: top;">Result</th>
            <td class="item-content assessment-detail-modal">
              <span style="position: relative;">
                <assessment-detail-result [assessment]="assessment"></assessment-detail-result>
              </span>
            </td>
          </tr>
        </table>
        <hr *ngIf="index != assessments.length-1" class="list-item-hr" aria-hidden="true" />
      </li>
    </ul>
  </div>

  <div class="footer">
    <hr class="header-footer-hr" aria-hidden="true" />
    <div class="footer-button">
      <button class="ren-link" (click)="close()">Close</button>
    </div>
  </div>
</div>
