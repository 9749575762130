<a
  id="pendo-available-to-teachers-text-reports-search-desktop"
  class="available-to-teachers-text"
  href="#"
  (click)="$event.preventDefault()"
  role="button"
>
  <span class="glyph glyph-info"></span>
  <span>Available to teachers</span>
</a>
