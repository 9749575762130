<loading-animation></loading-animation>
<mat-sidenav-container>
  <mat-sidenav-content>
    <a class="skip-to-content-link" [routerLink]="[]" (click)="navigateSkipLink('skip-nav')">Skip to main content</a>
    <element-renaissance-header
      *axLazyElement="headerElementUrl; errorTemplate: error"
      [elementConfig]="headerElementConfig"
      [helpUrl]="helpUrl$ | async"
    >
    </element-renaissance-header>
    <ng-template #error>
      <div class="header-loading-margin-adjust">
        <app-header-loading></app-header-loading>
      </div>
    </ng-template>

    <div class='page'>
      <div class="left-padding"></div>
      <main class="new-main">
        <app-left-nav [sendFeedbackId]="'send-feedback-desktop'" class="left-nav"></app-left-nav>
        <div class="content">
          <app-home></app-home>
        </div>
      </main>
      <div class="right-padding"></div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
