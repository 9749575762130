<div *ngIf="showEmptyStudentGroups && inactiveStudents && skillsPracticeCards.length == 0; else hideEmptyStudentGroups">
  <empty-student-groups></empty-student-groups>
</div>

<ng-template #hideEmptyStudentGroups>
  <inactive-card *ngIf="inactiveStudents.length > 0" [inactiveStudents]="inactiveStudents"></inactive-card>

  <!--clean this up with skills-practice-experiment-toggle-->
  <div class="recommended-skills-container" *ngIf="useRecommendedSkillGroups === true && topStuckCards.length > 0 || topReadyForChallengeCards.length > 0">
    <h3 class="recommended-skills-header">Recommended Skill Groups</h3>
    <p class="recommended-skills-description">Based on practice in {{recommendedSkillGroupProductText}}</p>
    <div class="active-skill-card"></div>
    <recommended-skill-card
      *ngIf="topStuckCards.length > 0"
      [skillsPracticeCards]="topStuckCards"
      [skillsPracticeCategory]="skillPracticeCategoryEnumValue.Stuck">
    </recommended-skill-card>
    <recommended-skill-card
      *ngIf="topReadyForChallengeCards.length > 0"
      [skillsPracticeCards]="topReadyForChallengeCards"
      [skillsPracticeCategory]="skillPracticeCategoryEnumValue.ReadyForChallenge">
    </recommended-skill-card>
  </div>
  <div class="show-all-skill-activity-button-container" *ngIf="allSkillActivitySectionCollapsed === true">
    <button class="show-all-skill-activity-button" (click)="showAllSkillActivitySection()">Show All Skill Activity and Recommendations</button>
  </div>

  <div *ngIf="allSkillActivitySectionCollapsed === false">
    <div class="active-skills-header-container">
      <h3 class="active-skills-header" *ngIf="skillsPracticeCards.length > 0">{{activeSkillText}}</h3>
      <app-filter-skills *ngIf="skillsPracticeCards.length > 0" (updateSort)="sort($event)"></app-filter-skills>
    </div>
    <div class="active-skill-card" *ngFor="let skillsPracticeCard of skillsPracticeCards">
        <active-skill-card [skillsPracticeCard]="skillsPracticeCard"></active-skill-card>
    </div>
  </div>
</ng-template>
