import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnChanges, OnInit {

  @Input() leftName: string = 'ATOS';
  @Input() leftValue: string = 'atos';
  @Input() rightName: string = 'Lexile ®';
  @Input() rightValue: string = 'lexile';
  @Input() value: string = this.leftValue;
  @Output() toggleChanged = new EventEmitter<string>();


  constructor(private element: ElementRef) {
    this._updateName();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._updateName();
  }

  ngOnInit(): void {
    this._updateName();
  }

  private _updateName() {
    // stringify is needed to double escape string for css var
    this.element.nativeElement.style.setProperty('--left-name', JSON.stringify(this.leftName));
    this.element.nativeElement.style.setProperty('--right-name', JSON.stringify(this.rightName));
  }

  public get rightSelected() {
    return this.value === this.rightValue;
  }

  public get leftSelected() {
    return this.value === this.leftValue;
  }

  public toggle() {
    if (this.rightSelected) {
      this.value = this.leftValue;
    } else {
      this.value = this.rightValue;
    }

    this.toggleChanged.emit(this.value);
  }

}
