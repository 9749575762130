export interface AppNavItem {
  id?: AppNavItemIds,
  displayName: string,
  longDisplayName?: string,
  subHeader?: string,
  applicationId?: string,
  taskId?: string,
  children?: AppNavItem[],
  url?: string,
  tileLinkType?: TileLinkType,
  imagePrefix?: string,
    /**
   * @deprecated Use deepLink
   */
  deepLinkId?: string,
  deepLink?: string,
  appCode?: string
}

export enum AppNavItemIds {
  REPORTS = 'REPORTS',
  AR = 'AR',
  STAR = 'STAR',
  RENU = 'RENU'
}

export enum TileLinkType {
  FRECKLE_REPORTS_PAGE = 1,
  LALILO_REPORTS_PAGE = 2,
  MYON_REPORTS_PAGE = 3,
  STARPHONICS_REPORTS_PAGE = 4
}
