import { Component, Input } from '@angular/core';
import { Student } from '../models/student.model';
import { MatDialog } from '@angular/material/dialog';
import { StudentSkillDetailModalData } from './student-skill-detail-modal/student-skill-detail-modal-data';
import { StudentSkillDetailModalComponent } from './student-skill-detail-modal/student-skill-detail-modal.component';
import { MaxModalDimensionsService } from 'src/app/services/max-modal-dimensions/max-modal-dimensions.service';
import { SkillMetadata } from '../models/skill-metadata.model';


@Component({
  selector: 'app-student-grouping',
  templateUrl: './student-grouping.component.html',
  styleUrls: ['./student-grouping.component.scss']
})

export class StudentGroupingComponent {
  @Input() students: Student[] = [];
  @Input() enablePopup: boolean = false;
  @Input() skillMetadata!: SkillMetadata;
  @Input() hasAmbiguousShortName: boolean = false;

  public constructor(
    private matDialog: MatDialog,
    private maxModalDimensions: MaxModalDimensionsService
  ) { }

  public openModal(student: Student): void {
    if (!this.enablePopup) {
      return;
    }

    this.matDialog.open<StudentSkillDetailModalComponent, StudentSkillDetailModalData>(StudentSkillDetailModalComponent, {
      maxWidth: this.maxModalDimensions.maxWidth(),
      maxHeight: this.maxModalDimensions.maxHeight(),
      restoreFocus: true,
      data: {
        student,
        skillMetadata: this.skillMetadata,
        hasAmbiguousShortName: this.hasAmbiguousShortName
      }
    });
  }
}
