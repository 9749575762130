import { Inject, Injectable } from '@angular/core';
import { HeaderElementConfig } from 'src/app/shared/models/header-element-config';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { ChatConfig } from 'src/app/shared/models/chat-config.model';
import { DOCUMENT } from '@angular/common';
import { AppNavConfig } from 'src/app/shared/models/app-nav-config.model';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { AdminHandlerService } from '../admin-handler/admin-handler.service';
import { AppNavV2Service } from '../app-nav-v2/app-nav-v2.service';
import { ProductAppCodes } from '../product-info/product-info.service';
import { NextLeaderLinkGeneratorV2Service } from '../link-generators-v2/nextleader-link-generator-v2.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderConfigService {
  private availableData: AppNavItem[] = [];
  private availableApps: AppNavItem[] = [];
  private otherAvailableApps: AppNavItem[] = [];
  private searchReports: AppNavItem[] = []
  private isDegradedNav: boolean = false;

  private readonly renAnalyticsTaskId = '4852';
  private readonly renAnalyticsApplicationId = '70';
  private readonly schoolZillaStarterTaskId = '4850';
  private readonly schoolZillaStarterApplicationId = '55';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private userService: UserService,
    private nextLeaderLinkGeneratorV2Service: NextLeaderLinkGeneratorV2Service,
    private adminHandlerService: AdminHandlerService,
    private appNavV2Service: AppNavV2Service,
  ) { }

  public async getConfig(): Promise<HeaderElementConfig> {
    let userDetail = (await this.userService.getUserDetail());
    let rgpBaseUrl = this.userService.getRgpBaseUrl();
    let homeUri = this.userService.getHomeUri();

    let chatConfig: ChatConfig = {
      customChatJSFile: environment.chatConfigScriptUrl,
      customerId: this.userService.getClientId()!,
      userToken: '',
      host: this.document.host
    }

    // The shared header is not configured to handle next leader links with launch control.
    // Until it is, we have to generate and send the pre-launch-control next leader link.
    let nextLeaderNavLink = this.getNextLeaderPageLink();

    await this.appNavV2Service.initializeAppNavV2();
    this.isDegradedNav = this.appNavV2Service.getIsDegraded();
    if (this.isDegradedNav == false) {
      this.availableApps = await this.appNavV2Service.getAvailableV2Apps();
      this.availableData = this.appNavV2Service.getAvailableV2MyData();
      this.otherAvailableApps = await this.appNavV2Service.getOtherAvailableApps();
      this.availableData = await this.appNavV2Service.resolveLinks(this.availableData);

      this.searchReports = await this.appNavV2Service.getAvailableV2SearchReports();
      this.searchReports = await this.appNavV2Service.resolveLinks(this.searchReports);
    }
    else {
      this.availableApps = this.appNavV2Service.getAppsForDegradedMode();
    }

    if (this.adminHandlerService.isAdmin()) {
      this.availableApps = this.availableApps.filter(x => x.appCode != ProductAppCodes.RenAnalytics
        && x.appCode != ProductAppCodes.SchoolZillaStarter
        && x.appCode != ProductAppCodes.Schoolzilla);
    }

    let headerAppNavConfig: AppNavConfig = {
      availableApps: this.availableApps,
      availableData: this.availableData,
      otherAvailableApps: this.otherAvailableApps,
      searchReports: this.searchReports,
      launchBaseUri: homeUri,
      isDegraded: this.isDegradedNav,
      useLaunchControl: true,
      nextAppNavConfig: this.adminHandlerService.isAdmin() ? { nextLeaderNavLink: nextLeaderNavLink, canSwitchToLeader: true } : undefined
    };

    // required config
    let userName = !userDetail ? '' : userDetail.firstName + ' ' + userDetail.lastName;

    let headerElementConfig: HeaderElementConfig = {
      logoutUrl: 'logout',
      userName: userName,
      baseUrl: environment.headerConfig.url,
      version: environment.headerConfig.version,
      hideUserNameDropdown: false,
      culture: this.userService.getCulture(),
      resourceSiteUrl: environment.rgpResourceSiteUrl,
      launchControlBaseUrl: environment.launchControlServiceUrl,
      accessToken: '',
    };

    headerElementConfig.supportLargerScreenWidth = true;

    // user menu
    headerElementConfig.settingsUrl = `${rgpBaseUrl}userprofileportal/`;
    headerElementConfig.manageAppsUrl = `${rgpBaseUrl}sismanagement/`;
    headerElementConfig.lockedStudentsUrl = `${rgpBaseUrl}sismanagement/users/unlock-my-students/`;
    headerElementConfig.preferenceUrl = `${rgpBaseUrl}preferenceportal/`;

    // nav menu
    headerElementConfig.appNavConfig = headerAppNavConfig;

    // help menu
    headerElementConfig.chatConfig = chatConfig;

    return headerElementConfig;
  }

  public getElementUrl(): string {
    let headerElementUrl = `${environment.headerConfig.url}/main.js`;
    return headerElementUrl;
  }

  private getNextLeaderPageLink(): string {
    const nextLeaderAppCode = this.nextLeaderLinkGeneratorV2Service.getNextLeaderAppCode();

    // Schoolzilla configured by default
    let taskId = this.schoolZillaStarterTaskId;
    let applicationId = this.schoolZillaStarterApplicationId;

    if (nextLeaderAppCode === ProductAppCodes.RenAnalytics) {
      taskId = this.renAnalyticsTaskId;
      applicationId = this.renAnalyticsApplicationId;
    }

    const educatorPortalBase = this.userService.getHomeUri();
    const launchUrl = `${educatorPortalBase}launch/?task=${taskId}&applicationId=${applicationId}`;

    return launchUrl;
  }
}
