<div class="search-results-container">
  <app-search-input-v2 #searchInput [isSearchboxScroll]="isSearchboxScroll" [showBackButton]="true"></app-search-input-v2>
  <ng-container *ngIf="showOops; else searchResultsTable">
    <div class="search-error-container renaissance-style-scoped">
      <object type="image/svg+xml" role="presentation" alt="" class="oops-image" data="../assets/hero-search_error-ani.svg"></object>
      <div class="oops-message-container">
        <div class="oops-message-header">
          {{searchConstants.SEARCH_RESULTS.SOMETHING_WRONG}}
        </div>
        <div class="oops-message-content">
          {{searchConstants.SEARCH_RESULTS.PROBLEM_SEARCH}}
        </div>
        <a class="ren-btn" href="/search?q={{query}}">{{searchConstants.SEARCH_RESULTS.TRY_AGAIN}}</a>
      </div>
    </div>
  </ng-container>
  <ng-template #searchResultsTable>
    <div class="search-results-table" (scroll)="onScroll($event)">
      <table class="margin-top-xlarge">
        <app-search-filters-v2></app-search-filters-v2>
        <ng-container *ngIf="!(searchService?.isLoading$ | async) && !isLoadingMoreResults()">
          <tbody *ngIf="searchResults.length > 0">
            <hr class="divider">
            <ng-container *ngFor="let result of searchResults">
              <tr class="search-results-tr" [attr.id]="result.product_skill_id" tabindex="-1" [searchResult]="result" search-result-v2></tr>
            </ng-container>
            <div class="more-results-container">
              <button *ngIf="!(searchService?.isLoading$ | async) && moreResults" id="pendo-guide-search-more-results" class="search-more-results"
                (click)="getMoreResults()">{{searchConstants.SEARCH_RESULTS.MORE_RESULTS}}</button>
              <mat-spinner *ngIf="isLoadingMoreResults()" diameter="20" mode="indeterminate" class="mat-spinner more-results-spinner"></mat-spinner>
              <div class="more-results-footer">
                <span class="glyph-lamp2 tips-glyph"></span>
                <div class="more-results-footer-text">
                  {{searchConstants.SEARCH_RESULTS.TROUBLE_FINDING}}
                  <a id="pendo-guide-learn-more-search-tips" class="more-results-tips" href="#" (click)="$event.preventDefault()" role="button">
                    {{searchConstants.SEARCH_RESULTS.TRY_TIPS}}
                  </a>
                </div>
              </div>
            </div>
          </tbody>
        </ng-container>
        <div *ngIf="searchResults.length == 0 && !(searchService?.isLoading$ | async)" class="no-search-results">
          <hr class="divider">
          <div class="no-search-results-text">
            {{searchConstants.SEARCH_RESULTS.NO_RESULTS}}
          </div>
        </div>
        <div class="footer-spacer" *ngIf="searchResults.length == 0 || (searchService?.isLoading$ | async)"></div>
        <app-footer></app-footer>
      </table>
    </div>
  </ng-template>
  <div *ngIf="(searchService?.isLoading$ | async) || isLoadingMoreResults()" id="search-loading-spinner" class="loading-spinner-container">
    <mat-spinner diameter="60" mode="indeterminate" class="mat-spinner search-spinner"></mat-spinner>
  </div>
</div>
