import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IntroAnimationService, IntroAnimationStatus } from 'src/app/services/intro-animation/intro-animation.service';
import * as CONSTANTS from '../settings/search.constants';

@Component({
  selector: 'app-searchbox-v2',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnDestroy, OnInit {
  public isIntroAnimationComplete: boolean = false;
  public playFadeInAnimation: boolean = false;
  public searchConstants: any = CONSTANTS;

  private subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private introAnimationService: IntroAnimationService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.setupAnimationStatus();
    this.cdr.detectChanges();
  }

  public setupAnimationStatus(): void {
    this.subscriptions.push(this.introAnimationService.searchIntroAnimationStatus$.subscribe((animationStatus: IntroAnimationStatus | undefined) => {
      if (animationStatus) {
        return this.checkAnimationStatus(animationStatus);
      }
      return false;
    }));
  }

  public checkAnimationStatus(animationStatus: IntroAnimationStatus): void {
    if (animationStatus.complete) {
      this.isIntroAnimationComplete = true;
    }

    if (animationStatus.enabled) {
      this.playAnimation();
    }
  }

  private playAnimation(): void {
    this.playFadeInAnimation = true;
    setTimeout(() => {this.playFadeInAnimation = false}, 400);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
