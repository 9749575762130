

<div *ngIf="!!nearpodLink" id="pendo-nearpod-skill" class="action-link-section">
  <div class="link-description">{{nearpodLink.description}}</div>
  <a id="nav-help"
    [href]="nearpodLink.link"
    attr.aria-label="{{nearpodLink.name}} (opens in a new tab)"
    class="action-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img [src]="nearpodLink.imageUrl" alt="nearpod_logo" class="action-product-logo" />
    <span class="link-name">{{nearpodLink.name}}</span>
    <span class="glyph glyph-externallink"></span>
  </a>
</div>
<div *ngIf="!!freckleLink" id="pendo-freckle-skill" class="action-link-section">
    <div class="link-description">{{freckleLink.description}}</div>
    <ng-container *ngIf="!adminHandlerService.isAdmin(); else adminTemplate">
      <a id="nav-help"
        [href]="freckleLink.link"
        attr.aria-label="{{freckleLink.name}} (opens in a new tab)"
        class="action-link"
        data-test="freckle-action-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img [src]="freckleLink.imageUrl" alt="freckle_logo" class="action-product-logo" />
        <span class="link-name">{{freckleLink.name}}</span>
        <span class="glyph glyph-externallink"></span>
      </a>
    </ng-container>
    <ng-template #adminTemplate>
      <div id="nav-help" class="action-link-disabled" data-test="freckle-action-link-disabled">
        <div class="action-link-container">
          <img [src]="freckleLink.imageUrl" alt="" class="action-product-logo" />
          <div class="action-link-text">
            <span class="link-name">{{freckleLink.name}}</span>
            <available-to-teachers id="pendo-available-to-teachers-text-take-action" class="take-action">
            </available-to-teachers>
          </div>
          <span class="glyph glyph-externallink"></span>
        </div>
      </div>
    </ng-template>
</div>
