<div class="app-link-button-container">
  <ng-container *ngIf="enableSkillsPracticeAppLink(); else disabledTemplate">
    <ng-container>
      <a class="app-link-button enabled" 
      (click)="navigateByLaunchControl($event, appName, appUrl)" 
      [attr.aria-label]="getAriaLabel()" href="#">
        <img class="app-link-button-logo" [src]="appSvg">
        <div class="app-link-button-name">{{appName}}</div>
        <div class="app-link-button-page">{{appPage}}</div>
      </a>
    </ng-container>
  </ng-container>
  <ng-template #disabledTemplate>
    <div class="app-link-button disabled" [attr.aria-label]="getAriaLabel()">
      <img class="app-link-button-logo grayscale" [src]="appSvg">
      <div class="app-link-button-name">{{appName}}</div>
      <div class="app-link-button-page">{{appPage}}</div>
    </div>
    <a tabindex="-1" id="pendo-available-to-teachers-text-skills-practice-link" class="skills-practice-app" href="#" (click)="$event.preventDefault()">
      <available-to-teachers></available-to-teachers>
    </a>
  </ng-template>
</div>
