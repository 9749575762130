import { Component, OnInit } from '@angular/core';
import { ContextDataService } from 'src/app/services/context-data/context-data.service';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { LicenseInfoService } from 'src/app/services/license-info/license-info.service';
import { FreckleLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/freckle-link-generator-v2.service';
import { LaliloLinkGeneratorV2Service } from 'src/app/services/link-generators-v2/lalilo-link-generator-v2.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { SubjectService } from 'src/app/services/subject/subject.service';
import { ClassContext } from 'src/app/shared/models/class.model';
import { StudentContext } from 'src/app/shared/models/student.model';

@Component({
  selector: 'empty-student-groups',
  templateUrl: './empty-student-groups.component.html',
  styleUrls: ['./empty-student-groups.component.scss']
})
export class EmptyStudentGroupsComponent implements OnInit {
  public readonly laliloLearnMoreLink: string = 'https://lalilo-help.renaissance.com/hc/en-us/categories/8781333653019-Getting-Started';
  public readonly freckleLearnMoreLink: string = 'https://frecklehelp.renaissance.com/hc/en-us/categories/10090035362075-Getting-Started-Guide-for-Teachers';
  private readonly laliloAssignmentPlanningPageName: string = 'assignment-planning';

  public showLaliloAssignPracticeLink: boolean = false;
  public showFreckleAssignPracticeLink: boolean = false;

  public laliloAssignPracticeLink: string = '';
  public freckleAssignPracticeLink: string = '';

  public constructor(
    private licenseInfoService: LicenseInfoService,
    private laliloLinkGeneratorV2Service: LaliloLinkGeneratorV2Service,
    private freckleLinkGeneratorV2Service: FreckleLinkGeneratorV2Service,
    private contextDataService: ContextDataService,
    private featureToggleService: FeatureToggleService,
    private subjectService: SubjectService,
    private launchControlService: LaunchControlService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.showLaliloAssignPracticeLink = await this.licenseInfoService.selectedClassHasLalilo() && this.subjectService.selectedSubjectIsReading();
    this.showFreckleAssignPracticeLink =
      (await this.licenseInfoService.selectedClassHasFreckleMath() && this.subjectService.selectedSubjectIsMath()) ||
      (await this.licenseInfoService.selectedClassHasFreckleReading() && this.subjectService.selectedSubjectIsReading());

    let students: StudentContext[] = [];

    this.contextDataService.classDataContext$.subscribe((classData: ClassContext | undefined) => {
      if (classData) {
        students = classData.students;
      }
    });

    if (this.showLaliloAssignPracticeLink) {
      this.laliloAssignPracticeLink = await this.laliloLinkGeneratorV2Service.getLaliloPageDeepLink(this.laliloAssignmentPlanningPageName);
    }

    if (this.showFreckleAssignPracticeLink) {
      this.freckleAssignPracticeLink = await this.freckleLinkGeneratorV2Service.getAssignTargetedPracticeDeepLink(students);
    }
  }

  public async launchLaliloAssignPractice(): Promise<void> {
    await this.featureToggleService.sendTrackEvent('empty-student-groups-freckle-assign-practice-click');
    this.launchControlService.launchApp(ProductAppCodes.Lalilo, this.laliloAssignPracticeLink, true);
  }

  public async launchFreckleAssignPractice(): Promise<void> {
    await this.featureToggleService.sendTrackEvent('empty-student-groups-lalilo-assign-practice-click');
    this.launchControlService.launchApp(ProductAppCodes.Freckle, this.freckleAssignPracticeLink, true);
  }
}
