<hr class="result-divider" aria-hidden="true">
<div class="report-search-container">
  <div *ngIf="this.searchResults.length == 0 && !this.showNoResultsFound; else backButton" class="header-text">Search for reports</div>
  <ng-template #backButton>
    <button class="back-button" (click)="back()">
      <span class="glyph-chevron-previous"></span>
      <span> Back</span>
    </button>
  </ng-template>
  <div class="search-input-container">
    <input #allyBox
      [(ngModel)]="this.searchValue"
      (keyup.enter)="onEnter(allyBox.value)"
      class="search-input"
      type="text"
      id="pendo-report-search-box">
    <button id="pendo-report-search-button" (click)="onEnter(allyBox.value)" aria-label="Search"><span class="glyph-search2"></span></button>
    <div *ngIf="this.shortQuery" class="short-query-message">
      Please enter at least 2 characters
    </div>
  </div>
  <mat-spinner diameter="30" mode="indeterminate" class="mat-spinner search-spinner" *ngIf="this.showSpinner"></mat-spinner>
  <div *ngIf="this.searchResults.length > 0 && !this.showSpinner" class="search-results-container">
    <h3 class="search-results-header">Results</h3>
    <!-- search results -->
    <div class="search-results" *ngFor="let result of this.searchResults">
      <div
        class="search-result"
        (click)="reportEnabledForUser(result) ? navigate(result) : $event.preventDefault()"
        (keydown.enter)="reportEnabledForUser(result) ? navigate(result) : $event.preventDefault()"
        tabindex="0"
      >
        <img *ngIf="result.imagePrefix" class="link-logo" alt="{{this.transformToTitleCase(result.imagePrefix)}}" src="../../../assets/logos/{{result.imagePrefix}}.svg">
        <div class="search-result-text">
          <h4 class="search-result-title">{{result.displayName}}</h4>
          <div class="search-result-description">{{result.subHeader}}</div>
          <a tabindex="-1" *ngIf="!reportEnabledForUser(result)" id="pendo-available-to-teachers-text-reports-search-desktop"
            class="view-activity-margins reports" href="#" (click)="$event.preventDefault()"
            (keydown.enter)="$event.stopPropagation()">
            <available-to-teachers
              data-test="available-to-teachers-component">
            </available-to-teachers>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showNoResultsFound && !this.showSpinner" class="no-results-message">
    No results found
  </div>
</div>
