import { Component, Input } from '@angular/core';
import { Student } from '../models/student.model';


@Component({
  selector: 'app-student-icon',
  templateUrl: './student-icon.component.html',
  styleUrls: ['./student-icon.component.scss']
})

export class StudentIconComponent {
  @Input() student!: Student;
}
