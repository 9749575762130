import { Injectable } from "@angular/core";
import { AppNavItemIds, AppNavItem, TileLinkType } from "src/app/shared/models/app-nav-item.model";

@Injectable({
  providedIn: 'root'
})
export class AppNavItemConfigurationService {

  public readonly AppId = {
    renAnalytics: '70',
    schoolZillaStarter: '55',
    lalilo: '62'
  };

  public getMyDataNavs() {
    let MYDATA_NAVS_REPORTS: AppNavItem = {
      id: AppNavItemIds.REPORTS,
      displayName: 'Reports',
      imagePrefix: 'reports_logo',
      children: this.getReportsLinks()
    };


    let MYDATA_NAVS: AppNavItem[] =
      [
        MYDATA_NAVS_REPORTS
      ];

    return MYDATA_NAVS;
  }

  private getReportsLinks() {
    let items: AppNavItem[] = [];
    items.push({
      displayName: 'Star / Assessment',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      children: [
        {
          displayName: 'Reports',
          applicationId: '7771',
          taskId: '777101',
        },
        {
          displayName: 'Data Insights',
          applicationId: '4',
          taskId: '409'
        }
      ]
    });
    items.push({
      displayName: 'Star CBM',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777105',
      children: [
        {
          displayName: 'Data Insights',
          applicationId: '7771',
          taskId: '777105'
        },
        {
          displayName: 'Caregiver Report',
          applicationId: '51',
          taskId: '5102'
        }
      ]
    });
    items.push({
      displayName: 'Star Phonics Reports',
      imagePrefix: 'star_logo',
      applicationId: '69',
      taskId: '6900',
      tileLinkType: TileLinkType.STARPHONICS_REPORTS_PAGE
    });
    items.push({
      displayName: 'Accelerated Reader Reports',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100'
    });
    items.push({
      displayName: 'Freckle Reports',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });
    items.push({
      displayName: 'myON Reports',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      tileLinkType: TileLinkType.MYON_REPORTS_PAGE
    })
    items.push({
      displayName: 'Lalilo Reports',
      imagePrefix: 'lalilo_logo',
      applicationId: this.AppId.lalilo,
      taskId: '6200',
      url: 'class-snapshot',
      tileLinkType: TileLinkType.LALILO_REPORTS_PAGE
    })
    items.push({
      displayName: 'Administrator Reports',
      applicationId: '7771',
      taskId: '777103'
    });
    items.push({
      displayName: 'My Created Reports',
      applicationId: '7771',
      taskId: '777104'
    });
    items.push({
      displayName: 'Data Sharing',
      applicationId: '7776',
      taskId: '777600'
    });
    items.push({
      displayName: 'Custom Reports',
      applicationId: '7777',
      taskId: '777700'
    });
    return items;
  }

  public getSearchReports() {
    let items: AppNavItem[] = [];

    // AR reports

    //// Monitor Progress
    items.push({
      displayName: 'Summary Diagnostic Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_SUMMARY_DIAGNOSTIC',
      subHeader: 'Summarizes student performance on assignments, showing progress towards goals and potential problems.'
    });

    items.push({
      displayName: 'Student Performance Record Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_STUDENT_PERF_RECORD',
      subHeader: 'Shows individual student performance on each assignment or quiz.'
    });

    items.push({
      displayName: 'AR Record Book & Goals',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_AR_RECORDBOOK_AND_GOALS',
      subHeader: 'View each student\'s latest quiz results and set goals. You can select a student to see details and take action.'
    });

    items.push({
      displayName: 'Schoolwide Summary Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_SCHOOLWIDE_SUMMARY',
      subHeader: 'Summarizes class and grade level performance.'
    });

    items.push({
      displayName: 'TWI Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_TWI',
      subHeader: 'Summarizes students\' reading on books read To, With, and Independently.'
    });

    items.push({
      displayName: 'Independent Reading Diagnostic Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_IND_READING_DIAGNOSTIC',
      subHeader: 'Shows current independent reading data, and groups students according to independent reading performance.'
    });

    items.push({
      displayName: 'Marking Period Progress Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_MARKING_PERIOD_PROGRESS',
      subHeader: 'Shows progress of a class or group as a whole from marking period to marking period.'
    });


    //// Certification and Achievement
    items.push({
      displayName: 'Word Count Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_WORD_COUNT',
      subHeader: 'Shows how many words each student has read based on Reading Practice, Other Reading, and Article Quizzes.'
    });

    items.push({
      displayName: 'Goal History Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_GOAL_HISTORY',
      subHeader: 'Shows student performance toward goals over time.'
    });

    items.push({
      displayName: 'Goals Met Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_GOALS_MET',
      subHeader: 'Shows students who met/did not meet goals.'
    });

    items.push({
      displayName: 'Point Club Summary Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_POINT_CLUB_SUMMARY',
      subHeader: 'Lists students who qualify for point clubs.'
    });

    items.push({
      displayName: 'Certificates for Student Achievement Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_CERTS_FOR_ACHIEVEMENT',
      subHeader: 'Provides certificates to recognize student achievement of new Reader Certification levels.'
    });

    items.push({
      displayName: 'Certificate Levels Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_CERT_LEVELS',
      subHeader: 'Shows the Reader Certification levels each student has achieved within Accelerated Reader.'
    });

    //// Management Reports
    items.push({
      displayName: 'Track Student Work Dashboard',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_TRACK_STUDENT_WORK',
      subHeader: 'Shows the status of your assignments and assessments and what action you need to take (such as scoring or review).'
    });

    items.push({
      displayName: 'Book Labels Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_BOOK_LABELS',
      subHeader: 'Lists printable book labels.'
    });

    items.push({
      displayName: 'Quiz List Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_QUIZ_LIST',
      subHeader: 'Provides all information about selected quizzes.'
    });

    //// Mastery Status Reports
    items.push({
      displayName: 'Monitor Student Mastery Dashboard (Reading)',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_MONITOR_MASTERY',
      subHeader: 'Summarizes students\' mastery of domains, standards, skills, and sub-skills based on data from Star tests, Accelerated Reader 360, AMIP, and Star Custom'
    });

    items.push({
      displayName: 'Skills Status Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_SKILLS_STATUS',
      subHeader: 'Shows student status for each skill'
    });

    items.push({
      displayName: 'Student Mastery Record Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_STUDENT_MASTERY_RECORD',
      subHeader: 'Lists all skills in Beginning, Developing, and Secured mastery, including average performance on each skill'
    });

    items.push({
      displayName: 'Words Learned Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_WORDS_LEARNED',
      subHeader: 'Lists all the words answered correctly on Vocabulary Practice Quizzes for each selected student.'
    });

    items.push({
      displayName: 'Ranking Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_RANKING',
      subHeader: 'Shows a ranking of students based on skills mastered or scores.'
    });

    //// Quiz Usage
    items.push({
      displayName: 'Quiz Takers Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_QUIZ_TAKERS',
      subHeader: 'Shows students\' activity on selected Reading Practice Quizzes, Article Quizzes, and Other Reading Quizzes within a selected time period.'
    });

    items.push({
      displayName: 'Reading Trends Dashboard',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777100',
      deepLinkId: 'RGP_READING_TRENDS',
      subHeader: 'Shows the top and bottom results for the number of quizzes taken, quiz ratings for Reading Practice Quizzes, or percent correct among selected students.'
    });

    //// Parent Reports
    items.push({
      displayName: 'Practice Parent Report',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777106',
      deepLinkId: 'RGP_READING_PRACTICE_PARENT',
      subHeader: 'Provides a printable student progress report for parents or guardians; available in English and Spanish.'
    });

    items.push({
      displayName: 'Home Connect Send Home Letter',
      imagePrefix: 'ar_logo',
      applicationId: '7771',
      taskId: '777106',
      deepLinkId: 'RGP_READING_HOME_CONNECT_SEND_HOME',
      subHeader: 'Provides information to parents that allows parents and students to log in to the software from home using Home Connect.'
    });

    // Star reports

    //// Screening and Instructional Planning
    items.push({
      displayName: 'Star Screening Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_SCREENING',
      subHeader: 'Graphs students\' placement above and below benchmark based on Star scores'
    });

    items.push({
      displayName: 'Star Record Book',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_RECORD_BOOK',
      subHeader: 'Shows assessment scores for Star Reading, Star Early Literacy, and Star Math. This also includes scores for any state summative test data that you have imported.'
    });

    items.push({
      displayName: 'Instructional Planning - Student Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_INSTRUCT_PLANNING_STUDENT',
      subHeader: 'Provides a list of recommended skills for individualized instruction based on the most recent student activity'
    });

    items.push({
      displayName: 'Instructional Planning - Class Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_INSTRUCT_PLANNING_CLASS',
      subHeader: 'Provides a list of recommended skills for for a group or class based on the most recent student activity'
    });

    items.push({
      displayName: 'Star Goal History Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_GOAL_HISTORY',
      subHeader: 'Shows student performance towards goals over time'
    });

    //// Growth and Progress
    items.push({
      displayName: 'Star Growth Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_GROWTH',
      subHeader: 'Shows growth for a group of students over time, with emphasis on SGP - Student Growth Percentile'
    });

    items.push({
      displayName: 'Star Student Progress Monitoring Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STUDENT_PROGRESS_MONITORING',
      subHeader: 'Graphs an individual student\'s progress towards Star goals.'
    });

    items.push({
      displayName: 'Star Annual Progress Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_ANNUAL_PROGRESS',
      subHeader: 'Graphs student progress over a school year.'
    });

    items.push({
      displayName: 'Star Test Record Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_TEST_RECORD',
      subHeader: 'Shows individual results for tests taken within a specific date range.'
    });

    items.push({
      displayName: 'Star Growth Proficiency',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_GROWTH_PROFICIENCY',
      subHeader: 'Plots Student Growth Percentiles SGP and proficiency on a quadrant draft; companion to the Growth Report'
    });

    //// State Performancy and Mastery
    items.push({
      displayName: 'Star State Performance - Student Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STATE_PERFORMANCE_STUDENT',
      subHeader: 'Graphs a student\'s pathway to proficiency on the state summative test.'
    });

    items.push({
      displayName: 'Star State Performance - Class Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STATE_PERFORMANCE_CLASS',
      subHeader: 'Graphs the percentage of students on the pathway to proficiency on the state summative test.'
    });

    items.push({
      displayName: 'State Standards Mastery - Class Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STATE_STANDARDS_MASTERY_CLASS',
      subHeader: 'Shows students\' levels of mastery in relation to state standards based on the students\' most recent assessments and assignments.'
    });

    items.push({
      displayName: 'Monitor Student Mastery Dashboard (Reading)',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_MONITOR_STUDENT_MASTERY_READING',
      subHeader: 'Summarizes students\' mastery of domains, standards, skills, and subskills based on data from Star Early Literacy and Star Reading tests, Accelerated Reader 360, and Star Custom.'
    });

    items.push({
      displayName: 'Monitor Student Mastery Dashboard (Math)',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_MONITOR_STUDENT_MASTERY_MATH',
      subHeader: 'Summarizes students\' mastery of domains, standards, skills, and subskills based on data from Star Math tests, Accelerated Math Instructional Practice, and Star Custom.'
    });

    items.push({
      displayName: 'Star Diagnostic Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_DIAGNOSTIC',
      subHeader: 'Displays individual student scores and test time from a single test.'
    });

    items.push({
      displayName: 'Star ACT®/SAT® Readiness Report (Grades 9-12)',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_ACT_SAT_READINESS',
      subHeader: 'Graphs whether students are on track to reach ACT or SAT college and career readiness benchmarks based on Star scores.'
    });

    //// Test Status and Results
    items.push({
      displayName: 'Star Test Activity Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_TEST_ACTIVITY',
      subHeader: 'Shows students who have and have not tested during a specific date range.'
    });

    items.push({
      displayName: 'Star Summary Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_SUMMARY',
      subHeader: 'Lists scores for a group within a specific date range.'
    });

    items.push({
      displayName: 'Review Results Dashboard',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_REVIEW_RESULTS',
      subHeader: 'Lists student scores and achievement by Lesson Plan, assignment, skill, or item type'
    });

    items.push({
      displayName: 'Track Student Work Dashboard',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_TRACK_STUDENT_WORK',
      subHeader: 'Shows the status of your assignments and assessments and what action you need to take (such as scoring or review).'
    });

    items.push({
      displayName: 'Star Reading Range Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_READING_RANGE',
      subHeader: 'Provides reading range information for each student.'
    });

    //// Star Custom
    items.push({
      displayName: 'Star Student Performance Record Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STUDENT_PERFORMANCE_RECORD',
      subHeader: 'Shows individual student performance on each assignment or quiz.'
    });

    items.push({
      displayName: 'Skills Status Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_SKILLS_STATUS',
      subHeader: 'Shows student status for each skill.'
    });

    items.push({
      displayName: 'Student Mastery Record Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STUDENT_MASTERY_RECORD',
      subHeader: 'Lists all skills in Beginning, Developing, and Secured mastery, including average performance on each skill.'
    });

    items.push({
      displayName: 'Item Responses Dashboard',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_ITEM_RESPONSES',
      subHeader: 'Shows data on responses chosen for assignment items.'
    });

    //// Parent and Other
    items.push({
      displayName: 'Star Parent Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777106',
      deepLinkId: 'RGP_STAR_PARENT',
      subHeader: 'Provides a printable information letter for parents or guardians; available in English and Spanish.'
    });

    items.push({
      displayName: 'Star Family Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_FAMILY',
      subHeader: 'Provides printable informational letter for families, including benchmark level and domain scores, available in English and Spanish.'
    });

    items.push({
      displayName: 'Star Student Report',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_STUDENT',
      subHeader: 'Provides printable information for students about their scaled score, reading/math level, growth percentile, tests scores, and domain scores. Available in English and Spanish.'
    });

    items.push({
      displayName: 'Home Connect Send Home Letter',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_HOME_CONNECT_SEND_HOME',
      subHeader: 'Provides information to parents about how to log in to Home Connect so that they can sign up to receive emails when their children complete a Star test.'
    });

    items.push({
      displayName: '3rd Grade Reading Proficiency Assessment - Student Score',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_THIRD_GRADE_PROFICIENCY_SCORE',
      subHeader: 'Results of the 3rd Grade Reading Gate test.'
    });

    items.push({
      displayName: '3rd Grade Reading Proficiency Assessment - Student Score Summary',
      imagePrefix: 'star_logo',
      applicationId: '7771',
      taskId: '777101',
      deepLinkId: 'RGP_STAR_THIRD_GRADE_PROFICIENCY_SUMMARY',
      subHeader: 'Summary of the 3rd Grade Reading Gate test.'
    });

    // Star CBM reports

    items.push({
      displayName: 'Star CBM Reading - Measure Proficiency',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_READING_PROFICIENCY',
      subHeader: 'Shows the answer to, "How are students performing against benchmarks?" for CBM Reading'
    });
    items.push({
      displayName: 'Star CBM Lectura - Measure Proficiency',
      imagePrefix: 'star_logo',
      applicationId: '66',
      taskId: '6601',
      deepLinkId: 'RGP_CBM_LECTURA_PROFICIENCY',
      subHeader: 'Shows the answer to, "How are students performing against benchmarks?" for CBM Lectura'
    });
    items.push({
      displayName: 'Star CBM Math - Measure Proficiency',
      imagePrefix: 'star_logo',
      applicationId: '52',
      taskId: '5201',
      deepLinkId: 'RGP_CBM_MATH_PROFICIENCY',
      subHeader: 'Shows the answer to, "How are students performing against benchmarks?" for CBM Math'
    });
    items.push({
      displayName: 'Star CBM Reading - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_READING_SCREENING',
      subHeader: 'Shows the answer to, "Are my students being screened?" for CBM Reading'
    });
    items.push({
      displayName: 'Star CBM Lectura - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_LECTURA_SCREENING',
      subHeader: 'Shows the answer to, "Are my students being screened?" for CBM Lectura'
    });
    items.push({
      displayName: 'Star CBM Math - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '66',
      taskId: '6601',
      deepLinkId: 'RGP_CBM_MATH_SCREENING',
      subHeader: 'Shows the answer to, "Are my students being screened?" for CBM Math'
    });
    items.push({
      displayName: 'Star CBM Reading - Participation Rate',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_READING_PARTICIPATION',
      subHeader: 'Shows the answer to, "How many students were assessed on at least one measure?" for CBM Reading'
    });
    items.push({
      displayName: 'Star CBM Lectura - Participation Rate',
      imagePrefix: 'star_logo',
      applicationId: '66',
      taskId: '6601',
      deepLinkId: 'RGP_CBM_LECTURA_PARTICIPATION',
      subHeader: 'Shows the answer to, "How many students were assessed on at least one measure?" for CBM Lectura'
    });

    items.push({
      displayName: 'Star CBM Math - Participation Rate',
      imagePrefix: 'star_logo',
      applicationId: '52',
      taskId: '5201',
      deepLinkId: 'RGP_CBM_MATH_PARTICIPATION',
      subHeader: 'Shows the answer to, "How many students were assessed on at least one measure?" for CBM Math'
    });
    items.push({
      displayName: 'Star CBM Reading - Benchmark Transitions',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_READING_BENCHMARK_TRANSITIONS',
      subHeader: 'Shows the answer to, "Are my interventions working?" for CBM Reading'
    });
    items.push({
      displayName: 'Star CBM Lectura - Benchmark Transitions',
      imagePrefix: 'star_logo',
      applicationId: '66',
      taskId: '6601',
      deepLinkId: 'RGP_CBM_LECTURA_BENCHMARK_TRANSITIONS',
      subHeader: 'Shows the answer to, "Are my interventions working?" for CBM Lectura'
    });
    items.push({
      displayName: 'Star CBM Math - Benchmark Transitions',
      imagePrefix: 'star_logo',
      applicationId: '52',
      taskId: '5201',
      deepLinkId: 'RGP_CBM_MATH_BENCHMARK_TRANSITIONS',
      subHeader: 'Shows the answer to, "Are my interventions working?" for CBM Math'
    });
    items.push({
      displayName: 'Star CBM Reading - Overall Risk Score',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_READING_OVERALL_RISK_SCORE',
      subHeader: 'Shows the answer to, "How many students are at risk overall?" for CBM Reading'
    });
    items.push({
      displayName: 'Star CBM Lectura - Overall Risk Score',
      imagePrefix: 'star_logo',
      applicationId: '66',
      taskId: '6601',
      deepLinkId: 'RGP_CBM_LECTURA_OVERALL_RISK_SCORE',
      subHeader: 'Shows the answer to, "How many students are at risk overall?" for CBM Lectura'
    });
    items.push({
      displayName: 'Star CBM Math - Overall Risk Score',
      imagePrefix: 'star_logo',
      applicationId: '52',
      taskId: '5201',
      deepLinkId: 'RGP_CBM_MATH_OVERALL_RISK_SCORE',
      subHeader: 'Shows the answer to, "How many students are at risk overall?" for CBM Math'
    });
    items.push({
      displayName: 'Star CBM Record Book',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_RECORD_BOOK',
      subHeader: 'The Star CBM English Assessments tab of Record Book is the hub of all Star CBM administration actions and resulting data. '
    });
    items.push({
      displayName: 'Star CBM Student Details page',
      imagePrefix: 'star_logo',
      applicationId: '51',
      taskId: '5101',
      deepLinkId: 'RGP_CBM_STUDENT_DETAILS',
      subHeader: 'View of all assessments for a student, organized by those tied to goals and other measures for which no goal is set'
    });

    // Star Adaptive Insights reports

    items.push({
      displayName: 'Star Adaptive Data Insights',
      imagePrefix: 'star_logo',
      applicationId: '4',
      taskId: '409',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS',
      subHeader: 'Star Adaptive Data Insights tiles are an interactive dashboard that answers key questions.'
    });
    items.push({
      displayName: 'Star Reading - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '4',
      taskId: '409',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_READING',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Reading'
    });
    items.push({
      displayName: 'Star Reading Spanish - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '35',
      taskId: '3503',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_READING_SPANISH',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Reading Spanish'
    });
    items.push({
      displayName: 'Star Early Literacy - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '7',
      taskId: '708',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_EARLY_LIT',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Early Literacy'
    });
    items.push({
      displayName: 'Star Star Early Literacy Spanish - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '35',
      taskId: '3503',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_EARLY_LIT_SPANISH',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Early Literacy Spanish'
    });
    items.push({
      displayName: 'Star Math - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '5',
      taskId: '508',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_MATH',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Math'
    });
    items.push({
      displayName: 'Star Math Spanish - Screening Rate',
      imagePrefix: 'star_logo',
      applicationId: '35',
      taskId: '3503',
      deepLinkId: 'RGP_STAR_ADAPTIVE_INSIGHTS_MATH_SPANISH',
      subHeader: 'Sortable list of schools and the screening rates of their students for Star Math Spanish'
    });

    // Myon reports
    items.push({
      displayName: 'Activity Dashboard',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS',
      subHeader: 'Dashboard that shows faculty information about their roster, groups, students, their students\' placement scores, and their students\' reading in myON'
    });

    items.push({
      displayName: 'Core Report',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_CORE',
      subHeader: 'Monitor student activity and progress with this broadly scoped report. A great place to take the general pulse or identify areas to dig deeper with other reports.'
    });

    items.push({
      displayName: 'Quizzes',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_QUIZZES',
      subHeader: 'Expanded quiz reporting now tells you more than just who took quizzes and how they scored. New metrics reveal question type proficiency and other details from your students’ quizzing activity.'
    });

    items.push({
      displayName: 'Reading Habits',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_TIME_OF_DAY',
      subHeader: 'Students can access myON all day, every day. Use this report to discover when they’re reading – and measure how much they’re reading in and out of school!'
    });

    items.push({
      displayName: 'Timeline',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_TIMELINE',
      subHeader: 'Discover patterns and trends over time on a variety of myON metrics. Learn more than just what students have done – Uncover where they’re going!'
    });

    items.push({
      displayName: 'Most Popular Books',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_CONTENT',
      subHeader: 'Discover what your kids like reading! Knowing what they choose to read and how they invest their reading time can help you design more effective projects and recommend books they’re sure to love!'
    });

    items.push({
      displayName: 'Most Popular Categories',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_POPULAR_CATEGORIES',
      subHeader: 'Uncover the hidden interests of your students by learning their most selected book category choices. Will it be Adventure - Or Chapter Books - Or Monsters and mysteries? You might be surprised!'
    });

    items.push({
      displayName: 'Reading Details',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_DETAILS',
      subHeader: 'Delve a little deeper into your students’ reading data. With new detail metrics and corresponding goals, this report may uncover some very interesting information about your readers!'
    });

    items.push({
      displayName: 'myON with Star',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_MYON_WITH_STAR',
      subHeader: 'Use this report to discover which of your students have connected myON to Star Reading and see all their latest Star Reading assessment results.'
    });

    items.push({
      displayName: 'myON Placement Growth Report',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_PLACEMENT_GROWTH',
      subHeader: 'What are your students’ current reading levels? Are your students growing? Use this report to see your students’ growth and most recent placement.'
    });

    items.push({
      displayName: 'On-Target Reading',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_ON_TARGET',
      subHeader: 'What level of books are your kids choosing to read? Are they too low, too high or just right? This report spotlights students’ book choices to inform you where reading activity is occurring.'
    });

    items.push({
      displayName: 'Extended User Activity',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_EXTENDED_ACTIVITY',
      subHeader: 'Need to know your current students\' myON activity prior to the start of this school year? This report can give you a look at their growth and accomplishments throughout their myON journey.'
    });

    items.push({
      displayName: 'News Content',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_NEWS_CONTENT',
      subHeader: 'Shows which categories and articles are most liked, and which are read the longest. This report also gives you information on myON News quiz results.'
    });

    items.push({
      displayName: 'News Reading',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_NEWS',
      subHeader: 'Shows how many articles students have read, along with time spent reading articles. When you generate this report, you can set goals for the percentage of days that students read myON News and the average number of minutes per week spent reading myON News.'
    });

    items.push({
      displayName: 'Lexiles',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
      deepLinkId: 'MYON_REPORTS_LEXILE',
      subHeader: 'If your license includes Lexile® assessments, this report identifies students that haven\'t taken a benchmark exam as well as those that are off track to meet the yearly growth goal. When you generate this report, you can set goals for the average growth per Lexile® test and the yearly growth trajectory; enter the number of Lexile® levels that you expect students to grow.'
    });

    // Freckle reports
    items.push({
      displayName: 'Math Levels',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'math-diagnostic-report',
      subHeader: 'View and edit starting pretest levels, current levels, and growth data across domains.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Report Cards',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'report-card-summaries',
      subHeader: 'See each student\'s math performance by domain.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Performance by Standard',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'math-standards-report',
      subHeader: 'View class performance on specific standards.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Student Goal Setting',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'student-goals',
      subHeader: 'View status and progress of your students\' goals.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Class Grouping',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'class-grouping-report',
      subHeader: 'Group students by their level (same or mixed).',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Skills Progress',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'math-skills-progress-report',
      subHeader: 'See which skills students have mastered in Focus Skills and High School Courses.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Assessments',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'math-assessment-growth',
      subHeader: 'Track student growth across assessments.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Fact Practice',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'fact-practice-reports',
      subHeader: 'Monitor student fact mastery.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'ELA Levels',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/reports/levels',
      subHeader: 'View student levels in Reading and Word Study to track growth.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'ELA Report Cards',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/report-cards',
      subHeader: 'A summary of each student\'s reading and word study performance.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Student Goal Setting',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/student-goals',
      subHeader: 'View status and progress of your students\' goals.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Performance by Standard',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/reports/performance-by-standard',
      subHeader: 'Use this to see how your class is performing on grade level standards.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Class Grouping',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/groups-report',
      subHeader: 'Your students grouped by reading level or skill.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Word Study Matrix',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/word-study-matrix',
      subHeader: 'A matrix of each student\'s word study level.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Assessments Report',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'ela/reports/assessments',
      subHeader: 'Assessment performance for your classes and students.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Article & Story Activity',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'session-history/ela-article',
      subHeader: 'See which ELA articles your students have read, at what level, and how well they\'re comprehending them.',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Social Studies Student Activity',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'session-history/social-studies',
      subHeader: 'See student performance on Social Studies articles',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    items.push({
      displayName: 'Science Student Activity',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
      url: 'session-history/science',
      subHeader: 'See student performance on Science articles',
      tileLinkType: TileLinkType.FRECKLE_REPORTS_PAGE
    });

    //lalilo reports
    items.push({
      displayName: 'Lesson Progress',
      imagePrefix: 'lalilo_logo',
      applicationId: this.AppId.lalilo,
      taskId: '6200',
      url: 'lesson-progress',
      subHeader: "Shows extensive data about students' proficiency on all Lalilo's Scope & Sequence",
      tileLinkType: TileLinkType.LALILO_REPORTS_PAGE
    });

    items.push({
      displayName: 'Placement Test Result',
      imagePrefix: 'lalilo_logo',
      applicationId: this.AppId.lalilo,
      taskId: '6200',
      url: 'students-progress',
      subHeader: "Shows where students are placed in Lalilo's progression after taking a Star Asssessment or Lalilo's placement test",
      tileLinkType: TileLinkType.LALILO_REPORTS_PAGE
    });

    items.push({
      displayName: 'Assignment Report',
      imagePrefix: 'lalilo_logo',
      applicationId: this.AppId.lalilo,
      taskId: '6200',
      url: 'assignment-report',
      subHeader: "Shows completion and proficiency data on assignments created by the teacher",
      tileLinkType: TileLinkType.LALILO_REPORTS_PAGE
    });

    return items;
  }

  public getMyAppsNavs() {
    let MYAPPS_NAVS_AR: AppNavItem = {
      id: AppNavItemIds.AR,
      displayName: 'AR',
      longDisplayName: 'Accelerated Reader',
      imagePrefix: 'ar_logo',
      applicationId: '1',
      children: this.getArItems()
    }

    let MYAPPS_NAVS_FRECKLE: AppNavItem = {
      displayName: 'Freckle',
      imagePrefix: 'freckle_logo',
      applicationId: '7779',
      taskId: '777900',
    };

    let MYAPPS_NAVS_LALILO: AppNavItem = {
      displayName: 'Lalilo',
      imagePrefix: 'lalilo_logo',
      applicationId: this.AppId.lalilo,
      taskId: '6200',
    };

    let MYAPPS_NAVS_MYON: AppNavItem = {
      displayName: 'myON',
      imagePrefix: 'myon_logo',
      applicationId: '49',
      taskId: '4900',
    };

    let MYAPPS_NAVS_NEARPOD: AppNavItem = {
      displayName: 'Nearpod',
      imagePrefix: 'nearpod_logo',
      url: 'https://nearpod.com/?referrer=ren_next',
    };

    let MYAPPS_NAVS_SCHOOLZILLA: AppNavItem = {
      displayName: 'Schoolzilla',
      imagePrefix: 'schoolzilla_logo',
      applicationId: '56',
      taskId: '4851',
    };

    let MYAPPS_NAVS_STAR: AppNavItem = {
      id: AppNavItemIds.STAR,
      displayName: 'Star',
      longDisplayName: 'Star Assessments',
      imagePrefix: 'star_logo',
      // omitting application ID - multiple star apps with different IDs should map to this group
      children: this.getStarItems()
    };

    let MYAPPS_NAVS_RENU: AppNavItem = {
      id: AppNavItemIds.RENU,
      displayName: 'Renaissance-U',
      imagePrefix: 'ren_u_logo',
      applicationId: '33',
      taskId: '3301',
    }

    let MYAPPS_NAVS: AppNavItem[] =
      [
        // most are alphabetical:
        MYAPPS_NAVS_AR,
        MYAPPS_NAVS_FRECKLE,
        MYAPPS_NAVS_LALILO,
        MYAPPS_NAVS_MYON,
        MYAPPS_NAVS_NEARPOD,
        MYAPPS_NAVS_SCHOOLZILLA,
        MYAPPS_NAVS_STAR,
        // REN U is last
        MYAPPS_NAVS_RENU,
      ];

    return MYAPPS_NAVS;
  }

  public getMyAppsNavs2() {
    let myAppNavs = this.getMyAppsNavs();
    let MYAPPS_NAVS_AR: AppNavItem = {
      id: AppNavItemIds.AR,
      displayName: 'AR',
      longDisplayName: 'Accelerated Reader',
      imagePrefix: 'ar_logo',
      applicationId: '1',
      taskId: '105'
    }

    myAppNavs[0] = MYAPPS_NAVS_AR;

    return myAppNavs;
  }

  private getArItems() {
    let items: AppNavItem[] = []
    items.push({
      displayName: 'Independent Reading',
      subHeader: 'Set goals for books and articles',
      applicationId: '1',
      children: [
        {
          displayName: 'Record Book & Goals',
          applicationId: '1',
          taskId: '105'
        },
        {
          displayName: 'Reports',
          applicationId: '1',
          taskId: '101'
        },
        {
          displayName: 'Manage Books & Quizzes',
          applicationId: '1',
          taskId: '102'
        },
        {
          displayName: 'Share Reading Snapshots',
          applicationId: '1',
          taskId: '106'
        },
        {
          displayName: 'Preferences',
          applicationId: '1',
          taskId: '103'
        },
        {
          displayName: 'Resources',
          applicationId: '1',
          taskId: '104'
        },
      ]
    });

    items.push({
      displayName: 'Manage Content',
      subHeader: 'Books, quizzes, articles or assessments',
      applicationId: '7770',
      taskId: '777000'
    });
    items.push({
      displayName: 'Reading Snapshots',
      subHeader: 'Create and manage shareable snapshots',
      applicationId: '7778',
      taskId: '777800'
    });

    return items;
  }

  private getStarItems() {
    let items: AppNavItem[] = [];
    items.push(
      {
        displayName: 'Star Reading',
        subHeader: 'For Grades K-12',
        applicationId: '4',
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            applicationId: '4',
            taskId: '400'
          },
          {
            displayName: 'Record Book',
            applicationId: '4',
            taskId: '401'
          },
          {
            displayName: 'Data Insights',
            applicationId: '4',
            taskId: '409'
          },
          {
            displayName: 'Reports',
            applicationId: '4',
            taskId: '402'
          },
          {
            displayName: 'Preferences',
            applicationId: '4',
            taskId: '406'
          },
          {
            displayName: 'Resources',
            applicationId: '4',
            taskId: '407'
          },
          {
            displayName: 'Third Grade Reading Proficiency Assessment',
            applicationId: '4',
            taskId: '408'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Early Literacy',
        subHeader: 'For Grades pre-K-3',
        applicationId: '7',
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            applicationId: '7',
            taskId: '700'
          },
          {
            displayName: 'Record Book',
            applicationId: '7',
            taskId: '701'
          },
          {
            displayName: 'Data Insights',
            applicationId: '7',
            taskId: '708'
          },
          {
            displayName: 'Reports',
            applicationId: '7',
            taskId: '702'
          },
          {
            displayName: 'Preferences',
            applicationId: '7',
            taskId: '706'
          },
          {
            displayName: 'Resources',
            applicationId: '7',
            taskId: '707'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Math',
        subHeader: 'For Grades K-12',
        applicationId: '5',
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            applicationId: '5',
            taskId: '500'
          },
          {
            displayName: 'Record Book',
            applicationId: '5',
            taskId: '501'
          },
          {
            displayName: 'Data Insights',
            applicationId: '5',
            taskId: '508'
          },
          {
            displayName: 'Reports',
            applicationId: '5',
            taskId: '502'
          },
          {
            displayName: 'Preferences',
            applicationId: '5',
            taskId: '506'
          },
          {
            displayName: 'Resources',
            applicationId: '5',
            taskId: '507'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Reading',
        subHeader: 'For Grades K-6',
        applicationId: '51',
        children: [
          {
            displayName: 'Record Book & Assessments',
            applicationId: '51',
            taskId: '5100'
          },
          {
            displayName: 'Data Insights',
            applicationId: '51',
            taskId: '5101'
          },
          {
            displayName: 'Caregiver Report',
            applicationId: '51',
            taskId: '5102'
          },
          {
            displayName: 'Preferences',
            applicationId: '51',
            taskId: '5104'
          },
          {
            displayName: 'Resources',
            applicationId: '51',
            taskId: '5105'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Math',
        subHeader: 'For Grades K-3',
        applicationId: '52',
        children: [
          {
            displayName: 'Record Book & Assessments',
            applicationId: '52',
            taskId: '5200'
          },
          {
            displayName: 'Data Insights',
            applicationId: '52',
            taskId: '5201'
          },
          {
            displayName: 'Caregiver Report',
            applicationId: '52',
            taskId: '5202'
          },
          {
            displayName: 'Preferences',
            applicationId: '52',
            taskId: '5204'
          },
          {
            displayName: 'Resources',
            applicationId: '52',
            taskId: '5205'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Lectura',
        subHeader: 'For Grades K-6',
        applicationId: '66',
        children: [
          {
            displayName: 'Record Book & Assessments',
            applicationId: '66',
            taskId: '6600'
          },
          {
            displayName: 'Data Insights',
            applicationId: '66',
            taskId: '6601'
          },
          {
            displayName: 'Caregiver Report',
            applicationId: '66',
            taskId: '6602'
          },
          {
            displayName: 'Preferences',
            applicationId: '66',
            taskId: '6604'
          },
          {
            displayName: 'Resources',
            applicationId: '66',
            taskId: '6605'
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Spanish',
        subHeader: 'For Grades 1-12',
        applicationId: '35',
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            applicationId: '35',
            taskId: '3502'
          },
          {
            displayName: 'Record Book',
            applicationId: '35',
            taskId: '3501'
          },
          {
            displayName: 'Data Insights',
            applicationId: '35',
            taskId: '3503'
          },
          {
            displayName: 'Reports',
            applicationId: '35',
            taskId: '3504'
          },
          {
            displayName: 'Preferences',
            applicationId: '35',
            taskId: '3500'
          },
          {
            displayName: 'Reading Resources',
            applicationId: '35',
            taskId: '3505'
          },
          {
            displayName: 'Early Literacy Resources',
            applicationId: '35',
            taskId: '3506'
          },
          {
            displayName: 'Math Resources',
            applicationId: '35',
            taskId: '3507'
          }
        ]
      }
    );
    items.push({
      displayName: 'Star Preschool',
      subHeader: 'Early Education and Pre-K',
      children: [
        {
          displayName: 'Assessment Dashboard & Assessments',
          applicationId: '68',
          taskId: '6800'
        },
        {
          displayName: 'Resources',
          applicationId: '68',
          taskId: '6801'
        }
      ]
    });
    items.push({
      displayName: 'Star Phonics',
      subHeader: 'For Grades K-6+',
      children: [
        {
          displayName: 'Educator Login',
          applicationId: '69',
          taskId: '6900'
        },
        {
          displayName: 'Student Test Code Page',
          applicationId: '69',
          taskId: '6901'
        }
      ]
    });
    items.push(
      {
        displayName: 'Star Custom',
        subHeader: 'Tests designed for specific skills',
        applicationId: '26',
        taskId: '2600'
      }
    );
    items.push({
      displayName: 'SAEBRS',
      subHeader: 'For Grades K-12',
      applicationId: '71',
      taskId: '2650'
    });
    items.push(
      {
        displayName: 'Planner',
        subHeader: 'Assign articles, quizzes, and assignments',
        applicationId: '34',
        taskId: '3400'
      }
    );

    return items;
  }
}
