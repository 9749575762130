import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, firstValueFrom, of, take } from 'rxjs';


export enum PreferenceKey {
  SavvasEnabled = "NEXT_SAVVAS_ENABLED"
}

export interface UserPreference {
  key: PreferenceKey,
  value: string
}  

@Injectable({
  providedIn: 'root'
})

export class UserPreferenceService {
  private userPreferences: UserPreference[] = [];

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }


  public async getPreferenceValue(preferenceKey: PreferenceKey): Promise<string> {
    if (this.userPreferences.length == 0) {
      await this.initializePreferences();
    }

    const preference = this.userPreferences.find(item => item.key == preferenceKey)
    
    if (!preference) {
      throw new Error(`Unable to locate preference with key ${preferenceKey}`)
    }

    return preference.value;
  }

  private async initializePreferences() {
    if (this.userPreferences.length != 0) {
      return;
    }

    const baseUrl = this.removeTrailingSlash(this.userService.getRgpTeacherPreferencesUrl());
    const clientId = this.userService.getClientId();
    const userId = this.userService.getUserId();

    const url = `${baseUrl}/${clientId}/${userId}`;
    
    let headers = new HttpHeaders({ 'access-token': this.userService.getRawJwt() ?? '' });

    let userPreferenceResponse = await firstValueFrom(this.http.get(url, {
      headers: headers
    }).pipe(
      take(1),
      catchError((error: HttpErrorResponse) => of(null))
    )); 

    if (userPreferenceResponse == null) {
      throw new Error(`could not read preferences for user ${userId}`);
    }

    this.userPreferences = Object.entries(userPreferenceResponse)
    .map(([key, value]) => ({ key, value})) as UserPreference[];
  }

  private removeTrailingSlash(input: string): string {
    return input.endsWith('/') ?
        input.slice(0, -1) :
        input;
  };
}