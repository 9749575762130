import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaunchControlService } from 'src/app/services/launch-control/launch-control.service';
import { ProductAppCodes } from 'src/app/services/product-info/product-info.service';
import { MyONMadeRecommendation } from 'src/app/services/recommendation-v2/myon-made-recommendation/myonMadeRecommendation';
import { ProductKeys, ActionLink } from 'src/app/shared/models/recommendation.model';
import { Link } from 'src/app/util/link/link';
import { ActionClickEvent } from './action-click-event';

@Component({
  selector: 'myon-made-take-action-link',
  templateUrl: './myonMade-take-action-link.component.html',
  styleUrls: ['./take-action-links.component.scss'],
})
export class MyONMadeTakeActionComponent implements OnInit {

  @Input() public recommendation!: MyONMadeRecommendation;
  @Output() public actionClick = new EventEmitter<ActionClickEvent>();
  @Input() public category: string = '';

  public myONLink: ActionLink = {
    description: '',
    imageUrl: '',
    link: {
      url: '',
      type: 'exact',
    },
    name: '',
    type: ProductKeys.MyON
  };

  constructor(
    private launchControlService: LaunchControlService
  ) {
  }

  public ngOnInit(): void {
    this.myONLink = this.recommendation.actionLink;
    this.category = this.category.replace(/'/g, '');    // Sanitize the category to remove any single quotes 
  }
  public navigateMyONByLaunchControl(event: Event, link: Link) {
    event.preventDefault();
    this.launchControlService.launchApp(ProductAppCodes.Myon, link.url, true);
    this.actionClick.emit({ type: 'myONLink', isClicked: true });
  }
}
