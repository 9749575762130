import { DatePipe } from '@angular/common';
import { Component, Input, OnInit  } from '@angular/core';
import { Student } from 'src/app/shared/models/student.model';

@Component({
  selector: 'app-superstar-print',
  templateUrl: './superstar-print.component.html',
  styleUrls: ['./superstar-print.component.scss'],
})
export class SuperstarPrintComponent implements OnInit {
 
  @Input()  public student!: Student;
  @Input()  public svgIcon!: string;
  @Input()  public message!: string;
  @Input()  public teacherName!: string;
  @Input()  public toImgUrl!: string;

  formattedDate: string = '';
      constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const currentDate = new Date();
    this.formattedDate = this.datePipe.transform(currentDate, 'MMMM d, yyyy') ?? '';
  }


  
}
