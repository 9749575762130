import { Injectable } from '@angular/core';
import { ClassProductUsage, StudentContext } from 'src/app/shared/models/student.model';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';
import { SkillAggregate } from 'src/app/util/skill-aggregate/skill-aggregate';
import { ProductAppDisplayNames, ProductIds, ProductIdsByGroup, ProductTypeGroups } from '../../product-info/product-info.service';
import { SkillMetadata } from 'src/app/shared/models/skill-metadata.model';
import { Skill } from 'src/app/shared/models/skill.model';
import { GraphqlService } from '../../graphql/graphql.service';
import { RecommendationType, Recommendation, BaseRecommendationSkill, ProductKeys } from 'src/app/shared/models/recommendation.model';
import { UserService } from '../../user/user.service';
import { FeatureToggleService } from '../../feature-toggle/feature-toggle.service';
import { SkillProgressionMetadata } from '../../graphql/skill-progression-metadata-response';
import { SubjectTypes } from '../../subject/subject-types';
import { OverlapSkillRecommendationService } from '../overlap-skill/overlap-skill-recommendation.service';
import { SubjectService } from '../../subject/subject.service';
import { SkillBasedActionRecommendationService } from '../action/skill-based-action-recommendation.service';

@Injectable({
  providedIn: 'root'
})
export class FreckleRecommendationService {

  constructor(
    private graphqlService: GraphqlService,
    private skillBasedActionRecommendationService: SkillBasedActionRecommendationService,
    private overlapSkillRecommendationService: OverlapSkillRecommendationService,
    private userService: UserService,
    private subjectService: SubjectService,
    private featureToggleService: FeatureToggleService
  ) { }

  public async getFreckleSkillRecommendation(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<Recommendation | null> {
    let freckleSkillRecommendation: Recommendation | null = null;

    // Selected student must have Freckle activity
    if (SkillTests.studentHasPracticeActivity(selectedStudent) && SkillTests.studentHasFreckleActivity(selectedStudent)) {

      // Get highlighted Freckle skill for student
      let freckleSkills = selectedStudent.skills.filter(skill => ProductIdsByGroup.FreckleProductIds.includes(skill.productId));
      let freckleBaseRecommendationSkill = await this.getFreckleBaseRecommendationSkill(freckleSkills);

      if (freckleBaseRecommendationSkill) {

        // Get take action links
        if (freckleBaseRecommendationSkill.recommendationType === RecommendationType.NeedsHelp) {
          let stuckStudents = SkillTests.getStuckStudentsForSkill(allStudents, freckleBaseRecommendationSkill.skillId);
          freckleSkillRecommendation = await this.skillBasedActionRecommendationService.getSkillBasedRecommendation(freckleBaseRecommendationSkill, stuckStudents, selectedStudent.classProductUsage);
        }
        else {
          freckleSkillRecommendation = await this.skillBasedActionRecommendationService.getSkillBasedRecommendation(freckleBaseRecommendationSkill, [selectedStudent], selectedStudent.classProductUsage);
        }

        // Set product ID and rationale/nudge text
        if (freckleSkillRecommendation) {
          freckleSkillRecommendation.productId = this.getFreckleProductIdForSelectedSubject();

          let rationale = this.getRationale(freckleSkillRecommendation, selectedStudent, freckleSkillRecommendation.productActionLinks[ProductKeys.Freckle] != null);
          freckleSkillRecommendation.rationale = rationale;
          freckleSkillRecommendation.nudgeText = { skillsPractice: rationale };
        }
      }
    }

    if (freckleSkillRecommendation != null) {
      freckleSkillRecommendation.skillsPracticeProducts = [ProductAppDisplayNames.Freckle];
    }

    return freckleSkillRecommendation;
  }

  public async getFreckleSkillRecommendationForStudentsWithSkill(students: StudentContext[], skill: Skill, classProductUsage: ClassProductUsage): Promise<Recommendation | null> {
    let freckleSkillRecommendation: Recommendation | null = null;

    let freckleBaseRecommendationSkill = await this.getFreckleBaseRecommendationSkill([skill]);

    if (freckleBaseRecommendationSkill) {

      // Get take action links
      freckleSkillRecommendation = await this.skillBasedActionRecommendationService.getSkillBasedRecommendation(freckleBaseRecommendationSkill, students, classProductUsage);

      if (freckleSkillRecommendation) {
        freckleSkillRecommendation.rationale = this.getRationaleForStudentsWithSkill(freckleSkillRecommendation, students, Object.keys(freckleSkillRecommendation.productActionLinks).length > 0);
      }
    }

    return freckleSkillRecommendation;
  }

  public async getFreckleBaseRecommendationSkill(skills: Skill[]): Promise<BaseRecommendationSkill | null> {
    let recAudit = 'Freckle highlighted skill recommendation audit\n';

    let aggregatedSkills = SkillAggregate.aggregate(skills);
    if (aggregatedSkills.length == 0) {
      return null;
    }

    // Determine which skill to base recommendation
    let stuckSkills = aggregatedSkills.filter(skill => SkillTests.isStuckSkill(skill));

    // Stuck skills -> Needs helping hand
    if (stuckSkills.length !== 0) {

      // filter for targeted and focus skills
      stuckSkills = this.filterSkillsByProductTypeAndFocus(stuckSkills);

      return await this.getFreckleBaseRecommendationSkillForStuck(stuckSkills, recAudit);
    }

    // No stuck skills
    else {
      let relevantSkills = aggregatedSkills.filter(skill => SkillTests.hasThreeOrMoreItems(skill));

      // No skills with sufficient items
      if (relevantSkills.length == 0) {
        return null;
      }

      // Check if working at appropriate level
      let appropriateLevelSkills = relevantSkills.filter(skill => {
        let accuracyRate = SkillTests.getSkillAccuracyRate(skill);
        return SkillTests.isWorkingAtAppropriateLevelBasedOnAccuracyRate(accuracyRate) &&
          !SkillTests.isReadyForChallengeBasedOnAccuracyRate(accuracyRate);
      });

      // Working at an appropriate level
      if (appropriateLevelSkills.length > 0) {
        appropriateLevelSkills = this.filterSkillsByProductTypeAndFocus(appropriateLevelSkills);

        return await this.getFreckleBaseRecommendationSkillForAppropriate(appropriateLevelSkills, recAudit);
      }

      // Ready for a Challenge
      let readyForMoreSkills = this.filterSkillsByProductTypeAndFocus(relevantSkills);
      return this.getFreckleBaseRecommendationSkillForReadyForMore(readyForMoreSkills, recAudit);
    }
  }

  public async getFreckleBaseRecommendationSkillForStuck(stuckSkills: Skill[], recAudit: string): Promise<BaseRecommendationSkill | null> {

    let useNewStandardSet = await this.featureToggleService.isTrueAsync('use-rgp-standard-sets-for-freckle-recommendations');

    if (!useNewStandardSet) {
      let lowestSkill = this.getLowestSkillOnProgressionOld(stuckSkills);

      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: lowestSkill.renaissanceSkillId!,
        recommendationType: RecommendationType.NeedsHelp,
        subject: lowestSkill.subject,
        practiceProductType: lowestSkill.product
      }

      // audit
      recAudit += `Has stuck skills:`;
      for (var stuckSkill of stuckSkills) {
        recAudit += `${stuckSkill.renaissanceSkillId}(${stuckSkill.skillShortName})|`
      }
      recAudit += '\n';
      recAudit += `Needs help - recommending lowest skill among stuck skills:${lowestSkill.renaissanceSkillId}(${lowestSkill.skillShortName})`;
      baseRecommendationSkill.recommendationAudit = recAudit;

      return baseRecommendationSkill;
    }

    // Only one skill and subject math -> base recommendation on skill
    // For subject reading we still need the progression metadata to determine the prerequisite
    if (stuckSkills.length === 1 && stuckSkills[0].subject === SubjectTypes.MATH) {
      let stuckSkill = stuckSkills[0];
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: stuckSkill.renaissanceSkillId!,
        recommendationType: RecommendationType.NeedsHelp,
        subject: stuckSkill.subject,
        practiceProductType: stuckSkill.product
      };
      return baseRecommendationSkill;
    }

    // Get LPS progression metadata for skills
    let stuckSkillsMetadata = await this.getSkillsProgressionMetadata(stuckSkills);

    // No LPS progression metadata for skills -> fallback to most recently practiced
    if (stuckSkillsMetadata.length === 0) {
      stuckSkills.sort((a, b) => this.compareSkillsByDate(a, b));
      let mostRecentPracticedSkill = stuckSkills[stuckSkills.length - 1];

      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: mostRecentPracticedSkill!.renaissanceSkillId!,
        recommendationType: RecommendationType.NeedsHelp,
        subject: mostRecentPracticedSkill!.subject,
        practiceProductType: mostRecentPracticedSkill!.product
      };
      return baseRecommendationSkill;
    }

    // Find lowest skill on progression and recommend
    let lowestSkillMetadata = this.getLowestSkillOnProgression(stuckSkillsMetadata);
    let lowestSkill = stuckSkills.find(skill => this.guidify(skill.renaissanceSkillId) === lowestSkillMetadata.renaissanceSkillId);
    let baseRecommendationSkill: BaseRecommendationSkill = {
      skillId: lowestSkill!.renaissanceSkillId!,
      recommendationType: RecommendationType.NeedsHelp,
      subject: lowestSkill!.subject,
      prerequisiteSkillId: lowestSkillMetadata.prerequisiteSkillId,
      practiceProductType: lowestSkill!.product
    };
    return baseRecommendationSkill;
  }

  public async getFreckleBaseRecommendationSkillForAppropriate(appropriateSkills: Skill[], recAudit: string): Promise<BaseRecommendationSkill | null> {
    let useNewStandardSet = await this.featureToggleService.isTrueAsync('use-rgp-standard-sets-for-freckle-recommendations');
    if (!useNewStandardSet) {
      let lowestSkill = this.getLowestSkillOnProgressionOld(appropriateSkills);
      let lowestSkillAccuracyRate = SkillTests.getSkillAccuracyRate(lowestSkill);

      // audit
      recAudit += `Skills that are working at appropriate level but NOT ready for a challenge - only these are relevant:`;
      for (var appropriateLevelSkill of appropriateSkills) {
        recAudit += `${appropriateLevelSkill.renaissanceSkillId}(${appropriateLevelSkill.skillShortName})|`
      }
      recAudit += '\n';
      recAudit += `Appropriate skill lowest in progression: ${lowestSkill.renaissanceSkillId}(${lowestSkill.skillShortName})-AccuracyRate: ${lowestSkillAccuracyRate}\n`;

      // Working at appropriate level
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: lowestSkill.renaissanceSkillId,
        recommendationType: RecommendationType.Appropriate,
        subject: lowestSkill.subject,
        practiceProductType: lowestSkill.product
      }
      recAudit += `Recommending to continue practice on lowest level skill in domain that is not yet ready for challenge: ${lowestSkill.renaissanceSkillId}(${lowestSkill.skillShortName})})\n`;
      baseRecommendationSkill.recommendationAudit = recAudit;

      return baseRecommendationSkill;
    }

    let appropriateSkillsMetadata = await this.getSkillsProgressionMetadata(appropriateSkills);

    if (appropriateSkillsMetadata.length === 0) {
      appropriateSkills.sort((a, b) => this.compareSkillsByDate(a, b));
      let mostRecentPracticedSkill = appropriateSkills[appropriateSkills.length - 1];

      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: mostRecentPracticedSkill!.renaissanceSkillId!,
        recommendationType: RecommendationType.Appropriate,
        subject: mostRecentPracticedSkill!.subject,
        practiceProductType: mostRecentPracticedSkill!.product
      };
      return baseRecommendationSkill;
    }

    let lowestSkillMetadata = this.getLowestSkillOnProgression(appropriateSkillsMetadata);
    let lowestSkill = appropriateSkills.find(skill => this.guidify(skill.renaissanceSkillId) === lowestSkillMetadata.renaissanceSkillId);
    let baseRecommendationSkill: BaseRecommendationSkill = {
      skillId: lowestSkill!.renaissanceSkillId!,
      recommendationType: RecommendationType.Appropriate,
      subject: lowestSkill!.subject,
      practiceProductType: lowestSkill!.product
    };
    return baseRecommendationSkill;
  }

  public async getFreckleBaseRecommendationSkillForReadyForMore(readyForMoreSkills: Skill[], recAudit: string): Promise<BaseRecommendationSkill | null> {
    let useNewStandardSet = await this.featureToggleService.isTrueAsync('use-rgp-standard-sets-for-freckle-recommendations');

    let highestSkill: Skill;
    let readyForMoreSkillsMetadata: SkillProgressionMetadata[] = [];

    if (useNewStandardSet) {
      readyForMoreSkillsMetadata = await this.getSkillsProgressionMetadata(readyForMoreSkills);
    }

    // No LPS progression metadata for skills or toggle off -> fallback to old progression
    if (!useNewStandardSet || readyForMoreSkillsMetadata.length === 0) {
      highestSkill = this.getHighestSkillOnProgressionOld(readyForMoreSkills);

      let highestSkillAccuracyRate = SkillTests.getSkillAccuracyRate(highestSkill);
      // audit
      recAudit += 'All skills are ready for a challenge - all are relevant.';
      for (var readyForMoreSkill of readyForMoreSkills) {
        recAudit += `${readyForMoreSkill.renaissanceSkillId}(${readyForMoreSkill.skillShortName})|`
      }
      recAudit += '\n';
      recAudit += `Relevant skill highest in progression: ${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})-AccuracyRate: ${highestSkillAccuracyRate}\n`;

      let skillProgressionMetadata: SkillMetadata[] = [];

      skillProgressionMetadata = await this.getSkillProgressionMetadataOld(highestSkill);

      if (skillProgressionMetadata.length === 0) {
        return null;
      }

      let highestSkillIndex = skillProgressionMetadata.findIndex(metadata => {
        if (metadata.standardProgressionOrder == highestSkill.standardSetMetadata?.standardProgressionOrder &&
          metadata.skillProgressionOrder == highestSkill.standardSetMetadata?.skillProgressionOrder) {
          return true;
        }
        return false;
      });

      var baseRecommendationSkill: BaseRecommendationSkill;

      // Freckle skill is Lalilo overlap skill
      // Do not recommend next skill in progression. Recommend same skill
      if (useNewStandardSet) {
        const isFreckleLaliloOverlapSkill = this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(highestSkill.contentActivityId);

        if (isFreckleLaliloOverlapSkill) {
          let baseRecommendationSkill: BaseRecommendationSkill = {
            skillId: highestSkill.renaissanceSkillId,
            recommendationType: RecommendationType.NeedsChallenge,
            subject: highestSkill.subject,
            practiceProductType: highestSkill.product
          };

          return baseRecommendationSkill;
        }
      }

      // Student has reached the end of the progression!
      if (highestSkillIndex === skillProgressionMetadata.length - 1) {
        baseRecommendationSkill = {
          skillId: highestSkill.renaissanceSkillId,
          recommendationType: RecommendationType.EndOfProgression,
          subject: highestSkill.subject,
          practiceProductType: highestSkill.product
        }
        recAudit += `Student is at the end of the progression. Can only recommend highest skill:${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})\n`;
      }

      // Find next skill in the progression to recommend
      else {
        let recommendedSkillMetaData = skillProgressionMetadata[highestSkillIndex + 1];
        baseRecommendationSkill = {
          skillId: recommendedSkillMetaData.renaissanceSkillId,
          recommendationType: RecommendationType.NeedsChallenge,
          subject: highestSkill.subject,
          practiceProductType: highestSkill.product
        }
        recAudit += `Ready for challenge - recommending skill after the highest ready for challenge level skill:${recommendedSkillMetaData.renaissanceSkillId}(${recommendedSkillMetaData.skillShortName})\n`;
      }

      baseRecommendationSkill.recommendationAudit = recAudit;

      return baseRecommendationSkill;
    }

    // Get highest practiced skill in LPS progression
    let highestSkillMetadata = this.getHighestSkillOnProgression(readyForMoreSkillsMetadata);
    highestSkill = readyForMoreSkills.find(skill => this.guidify(skill.renaissanceSkillId) === highestSkillMetadata.renaissanceSkillId)!;

    let highestSkillAccuracyRate = SkillTests.getSkillAccuracyRate(highestSkill);

    // audit
    recAudit += 'All skills are ready for a challenge - all are relevant.';
    for (var readyForMoreSkill of readyForMoreSkills) {
      recAudit += `${readyForMoreSkill.renaissanceSkillId}(${readyForMoreSkill.skillShortName})|`
    }
    recAudit += '\n';
    recAudit += `Relevant skill highest in progression: ${highestSkill.renaissanceSkillId}(${highestSkill.skillShortName})-AccuracyRate: ${highestSkillAccuracyRate}\n`;

    let skillProgressionMetadata = await this.getSkillProgressionMetadata(highestSkill);

    if (skillProgressionMetadata.length === 0) {
      return null;
    }

    // Freckle skill is Lalilo overlap skill
    const isFreckleLaliloOverlapSkill = this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(highestSkill.contentActivityId);

    if (isFreckleLaliloOverlapSkill) {
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: highestSkill.renaissanceSkillId,
        recommendationType: RecommendationType.NeedsChallenge,
        subject: highestSkill.subject,
        practiceProductType: highestSkill.product
      };

      return baseRecommendationSkill;
    }

    let nextSkillInProgression = await this.getNextSkillInProgressionMetadata(highestSkill, highestSkillMetadata.progressionOrder + 1);

    // Student has reached the end of the progression!
    if (nextSkillInProgression == null) {
      recAudit += `Student is at the end of the progression. Nothing to recommend.\n`;
      return null;
    }

    // Find next skill in the progression to recommend
    else {
      let baseRecommendationSkill: BaseRecommendationSkill = {
        skillId: nextSkillInProgression.renaissanceSkillId,
        recommendationType: RecommendationType.NeedsChallenge,
        subject: highestSkill.subject,
        practiceProductType: highestSkill.product
      };
      recAudit += `Ready for challenge - recommending skill after the highest ready for challenge level skill:${nextSkillInProgression.renaissanceSkillId}\n`;
      baseRecommendationSkill.recommendationAudit = recAudit;

      return baseRecommendationSkill;
    }
  }

  public getLowestSkillOnProgressionOld(skills: Skill[]): Skill {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrderOld(a.standardSetMetadata, b.standardSetMetadata))[0];
  }

  public getHighestSkillOnProgressionOld(skills: Skill[]): Skill {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrderOld(a.standardSetMetadata, b.standardSetMetadata))[skills.length - 1];
  }

  public getLowestSkillOnProgression(skills: SkillProgressionMetadata[]): SkillProgressionMetadata {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrder(a, b))[0];
  }

  public getHighestSkillOnProgression(skills: SkillProgressionMetadata[]): SkillProgressionMetadata {
    return skills.sort((a, b) => this.compareSkillsByProgressionOrder(a, b))[skills.length - 1];
  }

  public filterSkillsByProductTypeAndFocus(skills: Skill[]): Skill[] {
    if (skills.find(skill => ProductTypeGroups.TargetedPracticeIds.includes(skill.product))) {
      skills = skills.filter(skill => ProductTypeGroups.TargetedPracticeIds.includes(skill.product));
    }

    if (skills.find(skill => skill.standardSetMetadata?.skillIsFocus === true)) {
      skills = skills.filter(skill => skill.standardSetMetadata?.skillIsFocus === true);
    }
    return skills;
  }

  public getRationale(recommendation: Recommendation, selectedStudent: StudentContext, hasFreckleRecommendedAction: boolean): string {

    // Lalilo overlap skill
    const isFreckleLaliloOverlapSkill = recommendation.skillMetadata?.contentActivityId && this.overlapSkillRecommendationService.isFreckleLaliloOverlapSkill(recommendation.skillMetadata?.contentActivityId);

    if (isFreckleLaliloOverlapSkill) {

      // Student struggling on overlap skill
      if (recommendation.recommendationType == RecommendationType.NeedsHelp) {
        return `${selectedStudent.firstName} is struggling on this skill in Freckle. Based on the skill grade-level, we recommend having ${selectedStudent.firstName} switch to practicing in Lalilo.`;
      }

      // Student not struggling on overlap skill
      else {
        return `Based on the skill grade-level, we recommend having ${selectedStudent.firstName} switch to practicing in Lalilo.`
      }
    }

    // Normal skill
    else {
      if (recommendation.practiceProductType && ProductTypeGroups.TargetedPracticeIds.includes(recommendation.practiceProductType)) {
        if (recommendation.recommendationType == RecommendationType.NeedsHelp) {
          return `${selectedStudent.firstName} is struggling on this skill in Freckle. In addition to reteaching, below are resources for independent prerequisite skill practice and teacher-led instruction in whole or small groups.`;
        }
        if (recommendation.recommendationType == RecommendationType.Appropriate) {
          return `${selectedStudent.firstName} is working hard on this skill in Freckle. You may want to reassign it so they can keep practicing. Below is a resource for teacher-led instruction in whole or small groups that might help.`;
        }
        if (recommendation.recommendationType == RecommendationType.NeedsChallenge) {
          if (hasFreckleRecommendedAction) {
            return `${selectedStudent.firstName} is doing great and may be ready to move on to this skill in Freckle. Below are resources for independent practice and teacher-led instruction in whole or small groups.`;
          }
          return `${selectedStudent.firstName} is doing great and may be ready to move on to this skill. Below are resources for independent practice and teacher-led instruction in whole or small groups.`;
        }
      }
      else {
        if (recommendation.recommendationType == RecommendationType.NeedsHelp) {
          return `${selectedStudent.firstName} is struggling on this skill in Freckle adaptive practice. Encourage them to keep at it! Below is a resource for teacher-led instruction in whole or small groups that may help.`;
        }
        if (recommendation.recommendationType == RecommendationType.Appropriate) {
          return `${selectedStudent.firstName} is working hard on this skill in Freckle. Encourage them to keep at it! Below is a resource for teacher-led instruction in whole or small groups that may help.`;
        }
        if (recommendation.recommendationType == RecommendationType.NeedsChallenge) {
          return `${selectedStudent.firstName} is doing great in their Freckle adaptive practice! Encourage them to keep going!`;
        }
      }
    }

    // default fallback
    return `Based on ${selectedStudent.firstName}'s latest work in Freckle:`;
  }

  public getRationaleForStudentsWithSkill(recommendation: Recommendation, students: StudentContext[], hasRecommendedActions: boolean): string {

    // Add rationale if student(s) practice is:
    // 1. adaptive
    // 2. in Needs Challenge category
    // 3. has no recommended actions
    if (recommendation.practiceProductType &&
      ProductTypeGroups.AdaptivePracticeIds.includes(recommendation.practiceProductType) &&
      recommendation.recommendationType === RecommendationType.NeedsChallenge &&
      !hasRecommendedActions) {

      const rationaleText = 'doing great on their adaptive practice. Encourage them to keep going!';
      const numStudents = students.length;
      if (numStudents == 1) {
        return 'This student is ' + rationaleText;
      }
      else {
        return 'These students are ' + rationaleText;
      }
    }

    // Default fallback
    return '';
  }

  // Sort skill/metadata by standard progression order, then skill progression order
  private compareSkillsByProgressionOrderOld(a: SkillMetadata | undefined, b: SkillMetadata | undefined) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }

    if (a.standardProgressionOrder > b.standardProgressionOrder) {
      return 1;
    }
    if (a.standardProgressionOrder < b.standardProgressionOrder) {
      return -1;
    }

    if (a.skillProgressionOrder > b.skillProgressionOrder) {
      return 1;
    }
    if (a.skillProgressionOrder < b.skillProgressionOrder) {
      return -1;
    }

    return 0;
  }

  private compareSkillsByProgressionOrder(a: SkillProgressionMetadata | undefined, b: SkillProgressionMetadata | undefined) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }

    if (a.progressionOrder > b.progressionOrder) {
      return 1;
    }
    if (a.progressionOrder < b.progressionOrder) {
      return -1;
    }

    return 0;
  }

  private compareSkillsByDate(a: Skill | undefined, b: Skill | undefined) {
    if (typeof a === 'undefined' || typeof b === 'undefined') {
      return 0;
    }

    let aLastPracticedDate = new Date(a.lastPracticedDate);
    let bLastPracticedDate = new Date(b.lastPracticedDate);

    if (aLastPracticedDate > bLastPracticedDate) {
      return 1;
    }
    if (aLastPracticedDate < bLastPracticedDate) {
      return -1;
    }

    return 0;
  }

  private async getSkillProgressionMetadataOld(skill: Skill): Promise<SkillMetadata[]> {
    let standardSetId = skill.standardSetId;
    let domainId = skill.domainId;

    // Copying object since response is immutable
    let skillMetadata = [...(await this.graphqlService.getSkillProgression(standardSetId, domainId)).skillMetadata];
    return skillMetadata.sort((a, b) => this.compareSkillsByProgressionOrderOld(a, b));
  }

  private async getSkillProgressionMetadata(skill: Skill): Promise<SkillProgressionMetadata[]> {
    let standardSets = this.userService.getStandardSets();
    let subject = skill.subject.toLowerCase();
    let readingStandardSet = standardSets.filter(x => x.ContentAreaName.toLowerCase() === subject)[0];
    let response = await this.graphqlService.getBrandedStandardSetToStandardSetMap(readingStandardSet.Id);
    let standardSetId = response.standardSetId;

    // Copying object since response is immutable
    let skillProgressionMetadata = [...await this.graphqlService.getSkillProgressionMetadata(standardSetId, this.guidify(skill.renaissanceSkillId))];

    skillProgressionMetadata = skillProgressionMetadata.sort((a, b) => this.compareSkillsByProgressionOrder(a, b));
    return skillProgressionMetadata;
  }

  private async getNextSkillInProgressionMetadata(skill: Skill, nextProgressionOrder: number): Promise<SkillProgressionMetadata | null> {
    let standardSets = this.userService.getStandardSets();
    let subject = skill.subject.toLowerCase();
    let readingStandardSet = standardSets.filter(x => x.ContentAreaName.toLowerCase() === subject)[0];
    let response = await this.graphqlService.getBrandedStandardSetToStandardSetMap(readingStandardSet.Id);
    let standardSetId = response.standardSetId;

    let skillProgressionMetadata = await this.graphqlService.getSkillProgressionMetadataByProgressionOrder(standardSetId, nextProgressionOrder);

    return skillProgressionMetadata;
  }

  private async getSkillsProgressionMetadata(skills: Skill[]): Promise<SkillProgressionMetadata[]> {
    let standardSets = this.userService.getStandardSets();
    let subject = skills[0].subject.toLowerCase();
    let readingStandardSet = standardSets.filter(x => x.ContentAreaName.toLowerCase() === subject)[0];
    let response = await this.graphqlService.getBrandedStandardSetToStandardSetMap(readingStandardSet.Id);
    let standardSetId = response.standardSetId;

    // Copying object since response is immutable
    let skillProgressionMetadata = [...await this.graphqlService.getSkillsProgressionMetadata(standardSetId, skills.map(x => this.guidify(x.renaissanceSkillId)))];

    skillProgressionMetadata = skillProgressionMetadata.sort((a, b) => this.compareSkillsByProgressionOrder(a, b));
    return skillProgressionMetadata;
  }

  private guidify(renaissanceSkillId: string): string {
    if (!renaissanceSkillId.includes('-') && renaissanceSkillId.length === 32) {
      let guid = renaissanceSkillId.split('');
      guid.splice(8, 0, '-');
      guid.splice(8 + 4 + 1, 0, '-');
      guid.splice(8 + 4 + 4 + 2, 0, '-');
      guid.splice(8 + 4 + 4 + 4 + 3, 0, '-');
      let renSkillGuid = guid.join('');
      return renSkillGuid;
    }
    return renaissanceSkillId;
  }

  private getFreckleProductIdForSelectedSubject(): string {
    return this.subjectService.selectedSubject$.value === SubjectTypes.MATH ?
      ProductIds.FreckleMath : ProductIds.FreckleReading;
  }
}
