import { StudentContext } from "src/app/shared/models/student.model";
import { ProductIds, ProductIdsByGroup, ProductInfoService } from "../../product-info/product-info.service";
import { Injectable } from "@angular/core";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { StarService } from "../../star/star.service";

@Injectable({
  providedIn: 'root'
})
export class SuperstarPerformanceService {
  constructor(
    private productInfoService: ProductInfoService,
    private starService: StarService)
  { }

  public getPerformanceQualifications(student: StudentContext): string[] {
    let qualifications: string[] = [];

    // 3 qualifiers for Performance:
    // Meets or exceeds benchmark on recent Star CAT or CBM within the week
    const meetsBenchmarkQualified = this.qualifyAssessmentProficiency(student);
    if (meetsBenchmarkQualified) {
      qualifications.push(meetsBenchmarkQualified);
    }

    // Weekly accuracy rate at or above 80%
    const practiceAccuracyQualified = this.qualifyPracticeAccuracyRate(student);
    if (practiceAccuracyQualified) {
      qualifications.push(practiceAccuracyQualified);
    }

    // Weekly average AR quiz rate at or above 85%
    const arQuizRateQualified = this.qualifyArQuizRate(student);
    if (arQuizRateQualified) {
      qualifications.push(arQuizRateQualified);
    }

    return qualifications;
  }

  private qualifyAssessmentProficiency(student: StudentContext): string {
    let qualification: string = '';
    let proficientTestNames: string[] = [];

    let lastSevenDaysAssessments = student.assessments.filter(a => DateHelper.isWithinOneWeekOfToday(a.completedDate, true));
    for (let assessment of lastSevenDaysAssessments) {
      if (this.starService.isAssessmentProficient(assessment)) {
        let testName = this.productInfoService.GetProductNameForAssessment(assessment);
        proficientTestNames.push(testName);
      }

    }

    if (proficientTestNames.length > 0) {
      qualification = `Achieved proficiency on ${proficientTestNames.join(', ')}`;
    }

    return qualification;
  }

  private qualifyPracticeAccuracyRate(student: StudentContext): string {
    let qualification: string = '';
    let SKILL_TARGET_ACCURACY = .80;
    let qualifyingProducts: string[] = [];

    let lastSevenDaysSkills = student.skills.filter(skill => DateHelper.isWithinOneWeekOfToday(skill.lastPracticedDate, true));
    for (let practiceProductId of ProductIdsByGroup.PracticeProductIds) {
      let productSkills = lastSevenDaysSkills.filter(skill => skill.productId === practiceProductId);
      let productTotalAttempted = 0;
      let productTotalCorrect = 0;
      for (let skill of productSkills) {
        productTotalAttempted += skill.totalAnswers;
        productTotalCorrect += skill.correctAnswers;
      }
      let productAccuracy = productTotalAttempted > 0 ? productTotalCorrect / productTotalAttempted : 0;
      if (productAccuracy >= SKILL_TARGET_ACCURACY) {
        let productName = this.productInfoService.GetProductName(practiceProductId);
        qualifyingProducts.push(productName);
      }
    }

    if (qualifyingProducts.length > 0) {
      qualification = `Accurate practice in ${qualifyingProducts.join(', ')}`;
    }

    return qualification;
  }

  private qualifyArQuizRate(student: StudentContext): string {
    let qualification: string = '';
    let AR_TARGET_ACCURACY = .85;

    let readingFinishedThisWeek = student.readingPractices.filter(rp => DateHelper.isWithinOneWeekOfToday(rp.mostRecentPracticedDate, true));
    let arQuizzes = readingFinishedThisWeek.filter(rp => rp.product === ProductIds.AR);
    let quizTotalPercentCorrect: number = 0;
    let quizTotalAttempts: number = 0;
    for (let quiz of arQuizzes) {
      quizTotalPercentCorrect += quiz.percentCorrect ?? 0;
      quizTotalAttempts ++;
    }
    let avgPercentCorrect = quizTotalAttempts !== 0 ? quizTotalPercentCorrect / quizTotalAttempts : 0;
    if (avgPercentCorrect >= AR_TARGET_ACCURACY) {
      qualification = `Average Accelerated Reader quiz score of ${Math.round(avgPercentCorrect * 100)}%`;
    }

    return qualification;
  }
}
