import { Component, OnInit } from '@angular/core';
import { FeatureToggleService } from 'src/app/services/feature-toggle/feature-toggle.service';

@Component({
  selector: 'app-search-results-wrapper',
  templateUrl: './search-results-wrapper.component.html',
})
export class SearchResultsWrapperComponent implements OnInit {
  public isV2Enabled: boolean = false;

  constructor(private featureToggleService: FeatureToggleService) {}

  async ngOnInit() {
    this.isV2Enabled = await this.featureToggleService.isTrueAsync('nrd-42-enable-v2-search-architecture');
  }
}
