<div class="needs-helping-hand-header" *ngIf="categoryIsNeedsHelpingHand()">
  <img [src]="alertIconPath" alt="alert" class="alert-dot" aria-label="alert"/>Alerts based on activity within the last two weeks
</div>

<table class="desktop-layout" title="">
  <thead>
    <tr>
      <th  width="18%">
        Student
      </th>
      <th width="24%">
        Skills Practice In Progress
        <div class="product">
          {{skillsPracticeProductsHeader}}
        </div>
      </th>
      <th  width="24%" *ngIf="this.selectedSubjectIsReading()">
        Reading Practice
        <div class="product">
          {{readingPracticeProductsHeader}}
        </div>
      </th>
      <th  width="20%">
        Assessment
        <div class="product">
          {{assessmentProductsHeader}}
        </div>
      </th>
      <th width="15%" *ngIf="showTakeAction">
        Recommended Actions
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let student of students;"
      [category]="category"
      [studentContext]="student"
      [students]="students"
      [classProducts]="classProducts"
      [showTakeAction]="showTakeAction"
      template="desktopLayout"
      student-table-row>
    </tr>
  </tbody>
</table>

<table class="tablet-layout" title="">
  <thead>
    <tr>
      <th>
        Student
      </th>
      <th>
        Skills Practice In Progress
        <div class="product">
          {{skillsPracticeProductsHeader}}
        </div>
      </th>
      <th *ngIf="this.selectedSubjectIsReading()">
        Reading Practice
        <div class="product">
          {{readingPracticeProductsHeader}}
        </div>
      </th>
      <th>
        Assessment
        <div class="product">
          {{assessmentProductsHeader}}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let student of students;"
      [category]="category"
      [studentContext]="student"
      [students]="students"
      [classProducts]="classProducts"
      [showTakeAction]="showTakeAction"
      template="tabletLayout"
      student-table-row>
    </tr>
  </tbody>
</table>

<div class="mobile-layout" title="">
  <ul>
    <li *ngFor="let student of students;"
      [category]="category"
      [studentContext]="student"
      [students]="students"
      [skillsPracticeProductsHeader]="skillsPracticeProductsHeader"
      [readingPracticeProductsHeader]="readingPracticeProductsHeader"
      [assessmentProductsHeader]="assessmentProductsHeader"
      [classProducts]="classProducts"
      [showTakeAction]="showTakeAction"
      template="mobileLayout"
      student-table-row>
    </li>
  </ul>
</div>
