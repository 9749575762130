import { Injectable } from '@angular/core';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';

@Injectable({
  providedIn: 'root'
})
export class ReportSearchFilter {


  constructor() { }

  public removeReportForChicago(searchReportNavs: AppNavItem[]) {
    let hiddenForChicagoList = [
      //AR and Reading Practice
      'RGP_READING_MONITOR_MASTERY',
      'RGP_READING_TRACK_STUDENT_WORK',
      'RGP_READING_STUDENT_MASTERY_RECORD',

      //Star and Assessment
      'RGP_STAR_GOAL_HISTORY',
      'RGP_STAR_MONITOR_STUDENT_MASTERY_READING',
      'RGP_STAR_MONITOR_STUDENT_MASTERY_MATH',
      'RGP_STAR_REVIEW_RESULTS',
      'RGP_STAR_TRACK_STUDENT_WORK',
      'RGP_STAR_ACT_SAT_READINESS',
      'RGP_STAR_READING_RANGE',
      'RGP_STAR_STUDENT_PERFORMANCE_RECORD',
      'RGP_STAR_STUDENT_MASTERY_RECORD',
      'RGP_STAR_STATE_STANDARDS_MASTERY_CLASS',
      'RGP_STAR_ITEM_RESPONSES',
      'RGP_STAR_THIRD_GRADE_PROFICIENCY_SCORE',
      'RGP_STAR_THIRD_GRADE_PROFICIENCY_SUMMARY'
    ];

    return searchReportNavs.filter(x => !hiddenForChicagoList.includes(x.deepLinkId ?? ''));
  }

  public removeReportForCustom(searchReportNavs: AppNavItem[]) {
    let customHiddenReport = [
      'RGP_STAR_REVIEW_RESULTS',
      'RGP_STAR_TRACK_STUDENT_WORK'
    ];

    return searchReportNavs.filter(x => !customHiddenReport.includes(x.deepLinkId ?? ''));

  }

  public getTranslationForFlorida(searchReportNavs: AppNavItem[]) {
    let floridaTranslateList = [{ "id": "RGP_READING_MONITOR_MASTERY", "name": null, "description": "Summarizes students' mastery of strands, benchmarks, skills, and sub-skills based on data from Star tests, Accelerated Reader 360, AMIP, and Star Custom" },
    { "id": "RGP_STAR_MONITOR_STUDENT_MASTERY_READING", "name": null, "description": "Summarizes students' mastery of strands, benchmarks, skills, and sub-skills based on data from Star tests, Accelerated Reader 360, AMIP, and Star Custom" },
    { "id": "RGP_STAR_MONITOR_STUDENT_MASTERY_MATH", "name": null, "description": "Summarizes students' mastery of strands, benchmarks, skills, and sub-skills based on data from Star tests, Accelerated Reader 360, AMIP, and Star Custom" },
    { "id": "RGP_STAR_STATE_STANDARDS_MASTERY_CLASS", "name": "State Benchmarks Mastery - Class Report", "description": "Summarizes students' mastery of the state benchmarks" },
    { "id": "RGP_STAR_FAMILY", "name": null, "description": "Provides printable informational letter for families, including benchmark level and strand scores, available in English and Spanish" },
    { "id": "RGP_STAR_STUDENT", "name": null, "description": "Provides printable information for students about their scaled score, reading/math level, growth percentile, tests scores, and strand scores. Available in English and Spanish." }];

    let floridaTranslateMap = new Map(floridaTranslateList.map(item => [item.id, item]));
    searchReportNavs.forEach(x => {
      let found = floridaTranslateMap.get(x.deepLinkId ?? '');
      if (found) {
        x.displayName = found.name ?? x.displayName;
        x.subHeader = found.description ?? x.subHeader;
      }
    });

    return searchReportNavs;
  }
}