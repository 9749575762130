import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FeatureToggleService } from "src/app/services/feature-toggle/feature-toggle.service";
import { NewRelicInstrumentationService } from "src/app/services/new-relic-instrumentation/new-relic-instrumentation.service";

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(private router: Router,
    private featureToggleService: FeatureToggleService,
    private newRelicService: NewRelicInstrumentationService) { }

  async handleError(error: any): Promise<void> {
    this.newRelicService.noticeError(error);

    if (await this.featureToggleService.isTrueAsync('tj-sabotage-javascript-error')) {
      this.newRelicService.recordHomeDegradedInfo('home-sabotage-toggle');
    }
    else {
      this.newRelicService.recordHomeDegradedInfo(`home-js-error-${error.name}`);
    }

    this.router.navigate(['/error']);

    console.error(error);
  }
}
